import * as c from './constants';


const initialState = {
    user: {},
    opop: {}
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case c.setSearchedUser.success: {
            return {
                ...state,
                user: action.payload
            };
        }
        case c.getOpopById.success: {
            return {
                ...state,
                opop: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducers;
