/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputV2 } from 'components';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { ButtonV2 } from '../../../../../../index';


const DisciplineBlock2 = ({ block2EmptyObj }) => { // 5.9, блок 2
    const { t } = useTranslation('dev-educational-program');
    const b = bem('work-discipline-logistics-tab');
    const { sizeByTypes } = useSelector((state) => state.documents.documentDetails?.[5.5] || {});

    const courseList = useMemo(
        () => (sizeByTypes || [])
            .filter(({ course }, index, items) => course
                && items.findIndex(({ course: foundCourse }) => foundCourse === course) === index)
            .map(({ course }) => ({
                label: course,
                value: course,
            }))
            .sort((item1, item2) => item1.value - item2.value),
        [sizeByTypes]
    );
    const [isCoursesDisabled, setIsCoursesDisabled] = useState(false);
    useEffect(() => setIsCoursesDisabled(!courseList?.length), [courseList]);

    const semesterList = useMemo(
        () => (sizeByTypes || [])
            .filter(({ semester }, index, items) => semester
                && items.findIndex(({ semester: foundSemester }) => foundSemester === semester) === index)
            .map(({ semester }) => ({
                label: semester,
                value: semester,
            }))
            .sort((item1, item2) => item1.value - item2.value),
        [sizeByTypes]
    );
    const [isSemestersDisabled, setIsSemestersDisabled] = useState(false);
    useEffect(() => setIsSemestersDisabled(!semesterList?.length), [semesterList]);

    const intermediateAttestationFormList = useMemo(
        () => (sizeByTypes || [])
            .filter(({ intermediateAttestationForm }) => intermediateAttestationForm)
            .map(({ intermediateAttestationForm }) => ({
                label: intermediateAttestationForm,
                value: intermediateAttestationForm,
            })),
        [sizeByTypes]
    );
    const [isIntermediateAttestationFormsDisabled, setIsIntermediateAttestationFormsDisabled] = useState(false);
    useEffect(() => setIsIntermediateAttestationFormsDisabled(!intermediateAttestationFormList?.length),
        [intermediateAttestationFormList]);

    return (
        <section>
            <h5 className="subtitle">
                {t('block-2')}
            </h5>
            <Form.List name="block2">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({ key, name, ...restField }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                {sizeByTypes?.length > 1 && (
                                                    <Row gutter={32}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'course']}
                                                                label="Курс"
                                                                {...handleFieldEnableStatus(
                                                                    !!courseList?.length,
                                                                    <span>
                                                                        {`${t('fill-in-field-first-form')} 
                                                                    "${t('course')}" 
                                                                    ${t('in-chapter')} `}
                                                                        <Link
                                                                            to={(location) => ({
                                                                                ...location,
                                                                                hash: '#5.5'
                                                                            })}
                                                                        >
                                                                            {t('work-discipline-size')}
                                                                        </Link>
                                                                    </span>,
                                                                    'Курс'
                                                                )}
                                                            >
                                                                <Select
                                                                    className="form-select"
                                                                    size="large"
                                                                    showSearch
                                                                    options={courseList}
                                                                    notFoundContent={t('not-found-content-text')}
                                                                    placeholder="Выберите курс"
                                                                    disabled={isCoursesDisabled}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'semester']}
                                                                label={t('semester')}
                                                                {...handleFieldEnableStatus(
                                                                    !!semesterList?.length,
                                                                    <span>
                                                                        {`${t('fill-in-field-first-form')} 
                                                                    "${t('semester')}" 
                                                                    ${t('in-chapter')} `}
                                                                        <Link
                                                                            to={(location) => ({
                                                                                ...location,
                                                                                hash: '#5.5'
                                                                            })}
                                                                        >
                                                                            {t('work-discipline-size')}
                                                                        </Link>
                                                                    </span>,
                                                                    t('semester')
                                                                )}
                                                            >
                                                                <Select
                                                                    className="form-select"
                                                                    size="large"
                                                                    showSearch
                                                                    options={semesterList}
                                                                    notFoundContent={t('not-found-content-text')}
                                                                    placeholder="Выберите семестр"
                                                                    disabled={isSemestersDisabled}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'certification']}
                                                    label={t('intermediate-attestation-form')}
                                                    {...handleFieldEnableStatus(
                                                        !!intermediateAttestationFormList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('form-attestation')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.5' })}
                                                            >
                                                                {t('work-discipline-size')}
                                                            </Link>
                                                        </span>,
                                                        t('intermediate-attestation-form')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        options={intermediateAttestationFormList}
                                                        placeholder="Выберите одну форму из ранее введенных"
                                                        disabled={isIntermediateAttestationFormsDisabled}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'method']}
                                                    label="Способ проведения промежуточной аттестации"
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder="Введите cпособ проведения"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'register']}
                                                    label="Перечень видов оценочных средств, используемых для промежуточной аттестации по дисциплине (модулю)"
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder="Введите виды оценочных средств"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        {sizeByTypes?.length > 1 && (
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    className="item-plus-action"
                                    onClick={() => add(block2EmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    Добавить еще блок полей для заполнения
                                </ButtonV2>
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineBlock2;
