import axios from 'axios';
import {
    get as oGet,
    post as oPost,
    put as oPut,
    remove as oRemove,
} from '../../utils/request';
/** OData */

const stagegipUrl = process.env.REACT_APP_BACKEND_SERVISE;

export const uploadStudyPlan = (file) => {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
    };
    return axios.post(`${stagegipUrl}/attachment/files/`, { file }, config);
};

export const getDocsListByUserId = (userId, token) => {
    const url = '/list';
    return oGet(`${url}`, {}, stagegipUrl, token);
};

export const getDocsList = (perPage = 20, page = 1) => {
    const url = '/list';

    return oGet(`${url}`, {}, stagegipUrl);
};

export const updateDocById = async ({ payload }) => {
    const token = await window.unione.getUnioneToken();

    const url = '/list';
    const {
        id, data, rpdList, rppList
    } = payload;
    const clone = { ...data };
    Object.keys(clone).forEach(
        (key) => clone[key] === undefined && delete clone[key]
    );

    return oPut(
        `${url}/${id}`,
        JSON.stringify({
            data: clone, rpdList, rppList, status: 'draft', type: 'default'
        }),
        {
            'content-type': 'application/json',
            Authorization: `${token}`
        },
        stagegipUrl
    );
};

export const updateDocUsersById = async (payload) => {
    const token = await window.unione.getUnioneToken();

    const url = '/list';
    const {
        opopId, addUsers
    } = payload;
    const usersId = addUsers.map((item) => item.id);

    return oPut(
        `${url}/${opopId}/developer`,
        JSON.stringify({
            developers: usersId
        }),
        {
            'content-type': 'application/json',
            Authorization: `${token}`
        },
        stagegipUrl
    );
};

export const setMainUser = async ({ payload }) => {
    const token = await window.unione.getUnioneToken();
    const url = '/list';

    const {
        opop, addUsers, user
    } = payload;
    return oPut(
        `${url}/${opop?.id}/user`,
        JSON.stringify({
            userId: user?.id
        }),
        {
            'content-type': 'application/json',
            Authorization: `${token}`
        },
        stagegipUrl
    );
};

export const removeDocById = async ({ payload }) => {
    const url = '/list';
    const { id } = payload;
    const token = await window.unione.getUnioneToken();

    return oRemove(`${url}/${id}`, { Authorization: `${token}` }, stagegipUrl);
};

export const getDocById = ({ payload }, token) => {
    const url = `/list/${payload.id}`;
    return oGet(`${url}`, {}, stagegipUrl, token);
};

export const createDoc = async (data) => {
    const url = '/list';
    const token = await window.unione.getUnioneToken();

    const clone = { ...data.payload };

    Object.keys(clone).forEach(
        (key) => clone[key] === undefined && delete clone[key]
    );

    return oPost(
        `${url}`,
        JSON.stringify({ data: clone, status: 'draft', type: 'default' }),
        {
            'content-type':
                'application/json',
            Authorization: `${token}`
        },
        false,
        stagegipUrl
    );
};

export const createAcademicPlan = async (payload) => {
    const url = '/attachment/files';
    const token = await window.unione.getUnioneToken();

    const data = new FormData();
    data.append('file', payload);
    return oPost(`${url}`, data, {
        Authorization: `${token}`
    }, false, stagegipUrl);
};

export const removeAcademicPlanById = async ({ id }) => {
    const url = `/attachment/files/${id}`;
    const token = await window.unione.getUnioneToken();

    return oRemove(url, { Authorization: `${token}` }, stagegipUrl);
};

export const getAcademicPlansById = async (id) => {
    const url = `/attachment/files/${id}`;
    return oGet(url, { mode: 'no-cors' }, stagegipUrl);
};

export const getDirectoryListMock = async () => {
    const url = 'mock/opop-constructor/directory.json';
    return oGet(url, { mode: 'no-cors' }, '/');
};
