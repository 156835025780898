import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import bem from 'easy-bem';
import { Spin, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import routes from 'utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { IoProvider } from 'socket.io-react-hook';
import { tabData } from '../../utils/data/tabData';
import { setUserAction } from '../../models/auth/actions';
import { getUser, getUserRole } from '../../models/auth/selectors';
import './style.less';


const { TabPane } = Tabs;

const PageWrapper = () => {
    const b = bem('page-wrapper');
    const history = useHistory();
    const dispatch = useDispatch();
    const onTabChange = (route) => history.push(route);
    const role = useSelector(getUserRole);
    const user = useSelector(getUser);
    const location = useLocation();

    useEffect(() => {
        if (!user || !user?.id) {
            return;
        }
    }, [user]);

    useEffect(() => {
        dispatch(setUserAction());
        if (location.pathname === '/') {
            const path = tabData['main-developer']?.[0]?.value;
            history.push(path);
        }
    }, []);

    const roles = [
        'admin-expert',
        'main-expert',
        'opop-developer',
        'main-developer',
        'replication-developer',
        'admin',
    ];

    if (!role) {
        return (
            <div className="spinner">
                <Spin indicator={<LoadingOutlined spin />} />
            </div>
        );
    }

    return (
        <IoProvider>
            <div className={b()}>
                <Tabs
                    animated={false}
                    defaultActiveKey={`/${location.pathname.split('/')[1]}/`}
                    onChange={onTabChange}
                    className="page-nav"
                >
                    {tabData['main-developer']?.map((tab) => (
                        <TabPane tab={tab.label} key={tab?.value} />
                    ))}
                    {/* {
                        <TabPane tab="Конструктор ОПОП" key="/opop-constructor/" />
                    } */}
                </Tabs>
                <div className={b('container')}>{routes}</div>
            </div>
        </IoProvider>
    );
};

export default PageWrapper;
