import React, {
    // useEffect,
    useState
} from 'react';
import {
    Button, Divider, notification, Radio, Row
} from 'antd';
import dayjs from 'dayjs';
import { ReactComponent as UserAddIcon } from 'assets/icons/add-user.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import notFoundImg from 'assets/images/not-found.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    // getOpopByIdAction,
    setAccessIdAction,
    setSearchedUserAction,
    setUserRoleAction
} from '../../../../../models/admin/actions';
import { InputV2, ModalV2, } from '../../../../index';
import { getOpopById, getUser } from '../../../../../models/admin/selectors';
import DeleteBtn from '../../../../delete-btn';
import { updateStatusAction } from '../../../../../models/opop-status/actions';


const MainOPOPDeveloper = ({
    item, t, opopTitle, updatedDate, createdDate, setItemToRemove, openDetails, setItemToManage
}) => {
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState(item.status);
    const [role, setNewRole] = useState('');
    const user = useSelector(getUser);
    const opop = useSelector(getOpopById);
    const openNotificationWithIcon = () => {
        notification.success({
            message: 'Доступ успешно выдан'
        });
    };
    // useEffect(() => {
    //     if (user) {
    //         dispatch(getOpopByIdAction(item._id));
    //         const newRole = user.roles?.[0];
    //         setNewRole(newRole);
    //     }
    // }, [user, setSearchValue]);

    const dispatch = useDispatch();
    const handleSearch = () => {
        dispatch(setSearchedUserAction(searchValue));
    };
    const giveAccess = (id, value) => {
        dispatch(setAccessIdAction(id, [...opop.access, value]));
        openNotificationWithIcon();
    };
    const handleOk = () => {
        dispatch(setUserRoleAction(searchValue, role));
        setSearchValue('');
        setVisible(false);
    };
    const handleCancel = () => {
        setSearchValue('');
        setVisible(false);
    };
    const updateStatus = (id, status) => {
        setStatus(status);
        dispatch(updateStatusAction(id, status));
    };
    const showUsers = () => {
        const users = [];
        let usersString;
        let string;
        let count;
        users.push(`${item.user.firstName} ${item.user.lastName.substring(0, 1)}.`);
        for (let i = 0; i < item.additionalUsers.length; i++) {
            users.push(`${item.additionalUsers[i]?.firstName} ${item.additionalUsers[i]?.lastName.substring(0, 1)}.`);
        }
        if (users.length > 3) {
            string = users.slice(0, 3);
            count = `еще ${users.length - 3}`;
            string.push(count);
            return usersString = string.join(', ');
        }
        return usersString = users.join(', ');
    };
    return (
        <div key={`item-${item.Id}`} className="list-item hover">
            <ModalV2
                width={800}
                cancelText={t('cancelText')}
                okText={t('okText')}
                title="Разработчики ОПОП "
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="search">
                    <InputV2
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={t('edit-modal-placeholder')}
                        size="large"
                        value={searchValue}
                        suffix={(
                            <SearchIcon
                                className="search-icon"
                                onClick={handleSearch}
                            />
                        )}
                    />
                </div>
                {
                    searchValue === user.id
                        ? (
                            <div>
                                <Row justify="space-between" align="middle">
                                    <p>{`${user.lastName} ${user.firstName}`}</p>
                                    <Button
                                        onClick={() => giveAccess(item.id, searchValue)}
                                        type="primary"
                                        ghost
                                    >
                                        Выдать доступ к ОПОП
                                    </Button>
                                </Row>
                                <Divider />
                                <h2>{t('role')}</h2>
                                <Radio.Group
                                    className="radio"
                                    onChange={(e) => setNewRole(e.target.value)}
                                    value={role}
                                >
                                    <Radio value="opop-developer">{t('opop-dev')}</Radio>
                                    <Radio value="replication-developer">{t('replication-developer')}</Radio>
                                </Radio.Group>
                            </div>
                        )
                        : (
                            <div className="not-found">
                                <img src={notFoundImg} alt="" />
                                <p>{t('not-found')}</p>
                            </div>
                        )
                }
            </ModalV2>

            <div className="item-status">
                <Radio.Group
                    // onChange={({ target }) => updateStatus(item.id, target.value)}
                    value={status}
                >
                    <Radio.Button className="item-mode-right" value="draft">{t('draft')}</Radio.Button>
                    {/* <Radio.Button className="item-mode-left" value="completed">{t('done')}</Radio.Button> */}
                </Radio.Group>
            </div>
            <div className="item-content">
                <div className="content-info">
                    <h5
                        className="direction-name"
                        onClick={() => openDetails({ ...item })}
                    >
                        {`Программа ${opopTitle}`}
                    </h5>
                    <p>
                        {t('last-change-date', { date: dayjs(updatedDate || createdDate).format('DD.MM.YYYY') })}
                    </p>
                    <p>
                        <UsersIcon />
                        {showUsers()}
                    </p>
                </div>
                <div className="content-actions">
                    {/* <UserAddIcon className="user-add-action" onClick={() => setItemToManage(item)} /> */}
                    <DeleteBtn
                        zIndex={1000}
                        onClick={() => setItemToRemove({
                            opopTitle, updatedDate, createdDate, id: item.id
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainOPOPDeveloper;
