import React from 'react';
import { List, Select, Tabs } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as GroupPeople } from 'assets/icons/creative.svg';
import {
    ButtonV2, InputV2, ModalV2, PaginationV2
} from '../../../../index';
import DeleteBtn from '../../../../delete-btn';


const { TabPane } = Tabs;
const { Option } = Select;

const myOrganization = ['Белякова Мэри Руслановна', 'Воронова Антонина Георгиевна'];
const otherOrganization = [
    'Белякова Мэри Руслановна',
    'Воронова Антонина Георгиевна',
    'Воронова Антонина Георгиевна',
    'Воронова Антонина Георгиевна',
    'Воронова Антонина Георгиевна'
];


const ReplicationDevModals = ({
    visibleDevelopers, setVisibleDevelopers, t, updatedDate, createdDate, visible, setVisible // todo: fix props
}) => (
    <>
        <ModalV2
            width={800}
            centered
            destroyOnClose
            visible={visibleDevelopers}
            onCancel={() => setVisibleDevelopers(false)}
            footer={<ButtonV2 onClick={() => setVisibleDevelopers(false)} type="primary" ghost>{t('ok')}</ButtonV2>}
            className="main-opop-modal"
        >
            <h5>{t('opop-developers')}</h5>
            <p className="subtitle">{t('program-magistrate')}</p>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('adding-developers')} key="1" className="plug-tab">
                    <GroupPeople />
                    <p>{t('plug')}</p>
                </TabPane>
                <TabPane disabled tab={t('developers-management')} key="2" />
            </Tabs>
        </ModalV2>
        <ModalV2
            width={800}
            centered
            destroyOnClose
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={<ButtonV2 onClick={() => setVisible(false)} type="primary" ghost>{t('ready')}</ButtonV2>}
            className="main-opop-modal"
        >
            <h5>{t('opop-developers')}</h5>
            <p className="subtitle">{t('program-magistrate')}</p>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('adding-developers')} key="1">
                    <div className="search">
                        <InputV2
                            placeholder={t('developer-search')}
                            size="large"
                            suffix={<SearchIcon className="search-icon" />}
                        />
                        <PaginationV2 />
                        <div className="programs-list">
                            <List
                                header={(
                                    <div>
                                        <p className="list-header">{t('full-name')}</p>
                                        <p className="list-name">{t('my-organization')}</p>
                                    </div>
                                )}
                                bordered
                                dataSource={myOrganization}
                                renderItem={(item) => (
                                    <List.Item>
                                        <p className="list-item">{item}</p>
                                        <PlusIcon />
                                    </List.Item>
                                )}
                            />
                            <List
                                header={<p className="list-name">АГГПУ ИМ. В.М. ШУКШИНА</p>}
                                bordered
                                dataSource={otherOrganization}
                                renderItem={(item) => (
                                    <List.Item>
                                        <p className="list-item">{item}</p>
                                        <PlusIcon />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t('developers-management')} key="2">
                    <div className="search">
                        <InputV2
                            placeholder={t('developer-search')}
                            size="large"
                            suffix={<SearchIcon className="search-icon" />}
                        />
                        <PaginationV2 total={5} />
                        <div className="programs-list">
                            <List
                                header={(
                                    <div>
                                        <p className="list-header">{t('full-name')}</p>
                                        <p className="list-name">{t('my-organization')}</p>
                                    </div>
                                )}
                                bordered
                                dataSource={myOrganization}
                                renderItem={(item) => (
                                    <List.Item>
                                        <p className="list-item">{item}</p>
                                        <Select defaultValue={t('opop-developer')} bordered={false}>
                                            <Option value={t('opop-developer')}>{t('opop-developer')}</Option>
                                            <Option value={t('main-opop-developer')}>{t('main-opop-developer')}</Option>
                                        </Select>
                                        <DeleteBtn />
                                    </List.Item>
                                )}
                            />
                            <List
                                header={<p className="list-name">АГГПУ ИМ. В.М. ШУКШИНА</p>}
                                bordered
                                dataSource={otherOrganization}
                                renderItem={(item) => (
                                    <List.Item>
                                        <p className="list-item">{item}</p>
                                        <Select defaultValue={t('opop-developer')} bordered={false}>
                                            <Option value={t('opop-developer')}>{t('opop-developer')}</Option>
                                            <Option value={t('main-opop-developer')}>{t('main-opop-developer')}</Option>
                                        </Select>
                                        <DeleteBtn />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </ModalV2>
    </>
);

export default ReplicationDevModals;
