import * as c from './constants';


/** OIDC */
export const getOIDCInfo = () => ({
    type: c.getOIDCInfo.tries
});

export const getUserOIDC = () => ({
    type: c.getUserOIDC.tries
});

export const saveOidcUserData = (user) => ({
    type: c.saveOidcUserData.success,
    payload: user
});
export const fetchUserData = (token) => ({
    type: c.fetch.success,
    payload: token
});

export const logoutUserOIDC = () => ({
    type: c.loginUserOIDC.tries
});

export const checkUserV2 = (callback) => ({
    type: c.checkUserV2.tries,
    callback
});

/** OData */
export const getUserInfoOdata = () => ({
    type: c.getUserInfoOdata.tries
});

export const setUserOrgOData = (orgId, positionId, org) => ({
    type: c.setUserOrgOData.tries,
    payload: { orgId, positionId, org }
});

export const removeUserOrgOData = () => ({
    type: c.removeUserOrgOData.tries
});
