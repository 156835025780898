/* eslint-disable max-len */
import { getConstant } from '../../utils/constant-factory';


export const getDocsList = getConstant('GET_DOCS_LIST');
export const getDocsListByUserId = getConstant('GET_DOCS_LIST_BY_USER_ID');
export const getDocById = getConstant('GET_DOC_BY_ID');
export const createDoc = getConstant('CREATE_DOC');
export const getDirectoryListMock = getConstant('GET_DIRECTORY_LIST_MOCK');
export const updateDocById = getConstant('UPDATE_DOC_BY_ID');
export const updateDocUsersById = getConstant('UPDATE_DOC_USERS_BY_ID');
export const setMainUser = getConstant('SET_MAIN_USER');
export const removeDocById = getConstant('REMOVE_DOC_BY_ID');


export const setAcademicPlans = getConstant('SET_ACADEMIC_PLANS');
export const createAcademicPlan = getConstant('CREATE_ACADEMIC_PLAN');
export const removeAcademicPlan = getConstant('REMOVE_ACADEMIC_PLAN');
export const setRpdList = getConstant('SET_RPD_LIST');
export const setCurrentRpdIndex = getConstant('SET_CURRENT_RPD_INDEX');
export const setDocLoadingStatus = getConstant('SET_DOC_LOADING_STATUS');
export const setRppList = getConstant('SET_RPP_LIST');
export const setCurrentRppIndex = getConstant('SET_CURRENT_RPP_INDEX');
export const setCurrentDocDetails = getConstant('SET_CURRENT_DOC_DETAILS');
export const setCurrentTabDetails = getConstant('SET_CURRENT_TAB_DETAILS');
export const setDocumentViewerVisible = getConstant('SET_DOCUMENT_VIEWER_VISIBLE');

export const setUploadStudyPlan = getConstant('SET_UPLOAD_STUDY_PLAN');
