import * as c from './constants';
// import { setDigitalForm } from './constants';


const initialState = {
    isMobile: false,
    drawerVisible: true,
    opkForm: null,
    characteristicForm: null,
    digitalForm: null,
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case c.setIsMobile.success: {
            return {
                ...state,
                isMobile: action.payload
            };
        }
        case c.setDrawerVisible.success: {
            return {
                ...state,
                drawerVisible: action.payload
            };
        }
        case c.setOpkForm.success: {
            return {
                ...state,
                opkForm: action.payload
            };
        }
        case c.setСharacteristicForm.success: {
            return {
                ...state,
                characteristicForm: action.payload
            };
        }
        case c.setDigitalForm.success: {
            return {
                ...state,
                digitalForm: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducers;
