// @ts-nocheck
import axios from 'axios';
import {
    get as oGet,
    post as oPost,
    put as oPut,
    remove as oRemove,
} from '../../utils/request';


const url = process.env.REACT_APP_BACKEND_SERVISE;

export const getUserInfo = (token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.get(`${url}/user/token`, config);
};

export const setMainUser2 = async ({ payload }) => {
    const token = await window.unione.getUnioneToken();
    const urls = '/list';
    
    const {
        opop, addUsers, user
    } = payload;
    return oPut(
        `${urls}/${opop?.id}/user`,
        JSON.stringify({
            userId: user?.id
        }),
        {
            'content-type': 'application/json',
            Authorization: `${token}`
        },
        url
    );
};
