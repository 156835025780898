import * as c from './constants';


const initialState = {
    status: '',
    dockList: []
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case c.updateStatus.success: {
            return {
                ...state,
                status: action.payload.status
            };
        }
        case c.getDockList.success: {
            return {
                ...state,
                dockList: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducers;
