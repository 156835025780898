/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, AutoComplete
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import { GenBlock } from 'components/generated-block/generatedBlock';
import expandArr from 'utils/expandArr';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { useXlsxParser } from 'utils/excel-parse';


const CompetenceProfFormBlock = ({ // 2.0, блок 2
    competenceProfEmptyObj, competenceProf = [],
    activityAreaSphere = [], activityTasks = [], activityLaborFunc = [], activityCharacteristic = [],
    profComp = [], profCompFunc = []
}) => {
    const b = bem('competence-prof-form-block');
    const { t } = useTranslation('dev-educational-program');
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser.isLoaded();
    const activityAreaSphereList = useMemo(
        () => (activityAreaSphere || [])
            .filter(({ area }) => area)
            .map(({ area }) => ({
                value: area,
                label: area
            })),
        [activityAreaSphere]
    );
    const [isAreaDisabled, setAreaDisabled] = useState(false);
    useEffect(() => setAreaDisabled(!activityAreaSphereList?.length), [activityAreaSphereList]);

    const disciplineModuleList = useMemo(() => {
        if (xlsxLoaded) {
            const disciplines = xlsxParser?.getDisciplineModules?.() || [];
            return disciplines.map((discipline) => ({
                label: discipline,
                value: discipline,
            }));
        }
        return [];
    }, [xlsxLoaded]);

    const taskTypeList = useMemo(
        () => (activityTasks || [])
            .filter(({ type }) => type)
            .map(({ type, tasks = [] }) => ({
                label: type,
                value: type,
                option: tasks
                    .filter(({ name }) => name)
                    .map(({ name }) => ({
                        value: name,
                        label: name,
                    }))
            })),
        [activityTasks]
    );
    const [isTaskTypeDisabled, setTaskTypeDisabled] = useState(false);
    useEffect(() => setTaskTypeDisabled(!taskTypeList?.length), [taskTypeList]);

    const taskLists = useMemo(
        () => competenceProf
            .map(({ taskType }) => taskTypeList
                .find(({ value }) => value === taskType)?.option || []),
        [competenceProf, taskTypeList]
    );
    const [isTasksDisabled, setTasksDisabled] = useState(
        new Array(competenceProf?.length).fill(false)
    );
    useEffect(() => setTasksDisabled(
        competenceProf.map(({ taskType }, i) => !taskType || !taskLists?.[i]?.length)
    ), [taskLists, competenceProf]);

    const psList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf',
        ])
            .filter(({ psName, psCode }) => psName && psCode)
            .map(({ psName, psCode }) => ({
                label: `${psCode}. ${psName}`,
                value: `${psCode}. ${psName}`,
            })),
        [activityLaborFunc]
    );
    const [isStandardDisabled, setStandardDisabled] = useState(false);
    useEffect(() => setStandardDisabled(!psList?.length), [psList]);

    const otfList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf',
            'codeAndNameOfOtfTf',
        ])
            .filter(({ commonСode, commonName }) => commonСode && commonName)
            .map(({ commonСode, commonName }) => ({
                label: `${commonСode}. ${commonName}`,
                value: `${commonСode}. ${commonName}`
            })),
        [activityLaborFunc]
    );
    const [isCommonLaborFuncDisabled, setCommonLaborFuncDisabled] = useState(false);
    useEffect(() => setCommonLaborFuncDisabled(!otfList?.length), [otfList]);

    const activityLaborFuncList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf',
            'codeAndNameOfOtfTf',
            'laborFunctions',
        ])
            .filter(({ code, name }) => code && name)
            .map(({ code, name }) => ({
                label: `${code}. ${name}`,
                value: `${code}. ${name}`,
            })),
        [activityLaborFunc]
    );
    const [isLaborFuncDisabled, setLaborFuncDisabled] = useState(false);

    useEffect(() => setLaborFuncDisabled(!activityLaborFuncList?.length), [activityLaborFuncList]);

    const activityCharacterisicLaborFuncList = useMemo(
        () => expandArr(activityCharacteristic || [], [
            'commonLaborFuncAndActions',
            'laborFuncAndActions'
        ])
            .filter(({ laborFunc }) => laborFunc)
            .map(({ laborFunc, laborActions }) => ({
                label: laborFunc,
                value: laborFunc,
                option: laborActions,
            })),
        [activityCharacteristic]
    );
    const laborActionLists = useMemo(
        () => (competenceProf || [])
            .map(({ laborFunc = [] }) => laborFunc
                .reduce((acc, currLaborFuncItem) => [
                    ...acc,
                    ...(activityCharacterisicLaborFuncList
                        .find(({ value }) => value === currLaborFuncItem)?.option || []
                        .filter(({ action }) => action)
                         || []
                    ),
                ], [])),
        [competenceProf, activityCharacterisicLaborFuncList]
    );
    const [isLaborActionsDisabled, setLaborActionsDisabled] = useState(
        new Array(competenceProf?.length).fill(false)
    );
    useEffect(() => setLaborActionsDisabled(
        competenceProf.map(({ laborAction }, i) => !laborAction || !laborActionLists?.[i]?.length)
    ), [laborActionLists, competenceProf]);

    const profCompList = useMemo(
        () => (profComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName }) => ({
                label: codeName,
                value: codeName,
            })),
        [profComp]
    );
    const [isCompetenceCodeAndNameDisabled, setCompetenceCodeAndNameDisabled] = useState(false);
    useEffect(() => setCompetenceCodeAndNameDisabled(!profCompList?.length), [profCompList]);

    const idkListP = useMemo(
        () => expandArr(profComp || [], ['list'])
            .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
            .map(({ indicatorCode, indicatorName }) => ({
                value: `${indicatorCode}. ${indicatorName}`,
                label: `${indicatorCode}. ${indicatorName}`,
            })),
        [profComp]
    );
    const [isIndicatorCodeAndNameDisabled, setIndicatorCodeAndNameDisabled] = useState(false);
    useEffect(() => setIndicatorCodeAndNameDisabled(!idkListP?.length), [idkListP]);

    const digitTechsList = useMemo(
        () => (profCompFunc || [])
            .filter(({ codeAndName }) => codeAndName)
            .map(({ codeAndName, codAndNameOfIdk }) => ({
                label: codeAndName,
                value: codeAndName,
                option: expandArr(codAndNameOfIdk || [], [
                    'disciplineNameBlock',
                    'digitTechNameMasteredByDisciplineStudyInternship',
                ])
                    .filter(({ digitTechName }) => digitTechName)
                    .map(({ digitTechName }) => ({
                        value: digitTechName,
                        label: digitTechName,
                    }))
            })), [profCompFunc]
    );

    const digitTechOptionsLists = useMemo(
        () => competenceProf
            .map(({ competenceCodeAndName }) => digitTechsList
                .find(({ value }) => value === competenceCodeAndName)?.option || []),
        [competenceProf, digitTechsList]
    );
    const [isDigitalTechnologyDisabled, setDigitalTechnologyDisabled] = useState(
        new Array(competenceProf?.length).fill(false)
    );
    useEffect(() => setDigitalTechnologyDisabled(
        competenceProf.map(
            ({ competenceCodeAndName }, i) => !competenceCodeAndName || !digitTechOptionsLists?.[i]?.length
        )
    ), [digitTechOptionsLists, competenceProf]);

    return (
        <section className={b()}>
            <h5 className="subtitle">{`${t('block-2')}: ${t('competence-prof')}`}</h5>
            <Form.List name="competenceProf">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('item')} form-items-group`} key={key}>
                                    <Row gutter={32} align="bottom" wrap={false}>
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('professional-activity-area')}
                                                            name={[name, 'area']}
                                                            {...handleFieldEnableStatus(
                                                                !!activityAreaSphereList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('activity-area')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-1')})`}
                                                                </span>,
                                                                t('professional-activity-area'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'choose-professional-activity-area'
                                                                )}
                                                                options={activityAreaSphereList}
                                                                disabled={isAreaDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            label={t('professional-activity-tasks-type')}
                                                            name={[name, 'taskType']}
                                                            {...handleFieldEnableStatus(
                                                                !!taskTypeList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('professional-activity-tasks-type')}" 
                                                                    ${t('block-2')},
                                                                    (${t('module')}
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('professional-activity-tasks-type')
                                                            )}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'enter-professional-activity-tasks-type'
                                                                )}
                                                                options={taskTypeList}
                                                                disabled={isTaskTypeDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('professional-activity-tasks')}
                                                            name={[name, 'tasks']}
                                                            {...handleFieldEnableStatus(
                                                                !!taskLists?.[name]?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('professional-activity-tasks')}" 
                                                                    ${t('block-2')},
                                                                    (${t('module')}
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('professional-activity-tasks')
                                                            )}
                                                            {...handleFieldEnableStatus(
                                                                !!competenceProf?.[name]?.taskType,
                                                                <span>
                                                                    Предварительно необходимо выбрать "Тип задач профессиональной деятельности"
                                                                </span>,
                                                                t('professional-activity-tasks')
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                mode="multiple"
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'enter-professional-activity-tasks'
                                                                )}
                                                                options={taskLists[name]}
                                                                disabled={isTasksDisabled[name]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('professional-standard')}
                                                            name={[name, 'standard']}
                                                            {...handleFieldEnableStatus(
                                                                !!psList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('ps-name-code')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-4')})`}
                                                                </span>,
                                                                t('professional-standard'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-professional-standard')}
                                                                options={psList}
                                                                disabled={isStandardDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('generalized-labor-function')}
                                                            name={[name, 'commonLaborFunc']}
                                                            {...handleFieldEnableStatus(
                                                                !!otfList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('labor-functions-common-name')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-4')})`}
                                                                </span>,
                                                                t('generalized-labor-function'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'select-generalized-labor-function'
                                                                )}
                                                                options={otfList}
                                                                disabled={isCommonLaborFuncDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('labor-function')}
                                                            name={[name, 'laborFunc']}
                                                            {...handleFieldEnableStatus(
                                                                !!activityLaborFuncList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('labor-functions-name')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-4')})`}
                                                                </span>,
                                                                t('labor-function'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                mode="multiple"
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-labor-function')}
                                                                options={activityLaborFuncList}
                                                                disabled={isLaborFuncDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('labor-action')}
                                                            name={[name, 'laborAction']}
                                                            {...handleFieldEnableStatus(
                                                                !!laborActionLists?.[name]?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('labor-actions')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.3'
                                                                        })}
                                                                    >
                                                                        {t('characteristic-prof-activity')}
                                                                    </Link>
                                                                    {`, ${t('block-5')})`}
                                                                </span>,
                                                                t('labor-action'),
                                                            )}
                                                            {...handleFieldEnableStatus(
                                                                !!competenceProf?.[name]?.laborFunc?.length,
                                                                <span>
                                                                    Предварительно необходимо выбрать "Трудовые функции"
                                                                </span>,
                                                                t('labor-action'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                mode="multiple"
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-labor-action')}
                                                                options={laborActionLists?.[name]?.map(({ action }) => ({
                                                                    label: action,
                                                                    value: action,
                                                                }))}
                                                                disabled={isLaborActionsDisabled[name]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            label={t('prof-comp-code-and-name')}
                                                            name={[name, 'competenceCodeAndName']}
                                                            {...handleFieldEnableStatus(
                                                                !!profCompList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('pc-name')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    {`, ${t('block-3')})`}
                                                                </span>,
                                                                t('prof-comp-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'select-prof-comp-code-and-name'
                                                                )}
                                                                options={profCompList}
                                                                disabled={isCompetenceCodeAndNameDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t(
                                                                'competence-achievement-indicator-code-and-name'
                                                            )}
                                                            name={[name, 'indicatorCodeAndName']}
                                                            {...handleFieldEnableStatus(
                                                                !!idkListP?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('pc-achievement-indicator-сode-and-name')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    {`, ${t('block-3')})`}
                                                                </span>,
                                                                t('competence-achievement-indicator-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'choose-competence-achievement-indicator-code-and-name'
                                                                )}
                                                                options={idkListP}
                                                                disabled={isIndicatorCodeAndNameDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('end-to-end-digital-technology')}
                                                            name={[name, 'digitalTechnologies']}
                                                            {...handleFieldEnableStatus(
                                                                !!digitTechOptionsLists?.[name]?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('digit-tech-name-mastered-by-discipline-study-internship')}" 
                                                                    (${t('block-2')}) 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.7'
                                                                        })}
                                                                    >
                                                                        {t('comp-in-digital-technologies-application')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('end-to-end-digital-technology')
                                                            )}
                                                            {...handleFieldEnableStatus(
                                                                !!competenceProf?.[name]?.competenceCodeAndName,
                                                                <span>
                                                                    Предварительно необходимо выбрать "Код и наименование профессиональной компетенции"
                                                                </span>,
                                                                t('end-to-end-digital-technology')
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'select-end-to-end-digital-technologies'
                                                                )}
                                                                options={digitTechOptionsLists?.[name]}
                                                                disabled={isDigitalTechnologyDisabled?.[name]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <div className="item-block-group-3">
                                                    <p className="item-label">
                                                        {t(
                                                            'disciplines-for-studying-end-to-end-digital-technology'
                                                        )}
                                                    </p>
                                                    <GenBlock
                                                        blockFieldName={name}
                                                        blockName="disciplinesForStudying"
                                                        initialValueAdd={
                                                            competenceProfEmptyObj.disciplinesForStudying[0]
                                                        }
                                                        {...restField}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'discipline']}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder={t('enter-discipline-for-studying-end-to-end-digital-technology')}
                                                                size="large"
                                                                showSearch
                                                                options={disciplineModuleList}
                                                            />
                                                        </Form.Item>
                                                    </GenBlock>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(competenceProfEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default CompetenceProfFormBlock;
