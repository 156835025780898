import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';

import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import Select from 'components/select';
import PsOtfTfCodeAndNameSubformBlock from './PS-OTF-TF-code-and-name-subform-block';


const ActivityLaborFuncFormBlock = ({
    activityLaborFuncEmptyObj, activityAreaSphere = [], activityLaborFunc = [], form
}) => {
    const b = bem('activity-labor-func-form-block');
    const { t } = useTranslation('dev-educational-program');

    const areaList = useMemo(() => (activityAreaSphere || [])
        .filter(({ area }) => area)
        .map(({ area }) => ({
            value: area,
            label: area
        })), [activityAreaSphere]);
    
    // TODO: Временное решение. В mobx уже реализован
    useEffect(() => {
        const areas = areaList?.map((item) => item?.value);

        const newActivityLaborFunc = activityLaborFunc?.map((item) => {
            const isExistArea = areas?.includes(item?.area);
            return isExistArea ? item : { ...item, area: '' };
        });

        form.setFieldsValue({ activityLaborFunc: newActivityLaborFunc });
    }, [areaList]);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-4')}: ${t('activity-labor-functions')}`}
            </h5>
            <Form.List name="activityLaborFunc">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('labor-functions-area')}
                                                            name={[name, 'area']}
                                                            {...handleFieldEnableStatus(
                                                                !!areaList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                        "${t('activity-area')}" 
                                                                        (${t('block-1')}) `}
                                                                </span>,
                                                                t('labor-functions-area'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-labor-functions-area')}
                                                                options={areaList}
                                                                disabled={!areaList?.length}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <PsOtfTfCodeAndNameSubformBlock
                                                    fieldName={name}
                                                    codeAndNameOfPsOtfTfEmptyObj={
                                                        activityLaborFuncEmptyObj?.codeAndNameOfPsOtfTf?.[0]
                                                    }
                                                    form={form}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(activityLaborFuncEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default ActivityLaborFuncFormBlock;
