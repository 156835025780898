import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import { PlusOutlined } from '@ant-design/icons';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import { Link } from 'react-router-dom';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { useSelector } from 'react-redux';
import expandArr from 'utils/expandArr';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineControl = ({ disciplineControlEmptyObj }) => { // 5.10, блок 1
    const { t } = useTranslation('dev-educational-program');
    const b = bem('dev-educational-program');
    const { evaluation = [] } = useSelector((state) => state.documents.documentDetails?.['5.9'] || {});

    const materialList = useMemo(
        () => expandArr(evaluation || [], ['list'])
            .filter((item) => item.eval)
            .map((item) => ({
                label: item.eval,
                value: item.eval,
            })),
        [evaluation]
    );
    const [isMaterialDisabled, setIsMaterialDisabled] = useState(false);
    useEffect(() => setIsMaterialDisabled(!materialList?.length), [materialList]);

    const themeList = useMemo(
        () => (evaluation || [])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name,
            })),
        [evaluation]
    );
    const [isThemeDisabled, setIsThemeDisabled] = useState(false);
    useEffect(() => setIsThemeDisabled(!themeList?.length), [themeList]);

    return (
        <section>
            <h5 className="subtitle">
                {t('block-1')}
                : Оценочные материалы для текущего контроля успеваемости
            </h5>
            <Form.List name="disciplineControl">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'material']}
                                                    label="Оценочный материал"
                                                    {...handleFieldEnableStatus(
                                                        !!materialList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first')} 
                                                            "Оценочные средства" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.9' })}
                                                            >
                                                                {t('work-discipline-evaluational')}
                                                            </Link>
                                                            {` (${t('block-1')})`}
                                                        </span>,
                                                        'Оценочный материал'
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Выберите оценочный материал"
                                                        options={materialList}
                                                        disabled={isMaterialDisabled}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'byTheme']}
                                                    label="По теме"
                                                    {...handleFieldEnableStatus(
                                                        !!themeList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first')} 
                                                            "${t('name-dicipline-module')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.9' })}
                                                            >
                                                                {t('work-discipline-evaluational')}
                                                            </Link>
                                                            {` (${t('block-1')})`}
                                                        </span>,
                                                        'По теме'
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Выберите тему"
                                                        options={themeList}
                                                        disabled={isThemeDisabled}
                                                    />
                                                </Form.Item>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="0 0 168px">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'count']}
                                                            label="В количестве"
                                                        >
                                                            <InputV2
                                                                className="form-select"
                                                                size="large"
                                                                placeholder="шт"
                                                                number
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col flex="0 0 168px">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'appNumber']}
                                                            label="Номер приложения"
                                                        >
                                                            <InputV2
                                                                className="form-select"
                                                                size="large"
                                                                number
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(disciplineControlEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineControl;
