import * as c from './constants';


export const getRegistryList = () => ({
    type: c.getRegistryList.tries,
});

export const getCompletedRegistryAction = () => ({
    type: c.getCompletedRegistry.tries
});

export const getProgramList = (query) => ({
    type: c.getProgramList.tries,
    payload: { query }
});

export const getListStatisticAction = () => ({
    type: c.getListStatistic.tries,
});

export const getTableDataAction = (payload) => ({
    type: c.getTableData.tries,
    payload
});

export const setDownloadStatisticAction = (params, id) => ({
    type: c.setDownloadStatistic.tries,
    params,
    id
});
