import React, { useEffect, useState } from 'react';
import { Button, List } from 'antd';
import Search from 'antd/es/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import dw from 'assets/icons/dw.png';
import '../opop-details-strapi/style.less';
import dayjs from 'dayjs';
import xlsx from 'xlsx-js-style';
import { getTableDataApi } from 'models/registry/api';
import {
    groupBy, mapValues, maxBy
} from 'lodash';
import { getStatistic } from '../../models/registry/selectors';
import { ModalV2, PaginationV2 } from '../index';
import { getListStatisticAction } from '../../models/registry/actions';


const commonStat = process.env.REACT_APP_COMMON_STAT;

const headerStyle = {
    font: { sz: 10, bold: true },
    alignment: {
        wrapText: true,
        horizontal: 'center',
        vertical: 'center',
    },
    border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
    },
};

const rowStyle = {
    font: { sz: 10 },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
    },
    border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
    },
};

const headerRow = [
    {
        v: 'Дата скачивания',
        s: headerStyle,
    },
    {
        v: 'Место работы пользователя',
        s: headerStyle,
    },
    {
        v: 'Должность пользователя',
        s: headerStyle,
    },
    {
        v: 'Скачиваемый модуль',
        s: headerStyle,
    },
    {
        v: 'Наименование ОПОП',
        s: headerStyle,
    },
];
const removeDuplicates = (data) => {
    const result = [];
    const obj = mapValues(
        groupBy(data, (item) => item.direction),
        (arr) => arr.reduce((prev, curr) => (prev += curr.count), 0)
    );

    for (const [key, value] of Object.entries(obj)) {
        result.push({ direction: key, count: value });
    }

    return result;
};

const StatisticModal = ({ visible, handleOk, handleCancel }) => {
    const statisticData = useSelector(getStatistic);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(statisticData);
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState(false);
    const sorted = removeDuplicates(statisticData).sort((a, b) => (a.direction.slice(9, a.direction?.length)
        > b.direction.slice(9, a.direction?.length)
        ? 1
        : -1));

    useEffect(() => {
        dispatch(getListStatisticAction());
        setData(sorted.slice(0, pageSize));
    }, [visible, pageSize]);

    const onPageChange = (value, pageSize) => {
        setCurrentPage(value);
        setPageSize(pageSize);
        setData(sorted.slice(11, pageSize));
        if (value === 1) {
            setData(sorted.slice(0, pageSize));
        }
        if (value === 2) {
            setData(sorted.slice(11, pageSize + 10));
        }
        if (value === 3) {
            setData(sorted.slice(21, 30));
        }
        if (value === 4) {
            setData(sorted.slice(31, 40));
        }
    };
    const onReset = () => {
        setPageSize(10);
        setCurrentPage(1);
        setData(sorted.slice(0, 10));
        setSearchValue('');
    };
    const onSearch = () => {
        if (searchValue?.length) {
            // eslint-disable-next-line max-len
            const searchedList = sorted.filter((item) => item.direction.toLowerCase().includes(searchValue.toLowerCase()));
            setData(searchedList);
        } else {
            setData(sorted);
        }
    };

    const onSort = () => {
        setSort(!sort);
        if (sort) {
            setData(sorted.sort((a, b) => b.count - a.count).slice(0, pageSize));
        } else {
            setData(sorted.slice(0, 10));
        }
    };

    const download = (direction) => {
        getTableDataApi(direction).then(({ data }) => {
            const wb = xlsx.utils.book_new();

            const dataRows = data.map((item) => [
                {
                    v: dayjs(item.downloadDate).format('DD.MM.YYYY'),
                    s: rowStyle,
                },
                {
                    v: item.workPlace,
                    s: rowStyle,
                },
                {
                    v: item.position,
                    s: rowStyle,
                },
                {
                    v: item.moduleName,
                    s: rowStyle,
                },
                {
                    v: item.name,
                    s: rowStyle,
                },
            ]);

            const ws = xlsx.utils.aoa_to_sheet([headerRow, ...dataRows]);
            const colsWidth = [
                {
                    wch: maxBy([headerRow, ...dataRows], (item) => item[0].v?.length)[0].v
                        ?.length,
                },
                {
                    wch: maxBy([headerRow, ...dataRows], (item) => item[1].v?.length)[1].v
                        ?.length,
                },
                {
                    wch: maxBy([headerRow, ...dataRows], (item) => item[2].v?.length)[2].v
                        ?.length,
                },
                {
                    wch: maxBy([headerRow, ...dataRows], (item) => item[3].v?.length)[3].v
                        ?.length,
                },
                {
                    wch: maxBy([headerRow, ...dataRows], (item) => item[4].v?.length)[4].v
                        ?.length,
                },
            ];
            ws['!cols'] = colsWidth;

            const fileName = `${dayjs().format('DD.MM.YYYY')} ${direction}`;
            xlsx.utils.book_append_sheet(
                wb,
                ws,
                `Статистика ${dayjs().format('DD.MM.YYYY')}`
            );

            xlsx.writeFile(wb, `${fileName}.xlsx`);
        });
    };

    return (
        <ModalV2
            width={802}
            title={(
                <div className="modal-header">
                    <p className="title">Статистика скачиваний по направлениям</p>
                    <a className="dwn-btn" href={commonStat} download>Общая статистика скачиваний</a>
                </div>
            )}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button ghost type="primary" key="back" onClick={handleCancel}>
                    Закрыть
                </Button>,
            ]}
        >
            <Search
                placeholder="Начните вводить код и наименование направления"
                className="m-20"
                value={searchValue}
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <PaginationV2
                total={sorted?.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={onPageChange}
                onReset={onReset}
            />
            <List
                header={(
                    <div className="modal-row">
                        <p>Код и наименование напраления</p>
                        <p className="count">
                            Кол-во скачиваний
                            <span onClick={onSort}>
                                <img className="dw-icon" src={dw} alt="" />
                            </span>
                        </p>
                    </div>
                )}
                bordered
                dataSource={data}
                renderItem={(item) => (
                    <List.Item key={item.direction}>
                        <div className="modal-row">
                            <p>{item.direction}</p>
                            <p className="count-item">{item.count}</p>
                            <button onClick={() => download(item.direction)}>
                                ...
                                <span className="tooltiptext">
                                    Скачать развернутую статистику
                                </span>
                            </button>
                        </div>
                    </List.Item>
                )}
            />
        </ModalV2>
    );
};

export default StatisticModal;
