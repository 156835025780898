/* eslint-disable max-len */
import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Radio, Col, Row
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { usePlxParser } from 'utils/plx';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '5.4';
const emptyObj = emptyDocDetails[tabKey];


const WorkDisciplinePlaceTab = ({ onNextClick }) => {
    const b = bem('work-discipline-place-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { disciplineType } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);
    const titleInfo = useSelector((state) => state.documents.documentDetails?.[5.1] || {});
    const plxParser = usePlxParser();
    const plxLoaded = plxParser?.isLoaded();


    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'disciplineType') {
                newTabDetails.isElective = '';
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey]
            ? { ...tabDetails?.[tabKey] }
            : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);

    useEffect(() => {
        if (!plxLoaded && !titleInfo?.disciplineCodeAndName) return;
       
        const discType = plxParser?.getDisciplineIs(titleInfo?.disciplineCodeAndName);
        if (!discType) return;

        const isElective = discType === 'элективная';
        form.setFieldsValue({ isElective: isElective ? 'да' : 'нет' });

        if (isElective) {
            const discReference = plxParser?.getDisciplineRefersTo(titleInfo?.disciplineCodeAndName);
            if (discReference === 'Обязательная часть') {
                form.setFieldsValue({ disciplineType: 'обязательной части' });
            }
        }
    }, [plxLoaded, titleInfo?.disciplineCodeAndName, plxParser]);


    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-discipline-place')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <section className={b()}>
                        <Form.Item name="disciplineType" label="Дисциплина относится к">
                            <Radio.Group>
                                <Radio value={t('discipline-type-required')}>{t('discipline-type-required')}</Radio>
                                <Radio value={t('discipline-type-relations')}>{t('discipline-type-relations')}</Radio>
                                <Radio value={t('discipline-type-faculty')}>{t('discipline-type-faculty')}</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {disciplineType === t('discipline-type-relations') || plxLoaded ? (
                            <Form.Item
                                name="isElective"
                                label="Является ли данная дисциплина элективной?"
                            >
                                <Radio.Group>
                                    <Radio value="да">да</Radio>
                                    <Radio value="нет">нет</Radio>
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </section>

                    <Divider />

                    <section className={b()}>
                        <p className="item-label">
                            Изучение дисциплины предполагает предварительное освоение следующей дисциплины из учебного плана
                        </p>
                        <Form.List name="requiredDisciplines">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({
                                            key, name, ...restField
                                        }) => (
                                            <div className={`${b('activity-item')} form-items-group`} key={key}>
                                                <Row
                                                    gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                        >
                                                            <InputV2 placeholder="Введите дисциплину" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(emptyObj.requiredDisciplines[0])}
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить еще дисциплину
                                            </ButtonV2>
                                        </Form.Item>
                                    </div>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <section className={b()}>
                        <p className="item-label">
                            Дисциплина является основополагающей для изучения следующей дисциплины из учебного плана
                        </p>
                        <Form.List name="dependentDisciplines">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({
                                            key, name, ...restField
                                        }) => (
                                            <div className={`${b('task-item')} form-item`} key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                        >
                                                            <InputV2 placeholder="Введите дисциплину" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(emptyObj.dependentDisciplines[0])}
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить еще дисциплину
                                            </ButtonV2>
                                        </Form.Item>
                                    </div>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>

    );
};

export default WorkDisciplinePlaceTab;
