/* eslint-disable dot-notation */
/* eslint-disable camelcase */
import * as c from '../constants';


const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case c.getUserInfoOdata.success: {
            const {
                id,
                name,
                surname,
                middleName,
                personDocuments,
                personContacts,
                personPositionOrganization,
            } = action.payload;

            const docsList = { ...personDocuments, ...personContacts };

            return {
                id,
                firstName: name,
                lastName: surname,
                middleName,
                userName: `${name} ${surname}`,
                email: docsList['Email'],
                phone: docsList['Телефон'],
                snils: docsList['Snils'],
                orgsList: personPositionOrganization,
            };
        }

        case c.setUserOrgOData.success: {
            const { org } = action.payload;
            const { position } = org;

            return {
                ...state,
                orgsList: [{
                    organization: org,
                    position: { ...position, name: position.value }
                }]
            };
        }

        case c.removeUserOrgOData.success: {
            return {
                ...state,
                orgsList: []
            };
        }

        // case c.logoutUser.success: {
        //     return {
        //         ...initialState,
        //     };
        // }

        default:
            return state;
    }
};

export default reducer;
