import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
    exact
    key="all-replication"
    path={['/all-replication', '/all-replication/programs']}
    component={Page}
/>;
