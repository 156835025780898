import React from 'react';
import {
    Checkbox, Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';
import OOCTextEditor from '../../../../../../text-editor';


const ExamQuestions = ({ // 7.4, блок 3
    b, t, examQuestions = [], theoreticalQuestionsEmptyObj, practicalTasksEmptyObj, allowedLiteratureSourcesEmptyObj
}) => (
    <>
        <section>
            <Form.Item
                label={t('exam-questions')}
                name="examQuestions"
            >
                <Checkbox.Group>
                    <Row>
                        <Col>
                            <Checkbox className="title2" value={t('theoretical-questions')}>
                                {t('theoretical-questions')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Checkbox className="title2" value={t('practical-tasks')}>
                                {t('practical-tasks')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Checkbox className="title2" value={t('other')}>
                                {t('other')}
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>

            {examQuestions.includes(t('theoretical-questions')) ? (
                <>
                    <p className="item-label">Теоретический вопрос</p>
                    <Form.List name="theoreticalQuestions">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-item`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                >
                                                    <InputV2
                                                        size="large"
                                                        placeholder={t('enter-question')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(theoreticalQuestionsEmptyObj)}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-question')}
                                    </ButtonV2>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </>
            ) : null}

            {examQuestions.includes(t('practical-tasks')) ? (
                <>
                    <p className="item-label">{t('practice-task')}</p>
                    <Form.List name="practicalTasks">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-item`} key={key}>
                                        <Row
                                            gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                >
                                                    <InputV2
                                                        size="large"
                                                        placeholder={t('enter-practice-task')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(practicalTasksEmptyObj)}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-practice')}
                                    </ButtonV2>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </>
            ) : null}

            {examQuestions.includes(t('other')) ? (
                <Row>
                    <Col flex="1">
                        <Form.Item
                            label={t('other')}
                            name="otherRequirements"
                        >
                            <OOCTextEditor
                                height="210px"
                                placeholder={t('requirements')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}
        </section>
        <Divider />

        <section>
            <p className="items-group-title">{t('order')}</p>
            <Form.Item
                label={t('exam-oop')}
                name="stateExamForm"
            >
                <InputV2
                    placeholder={t('enter-form')}
                />
            </Form.Item>
            <Form.Item
                label={t('description')}
                name="stateExamDescription"
                className="space"
            >
                <OOCTextEditor
                    height="210px"
                    placeholder={t('individual')}
                />
            </Form.Item>
        </section>
        <Divider />

        <section>
            <p className="item-label">{t('list')}</p>
            <Form.List name="allowedLiteratureSources">
                {(fields, { add, remove }) => (
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('item')} form-item`} key={key}>
                                <Row
                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                    align="bottom"
                                    wrap={false}
                                >
                                    <Col flex="1">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                        >
                                            <InputV2
                                                size="large"
                                                placeholder={t('enter-literature')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(allowedLiteratureSourcesEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-literature')}
                            </ButtonV2>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </section>
    </>
);

export default ExamQuestions;
