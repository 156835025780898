// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { TableV2, PaginationV2 } from 'components';
import { ReactComponent as SortSvg } from 'assets/icons/sort-none.svg';
import { ReactComponent as Users } from 'assets/icons/users-primary.svg';
import { ReactComponent as EditSvg } from 'assets/icons/edit-grey.svg';
import {
    Checkbox, Col, Row, Space
} from 'antd';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import EditModal from './editModal';
import { useSelector } from '../../store/use-selector';
import { getDocsList } from '../../models/opop-status/selectors';
import { getDockListAction } from '../../models/opop-status/actions';
import './style.less';
import { InputV2 } from '../index';


const AllOpop = () => {
    const dispatch = useDispatch();
    const path = useLocation();
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const data = useSelector(getDocsList);

    useEffect(() => {
        dispatch(getDockListAction('forReplication'));
    }, [dispatch, path]);
    const [expertKey, setExpertKey] = useState('');

    const showModal = () => {
        setIsEdit(false);
        setEditModalVisible(true);
    };

    const onPageChange = (value, pageSize) => {
        setCurrentPage(value);
        setPageSize(pageSize);
    };


    const columns = [
        {
            key: 'square',
            dataIndex: 'square',
            render: () => (
                <Checkbox />
            )
        },
        {
            title: 'Код и наименование ОПОП',
            key: 'data',
            dataIndex: 'data',
            render: (dt) => (
                <Space>
                    <p className="expert__codeAndName">
                        {dt?.['1.1']?.directionCodeOrName || ''}
                    </p>
                </Space>
            )

        },
        {
            key: 'mainDev',
            dataIndex: 'mainDev',
            title: () => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    Главный разработчик, дата создания
                    {' '}
                    <SortSvg />
                </div>
            ),
            render: () => (
                <div>
                    <h3 className="expert__sender--name">Ситникова Кармелитта Никитевна</h3>
                    <span className="expert__sender--date">12 марта 2022</span>
                </div>
            )
        },
        {
            title: 'Наименование организации',
            key: 'organizationName',
            dataIndex: 'organizationName',
            render: () => (
                <div>
                    <h3 className="expert__organizationName--title">
                        Федеральное Государственное Бюджетное
                        Образовательное
                        Учреждение Высшего Образования "Башкирский
                        Государственный Университет

                    </h3>
                    <span className="expert__organizationName--info">ФГБОУ ВО БАШКИРСКИЙ ГАУ</span>
                </div>
            )
        },
        {
            title: 'Эксперт',
            key: 'expert',
            dataIndex: 'expert',
            render: (text, record) => (
                <div>
                    {
                        record.expertAppointed
                            ? (
                                <div className="expert-edit">
                                    <EditSvg className="show-edit-icon" />
                                    <p>Власова Ж.В.</p>
                                </div>
                            )
                            : (
                                <p
                                    onClick={() => showModal(record.key)}
                                    className="expert__status--confirm"
                                >
                                    Назначить эксперта
                                </p>
                            )
                    }
                </div>
            )
        }
    ];

    const settingEdit = () => {
        setIsEdit(true);
        setEditModalVisible(true);
    };

    return (
        <div>
            <Row className="page-title-box">
                <Col span={10}>
                    <h2 className="page-title">Все ОПОП для тиражирования</h2>
                </Col>
                <Col offset={2} span={12}>
                    <span
                        className="edit-role"
                        onClick={settingEdit}
                    >
                        <Users />
                        <p>Редактирование роли главного разработчика ОПОП для тиражирования</p>
                    </span>
                </Col>
            </Row>
            <div className="search-opop">
                <InputV2
                    placeholder="Поиск по наименованию ОПОП или организации"
                    size="large"
                    suffix={<SearchIcon className="search-icon" />}
                />
            </div>
            <EditModal
                isEdit={isEdit}
                editModalVisible={editModalVisible}
                setEditModalVisible={setEditModalVisible}
            />
            {/* <AllOpopModal */}
            {/*    expertKey={expertKey} */}
            {/*    // tabData={tabData} */}
            {/*    // setTabData={setTabData} */}
            {/*    isModalVisible={isModalVisible} */}
            {/*    setIsModalVisible={setIsModalVisible} */}
            {/* /> */}
            <PaginationV2
                total={data?.length}
                showLeftTotal
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={onPageChange}
            />
            <TableV2
                dataSource={data}
                columns={columns}
                pagination={{
                    total: data?.length,
                    pageSize,
                    current: currentPage,
                    style: { display: 'none' }
                }}
                loading={false}
            />
        </div>
    );
};

export default AllOpop;
