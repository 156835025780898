import * as c from './constants';


export const setSearchedUserAction = (value) => ({
    type: c.setSearchedUser.tries,
    payload: value
});

export const setUserRoleAction = (id, role) => ({
    type: c.setUserRole.tries,
    id,
    role
});

export const setAccessIdAction = (id, accessId) => (
    {
        type: c.setAccessId.tries,
        accessId,
        id
    }
);

export const getOpopByIdAction = (id) => (
    {
        type: c.getOpopById.tries,
        id
    }
);
