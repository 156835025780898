/* eslint-disable max-len */
import {
    call, put, takeLatest
} from 'redux-saga/effects';
import * as c from './constants';
import {
    getRegistryList,
    getProgramList,
    // getCompletedRegistryApi,
    getRegistryListStatisticApi,
    setDownloadStatisticApi,
    getTableDataApi
} from './api';
import { adaptList } from './converters';
import { serverErrorsLogger } from '../../utils/server-errors-logger';
// import { getTableDataAction } from './actions';


function* getRegistryListSaga() {
    const { success, failure } = c.getRegistryList;
    try {
        const token = yield call(window.unione.getUnioneToken);

        const { data } = yield call(getRegistryList, token);
        yield put({ type: success, payload: { list: data } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}


function* getListStatisticSaga() {
    const { success, failure } = c.getListStatistic;
    try {
        const token = yield call(window.unione.getUnioneToken);

        const { data } = yield call(getRegistryListStatisticApi, token);
        yield put({ type: success, payload: { statistic: data } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getTableDataSaga({ payload }) {
    const { success, failure } = c.getTableData;
    try {
        const { data } = yield call(getTableDataApi, payload);
        yield put({ type: success, payload: { tableData: data } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setStatisticDownloadSaga(payload) {
    const { success, failure } = c.setDownloadStatistic;
    try {
        const token = yield call(window.unione.getUnioneToken);

        const { data } = yield call(setDownloadStatisticApi, payload.params, payload.id, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getProgramListSaga(action) {
    const { success, failure } = c.getProgramList;
    try {
        const token = yield call(window.unione.getUnioneToken);

        const { query } = action.payload;
        const { data } = yield call(
            getProgramList, query, token
        );

        yield put({ type: success, payload: { programList: adaptList(data.data) } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

// function* getCompletedRegistrySaga() {
//     const { success, failure } = c.getCompletedRegistry;
//     try {
//         const { data } = yield call(getCompletedRegistryApi);
//         yield put({ type: success, payload: { completedOpops: data } });
//     } catch (e) {
//         serverErrorsLogger(e);
//         yield put({ type: failure, payload: { message: e.message } });
//     }
// }
export default function* rootSaga() {
    yield takeLatest(c.getRegistryList.tries, getRegistryListSaga);
    yield takeLatest(c.getProgramList.tries, getProgramListSaga);
    // yield takeLatest(c.getCompletedRegistry.tries, getCompletedRegistrySaga);
    yield takeLatest(c.getListStatistic.tries, getListStatisticSaga);
    yield takeLatest(c.setDownloadStatistic.tries, setStatisticDownloadSaga);
    yield takeLatest(c.getTableData.tries, getTableDataSaga);
}
