import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeDocById } from 'models/documents/actions';
import dayjs from 'dayjs';
import ButtonV2 from '../../../button-v2';
import './style.less';


const RemoveOPOPModal = ({
    item, setItem
}) => {
    const b = bem('remove-opop-modal');
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation('remove-opop-modal');
    const { opopTitle, createdDate, updatedDate } = item || {};

    useEffect(() => {
        setVisible(!!item);
    }, [item]);

    const onCancel = () => {
        setItem(null);
    };

    const onRemove = async () => {
        await dispatch(removeDocById(item.id));
        onCancel();
    };

    return (
        <Modal
            footer={[
                <ButtonV2
                    onClick={onRemove}
                    type="link"
                >
                    {t('remove-opop')}
                </ButtonV2>,
                <ButtonV2
                    onClick={onCancel}
                    type="primary"
                >
                    {t('cancel')}
                </ButtonV2>
            ]}
            closable={false}
            className={b()}
            title={t('title')}
            visible={visible}
            width="100%"
            centered
        >
            <p>
                {`Вы действительно хотите удалить программу 
                ${opopTitle} (изменена ${dayjs(updatedDate || createdDate).format('DD.MM.YYYY')})?`}
            </p>
            <p>{t('changes-be-removed')}</p>
        </Modal>
    );
};

export default RemoveOPOPModal;
