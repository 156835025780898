/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import bem from 'easy-bem';
import expandArr from 'utils/expandArr';
import Select from 'components/select';
import DeleteBtn from 'components/delete-btn';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { ButtonV2, InputV2 } from '../../../../../../index';
import { GenBlock } from '../../../../../../generated-block/generatedBlock';


const DisciplineLearning = ({ // 5.6, блок 6
    task = [], seminarTypeClasses = [], laboratoryStudies = [], independentWork = [], structure = [],
    contentOfSectionsEmptyObj
}) => {
    const { t } = useTranslation('dev-educational-program');
    const b = bem('dev-educational-program');
    const { generalProfCompFunc = [], profCompFunc = [] } = useSelector((state) => state.documents.documentDetails?.[1.7] || {});

    const technologiesList = useMemo(
        () => [
            ...expandArr(generalProfCompFunc || [], [
                'codAndNameOfIdk',
                'disciplineNameBlock',
                'digitTechNameMasteredByDisciplineStudyInternship'
            ])
                .filter(({ digitTechName }) => digitTechName)
                .map(({ digitTechName }) => ({
                    value: digitTechName,
                    label: digitTechName
                })),
            ...expandArr(profCompFunc || [], [
                'codAndNameOfIdk',
                'disciplineNameBlock',
                'digitTechNameMasteredByDisciplineStudyInternship'
            ])
                .filter(({ digitTechName }) => digitTechName)
                .map(({ digitTechName }) => ({
                    value: digitTechName,
                    label: digitTechName
                })),
        ],
        [generalProfCompFunc, profCompFunc]
    );
    const [isTechnologiesListDisabled, setIsTechnologiesListDisabled] = useState(false);
    useEffect(() => setIsTechnologiesListDisabled(!technologiesList?.length), [technologiesList]);

    const moduleNameList = useMemo(
        () => [
            ...expandArr(task || [], [
                'list',
            ])
                .filter(({ lectureTheme }) => lectureTheme)
                .map(({ lectureTheme }) => ({
                    value: lectureTheme,
                    label: lectureTheme
                })),
            ...expandArr(seminarTypeClasses || [], [
                'list',
            ])
                .filter(({ goal }) => goal)
                .map(({ goal }) => ({
                    value: goal,
                    label: goal
                })),
            ...expandArr(laboratoryStudies || [], [
                'list',
            ])
                .filter(({ goal }) => goal)
                .map(({ goal }) => ({
                    value: goal,
                    label: goal
                })),
            ...expandArr(independentWork || [], [
                'list',
            ])
                .filter(({ goal }) => goal)
                .map(({ goal }) => ({
                    value: goal,
                    label: goal
                })),
        ],
        [independentWork, laboratoryStudies, seminarTypeClasses, task]
    );
    const [isModuleNameListDisabled, setIsModuleNameListDisabled] = useState(false);
    useEffect(() => setIsModuleNameListDisabled(!moduleNameList?.length), [moduleNameList]);
    const idkList = useMemo(
        () => expandArr(structure || [], [
            'idkCodes'
        ])
            .filter((idk) => idk)
            .map(
                (idk) => ({
                    label: idk,
                    value: idk,
                })
            ),
        [structure]
    );
    const [isIdkListDisabled, setIdkListDisabled] = useState(false);
    useEffect(() => setIdkListDisabled(!idkList?.length), [idkList]);

    return (
        <section>
            <h5 className="subtitle">
                Блок 6: Содержание разделов (тем) дисциплины (модуля), направленное на
                изучение цифровых технологий, востребованных в профессиональной
                деятельности выпускника
            </h5>

            <Form.List name="contentOfSections">
                {(fields, { add, remove }) => (
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('activity-item')} form-items-group`} key={key}>
                                <Row
                                    gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                    align="bottom"
                                    wrap={false}
                                >
                                    <Col flex="1">
                                        <div className="item-block-group">
                                            <Form.Item
                                                name={[name, 'technologies']}
                                                label="Наименование сквозной цифровой технологии"
                                                {...handleFieldEnableStatus(
                                                    !!technologiesList?.length,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                        "Наименование сквозной цифровой технологии, 
                                                        осваиваемой при изучении дисциплины, прохождении практики" 
                                                        (модуль "Общая характеристика ОПОП", 
                                                        раздел `}
                                                        <Link
                                                            to={(location) => ({ ...location, hash: '#1.7' })}
                                                        >
                                                            {t('digital-technologies-application-сomp')}
                                                        </Link>
                                                        {', блок 1)'}
                                                    </span>,
                                                    'Наименование сквозной цифровой технологии',
                                                )}
                                            >
                                                <Select
                                                    className="form-select"
                                                    size="large"
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    options={technologiesList}
                                                    mode="multiple"
                                                    placeholder="Выберите из ранее введенных сквозных цифровых технологий"
                                                    disabled={isTechnologiesListDisabled}
                                                />
                                            </Form.Item>
                                            <Divider />

                                            <Form.Item
                                                name={[name, 'moduleName']}
                                                label="Наименование раздела (темы) дисциплины (модуля)"
                                                {...handleFieldEnableStatus(
                                                    !!moduleNameList?.length,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                        "Тема лекции. Краткое содержание" 
                                                        (блок "Содержание дисциплины (модуля) по 
                                                        видам учебных занятий. Лекционные занятия"),
                                                        "Тема занятия. Краткое содержание" 
                                                        (блок "Занятия семинарского типа. Семинары, 
                                                        практические занятия, коллоквиумы и другие формы занятий"), 
                                                        "Тема занятия. Наименование лабораторной работы 
                                                        (лабораторного практикума)" 
                                                        (блок "Лабораторные занятия") или
                                                        "Тема занятия. Вид СРС" 
                                                        (блок "Самостоятельная работа обучающегося (СРС)")
                                                        `}
                                                    </span>,
                                                    'Наименование раздела (темы) дисциплины (модуля)',
                                                )}
                                            >
                                                <Select
                                                    className="form-select"
                                                    size="large"
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    options={moduleNameList}
                                                    placeholder="Выберите из ранее введенных наименований"
                                                    disabled={isModuleNameListDisabled}
                                                />
                                            </Form.Item>

                                            <div className="item-block-group-3">
                                                <p className="item-label">
                                                    Дидактические единицы, направленные на изучение цифровых технологий
                                                </p>
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="endToEndDigitlTech"
                                                    addText={t('add-more-didactic')}
                                                    {...restField}
                                                >
                                                    <Form.Item
                                                        name={[name, 'dot']}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите дидактические единицы"
                                                            className="textarea_80h"
                                                        />
                                                    </Form.Item>
                                                </GenBlock>
                                            </div>

                                            <Form.Item
                                                name={[name, 'code']}
                                                label="Код ИДК"
                                                {...handleFieldEnableStatus(
                                                    !!moduleNameList?.length,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                        "Код ИДК" 
                                                        (блок "Структура дисциплины (модуля) и объем тематических 
                                                        разделов по видам учебных занятий, индикаторы достижения 
                                                        формируемых компетенций, соотнесенные с разделами 
                                                        дисциплины (модуля)")
                                                        `}
                                                    </span>,
                                                    'Код ИДК',
                                                )}
                                            >
                                                <Select
                                                    size="large"
                                                    className="form-select"
                                                    placeholder="Выберите один или несколько кодов ИДК"
                                                    mode="multiple"
                                                    options={idkList}
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    disabled={isIdkListDisabled}
                                                />
                                            </Form.Item>
                                            <Divider />

                                            <p className="item-label">
                                                Оценочные средства, применяемые для текущего контроля
                                                успеваемости и промежуточной аттестации:
                                            </p>
                                            <div className="item-block-group-3">
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="list"
                                                    addText={t('add-discipline-name-number')}
                                                    {...restField}
                                                >
                                                    <div className="form-items-group" key={key}>
                                                        <Row
                                                            gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                            align="bottom"
                                                            wrap
                                                        >
                                                            <Col flex="1 0 300px">
                                                                <Form.Item
                                                                    name={[name, 'name']}
                                                                    label="Наименование"
                                                                >
                                                                    <InputV2 placeholder="Введите наименование" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="0 0 144px">
                                                                <Form.Item
                                                                    name={[name, 'number']}
                                                                    label="№ приложения"
                                                                >
                                                                    <InputV2 />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </GenBlock>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Row>
                            <Col span={22}>
                                <Form.Item>
                                    <Row align="middle" justify="space-between">
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(contentOfSectionsEmptyObj)}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-more-block')}
                                        </ButtonV2>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineLearning;
