/* eslint-disable max-len */
import {
    call, put, takeLatest
} from 'redux-saga/effects';
import * as c from './constants';
import {
    getRegistryList, getProgramList,
    // getCompletedRegistryApi
} from './api';
import { adaptList, adaptListStrapi } from './converters';
import { serverErrorsLogger } from '../../utils/server-errors-logger';


// function* getRegistryListSaga(action) {
//     const { success, failure } = c.getRegistryList;
//     try {
//         const  {query} = action.payload;
//         const { data } = yield call(getRegistryList, query );
//         yield put({ type: success, payload: { list: data} });
//     } catch (e) {
//         serverErrorsLogger(e);
//         yield put({ type: failure, payload: { message: e.message } });
//     }
// }

function* getRegistryListSaga({ query }) {
    const { success, failure } = c.getRegistryList;
    try {
        // @ts-ignore
        const { data } = yield call(
            getRegistryList, query
        );
        yield put({ type: success, payload: { list: adaptListStrapi(data.data), pagination: data.meta.pagination } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getProgramListSaga(action) {
    const { success, failure } = c.getProgramList;
    try {
        const { query } = action.payload;
        const { data } = yield call(
            getProgramList, query
        );

        yield put({ type: success, payload: { programList: adaptList(data.data) } });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

// function* getCompletedRegistrySaga() {
//     const { success, failure } = c.getCompletedRegistry;
//     try {
//         const { data } = yield call(getCompletedRegistryApi);
//         yield put({ type: success, payload: { completedOpops: data } });
//     } catch (e) {
//         serverErrorsLogger(e);
//         yield put({ type: failure, payload: { message: e.message } });
//     }
// }

export default function* rootSaga() {
    yield takeLatest(c.getRegistryList.tries, getRegistryListSaga);
    yield takeLatest(c.getProgramList.tries, getProgramListSaga);
}
