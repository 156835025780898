import React from 'react';
import bem from 'easy-bem';
import { Form, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { InputV2, ButtonV2 } from 'components';
import { GenBlock } from 'components/generated-block/generatedBlock';

import { useTranslation } from 'react-i18next';
import DeleteBtn from 'components/delete-btn';


const OtfTfCodeAndNameSubformBlock = ({ fieldName, codeAndNameOfOtfTfEmptyObj }) => {
    const b = bem('otf-tf-code-and-name-subform-block');
    const { t } = useTranslation('dev-educational-program');

    return (
        <Form.List name={[fieldName, 'codeAndNameOfOtfTf']}>
            {(fields, { add, remove }) => (
                <>
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('activity-item')} form-item`} key={key}>
                                <Row gutter={{ md: 4, lg: 8 }} align="bottom" wrap={false}>
                                    <Col flex="1">
                                        <div className="item-block-group-2">
                                            <p className="items-group-title">
                                                {t('labor-functions-common')}
                                            </p>
                                            <Row gutter={[32, 16]} justify="space-between">
                                                <Col flex="1 1 197px">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'commonName']}
                                                    >
                                                        <InputV2
                                                            textarea
                                                            autoSize={{ minRows: 4, maxRows: 6 }}
                                                            placeholder={t(
                                                                'enter-labor-functions-common-name'
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex="0 1 197px">
                                                    <Row gutter={[32, 16]}>
                                                        <Col>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'commonСode']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t(
                                                                        'labor-functions-common-code'
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'commonLevel']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t(
                                                                        'labor-functions-common-level'
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <p className="items-group-title">
                                                {t('labor-functions')}
                                            </p>
                                            <div className="item-block-group-3">
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="laborFunctions"
                                                    initialValueAdd={codeAndNameOfOtfTfEmptyObj.laborFunctions[0]}
                                                    {...restField}
                                                >
                                                    <Row gutter={[32, 16]} justify="space-between">
                                                        <Col flex="1 0 197px">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'name']}
                                                            >
                                                                <InputV2
                                                                    textarea
                                                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                                                    placeholder={t(
                                                                        'enter-labor-functions-name'
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col flex="0 1 197px">
                                                            <Row gutter={[32, 16]}>
                                                                <Col>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'code']}
                                                                    >
                                                                        <InputV2
                                                                            placeholder={t('labor-functions-code')}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'level']}
                                                                    >
                                                                        <InputV2
                                                                            placeholder={t('labor-functions-level')}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </GenBlock>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn onClick={() => remove(name)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                    <Row wrap={false}>
                        <Col flex="1" align="right">
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(codeAndNameOfOtfTfEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-OTF-TF')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col flex="33px" />
                    </Row>
                </>
            )}
        </Form.List>
    );
};

export default OtfTfCodeAndNameSubformBlock;
