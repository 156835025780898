import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import InputV2 from 'components/input-v2';

import './style.less';
import openNotification from 'utils/notification';


const StructureModulesFormBlock = ({
    modulesCredits = 0, modulesRequiredCredits = 0, modulesFormedCredits = 0
}) => {
    const b = bem('structure-modules-form-block');
    const { t } = useTranslation('dev-educational-program');

    useEffect(() => {
        if ((typeof modulesCredits === 'number'
            && typeof modulesRequiredCredits === 'number'
            && typeof modulesFormedCredits === 'number')
            && (modulesCredits !== modulesRequiredCredits + modulesFormedCredits)) {
            openNotification('warning', t('disciplines-credits-correlation-warn-msg'));
        }
    }, [modulesCredits, modulesRequiredCredits, modulesFormedCredits]);

    return (
        <div className={b()}>
            <Row gutter={32} justify="end" className={b('top-field-name')}>
                <Col flex="232px">
                    <p className="item-label item-label-big">{t('blocks-volume-in-cu')}</p>
                </Col>
            </Row>
            <Row gutter={[32, 24]}>
                <Col flex="99px">
                    <h5 className="subtitle">
                        {t('block-1')}
                    </h5>
                </Col>
                <Col flex="1 1 625px" className="item-block-group-horizontal">
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="modulesCredits"
                                className="item-label item-label-big"
                            >
                                {t('disciplines-modules')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="modulesCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="modulesRequiredCredits"
                                className="item-label item-label-big"
                            >
                                {t('mandatory-part')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="modulesRequiredCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="modulesFormedCredits"
                                className="item-label item-label-big"
                            >
                                {t('part-formed-by-participants-in-educational-relations')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="modulesFormedCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default StructureModulesFormBlock;
