import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MainOPOPDeveloper from './roles/mainOPOPDeveloper';
import OPOPDeveloper from './roles/opopDeveloper';
import ReplicationDeveloper from './roles/replicationDeveloper';
import { getUserRole } from '../../../../models/auth/selectors';


const getProgramForm = (program) => {
    switch (program) {
        case 'бакалавриат':
            return 'бакалавриата';
        case 'магистратура':
            return 'магистратуры';
        case 'специалитет':
            return 'специалитета';
        default:
            return '';
    }
};

const ProgramsList = ({
    programList, openDetails, setItemToRemove, setItemToManage
}) => {
    const { t } = useTranslation('my-educational-programs');
    const role = useSelector(getUserRole);
    const mainDevPolicy = useSelector((state) => state?.auth?.user?.policies?.find(
        (item) => item?.name === 'AccessToOwnListPolicy'
    ));
    const devPolicy = useSelector((state) => state?.auth?.user?.policies?.find(
        (item) => item?.name === 'AccessToListPolicy'
    ));
    return programList
        .sort((opop1, opop2) => new Date(opop2?.updatedAt) - new Date(opop1?.updatedAt))
        .map((item, index) => {
            const {
                status, updatedAt, createdAt, data: {
                    1.1: { program = '', directionCodeOrName = '', direction = '' } = {}
                } = {}
            } = item || {};
            const opopTitle = `${getProgramForm(program)} ${directionCodeOrName} ${direction}`;
            let userRole;
            if (mainDevPolicy.resources?.some((id) => id === item.id)
                || devPolicy?.resources?.some((id) => id === item.id)) {
                userRole = 'main-developer';
            }
            switch (userRole) {
                case 'opop-developer':
                    return (
                        <OPOPDeveloper
                            openDetails={openDetails}
                            status={status}
                            updatedDate={updatedAt}
                            createdDate={createdAt}
                            opopTitle={opopTitle}
                            item={item}
                            key={index}
                            t={t}
                        />
                    );
                case 'main-developer':
                    return (
                        <MainOPOPDeveloper
                            openDetails={openDetails}
                            updatedDate={updatedAt}
                            createdDate={createdAt}
                            directionCodeOrName={directionCodeOrName}
                            opopTitle={opopTitle}
                            item={item}
                            setItemToRemove={setItemToRemove}
                            setItemToManage={setItemToManage}
                            key={index}
                            t={t}
                        />
                    );
                case 'replication-developer':
                    return (
                        <ReplicationDeveloper
                            updatedDate={updatedAt}
                            createdDate={createdAt}
                            key={index}
                            t={t}
                        />
                    );
                default:
                    return (
                        <OPOPDeveloper
                            openDetails={openDetails}
                            status={status}
                            updatedDate={updatedAt}
                            createdDate={createdAt}
                            opopTitle={opopTitle}
                            item={item}
                            key={index}
                            t={t}
                        />
                    );
            }
        });
};

export default ProgramsList;
