import React, { ReactNode, useMemo } from 'react';
import PLXParserContext from './Context';
import { PLXParserProps, PLXParser } from './Parser';


export { PLXParser, parseTitleInfo } from './Parser';


export const usePlxParser = (): PLXParserProps => React.useContext(PLXParserContext);

export const PLXParserProvider = ({ children }: { children?: ReactNode }) => {
    const parser = useMemo(() => PLXParser(), []);
   
    return (
        <PLXParserContext.Provider value={parser}>
            {children}
        </PLXParserContext.Provider>
    );
};
