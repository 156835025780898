import React from 'react';
import {
    Col, Divider, Form, Radio, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineResources = ({ // 5.7, блок 3
    disciplineResourcesEmptyObj, electronicLibrarySysEmptyObj, profDatabasesEmptyObj
}) => {
    const { t } = useTranslation('dev-educational-program');
    const b = bem('dev-educational-program');

    return (
        <>
            <section>
                <h5 className="subtitle">
                    Ресурсы сети Интернет, необходимые для изучения дисциплины
                </h5>
                <p className="text">Информационно-справочные системы</p>
                <Form.List name="disciplineResources">
                    {(fields, { add, remove }) => (
                        <>
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('activity-item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        name={[name, 'name']}
                                                        label="Наименование"
                                                        {...restField}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[name, 'resourceLink']}
                                                        label="Ссылка на ресурс"
                                                        {...restField}
                                                    >
                                                        <InputV2 placeholder="Введите ссылку на ресурс" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[name, 'availability']}
                                                        label="Доступность"
                                                        {...restField}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value="свободный доступ">
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio value="ограниченный доступ">
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                            <Row>
                                <Col span={22}>
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(disciplineResourcesEmptyObj)}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-more-block')}
                                        </ButtonV2>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </section>

            <Divider />

            <section>
                <p className="text">
                    Электронно-библиотечные системы
                </p>
                <Form.List name="electronicLibrarySys">
                    {(fields, { add, remove }) => (
                        <>
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('activity-item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        name={[name, 'name']}
                                                        label="Наименование"
                                                        {...restField}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, 'resourseLink']}
                                                        label="Ссылка на ресурс"
                                                        {...restField}
                                                    >
                                                        <InputV2 placeholder="Введите ссылку на ресурс" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, 'availability']}
                                                        label="Доступность"
                                                        {...restField}
                                                    >
                                                        <Radio.Group>
                                                            <Radio
                                                                value="свободный доступ"
                                                            >
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio
                                                                value="ограниченный доступ"
                                                            >
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(electronicLibrarySysEmptyObj)}
                                                icon={<PlusOutlined />}
                                            >
                                                {t('add-more-block')}
                                            </ButtonV2>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )}
                </Form.List>
            </section>

            <Divider />

            <section>
                <p className="text">
                    Профессиональные базы данных
                </p>
                <Form.List name="profDatabases">
                    {(fields, { add, remove }) => (
                        <>
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('activity-item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        name={[name, 'name']}
                                                        label="Наименование"
                                                        {...restField}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, 'resourceLink']}
                                                        label="Ссылка на ресурс"
                                                        {...restField}
                                                    >
                                                        <InputV2 placeholder="Введите ссылку на ресурс" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[name, 'availability']}
                                                        label="Доступность"
                                                        {...restField}
                                                    >
                                                        <Radio.Group>
                                                            <Radio
                                                                value="свободный доступ"
                                                            >
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio
                                                                value="ограниченный доступ"
                                                            >
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(profDatabasesEmptyObj)}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-block')}
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )}
                </Form.List>
            </section>
        </>
    );
};

export default DisciplineResources;
