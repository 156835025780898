import dayjs from 'dayjs';
import { setCurrentDocDetails, setCurrentTabDetails } from '../models/documents/actions';


export const updateDocDetails = (dispatch, docDetails, tabKey, tempDoc) => {
    dispatch(setCurrentDocDetails({
        ...docDetails,
        [tabKey]: {
            ...tempDoc,
            updatedAt: dayjs().format(),
        },
    }));
};

export const updateTabDetails = (tabKey, data, dispatch) => {
    dispatch(setCurrentTabDetails(tabKey, {
        ...data,
        updatedAt: dayjs().format(),
    }));
};
