import React from 'react';
import { ReactComponent as PageOne } from '../../../../assets/images/all-opop/1common-info.svg';
import { ReactComponent as PageTwo } from '../../../../assets/images/all-opop/2common-info.svg';
import { ReactComponent as CommonInfoPage } from '../../../../assets/images/all-opop/common-info.svg';
import { ReactComponent as Boost } from '../../../../assets/icons/boost.svg';
import { ReactComponent as UnBoost } from '../../../../assets/icons/unboost.svg';

import './style.less';


const CommonInfo = () => (
    <div className="common-info-block">
        <div className="common-info-sider">
            <PageOne />
            <PageTwo />
        </div>
        <div className="info-viewer">
            <div className="info-viewer-helpers">
                <div>
                    <CommonInfoPage className="info" />
                    <CommonInfoPage className="info" />
                </div>
                <div className="info-viewer-icons">
                    <Boost />
                    <UnBoost />
                </div>
            </div>
        </div>
    </div>
);

export default CommonInfo;
