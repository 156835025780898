// const isJsonValid = (str) => {
//     try {
//         JSON.parse(str);
//     } catch (e) {
//         return false;
//     }
//     return true;
// };


export const docsListToClientOData = (rawData) => ({
    documentList: rawData.map(((item) => ({
        ...item,
        data: item.data,
    }))).sort((a, b) => new Date(b.EditDate) - new Date(a.EditDate))
});

