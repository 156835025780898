import axios from 'axios';
import { setDocumentViewerVisible } from 'models/documents/actions';
import _pick from 'lodash/pick';
import { last } from 'lodash';
import { emptyDocDetails, importDependencies } from './constants';


export const generatorUrl = process.env.REACT_APP_BACKEND_STAT_SERVISE;
export const serviceUrl = process.env.REACT_APP_BACKEND_SERVISE;

export const generateTemplate = async (tabKey, obj) => {
    try {
        return axios
            .post(`${generatorUrl}/format`, { key: tabKey, data: obj })
            .then((response) => response.data.tempPath);
    } catch {
        return null;
    }
};

export const getUploadedAcademicPlanDocument = async (uploadedFileList, dispatch) => {
    const validFile = uploadedFileList.find((item) => {
        const extension = last(item?.filename?.split('.'));
        return ['xls', 'xlsx'].includes(extension);
    });
    if (!validFile) {
        console.log('Doesn\'t have a file with proper format to open');
        return null;
    }
    const url = `${serviceUrl}/attachment/files/${validFile.id}`;
    dispatch(setDocumentViewerVisible(url));
};

export const generateAndMergeTemplate = async (obj, filename) => {
    try {
        const response = await axios.post(`${generatorUrl}/merge`, { data: obj, filename });
        return response.data.tempPath.tempPath;
    } catch {
        return null;
    }
};

export const previewDoc = async (tabKey, data, dispatch) => {
    const url = await generateTemplate(tabKey, data);
    if (!url) {
        return;
    }

    dispatch(setDocumentViewerVisible(`${url}`));
};

export const importDocDetails = (docDetails, tabKey) => Object.entries(importDependencies[tabKey] || {})
    .reduce((acc, [importedKey, importedDetails]) => ({
        ...acc,
        ..._pick(docDetails?.[importedKey], importedDetails),
    }), {});

export const generateAndDownloadDocument = async (docDetails, tabKeys = [], filename = '') => {
    const getUrl = (docDetails, tabKeys) => {
        if (tabKeys?.length === 1) {
            const tabKey = tabKeys[0];
            const data = {
                ...emptyDocDetails?.[tabKey],
                ...docDetails?.[tabKey],
                ...importDocDetails(docDetails, tabKey),
            };
            // return generateTemplate(tabKey, data);
        }
        const data = {
            ..._pick(docDetails, tabKeys),
            ...tabKeys.reduce((accDocDetails, currentKey) => (
                {
                    ...accDocDetails,
                    [currentKey]: {
                        ...emptyDocDetails?.[currentKey],
                        ...docDetails[currentKey],
                        ...importDocDetails(docDetails, currentKey),
                    },
                }
            ), {}),
        };
        return generateAndMergeTemplate(data, filename);
    };

    const url = await getUrl(docDetails, tabKeys);

    if (!url) {
        return;
    }
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    setTimeout(() => document.body.removeChild(element), 0);
};
