import axios from 'axios';


const url = process.env.REACT_APP_BACKEND_SERVISE;

export const getSearchedUser = (id, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.get(`${url}/user/${id}`, config);
};

export const setRole = (id, role, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.put(
        `${url}/user/${id}`,
        { roles: [role] },
        config
    );
};

export const setAccess = (id, accessArr, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.put(
        `${url}/list/${id}`,
        { access: accessArr },
        config
    );
};
export const getOpopByIdApi = (id, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.get(`${url}/list/${id}`, config);
};
