import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import {
    Divider, Form
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import Next from '../../../../../next';
import Structure from './components/structure';
import Content from './components/content';
import ExamQuestions from './components/exam-questions';
import Criterias from './components/criterias';
import useDebouncedCallback from '../../../../../../utils/use-debounce-callback';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '7.4';
const emptyObj = emptyDocDetails[tabKey];

const stateExam = 'государственный экзамен (ГЭ)';


const AttestationProgramExam = ({ onNextClick }) => {
    const { t } = useTranslation('attestation-exam');
    const b = bem('attestation-exam');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { compulsoryDisciplines, formedDisciplines, examQuestions } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        7.2: useSelector((state) => state.documents.documentDetails?.['7.2'] || {}),
    };
    const { workProgram = [] } = importedTabDetails[7.2];

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    const [isStateExamChecked, setIsStateExamChecked] = useState(false);
    useEffect(() => setIsStateExamChecked(workProgram.includes(stateExam)), [workProgram]);


    return (
        <div className="constructor-form">
            <h4 className="title">{t('title')}</h4>
            {isStateExamChecked ? (
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                >
                    <Structure
                        b={b}
                        t={t}
                        compulsoryDisciplinesEmptyObj={emptyObj.compulsoryDisciplines[0]}
                        formedDisciplinesEmptyObj={emptyObj.formedDisciplines[0]}
                    />
                    <Divider />

                    <Content
                        b={b}
                        t={t}
                        stateExamContentsEmptyObj={emptyObj.stateExamContents[0]}
                        compulsoryDisciplines={compulsoryDisciplines}
                        formedDisciplines={formedDisciplines}
                    />
                    <Divider />

                    <ExamQuestions
                        b={b}
                        t={t}
                        theoreticalQuestionsEmptyObj={emptyObj.theoreticalQuestions[0]}
                        practicalTasksEmptyObj={emptyObj.practicalTasks[0]}
                        allowedLiteratureSourcesEmptyObj={emptyObj.allowedLiteratureSources[0]}
                        examQuestions={examQuestions}
                    />
                    <Divider />

                    <Criterias t={t} />
                </Form>
            ) : (
                <h3 style={{ margin: 0 }}>
                    {`${t('emptyFormStateExam')} `}
                    <Link
                        to={(location) => ({ ...location, hash: '#7.2' })}
                    >
                        {t('generalProvisions')}
                    </Link>
                </h3>
            )}
            <Divider />
            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                    ...importDocDetails(importedTabDetails, tabKey)
                }, dispatch)}
                onNextClick={() => onNextClick()}
            />
        </div>
    );
};

export default AttestationProgramExam;
