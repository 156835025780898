/* eslint-disable react/no-array-index-key */
import React from 'react';
import bem from 'easy-bem';
import { Select } from 'antd';

import './style.less';


const { Option } = Select;

const OOCDropdownSearch = (props) => {
    const b = bem('ooc-dropdown-search');

    const {
        className: classNameProp,
        data = [],
        liftOption = () => {},
        ...rest
    } = props;

    const onFilter = (input, option) => {
        if (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
            liftOption(option);
            return true;
        }

        return false;
    };

    const onChange = (_, option) => liftOption(option);
    
    const options = data.map(
        (item) => <Option key={item.id} value={item.name}>{item.name}</Option>
    );

    const cn = classNameProp ? `${classNameProp} ${b()}` : b();

    return (
        <div className={cn}>
            <Select
                showSearch
                dropdownClassName={cn}
                optionLabelProp="label"
                optionFilterProp="children"
                notFoundContent={null}
                filterOption={onFilter}
                onChange={onChange}
                {...rest}
            >
                { options }
            </Select>
        </div>
    );
};


export default OOCDropdownSearch;
