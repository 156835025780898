/* eslint-disable max-len */
import React, { useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Row, Col, Radio, AutoComplete
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { InputV2, ButtonV2 } from 'components';
import DeleteBtn from 'components/delete-btn';
import { GenBlock } from 'components/generated-block/generatedBlock';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { digitalTechnologies } from 'components/opop-constructor/components/dev-educational-program/constants';
import { useXlsxParser } from 'utils/excel-parse';


const digitalTechnologiesList = digitalTechnologies.map((i) => ({ label: i, value: i }));


const DisciplineNameSubformBlock = ({ fieldName, disciplineNameBlockEmptyObj, parentIndex }) => {
    const b = bem('discipline-name-subform-block');
    const { t } = useTranslation('dev-educational-program');
    const {
        duration: { durationInYears = 0, durationInMonths = 0 } = {}
    } = useSelector((state) => state.documents.documentDetails?.['1.2'] || {});
    const generalProfCompFunc = useSelector((state) => state.documents.documentDetails?.['1.7']?.generalProfCompFunc[parentIndex] || {});
    const courseMaxNumber = Math.ceil(durationInYears + durationInMonths / 12);
    
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    
    const getNameofDiciplineModule = useMemo(() => {
        if (xlsxLoaded) {
            return xlsxParser.getNameOfDicipline?.(generalProfCompFunc?.codeAndName?.split('.')?.[0])?.allData || [];
        }
        return [];
    }, [generalProfCompFunc?.codAndNameOfIdk?.[fieldName]]);

    return (
        <Form.List name={[fieldName, 'disciplineNameBlock']}>
            {(fields, { add, remove }) => (
                <>
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }, index) => (
                            <div className={`${b('activity-item')} form-item`} key={key}>
                                <Row gutter={{ md: 4, lg: 8 }} align="bottom" wrap={false}>
                                    <Col flex="1">
                                        <div className="item-block-group-2">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'disciplineName']}
                                                label={t('discipline-name-forming-IDK-learning-outcomes')}
                                            >
                                                {xlsxLoaded && getNameofDiciplineModule?.length ? (
                                                    <Select
                                                        className="form-select"
                                                        placeholder={t('enter-activity-area')}
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        options={getNameofDiciplineModule}
                                                    />
                                                )
                                                    : (
                                                        <InputV2 placeholder={t('enter-discipline-name-forming-IDK-learning-outcomes')} />
                                                    )}
                                            </Form.Item>

                                            <div className="item-block-group-3">
                                                <p className="item-label">{t('IDK-learning-outcomes')}</p>
                                                <Row
                                                    gutter={[16, 8]}
                                                >
                                                    <Col flex="100%">
                                                        <GenBlock
                                                            blockFieldName={name}
                                                            blockName="toKnow"
                                                            initialValueAdd={disciplineNameBlockEmptyObj.toKnow[0]}
                                                            {...restField}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'type']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('toKnow-outcome')}
                                                                />
                                                            </Form.Item>
                                                        </GenBlock>
                                                    </Col>
                                                    <Col flex="100%">
                                                        <GenBlock
                                                            blockFieldName={name}
                                                            blockName="toBeAble"
                                                            initialValueAdd={disciplineNameBlockEmptyObj.toBeAble[0]}
                                                            {...restField}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'type']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('toBeAble-outcome')}
                                                                />
                                                            </Form.Item>
                                                        </GenBlock>
                                                    </Col>
                                                    <Col flex="100%">
                                                        <GenBlock
                                                            blockFieldName={name}
                                                            blockName="toPossess"
                                                            initialValueAdd={disciplineNameBlockEmptyObj.toPossess[0]}
                                                            {...restField}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'type']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('toPossess-outcome')}
                                                                />
                                                            </Form.Item>
                                                        </GenBlock>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="item-block-group-3">
                                                <p className="item-label">
                                                    {t('digit-tech-name-mastered-by-discipline-study-internship')}
                                                </p>
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="digitTechNameMasteredByDisciplineStudyInternship"
                                                    initialValueAdd={
                                                        disciplineNameBlockEmptyObj.digitTechNameMasteredByDisciplineStudyInternship[0]
                                                    }
                                                    {...restField}
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'digitTechName']}
                                                    >
                                                        <AutoComplete
                                                            size="large"
                                                            className="form-select"
                                                            placeholder={
                                                                t('enter-digit-tech-name-mastered-by-discipline-study-internship')
                                                            }
                                                            options={digitalTechnologiesList}
                                                        />
                                                    </Form.Item>
                                                </GenBlock>
                                            </div>
                                            <Form.List
                                                name={[name, 'courseAndSemester']}
                                            >
                                                {(fields, { add, remove }) => (
                                                    <div>
                                                        <>
                                                            {fields.map(({
                                                                key, name, ...restField
                                                            }) => (
                                                                <Row
                                                                    gutter={{ md: 4, lg: 8 }}
                                                                    align="bottom"
                                                                    wrap={false}
                                                                >
                                                                    <Col>
                                                                        <div className="item-block-group-4">
                                                                            <Form.Item
                                                                                name={[name, 'courseNumber']}
                                                                                label={t('select-course-number')}
                                                                                {...restField}
                                                                            >
                                                                                {courseMaxNumber > 0 ? (
                                                                                    <Radio.Group>
                                                                                        {Array.from(
                                                                                            { length: courseMaxNumber },
                                                                                            (_, i) => (
                                                                                                <Radio
                                                                                                    key={
                                                                                                        `${i + 1} ${t('course')}`
                                                                                                    }
                                                                                                    value={i + 1}
                                                                                                >
                                                                                                    {`${i + 1} курс`}
                                                                                                </Radio>
                                                                                            )
                                                                                        )}
                                                                                    </Radio.Group>
                                                                                ) : (
                                                                                    <span className="prompt-message">
                                                                                        {`${t('fill-in-field-first')} 
                                                                                            "${t('ed-duration')}" 
                                                                                            (${t('module')} 
                                                                                            "${t('opop-general-characteristics')}", 
                                                                                            ${t('chapter')} `}
                                                                                        <Link
                                                                                            to={(location) => ({
                                                                                                ...location,
                                                                                                hash: '#1.2'
                                                                                            })}
                                                                                        >
                                                                                            {t('general-info')}
                                                                                        </Link>
                                                                                        )
                                                                                    </span>
                                                                                )}
                                                                            </Form.Item>
                                                                            <p className="item-label">
                                                                                {t('semester')}
                                                                            </p>
                                                                            <Row
                                                                                gutter={4}
                                                                                align="bottom"
                                                                                wrap={false}
                                                                            >
                                                                                <Col flex="150px">
                                                                                    <GenBlock
                                                                                        blockFieldName={name}
                                                                                        blockName="semester"
                                                                                        initialValueAdd={
                                                                                            disciplineNameBlockEmptyObj.courseAndSemester[0].semester[0]
                                                                                        }
                                                                                        {...restField}
                                                                                    >
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[name, 'name']}
                                                                                        >
                                                                                            <InputV2 number />
                                                                                        </Form.Item>
                                                                                    </GenBlock>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item>
                                                                            <DeleteBtn
                                                                                onClick={() => remove(name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </>
                                                        <Row wrap={false}>
                                                            <Col flex="1" align="right">
                                                                <Form.Item>
                                                                    <ButtonV2
                                                                        type="link"
                                                                        onClick={() => add(
                                                                            disciplineNameBlockEmptyObj.courseAndSemester[0]
                                                                        )}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {t('add-discipline-name')}
                                                                    </ButtonV2>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="33px" />
                                                        </Row>
                                                    </div>
                                                )}
                                            </Form.List>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                    <Row wrap={false}>
                        <Col flex="1" align="right">
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(disciplineNameBlockEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-discipline-name')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col flex="33px" />
                    </Row>
                </>
            )}
        </Form.List>
    );
};

export default DisciplineNameSubformBlock;
