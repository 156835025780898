import React, { useEffect, useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Row, Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/download-file.svg';
import { useTranslation } from 'react-i18next';

import ButtonV2 from 'components/button-v2';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { updateTabDetails } from 'utils/dispatches';
import { generateAndDownloadDocument, importDocDetails, previewDoc } from '../../utils';
import CompetenceProfFormBlock from '../competence-prof-form-block';
import CompetenceCommonFormBlock from '../competence-common-form-block';
import { emptyDocDetails } from '../../constants';
import Next from '../../../../../next';


const tabKey = '2.0';
const emptyObj = emptyDocDetails[tabKey];


const CharacteristicTab = ({ onNextClick }) => {
    const b = bem('competence-model-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const moduleDetails = {
        '2.0': useSelector((state) => state.documents.documentDetails?.['2.0']),
    };
    const tabDetails = moduleDetails[tabKey];

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const {
        activityAreaSphere = [], activityTasks = [], activityLaborFunc = [], activityCharacteristic = []
    } = useSelector((state) => state.documents.documentDetails?.[1.3] || {});
    const {
        universalComp = [], commonComp = [], profComp = []
    } = useSelector((state) => state.documents.documentDetails?.[1.4] || {});
    const {
        generalProfCompFunc = [], profCompFunc = []
    } = useSelector((state) => state.documents.documentDetails?.[1.7] || {});

    const directionCodeOrName = useMemo(() => {
        let name = importedTabDetails?.['1.1']?.directionCodeOrName?.replaceAll('.', '_')?.replaceAll('/', '-');
        if (name.length > 100) {
            let nameArr = name.split(' ');
            while (nameArr.join(' ').length > 100) {
                nameArr = nameArr.slice(0, -1);
            }
            return nameArr.join(' ');
        }
        return name;
    }, [importedTabDetails?.['1.1']?.directionCodeOrName]);

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'competenceCommon' && name[2] === 'codeAndName') {
                newTabDetails.competenceCommon[name[1]].codeAndNameIndicator = [];
                newTabDetails.competenceCommon[name[1]].digitalTechnologies = [];
            }
            if (name[0] === 'competenceProf') {
                if (name[2] === 'taskType') {
                    newTabDetails.competenceProf[name[1]].tasks = [];
                }
                if (name[2] === 'laborFunc') {
                    newTabDetails.competenceProf[name[1]].laborAction = [];
                }
                if (name[2] === 'competenceCodeAndName') {
                    newTabDetails.competenceProf[name[1]].indicatorCodeAndName = [];
                    newTabDetails.competenceProf[name[1]].digitalTechnologies = [];
                }
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);
    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('competence-model-graduate')}</h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <CompetenceCommonFormBlock
                        competenceCommonEmptyObj={emptyObj.competenceCommon[0]}
                        competenceCommon={tabDetails?.competenceCommon}
                        universalComp={universalComp}
                        commonComp={commonComp}
                        generalProfCompFunc={generalProfCompFunc}
                    />
                    <Divider />

                    <CompetenceProfFormBlock
                        competenceProfEmptyObj={emptyObj.competenceProf[0]}
                        competenceProf={tabDetails?.competenceProf}
                        activityAreaSphere={activityAreaSphere}
                        activityTasks={activityTasks}
                        activityLaborFunc={activityLaborFunc}
                        activityCharacteristic={activityCharacteristic}
                        profComp={profComp}
                        profCompFunc={profCompFunc}
                    />
                    <Divider />
                    <Row gutter={[40, 24]} align="middle" justify="end">
                        <Col flex="1">
                            <Form.Item>
                                <ButtonV2
                                    className="download-btn"
                                    onClick={() => generateAndDownloadDocument(
                                        {
                                            ...moduleDetails,
                                            ...importedTabDetails
                                        },
                                        Object.keys(moduleDetails),
                                        `Компетентностная модель выпускника ${directionCodeOrName || ''}`
                                    )}
                                    type="primary"
                                    icon={<FileDownloadIcon />}
                                >
                                    {t('download-graduate-competency-model')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Next
                                onPreview={() => previewDoc(tabKey, {
                                    ...emptyObj,
                                    ...tabDetails,
                                    ...importDocDetails(importedTabDetails, tabKey)
                                }, dispatch)}
                                onNextClick={() => onNextClick()}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>

    );
};

export default CharacteristicTab;
