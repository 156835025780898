/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { usePlxParser } from 'utils/plx';
import { useXlsxParser } from 'utils/excel-parse';
import { getDocDetails } from 'models/documents/selectors';
import Next from '../../../../../next';
import { ButtonV2, InputV2 } from '../../../../../index';
import { GenBlock } from '../../../../../generated-block/generatedBlock';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '6.3';
const emptyObj = emptyDocDetails[tabKey];


const WorkProgramLearning = ({ onNextClick }) => {
    const { t } = useTranslation('planned-learning-outcomes');
    const b = bem('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const workProgram = useSelector((state) => state.documents.documentDetails?.['6.1']?.workProgram);
    const { tasks = [] } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const docDetails = useSelector(getDocDetails);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const { type = '' } = useSelector((state) => state.documents.documentDetails?.['6.1'] || {});

    const {
        universalComp = [], commonComp = [], profComp = []
    } = useSelector((state) => state.documents.documentDetails?.['1.4'] || {});

    const compList = useMemo(() => [
        ...(universalComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
        ...(commonComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
        ...(profComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
    ], [universalComp, commonComp, profComp]);

    // const plxCompList = useMemo(() => {
    //     if (!plxLoaded || !type) {
    //         return [];
    //     }

    //     const practiceType = plxParser.getPracticeType().find(({ practiceType }) => practiceType === type);
    //     if (!practiceType) {
    //         return [];
    //     }

    //     const list = plxParser.getRppCompetenceCodeAndName(practiceType.code);
    //     return list;
    // }, [type]);

    const [isCodeAndNameDisabled, setCodeAndNameDisabled] = useState(false);
    useEffect(() => setCodeAndNameDisabled(!compList?.length), [compList]);
    const [isCodeAndNameIndicatorDisabled, setCodeAndNameIndicatorDisabled] = useState(
        new Array(tasks?.length).fill(false)
    );
    useEffect(() => setCodeAndNameIndicatorDisabled(
        tasks.map(({ pickCode }, i) => !pickCode)
    ), [docDetails?.[6.3]?.tasks]);

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        // eslint-disable-next-line no-restricted-syntax
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'tasks' && name[2] === 'pickCode') {
                newTabDetails.tasks[name[1]].endToEndDigitlTech = (newTabDetails
                    .tasks[name[1]].endToEndDigitlTech || [])
                    .map(({ idkCode, ...rest }) => ({
                        ...rest,
                        idkCode: ''
                    }));
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    const competencyAndName = useMemo(() => {
        if (xlsxLoaded) {
            return xlsxParser?.getCompetencyAndNameRpp(type)
                ?.map((item) => ({
                    label: item,
                    value: item,
                })) || [];
        }
        return [];
    }, [type]);

    useEffect(() => {
        // updateTabDetails(tabKey, initialValues, dispatch);
        const newTasks = tasks.map((item) => {
            const tasksItem = { ...item };
            if (!xlsxLoaded && !compList.some((elem) => elem.value === tasksItem?.pickCode)) {
                tasksItem.pickCode = '';
                tasksItem.endToEndDigitlTech?.map((x) => {
                    x.idkCode = '';
                    return x;
                });
            }
            if (xlsxLoaded) {
                const data = xlsxParser?.getCompetencyAndName(docDetails[6.1]?.type);
                if (!data?.some((elem) => elem === tasksItem?.pickCode)) {
                    tasksItem.pickCode = '';
                    tasksItem.endToEndDigitlTech?.map((x) => {
                        x.idkCode = '';
                        return x;
                    });
                }
            }
            return tasksItem;
        });
        form.setFieldsValue({ tasks: newTasks });
    }, []);
    useEffect(() => {
        const newTabDetails = { ...form.getFieldsValue() };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, [form]);
    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className="constructor-form">
            <h4 className="title m-24">{t('title')}</h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1 ? '' : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onFieldsChange={onFieldsChange}
                layout="vertical"
            >
                <section>
                    <h5 className="subtitle">
                        {t('results')}
                    </h5>
                    <Form.List name="tasks">
                        {(fields, { add, remove }) => (
                            <>
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }, index) => (
                                        <div className={`${b('item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <div className="item-block-group">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'pickCode']}
                                                            label={t('code')}
                                                            {...handleFieldEnableStatus(
                                                                xlsxLoaded ? true : !!compList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first-form')}
                                                                    ${t('code-names')}
                                                                    ${t('in-chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                </span>,
                                                                t('code')
                                                            )}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent="Нет данных"
                                                                placeholder={t('pick-code')}
                                                                options={
                                                                    xlsxLoaded && competencyAndName.length
                                                                        ? competencyAndName
                                                                        : compList
                                                                }
                                                                disabled={
                                                                    xlsxLoaded
                                                                        ? !competencyAndName.length
                                                                        : isCodeAndNameDisabled
                                                                }
                                                            />
                                                        </Form.Item>

                                                        <GenBlock
                                                            blockFieldName={name}
                                                            blockName="endToEndDigitlTech"
                                                            initialValueAdd={
                                                                emptyObj.tasks[0].endToEndDigitlTech[0]
                                                            }
                                                            addText={t('add-idk')}
                                                            {...restField}
                                                        >
                                                            <Divider />

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'idkCode']}
                                                                label={t('code-idk')}
                                                                {...handleFieldEnableStatus(
                                                                    !!docDetails?.[6.3]?.tasks?.length,
                                                                    <span>
                                                                        {`${t('fill-in-field-first-form')}
                                                                        ${t('code-name-uk-pk-opk')}
                                                                        ${t('in-chapter')} `}
                                                                        <Link
                                                                            to={(location) => ({
                                                                                ...location,
                                                                                hash: '#1.4'
                                                                            })}
                                                                        >
                                                                            {t('educational-programs-development-results')}
                                                                        </Link>
                                                                    </span>,
                                                                    t('code-idk')
                                                                )}
                                                                {...handleFieldEnableStatus(
                                                                    !!tasks?.[name]?.pickCode,
                                                                    <span>
                                                                        Предварительно необходимо выбрать "Код и наименование компетенции"
                                                                    </span>,
                                                                    t('code-idk')
                                                                )}
                                                            >
                                                                <Select
                                                                    className="form-select"
                                                                    size="large"
                                                                    showSearch
                                                                    placeholder={t('pick-code-idk')}
                                                                    notFoundContent="Нет данных"
                                                                    // options={idkLists?.[name]}
                                                                    options={
                                                                        xlsxLoaded
                                                                            ? xlsxParser?.getCompetencyAndName6(docDetails[6.1]?.type, docDetails?.[6.3]?.tasks?.[index]?.pickCode)
                                                                            : compList
                                                                    }
                                                                    disabled={
                                                                        xlsxLoaded && tabDetails?.tasks?.[index]?.pickCode
                                                                            ? false
                                                                            : isCodeAndNameDisabled
                                                                    }
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'toKnow']}
                                                                label={t('teaching-results')}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('know')}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'toAble']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('able')}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'toOwn']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('own')}
                                                                />
                                                            </Form.Item>
                                                        </GenBlock>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        className={b('btn-indent')}
                                        onClick={() => add(emptyObj.tasks[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-more')}
                                    </ButtonV2>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </section>
            </Form>
            <Divider />

            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick(form)}
            />
        </div>
    );
};

export default WorkProgramLearning;
