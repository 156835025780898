export const tabData = {
    'admin-expert': [
        { label: 'Реестр ОПОП', value: '/opop-registry' },
        { label: 'Заявки на согласование ОПОП', value: '/applications' },
        { label: 'Все ОПОП для тиражирования', value: '/all-replication' },
    ],
    'main-expert': [
        { label: 'Реестр ОПОП', value: '/opop-registry' },
        { label: 'Заявки на согласование ОПОП', value: '/applications' },
    ],
    admin: [
        { label: 'Панель Админа', value: '/admin' },
    ],
    'opop-developer': [
        { label: 'Реестр ОПОП', value: '/opop-registry' },
    ],
    'main-developer': [
        { label: 'Реестр ОПОП', value: '/opop-registry' },
        { label: 'Конструктор ОПОП', value: '/opop-constructor/' }
    ],
    'replication-developer': [
        { label: 'Реестр ОПОП', value: '/opop-registry' },
    ]
};
