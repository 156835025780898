import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import { PlusOutlined } from '@ant-design/icons';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineAttestation = ({ disciplineAttestationEmptyObj }) => { // 5.10, блок 2
    const { t } = useTranslation('dev-educational-program');
    const b = bem('dev-educational-program');

    const { sizeByTypes = [] } = useSelector((state) => state.documents.documentDetails?.['5.5'] || {});
    const formList = useMemo(
        () => (sizeByTypes || [])
            .filter(({ intermediateAttestationForm }) => intermediateAttestationForm)
            .map(({ intermediateAttestationForm }) => ({
                label: intermediateAttestationForm,
                value: intermediateAttestationForm,
            })),
        [sizeByTypes]
    );
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    useEffect(() => setIsFormDisabled(!formList?.length), [formList]);

    return (
        <section>
            <h5 className="subtitle">
                {t('block-2')}
                : Оценочные материалы для промежуточной аттестации
            </h5>
            <Form.List name="disciplineAttestation">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    name={[name, 'material']}
                                                    label="Оценочный материал"
                                                    {...restField}
                                                >
                                                    <InputV2
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Введите оценочный материал"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, 'form']}
                                                    label="Форма промежуточной аттестации"
                                                    {...handleFieldEnableStatus(
                                                        !!formList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first')} 
                                                            "${t('form-attestation')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.5' })}
                                                            >
                                                                {t('work-discipline-size')}
                                                            </Link>
                                                        </span>,
                                                        'Форма промежуточной аттестации'
                                                    )}
                                                    {...restField}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Выберите форму промежуточной аттестации"
                                                        options={formList}
                                                        disabled={isFormDisabled}
                                                    />
                                                </Form.Item>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="0 0 168px">
                                                        <Form.Item
                                                            name={[name, 'count']}
                                                            label="В количестве"
                                                            {...restField}
                                                        >
                                                            <InputV2
                                                                className="form-select"
                                                                size="large"
                                                                placeholder="шт"
                                                                number
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col flex="0 0 168px">
                                                        <Form.Item
                                                            name={[name, 'appNumber']}
                                                            label="Номер приложения"
                                                            {...restField}
                                                        >
                                                            <InputV2
                                                                className="form-select"
                                                                size="large"
                                                                number
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(disciplineAttestationEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-block')}
                                </ButtonV2>
                            </Form.Item>
                        </div>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineAttestation;
