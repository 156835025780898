/* eslint-disable max-len */
import React from 'react';
import {
    Col, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineEducation = ({ disciplineEducationEmptyObj }) => { // 5.7, блок 1
    const b = bem('dev-educational-program');


    return (
        <section>
            <p className="item-label">
                Учебная и учебно-методическая литература и другие материалы, необходимые для изучения дисциплины
            </p>
            <Form.List name="disciplineEducation">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <Form.Item
                                                name={[name, 'eduLiterature']}
                                                {...restField}
                                            >
                                                <InputV2
                                                    textarea
                                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                                    placeholder="Оформление списка литературы должно соответствовать ГОСТ"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(disciplineEducationEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                Добавить еще поле для заполнения
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineEducation;
