import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, Divider
} from 'antd';
import Select from 'components/select';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightUpIcon } from 'assets/icons/arrow-right-up.svg';
import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import useDebouncedCallback from 'utils/use-debounce-callback';
import DeleteBtn from 'components/delete-btn';
import range from 'components/opop-constructor/components/range';
import Next from 'components/next';
import RegulatoryDocumentsModal from '../../../regulatory-documents-modal';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '1.2';
const emptyObj = emptyDocDetails[tabKey];

const monthsList = range(0, 11, 1).map((value) => ({ value, label: value }));
const yearsList = range(0, 6, 1).map((value) => ({ value, label: value }));

const validTerms = {
    bachelor: {
        fullTime: {
            years: 4, months: 0
        },
        partTime: {
            minYears: 4, minMonths: 6, maxYears: 5, maxMonths: 0
        },
    },
    master: {
        fullTime: {
            years: 2, months: 0
        },
        partTime: {
            minYears: 2, minMonths: 3, maxYears: 2, maxMonths: 6
        },
    },
    specialist: {
        partTime: {
            minYearsDiff: 0, minMonthsDiff: 6, maxYearsDiff: 1, maxMonthsDiff: 0
        },
    },
};

const getNewDuration = (
    program = '', studyForm = '', t, { durationInYears = '', durationInMonths = '' } = {}
) => {
    if (studyForm === t('full-time')) {
        if (program === t('bachelor')) {
            return {
                duration: {
                    durationInYears: validTerms.bachelor.fullTime.years,
                    durationInMonths: validTerms.bachelor.fullTime.months,
                },
                newIsDurationHidden: true,
            };
        }
        if (program === t('master')) {
            return {
                duration: {
                    durationInYears: validTerms.master.fullTime.years,
                    durationInMonths: validTerms.master.fullTime.months,
                },
                newIsDurationHidden: true,
            };
        }
    }
    return {
        duration: {
            durationInYears,
            durationInMonths,
        },
        newIsDurationHidden: false,
    };
};

const validateEdDuration = (
    { durationInYears = 0, durationInMonths = 0 },
    program = '', studyForm = '', directionTerm = '', directionExclTerm = '', t
) => {
    const duration = durationInYears + durationInMonths / 12;
    let minDuration = 0;
    let maxDuration = 0;
    const durationErrMsg = 'Срок получения образования не соответствует нормам. Необходимо исправить.';

    switch (program) {
        case t('bachelor'): {
            if (studyForm === t('full-time')) {
                const ranges = validTerms.bachelor.fullTime;
                const validDuration = ranges.years + ranges.months / 12;
                if (duration === validDuration) return Promise.resolve();
                return Promise.reject(new Error(durationErrMsg));
            }

            const ranges = validTerms.bachelor.partTime;
            minDuration = ranges.minYears + ranges.minMonths / 12;
            maxDuration = ranges.maxYears + ranges.maxMonths / 12;
            break;
        }
        case t('master'): {
            if (studyForm === t('full-time')) {
                const ranges = validTerms.master.fullTime;
                const validDuration = ranges.years + ranges.months / 12;
                if (duration === validDuration) return Promise.resolve();
                return Promise.reject(new Error(durationErrMsg));
            }

            const ranges = validTerms.master.partTime;
            minDuration = ranges.minYears + ranges.minMonths / 12;
            maxDuration = ranges.maxYears + ranges.maxMonths / 12;
            break;
        }
        case t('specialty'): {
            const directionTermDuration = parseFloat(directionTerm.replace(',', '.'));
            if (directionExclTerm) {
                const directionExclTermDuration = parseFloat(directionExclTerm.split(' ')[1].replace(',', '.'));
                if (studyForm === t('full-time')) {
                    if (duration === directionTermDuration
                        || duration === directionExclTermDuration) return Promise.resolve();
                    return Promise.reject(new Error(durationErrMsg));
                }
                const ranges = validTerms.specialist.partTime;
                minDuration = Math.min(directionTermDuration, directionExclTermDuration)
                    + ranges.minYearsDiff + ranges.minMonthsDiff / 12;
                maxDuration = Math.max(directionTermDuration, directionExclTermDuration)
                    + ranges.maxYearsDiff + ranges.maxMonthsDiff / 12;

                break;
            }

            if (studyForm === t('full-time')) {
                if (duration === directionTermDuration) return Promise.resolve();
                return Promise.reject(new Error(durationErrMsg));
            }

            const ranges = validTerms.specialist.partTime;
            minDuration = directionTermDuration + ranges.minYearsDiff + ranges.minMonthsDiff / 12;
            maxDuration = directionTermDuration + ranges.maxYearsDiff + ranges.maxMonthsDiff / 12;

            break;
        }
        default:
            break;
    }
    if (minDuration <= duration && duration <= maxDuration) return Promise.resolve();
    return Promise.reject(new Error(durationErrMsg));
};

const getNewDurationInYearsMonths = ({ durationInYears = 0, durationInMonths = 0 }) => {
    if ((durationInYears)) {
        if (durationInYears === 6) {
            return {
                duration: { durationInYears: 6, durationInMonths: 0 },
                newIsMonthsDisabled: true
            };
        }
        if (!durationInMonths) {
            return {
                duration: { durationInYears, durationInMonths: 0 },
                newIsMonthsDisabled: false
            };
        }
    }
    if (durationInMonths && !durationInYears) {
        return {
            duration: { durationInYears: 0, durationInMonths },
            newIsMonthsDisabled: false
        };
    }
    return {
        duration: { durationInYears, durationInMonths },
        newIsMonthsDisabled: false
    };
};

const getDirectionData = (directoryList = [], directionCodeOrName = '') => {
    const directionCode = directionCodeOrName?.replace?.(/[^\d.]/g, '');
    const {
        order, term: directionTerm, excl_term: directionExclTerm
    } = directoryList.find(({ code = '' }) => code === directionCode) || {};
    return {
        directionOrder: `Приказ ${order}`, directionTerm, directionExclTerm
    };
};

const getNewRegulatoryDocuments = (regulatoryDocuments = [], order = '', t) => {
    if (!regulatoryDocuments?.length) {
        return {
            regulatoryDocuments: [
                { name: t('fedLaw-N273-29Dec2012') },
                { name: t('eduMinOrder-N301-5Apr2017') },
                ...(order ? [{
                    name: order,
                    isDirectionOrder: true
                }] : []),
            ],
        };
    }

    const newRegulatoryDocuments = regulatoryDocuments;
    let directionOrderIndex = regulatoryDocuments.findIndex(({ isDirectionOrder }) => isDirectionOrder);
    if (directionOrderIndex === -1) {
        directionOrderIndex = regulatoryDocuments.findIndex(({ name }) => name) + 1;
    }

    newRegulatoryDocuments[directionOrderIndex] = { name: order, isDirectionOrder: true };
    return {
        regulatoryDocuments: newRegulatoryDocuments,
    };
};


const GeneralInfoTab = ({ onNextClick }) => {
    const b = bem('characteristic-general-info-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();
    const initialValues = tabDetails || {
        ...emptyObj,
        programImplementedBy: t('state-language-of-russian-federation'),
    };
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const {
        program = '', studyForm = '', directionCodeOrName = '',
    } = importedTabDetails['1.1'];

    const directoryList = useSelector((state) => state.documents.directoryList);
    const {
        directionOrder, directionTerm, directionExclTerm
    } = getDirectionData(directoryList, directionCodeOrName);

    const [isDurationHidden, setDurationHidden] = useState(false);
    useEffect(() => {
        const { regulatoryDocuments } = getNewRegulatoryDocuments(
            tabDetails?.regulatoryDocuments, directionOrder, t
        );
        const { duration, newIsDurationHidden } = getNewDuration(
            program, studyForm, t, tabDetails?.duration
        );
        form.setFieldsValue({ regulatoryDocuments, duration });
        setDurationHidden(newIsDurationHidden);
        form.validateFields();

        updateTabDetails(tabKey, {
            ...tabDetails,
            regulatoryDocuments,
            directionOrder,
            duration,
        }, dispatch);
    }, [directionOrder, program, studyForm]);

    useEffect(() => {
        if (tabDetails) {
            return;
        }
        const { regulatoryDocuments } = getNewRegulatoryDocuments(
            tabDetails?.regulatoryDocuments, directionOrder, t
        );
        const { duration, newIsDurationHidden } = getNewDuration(
            program, studyForm, t, tabDetails?.duration
        );
        form.setFieldsValue({ regulatoryDocuments });
        updateTabDetails(tabKey, {
            ...tabDetails,
            ...emptyObj,
            regulatoryDocuments,
            directionOrder,
            duration
        }, dispatch);
    }, [tabDetails]);

    const [isMonthsDisabled, setMonthsDisabled] = useState(false);

    const [visibleModal, setVisibleModal] = useState(false);
    const onShowDocumentsBtnClick = () => {
        setVisibleModal(true);
    };
    const onValuesChange = useDebouncedCallback((changed, all) => {
        let newTabDetails = {
            ...all,
            directionOrder
        };
        if (changed.duration) {
            const { duration, newIsMonthsDisabled } = getNewDurationInYearsMonths(all.duration);
            form.setFieldsValue({ duration });
            setMonthsDisabled(newIsMonthsDisabled);
            form.validateFields();
            newTabDetails = { ...newTabDetails, duration };
        }
        if (changed.regulatoryDocuments) {
            const changedRegulatoryDocumentIndex = changed.regulatoryDocuments.findIndex((doc) => doc);
            const isDirectionOrderChanged = all?.regulatoryDocuments[changedRegulatoryDocumentIndex]?.isDirectionOrder;
            if (isDirectionOrderChanged) {
                const newRegulatoryDocuments = all?.regulatoryDocuments;
                delete newRegulatoryDocuments[changedRegulatoryDocumentIndex].isDirectionOrder;
                newTabDetails = { ...newTabDetails, regulatoryDocuments: newRegulatoryDocuments };
            }
        }
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <>
            <RegulatoryDocumentsModal onClose={setVisibleModal} visible={visibleModal} />
            <div className={b()}>
                <div className="constructor-form">
                    <h4 className="title">{t('general-info')}</h4>
                    <Form
                        form={form}
                        initialValues={initialValues}
                        onValuesChange={onValuesChange}
                        layout="vertical"
                    >
                        <section>
                            <Form.Item name="goal" label={t('goal-ed-program')}>
                                <InputV2
                                    textarea
                                    placeholder={t('enter-goal-ed-program')}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            </Form.Item>

                            <p className="item-label">{t('tasks-ed-program')}</p>
                            <Form.List name="tasks">
                                {(fields, { add, remove }) => (
                                    <>
                                        <div className="form-items-list">
                                            {fields.map(({
                                                key, name, ...restField
                                            }) => (
                                                <div className={`${b('task-item')} form-item`} key={key}>
                                                    <Row
                                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                        align="bottom"
                                                        wrap={false}
                                                    >
                                                        <Col flex="1">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'task']}
                                                            >
                                                                <InputV2
                                                                    placeholder={t('enter-task')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item>
                                                                <DeleteBtn
                                                                    onClick={() => remove(name)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.tasks[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-task')}
                                                </ButtonV2>
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                            </Form.List>

                            <Row gutter={[32, 24]}>
                                <Col flex="1 0 345px">
                                    <Form.Item
                                        name="programImplementedBy"
                                        label={t('program-implemented-by')}
                                    >
                                        <InputV2
                                            placeholder={t('enter-implemented')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col flex="345px">
                                    <Form.Item
                                        name="duration"
                                        label={t('ed-duration')}
                                        rules={[
                                            {
                                                warningOnly: true,
                                                validator: (_, value = {}) => validateEdDuration(
                                                    value, program, studyForm, directionTerm, directionExclTerm, t
                                                )
                                            },
                                        ]}
                                        dependencies={
                                            [['duration', 'durationInYears'], ['duration', 'durationInMonths']]
                                        }
                                        hidden={isDurationHidden}
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col flex="1 1 168px">
                                                <Form.Item name={['duration', 'durationInYears']}>
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder={t('years')}
                                                        options={yearsList}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col flex="1 1 161px">
                                                <Form.Item name={['duration', 'durationInMonths']}>
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder={t('months')}
                                                        options={monthsList}
                                                        disabled={isMonthsDisabled}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </section>
                        <Divider />

                        <section>
                            <Row gutter={[{ sm: 16, md: 24, lg: 32 }, 16]} align="middle" justify="space-between">
                                <Col lg={24} xl={12}>
                                    <h5 className="subtitle">{t('regulatory-documents')}</h5>
                                </Col>
                                {/* <Col lg={24} xl={12} align="right">
                                    <ButtonV2
                                        type="link"
                                        onClick={onShowDocumentsBtnClick}
                                        icon={<Icon component={ArrowRightUpIcon} />}
                                        className="icon-on-right"
                                    >
                                        {t('reg-docs-show-list')}
                                    </ButtonV2>
                                </Col> */}
                            </Row>

                            <Form.List name="regulatoryDocuments">
                                {(fields, { add, remove }) => (
                                    <>
                                        <div className="form-items-list">
                                            {fields.map(({
                                                key, name, ...restField
                                            }) => (
                                                <div
                                                    className={`${b('regulatory-document-item')} form-item`}
                                                    key={key}
                                                >
                                                    <Row
                                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                        align="bottom"
                                                        wrap={false}
                                                    >
                                                        <Col flex="1">
                                                            <Form.Item
                                                                {...restField}
                                                                label={t('reg-docs-name')}
                                                                name={[name, 'name']}
                                                            >
                                                                <InputV2
                                                                    textarea
                                                                    autoSize={{ minRows: 1, maxRows: 6 }}
                                                                    placeholder={t('enter-reg-docs-name')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item>
                                                                <DeleteBtn
                                                                    onClick={() => remove(name)}
                                                                    disabled={fields?.length === 1}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.regulatoryDocuments[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-reg-doc')}
                                                </ButtonV2>
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                            </Form.List>
                        </section>
                        <Divider />

                        <Next
                            onPreview={() => previewDoc(tabKey, {
                                ...emptyObj,
                                ...tabDetails,
                                ...importDocDetails(importedTabDetails, tabKey)
                            }, dispatch)}
                            onNextClick={() => onNextClick(form)}
                        />
                    </Form>
                </div>
            </div>
        </>
    );
};

export default GeneralInfoTab;
