// @ts-nocheck
import React, {
    useState, useEffect, ReactNode, useMemo
} from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { TableV2, PaginationV2 } from 'components';
import { useTranslation } from 'react-i18next';
import { getRegistryList } from 'models/registry-strapi/actions';
import { getRegistryListSelector, getRegistryPaginationSelector } from 'models/registry-strapi/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Space } from 'antd';
import numeralize from 'utils/numiralize';
import FormTypeFilter from './filters/RadioFilter';
import SearchFilter from './filters/SearchFilter';
import 'dayjs/locale/ru';
import './style.less';
import StatisticModal from './statisticModal';
import { getStatistic } from '../../models/registry/selectors';
import { getUser, getUserRole } from '../../models/auth/selectors';


dayjs.locale('ru');

interface ColumnProps {
    key: string;
    title: string | ReactNode;
    dataIndex?: string;
    render?: (text: any, record: any) => ReactNode;
    filters?: any[];
    onFilter?: any;
}

const getTemplateField: any = (src = [], template: string) => src.find(
    (item: any) => item.__component === `templates.${template}`
);
const getPeriodLabel = (months: number) => `${Math.floor(months / 12)} 
${numeralize(Math.floor(months / 12), ['год', 'года', 'лет'])} 
${months % 12 > 0 ? `${months % 12} ${numeralize(months % 12, ['месяц', 'месяца', 'месяцев'])}` : ''}`;

const programLabels: Record<string, any> = {
    undergraduate: 'бакалавриат',
    specialty: 'специалитет',
    magistracy: 'магистратура'
};

const studyTypes: Record<string, string> = {
    fulltime: 'Очная',
    correspondence: 'Очно-заочная',
    parttime: 'Заочная'
};

const BASE_PAGE_SIZE = 20;

const OPOPRegistryStrapi = () => {
    const b = bem('opop-registry');
    const dispatch = useDispatch();
    const { t } = useTranslation('ooc-consortium-participants');
    const [, setActive] = useState(null);
    const registryList = useSelector(getRegistryListSelector);
    const statisticData = useSelector(getStatistic);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(BASE_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(1);
    const formTypeFilter = useState<string[]>();
    const programTypeFilter = useState<string[]>();
    const searchOrgFilter = useState<string | null | undefined>();
    const searchDirectionFilter = useState<string | null | undefined>();
    const [filtersChanged, setFiltersChanged] = useState(false);
    const { total } = useSelector(getRegistryPaginationSelector);
    const role = useSelector(getUserRole);
    const user = useSelector(getUser);
    const location = useLocation();
    const registryListCopy = [...registryList].sort((item1, item2) => (new Date(item2.approvalDate) - new Date(item1.approvalDate)));
    const columns = useMemo<ColumnProps[]>(() => [
        {
            key: 'directionCodeOrName',
            title: () => (
                <Space size={2}>
                    Код и наименование направления
                    {' '}
                    <SearchFilter placeholder="Введите код и наименование направления" state={searchDirectionFilter} />
                </Space>
            ),
            render: (_) => (
                <span
                    className="cell-text"
                >
                    {_?.directionCodeOrName || ''}
                </span>
            ),
        },
        {
            key: 'organization',
            title: () => (
                <Space size={2}>
                    Наименование организации
                    {' '}
                    <SearchFilter placeholder="Введите наименование организации" state={searchOrgFilter} />
                </Space>
            ),
            render: (_) => (
                <span className="cell-text cursor">
                    <p>{_?.orgName || ''}</p>
                    <p className="cell-text secondary">{_?.orgNameShort || ''}</p>
                </span>
            ),
        },
        {
            key: 'trainingPeriod',
            title: () => (
                <Space size={2}>
                    Программа, срок обучения
                    {' '}
                    <FormTypeFilter
                        values={[{ value: 'undergraduate', label: 'бакалавриат' },
                            { value: 'specialty', label: 'специалитет' },
                            { value: 'magistracy', label: 'магистратура' }]}
                        state={programTypeFilter}
                    />
                </Space>
            ),
            render: (_) => (
                <span className="cell-text">
                    <p>{programLabels[_.program]}</p>
                    <p className="cell-text secondary">{getPeriodLabel(_.trainingPeriod)}</p>
                </span>
            ),
        },
        {
            key: 'studyType',
            title: () => (
                <Space size={2}>
                    Форма обучения
                    <FormTypeFilter
                        values={[
                            { value: 'fulltime', label: 'очная' },
                            { value: 'parttime', label: 'заочная' },
                            { value: 'correspondence', label: 'очно-заочная' }]}
                        state={formTypeFilter}
                    />
                </Space>
            ),
            render: (_) => (
                <span className="cell-text">
                    {studyTypes[_?.studyType] || ''}
                </span>
            )
        },
        {
            key: 'approvalDate',
            title: 'Дата утверждения',
            render: (_) => (
                <span className="cell-text">
                    {dayjs(_.approvalDate).format('DD MMMM YYYY')}
                </span>
            ),
        },
        {
            key: 'statistic',
            title: 'Кол-во скачиваний',
            render: (_) => {
                let value = 0;
                statisticData.filter((el) => el.direction === _.directionCodeOrName).forEach((el) => {
                    value += el.count;
                });
                return (
                    <span className="cell-text">
                        {
                            value
                        }
                    </span>
                );
            }
        }
    ], [statisticData, location.pathname]);


    role === 'admin-expert' || role === 'main-expert' ? columns : delete columns[5];


    const onPageChange = (value: number, pageSize: number) => { setCurrentPage(value); setPageSize(pageSize); };

    const onRow = (record: any) => ({
        onClick: () => history.push(`/opop-registry/${record?.id}`),
    });

    const onReset = () => {
        setPageSize(20);
        setCurrentPage(1);
    };


    useEffect(() => {
        let query: any = {
            pagination: {
                page: currentPage,
                pageSize
            }
        };
        if (programTypeFilter[0]?.length || formTypeFilter[0]?.length
            || searchOrgFilter[0]?.length || searchDirectionFilter[0]?.length) {
            setFiltersChanged(true);
            query = {
                ...query,
                filters: {
                    $and: [],
                    $or: []
                }
            };
        }

        if (programTypeFilter[0]?.length) {
            query.filters.$or.push(...programTypeFilter[0].map((program: any) => ({ program })));
        }

        if (formTypeFilter[0]?.length) {
            query.filters.$or.push(...formTypeFilter[0].map((type: any) => ({ studyType: { $eq: type } })));
        }


        if (searchOrgFilter[0]?.length) {
            query.filters.$and.push({
                orgName: {
                    $containsi: searchOrgFilter[0]
                }
            });
        }

        if (searchDirectionFilter[0]?.length) {
            query.filters.$and.push({
                directionCodeOrName: {
                    $containsi: searchDirectionFilter[0]
                }
            });
        }


        dispatch(getRegistryList(query));
    }, [dispatch, pageSize, currentPage, programTypeFilter[0],
        formTypeFilter[0], searchOrgFilter[0], searchDirectionFilter[0]]);


    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setVisible(false);
        }, 3000);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    // @ts-ignore
    return (
        <div className={b()}>
            <StatisticModal
                handleCancel={handleCancel}
                visible={visible}
                handleOk={handleOk}
            />
            <div className="title">
                <h1 className="table-title">Основные профессиональные образовательные программы</h1>
                {
                    user.policies.some((el) => el.name === 'AccessToStatisticPolicy')
                    && (
                        <button
                            onClick={showModal}
                            className="statistic"
                        >
                            Статистика скачиваний по направлениям
                        </button>
                    )
                }
            </div>
            <div className="programs-table">
                <PaginationV2
                    total={total}
                    showLeftTotal
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onChangePage={onPageChange}
                    filtersChanged={filtersChanged}
                    onReset={onReset}
                />
                <TableV2
                    // @ts-ignore
                    columns={columns}
                    onRow={onRow}
                    dataSource={registryListCopy}
                    pagination={false}
                    loading={false}
                />
            </div>
        </div>
    );
};

export default OPOPRegistryStrapi;
