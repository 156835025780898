import React from 'react';
import bem from 'easy-bem';
import { Switch, Route } from 'react-router-dom';


import './style.less';
import AdminPanel from '../../components/admin-panel';


const AdminPage = () => {
    const b = bem('admin');

    return (
        <div className={b()}>
            <Route>
                <Switch>
                    <Route exact path="/admin">
                        <AdminPanel />
                    </Route>
                </Switch>
            </Route>
        </div>
    );
};

export default AdminPage;
