/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, Divider
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import { GenBlock } from 'components/generated-block/generatedBlock';
import DeleteBtn from 'components/delete-btn';
import expandArr from 'utils/expandArr';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import ButtonV3 from 'components/button-v3';
import Notification from 'components/notification';


const ActivityCharacteristicFormBlock = ({
    activityCharacteristicEmptyObj, activityCharacteristic = [], activityLaborFunc = [], activityTasks = [], form
}) => {
    const b = bem('activity-characteristic-form-block');
    const { t } = useTranslation('dev-educational-program');
    const psCodeNameList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf'
        ])
            .filter(({ psName, psCode }) => psName && psCode)
            .map(({ psName, psCode }) => ({
                psName,
                psCode,
                label: `${psCode}. ${psName}`,
                value: `${psCode}. ${psName}`,
            })),
        [activityLaborFunc]
    );
    const [isCodeDisabled, setCodeDisabled] = useState(false);
    useEffect(() => setCodeDisabled(!psCodeNameList?.length), [psCodeNameList]);

    const activityTasksList = useMemo(
        () => expandArr(activityTasks || [], [
            'tasks'
        ])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name,
            })),
        [activityTasks]
    );
    const [isTaskDisabled, setTaskDisabled] = useState(false);
    useEffect(() => setTaskDisabled(!activityTasksList?.length), [activityTasksList]);

    const [notification, setNotification] = useState([{}]);
    const checkFormData = () => {
        const selectedTasks = expandArr(activityCharacteristic || [], ['task']);
        const messages = (activityTasksList || [])
            .reduce((acc, { value }) => (
                selectedTasks.includes(value) ? acc : `${acc}\n- ${value}`
            ), '');
        if (messages) {
            return setNotification([{
                type: 'error',
                message: `В блоке 5 не выбраны следующие задачи профессиональной деятельности:${messages}`
            }]);
        }
        return setNotification([{
            type: 'success',
            message: 'Блок заполнен корректно'
        }]);
    };
    useEffect(() => setNotification([]), [activityTasksList, activityCharacteristic]);

    const commonLaborFuncList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf',
            'codeAndNameOfOtfTf',
        ])
            .filter(({ commonСode, commonName }) => commonСode && commonName)
            .map(({ commonСode, commonName }) => ({
                label: `${commonСode}. ${commonName}`,
                value: `${commonСode}. ${commonName}`
            })),
        [activityLaborFunc]
    );
    const [isCommonLaborFuncDisabled, setCommonLaborFuncDisabled] = useState(false);
    useEffect(() => setCommonLaborFuncDisabled(!commonLaborFuncList?.length), [commonLaborFuncList]);

    const laborFuncList = useMemo(
        () => expandArr(activityLaborFunc || [], [
            'codeAndNameOfPsOtfTf',
            'codeAndNameOfOtfTf',
            'laborFunctions',
        ])
            .filter(({ code, name }) => code && name)
            .map(({ code, name }) => ({
                label: `${code}. ${name}`,
                value: `${code}. ${name}`
            })),
        [activityLaborFunc]
    );
    const [islaborFuncDisabled, setlaborFuncDisabled] = useState(false);
    useEffect(() => setlaborFuncDisabled(!laborFuncList?.length), [laborFuncList]);

    // TODO: Временное решение. В mobx уже реализован
    useEffect(() => {
        const psCodes = psCodeNameList.map((item) => item?.value);
        const commonLaborFuncCodes = commonLaborFuncList.map((item) => item?.value);
        const laborFuncCodes = laborFuncList?.map((item) => item?.value);
        const newActivityCharacteristic = activityCharacteristic?.map((item) => {
            const characteristic = { ...item };
            const isExistCode = psCodes.includes(characteristic?.code);
            if (!isExistCode) {
                characteristic.code = '';
            }
            characteristic.commonLaborFuncAndActions = characteristic?.commonLaborFuncAndActions?.map((item) => {
                const LaborFuncAndAction = { ...item };
                const isExistCommonLaborFunc = commonLaborFuncCodes?.includes(LaborFuncAndAction?.commonLaborFunc);
                if (!isExistCommonLaborFunc) {
                    LaborFuncAndAction.commonLaborFunc = '';
                }
                LaborFuncAndAction.laborFuncAndActions = LaborFuncAndAction?.laborFuncAndActions
                    ?.map((item) => {
                        if (typeof item?.laborActions === 'string') {
                            item.laborActions = [{ action: item?.laborActions || '' }];
                        }
                        const isExistLaborFunc = laborFuncCodes?.includes(item?.laborFunc);
                        return isExistLaborFunc ? item : { ...item, laborFunc: '' };
                    });
                return LaborFuncAndAction;
            });
            return characteristic;
        });
        form.setFieldsValue({ activityCharacteristic: newActivityCharacteristic });
    }, [activityLaborFunc]);

    useEffect(() => {
        const taskList = activityTasksList?.map((item) => item.value);

        const newActivityCharacteristic = activityCharacteristic?.map((item) => {
            const task = item?.task?.filter((item) => taskList?.includes(item));
            return { ...item, task };
        });

        form.setFieldsValue({ activityCharacteristic: newActivityCharacteristic });
    }, [activityTasks]);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-5')}: ${t('characteristic-with-ps')}`}
            </h5>

            <Form.List name="activityCharacteristic">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div
                                    className="form-items-group"
                                    key={key}
                                >
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('char-ps-code')}
                                                            name={[name, 'code']}
                                                            {...handleFieldEnableStatus(
                                                                !!psCodeNameList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('ps-name-code')}" 
                                                                    (${t('block-4')}) `}
                                                                </span>,
                                                                t('char-ps-code'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-char-ps-code')}
                                                                options={psCodeNameList}
                                                                disabled={isCodeDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <div className="item-block-group-3">
                                                    <p className="item-label">
                                                        {t('professional-activity-type-ps')}
                                                    </p>
                                                    <GenBlock
                                                        blockFieldName={name}
                                                        blockName="professionalActivityTypePs"
                                                        initialValueAdd={
                                                            activityCharacteristicEmptyObj?.professionalActivityTypePs?.[0]
                                                        }
                                                        {...restField}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'type']}
                                                        >
                                                            <InputV2
                                                                placeholder={t(
                                                                    'enter-professional-activity-type-ps'
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </GenBlock>
                                                </div>
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('professional-activity-tasks')}
                                                            name={[name, 'task']}
                                                            {...handleFieldEnableStatus(
                                                                !!activityTasksList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('pc-professional-activity-task')}" 
                                                                    (${t('block-2')}) `}
                                                                </span>,
                                                                t('professional-activity-tasks'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'select-professional-activity-task'
                                                                )}
                                                                options={activityTasksList}
                                                                disabled={isTaskDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.List
                                                    name={[name, 'commonLaborFuncAndActions']}
                                                >
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({
                                                                key, name, ...restField
                                                            }) => (
                                                                <Row
                                                                    gutter={{ md: 4, lg: 8 }}
                                                                    align="bottom"
                                                                    wrap={false}
                                                                >
                                                                    <Col flex="1">
                                                                        <div key={key} className="item-block-group">
                                                                            <Row>
                                                                                <Col flex="1">
                                                                                    <Form.Item
                                                                                        label={t('common-labor-func')}
                                                                                        name={[name, 'commonLaborFunc']}
                                                                                        {...handleFieldEnableStatus(
                                                                                            !!commonLaborFuncList?.length,
                                                                                            <span>
                                                                                                {`${t('fill-in-field-first')} 
                                                                                                "${t('labor-functions-common-name')}" 
                                                                                                (${t('block-4')}) `}
                                                                                            </span>,
                                                                                            t('common-labor-func')
                                                                                        )}
                                                                                        {...restField}
                                                                                    >
                                                                                        <Select
                                                                                            className="form-select"
                                                                                            size="large"
                                                                                            showSearch
                                                                                            notFoundContent={t('not-found-content-text')}
                                                                                            placeholder={t('select-common-labor-func')}
                                                                                            options={commonLaborFuncList}
                                                                                            disabled={isCommonLaborFuncDisabled}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                            <Divider />

                                                                            <Form.List
                                                                                name={[name, 'laborFuncAndActions']}
                                                                            >
                                                                                {(fields, { add, remove }) => (
                                                                                    <>
                                                                                        {fields.map(({
                                                                                            key, name, ...restField
                                                                                        }) => (
                                                                                            <Row
                                                                                                gutter={{ md: 4, lg: 8 }}
                                                                                                align="bottom"
                                                                                                wrap={false}
                                                                                            >
                                                                                                <Col flex="1">
                                                                                                    <div key={key} className="item-block-group">
                                                                                                        <Row gutter={[32, 16]}>
                                                                                                            <Col flex="1 1 769px">
                                                                                                                <Form.Item
                                                                                                                    label="Трудовая функция"
                                                                                                                    name={[name, 'laborFunc']}
                                                                                                                    {...handleFieldEnableStatus(
                                                                                                                        !!laborFuncList?.length,
                                                                                                                        <span>
                                                                                                                            {`${t('fill-in-field-first')} 
                                                                                                                            "${t('labor-functions-name')}" 
                                                                                                                            (${t('block-4')}) `}
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {...restField}
                                                                                                                >
                                                                                                                    <Select
                                                                                                                        className="form-select"
                                                                                                                        size="large"
                                                                                                                        showSearch
                                                                                                                        notFoundContent={t('not-found-content-text')}
                                                                                                                        placeholder={t('select-labor-func')}
                                                                                                                        options={laborFuncList}
                                                                                                                        disabled={islaborFuncDisabled}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                            <Col flex="1 1 769px">
                                                                                                                <p className="item-label">
                                                                                                                    Трудовые действия
                                                                                                                </p>
                                                                                                                <GenBlock
                                                                                                                    blockFieldName={name}
                                                                                                                    blockName="laborActions"
                                                                                                                    initialValueAdd={
                                                                                                                        activityCharacteristicEmptyObj?.commonLaborFuncAndActions?.[0]?.laborFuncAndActions?.[0]?.laborActions?.[0]
                                                                                                                    }
                                                                                                                    {...restField}
                                                                                                                >

                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[name, 'action']}
                                                                                                                    >
                                                                                                                        <InputV2
                                                                                                                            placeholder="Введите трудовое действие"
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                </GenBlock>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Form.Item>
                                                                                                        <DeleteBtn onClick={() => remove(name)} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        ))}
                                                                                        <Row wrap={false}>
                                                                                            <Col flex="1" align="right">
                                                                                                <Form.Item>
                                                                                                    <ButtonV2
                                                                                                        type="link"
                                                                                                        onClick={() => add(activityCharacteristicEmptyObj?.commonLaborFuncAndActions?.[0]?.laborFuncAndActions?.[0])}
                                                                                                        icon={<PlusOutlined />}
                                                                                                    >
                                                                                                        добавить еще блок ТФ и ТД
                                                                                                    </ButtonV2>
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </>
                                                                                )}
                                                                            </Form.List>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item>
                                                                            <DeleteBtn
                                                                                onClick={() => remove(name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row wrap={false}>
                                                                <Col flex="1" align="right">
                                                                    <Form.Item>
                                                                        <ButtonV2
                                                                            type="link"
                                                                            onClick={() => add(activityCharacteristicEmptyObj?.commonLaborFuncAndActions?.[0])}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {t('add-more-OTF')}
                                                                        </ButtonV2>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <Row align="middle" justify="space-between">
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(activityCharacteristicEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-block')}
                                </ButtonV2>
                                {!isTaskDisabled && (
                                    <ButtonV3
                                        onClick={checkFormData}
                                    />
                                )}
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Notification notifications={notification} />
        </section>
    );
};

export default ActivityCharacteristicFormBlock;
