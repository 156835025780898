import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Divider, Form, Row
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import DeleteBtn from 'components/delete-btn';
import { GenBlock } from 'components/generated-block/generatedBlock';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { ButtonV2, InputV2 } from '../../../../../../index';


const Content = ({ // 7.4, блок 2
    t, b, compulsoryDisciplines = [], formedDisciplines = [], stateExamContentsEmptyObj
}) => {
    const disciplineList = useMemo(
        () => ([
            ...compulsoryDisciplines,
            ...formedDisciplines
        ] || [])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name,
            })),
        [compulsoryDisciplines, formedDisciplines]
    );
    const [isDisciplineDisabled, setIsDisciplineDisabled] = useState(true);
    useEffect(() => setIsDisciplineDisabled(!disciplineList?.length), [disciplineList]);

    return (
        <section>
            <h5 className="subtitle">{t('content')}</h5>
            <Form.List name="stateExamContents">
                {(fields, { add, remove }) => (
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('item')} form-item`} key={key}>
                                <Row
                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                    align="bottom"
                                    wrap={false}
                                >
                                    <Col flex="1">
                                        <div className="item-block-group">
                                            <Form.Item
                                                label={t('module-name')}
                                                name={[name, 'discipline']}
                                                {...handleFieldEnableStatus(
                                                    !!disciplineList?.length,
                                                    <span>
                                                        {`Предварительно необходимо заполнить 
                                                        "${t('discipline')}" или 
                                                        "${t('discipline-module')}"
                                                        (блок "${t('structure')}")`}
                                                    </span>,
                                                    t('module-name'),
                                                )}
                                                {...restField}
                                            >
                                                <Select
                                                    className="form-select"
                                                    size="large"
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    placeholder={t('choose-earlier')}
                                                    options={disciplineList}
                                                    disabled={isDisciplineDisabled}
                                                />
                                            </Form.Item>

                                            <p className="item-label">{t('didactic')}</p>
                                            <div className="item-block-group-3">
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="didacticUnits"
                                                    initialValueAdd={stateExamContentsEmptyObj.didacticUnits[0]}
                                                    {...restField}
                                                >
                                                    <Row>
                                                        <Col flex="1">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'name']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('for-checking')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </GenBlock>
                                            </div>

                                            <Divider />

                                            <p className="section-desc">{t('recommended')}</p>
                                            <p className="items-group-title">{t('major')}</p>
                                            <div className="item-block-group-3">
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="mainLiteratureSources"
                                                    initialValueAdd={stateExamContentsEmptyObj.mainLiteratureSources[0]}
                                                    {...restField}
                                                >
                                                    <Row
                                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 4]}
                                                        wrap
                                                    >
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={t('surname')}
                                                                {...restField}
                                                                name={[name, 'author']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-surname')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={t('year-place')}
                                                                name={[name, 'placeAndYear']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-place')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col flex="1">
                                                            <Form.Item
                                                                label={t('name')}
                                                                {...restField}
                                                                name={[name, 'name']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-name')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </GenBlock>
                                            </div>

                                            <Divider />

                                            <p className="items-group-title">{t('extension')}</p>
                                            <div className="item-block-group-3">
                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="extraLiteratureSources"
                                                    initialValueAdd={
                                                        stateExamContentsEmptyObj.extraLiteratureSources[0]
                                                    }
                                                    {...restField}
                                                >
                                                    <Row
                                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 4]}
                                                        wrap
                                                    >
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={t('surname')}
                                                                {...restField}
                                                                name={[name, 'author']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-surname')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={t('year-place')}
                                                                name={[name, 'placeAndYear']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-place')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col flex="1">
                                                            <Form.Item
                                                                label={t('name')}
                                                                {...restField}
                                                                name={[name, 'name']}
                                                            >
                                                                <InputV2
                                                                    size="large"
                                                                    placeholder={t('enter-name')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </GenBlock>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(stateExamContentsEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </section>
    );
};

export default Content;
