import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Checkbox, Popover, Space
} from 'antd';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-outline.svg';


interface ParamsProps {
    values: { label: string, value: string}[] ;
    state: [string[] | undefined, Dispatch<SetStateAction<string[] | undefined>>]
}

const CheckboxGroup = Checkbox.Group;


const RadioFilter = ({ state, values }: ParamsProps) => {
    const [, set] = state;
    const [active, setActive] = useState<boolean>(false);
    
    const onChange = (list: any[]) => {
        set(list);
        
        if (list?.length) {
            setActive(true);
            return;
        }

        setActive(false);
    };

    return (
        <Popover
            placement="bottomRight"
            content={(
                <CheckboxGroup
                    onChange={onChange}
                >
                    <Space direction="vertical">
                        {values.map((value) => (
                            <Checkbox
                                key={value.value}
                                value={value.value}
                            >
                                {value.label}
                            </Checkbox>
                        ))}
                    </Space>
                </CheckboxGroup>
            )}
            trigger="click"
        >
            <FilterIcon className={active ? 'active' : ''} />
        </Popover>
    );
};

export default RadioFilter;


