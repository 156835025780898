import * as c from './constants';


export const updateStatusAction = (id, status) => ({
    type: c.updateStatus.tries,
    id,
    status
});
export const getDockListAction = (status) => ({
    type: c.getDockList.tries,
    status
});

export const setCompletedAction = (opop) => ({
    type: c.setCompleted.tries,
    opop
});
