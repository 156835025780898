/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
    Col, Divider, Form, Radio, Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { previewDoc } from '../../utils';
import { ButtonV2, InputV2 } from '../../../../../index';
import Next from '../../../../../next';
import { emptyDocDetails } from '../../constants';
import { useTranslation } from 'react-i18next';


const tabKey = '6.8';
const emptyObj = emptyDocDetails[tabKey];


const WorkProgramEducational = ({ onNextClick }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('work-educational');
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const workProgram = useSelector((state) => state.documents.documentDetails?.['6.1']?.workProgram);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className="constructor-form">
            <h4 className="title m-0">
                Учебно-методическое и информационное обеспечение
            </h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1
                    ? ''
                    : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <section>
                    <p className="item-label">
                        Учебная и учебно-методическая литература, учебно-методические и другие материалы в т. ч. электронные, необходимые для обучения по практике
                    </p>
                    <Form.List name="internships">
                        {(fields, { add, remove }) => (
                            <>
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className="form-item" key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'literature']}
                                                    >
                                                        <InputV2
                                                            textarea
                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                            placeholder="Оформление списка литературы должно соответствовать ГОСТ"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.internships[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            Добавить еще поле для заполнения
                                        </ButtonV2>
                                    </Form.Item>
                                </div>
                            </>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle">
                        Ресурсы сети Интернет, необходимые для обучения по практике
                    </h5>
                    <p className="text">
                        Информационно-справочные системы
                    </p>
                    <Form.List name="resources">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div key={key}>
                                        <Row
                                            align="bottom"
                                            className="m-20"
                                        >
                                            <Col span={22}>
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        {...restField}
                                                        label="Наименование"
                                                        name={[name, 'name']}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Ссылка на ресурс"
                                                        name={[name, 'link']}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Доступность"
                                                        name={[name, 'availability']}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value="свободный доступ">
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio value="ограниченный доступ">
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col offset={1} span={1}>
                                                <div className="item-action">
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.resources[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Добавить еще блок полей для заполнения
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <p className="text">
                        Электронно-библиотечные системы
                    </p>
                    <Form.List name="electronic">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div key={key}>
                                        <Row
                                            align="bottom"
                                            className="m-20"
                                        >
                                            <Col span={22}>
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        {...restField}
                                                        label="Наименование"
                                                        name={[name, 'name']}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Ссылка на ресурс"
                                                        name={[name, 'link']}
                                                    >
                                                        <InputV2
                                                            placeholder="Ссылка на ресурс"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Доступность"
                                                        name={[name, 'availability']}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value="свободный доступ">
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio value="ограниченный доступ">
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col offset={1} span={1}>
                                                <div className="item-action">
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.electronic[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Добавить еще блок полей для заполнения
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <p className="text">Профессиональные базы данных</p>
                    <Form.List name="data">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div key={key}>
                                        <Row
                                            align="bottom"
                                            className="m-20"
                                        >
                                            <Col span={22}>
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        {...restField}
                                                        label="Наименование"
                                                        name={[name, 'name']}
                                                    >
                                                        <InputV2
                                                            placeholder="Введите наименование"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Ссылка на ресурс"
                                                        name={[name, 'link']}
                                                    >
                                                        <InputV2
                                                            placeholder="Ссылка на ресурс"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        label="Доступность"
                                                        name={[name, 'availability']}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value="свободный доступ">
                                                                свободный доступ
                                                            </Radio>
                                                            <Radio value="ограниченный доступ">
                                                                ограниченный доступ
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col offset={1} span={1}>
                                                <div className="item-action">
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.data[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Добавить еще блок полей для заполнения
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </section>
            </Form>
            <Divider />

            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick(form)}
            />
        </div>
    );
};

export default WorkProgramEducational;
