import React, { useState, useEffect } from 'react';
import bem from 'easy-bem';
import {
    PlusOutlined
} from '@ant-design/icons';
import { useDebounce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ButtonV2, InputV2 } from 'components';
import MainDeveloperModal from 'components/main-developer-modal';
import { getUserList } from 'models/user/api';
import RemoveOPOPModal from '../remove-opop-modal';
import './style.less';
import ProgramsList from './programsList';


const has = (str, query) => str?.toLowerCase().search(query) !== -1;

const MyEducationalPrograms = ({ docs }) => {
    const b = bem('my-educational-programs');
    const history = useHistory();
    const [programList, setProgramList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [itemToRemove, setItemToRemove] = useState(null);
    const [itemToManage, setItemToManage] = useState(null);
    const [users, setUsers] = useState([]);
    const [usersClone, setUsersClone] = useState([]);
    useDebounce(() => {
        if (searchValue) {
            const trimQuery = searchValue.trim().toLowerCase();

            if (trimQuery?.length) {
                setProgramList(docs.filter((x) => has(x.direction, trimQuery)
                 || has(x.directionCodeOrName, trimQuery)));
            } else {
                setProgramList(docs);
            }
        }
    }, 500, [searchValue]);
    useEffect(() => {
        setProgramList(docs);
        getUserList().then((res) => {
            // Фильтрую, т.к. есть пользователи без полей имени и фамилии,
            // а так же дополнительной информации о пользователе
            const list = res.data.filter(
                (item) => item?.firstName && item?.lastName
            );

            setUsers(
                list
            );
            setUsersClone(list);
        });
    }, [docs]);
    const openDetails = (details) => {
        const path = details ? details.id : 'new-program';
        history.push(`/opop-constructor/${path}`);
    };

    const createNew = () => history.push('/opop-constructor/new-program');

    return (
        <div className={b()}>
            <MainDeveloperModal
                users={users}
                usersClone={usersClone}
                setUsersClone={setUsersClone}
                opopDetails={itemToManage}
                onClose={() => setItemToManage(null)}
            />
            <RemoveOPOPModal item={itemToRemove} setItem={setItemToRemove} list={docs} />
            <div className="header">
                <h2 className="title">Мои образовательные программы</h2>
                <ButtonV2 onClick={createNew} type="link" icon={<PlusOutlined size="12px" />}>
                    Разработать новую ОПОП
                </ButtonV2>
            </div>
            <div className="search">
                <InputV2
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Поиск по ОПОП"
                    size="large"
                    suffix={<SearchIcon className="search-icon" />}
                />
            </div>
            <div className="programs-list">
                <ProgramsList
                    programList={programList}
                    openDetails={openDetails}
                    setItemToRemove={setItemToRemove}
                    setItemToManage={setItemToManage}
                />
            </div>
        </div>
    );
};

export default MyEducationalPrograms;
