import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
    exact
    key="opop-registry-strapi"
    path={['/opop-registry', '/opop-registry/:id', '/opop-registry-strapi', '/opop-registry-strapi/:id']}
    component={Page}
/>;
