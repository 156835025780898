/* eslint-disable max-len */
import React, { useMemo } from 'react';
import {
    Col, Form, Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import { InputV2 } from 'components';
import { PlusOutlined } from '@ant-design/icons';
import Select from 'components/select';
import DeleteBtn from 'components/delete-btn';
import bem from 'easy-bem';
import { useSelector } from 'react-redux';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import expandArr from 'utils/expandArr';
import { ButtonV2 } from '../../../../../../index';


const DisciplineEvaluation = ({ // 5.9, блок 1
    evaluationEmptyObj
}) => {
    const { t } = useTranslation('dev-educational-program');
    const b = bem('work-discipline-logistics-tab');
    const { structure } = useSelector((state) => state.documents.documentDetails?.['5.6'] || {});

    const evaluationName = useMemo(
        () => (structure || [])
            .filter(({ moduleName }) => moduleName)
            .map(({ moduleName }) => ({
                label: moduleName,
                value: moduleName
            })),
        [structure]
    );

    const evaluationCodeIdk = useMemo(
        () => expandArr(structure || [], [
            'idkCodes'
        ])
            .filter((code) => code)
            .map((code) => ({
                label: code,
                value: code
            })),
        [structure]
    );

    return (
        <section>
            <h5 className="subtitle">
                {t('block-1')}
                : Текущий контроль успеваемости обучающихся
            </h5>
            <Form.List name="evaluation">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    label={t('name-dicipline-module')}
                                                    {...handleFieldEnableStatus(
                                                        !!evaluationName?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('name-dicipline-module')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.6' })}
                                                            >
                                                                {t('work-discipline-structure')}
                                                            </Link>
                                                        </span>,
                                                        t('name-dicipline-module')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder="Выберите наименование из ранее введенных"
                                                        options={evaluationName}
                                                        disabled={!evaluationName?.length}
                                                    />
                                                </Form.Item>

                                                <Form.List name={[name, 'list']}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            <div className="form-items-list">
                                                                {fields.map(({
                                                                    key, name, ...restField
                                                                }) => (
                                                                    <div
                                                                        className={`${b('activity-item')} form-item`}
                                                                        key={key}
                                                                    >
                                                                        <Row
                                                                            gutter={{ md: 4, lg: 8 }}
                                                                            align="bottom"
                                                                            wrap={false}
                                                                        >
                                                                            <Col flex="1">
                                                                                <div className="item-block-group-2">
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'form']}
                                                                                        label="Форма текущего контроля"
                                                                                    >
                                                                                        <InputV2
                                                                                            placeholder="Введите форму текущего контроля"
                                                                                        />
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'eval']}
                                                                                        label="Оценочные средства"
                                                                                    >
                                                                                        <InputV2
                                                                                            placeholder="Введите оценочные средства"
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item>
                                                                                    <DeleteBtn
                                                                                        onClick={() => remove(name)}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <Row wrap={false}>
                                                                <Col flex="1" align="right">
                                                                    <Form.Item>
                                                                        <ButtonV2
                                                                            type="link"
                                                                            onClick={() => add(
                                                                                evaluationEmptyObj.list[0]
                                                                            )}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            {t('add-more')}
                                                                        </ButtonV2>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col flex="33px" />
                                                            </Row>
                                                        </>
                                                    )}
                                                </Form.List>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'idkCode']}
                                                    label={t('code-idk')}
                                                    {...handleFieldEnableStatus(
                                                        !!evaluationCodeIdk?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('code-idk')}" 
                                                            ${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.6' })}
                                                            >
                                                                {t('work-discipline-structure')}
                                                            </Link>
                                                        </span>,
                                                        t('code-idk')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        placeholder="Выберите один или несколько кодов ИДК"
                                                        mode="multiple"
                                                        showSearch
                                                        options={evaluationCodeIdk}
                                                        notFoundContent={t('not-found-content-text')}
                                                        disabled={!evaluationCodeIdk?.length}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(evaluationEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineEvaluation;
