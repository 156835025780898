import React from 'react';
import { Col, Form, Row } from 'antd';
import './style.less';
import { useTranslation } from 'react-i18next';
import { ButtonV2 } from '../index';


const Next = ({ onPreview, onNextClick }) => {
    const { t } = useTranslation('dev-educational-program');
    return (
        <Row align="middle" gutter={[40, 24]} justify="end">
            <Col>
                <Form.Item>
                    <ButtonV2
                        onClick={onPreview}
                        type="link"
                    >
                        {t('preview')}
                    </ButtonV2>
                </Form.Item>
            </Col>
            <Col>
                <Form.Item>
                    <ButtonV2
                        onClick={onNextClick}
                        className="form-next-btn"
                        type="primary"
                    >
                        {t('next')}
                    </ButtonV2>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default Next;
