import React from 'react';
import ReactDOM from 'react-dom';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import './index.css';
import App from 'components/app';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';


Sentry.init({
    dsn: 'https://b31abd4ff3ab4546a73316c04d26baa8@o4503997968220160.ingest.sentry.io/4503997969399808',
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

  
ReactDOM.render(
    <React.StrictMode>
        <Wrapper>
            <App />
        </Wrapper>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
