/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Row, Col
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonV2 } from 'components';

import { useTranslation } from 'react-i18next';
import DeleteBtn from 'components/delete-btn';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import DisciplineNameSubformBlock from './discipline-name-subform-block';


const IdkCodeAndNameSubformBlock = ({
    codAndNameOfIdkEmptyObj, fieldName, selectedCommonCompCodeAndName = '', opkList = []
}) => {
    const b = bem('IDK-code-and-name-subform-block');
    const { t } = useTranslation('dev-educational-program');

    const idkList = useMemo(
        () => ((opkList || [])
            .find(({ value }) => value === selectedCommonCompCodeAndName)?.option || []),
        [selectedCommonCompCodeAndName, opkList]
    );
    const [isPsNameDisabled, setPsNameDisabled] = useState(false);
    useEffect(() => setPsNameDisabled(!selectedCommonCompCodeAndName || !idkList?.length),
        [selectedCommonCompCodeAndName, idkList]);

    return (
        <Form.List name={[fieldName, 'codAndNameOfIdk']}>
            {(fields, { add, remove }) => (
                <>
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('activity-item')} form-item`} key={key}>
                                <Row gutter={{ md: 4, lg: 8 }} align="bottom" wrap={false}>
                                    <Col flex="1">
                                        <div className="item-block-group-2">
                                            <Form.Item
                                                label={t(
                                                    'digit-tech-competence-achievement-indicator-code-and-name'
                                                )}
                                                name={[name, 'psName']}
                                                {...handleFieldEnableStatus(
                                                    !!idkList?.length,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                            "${t('cc-achievement-indicator-сode-and-name')}" 
                                                            (${t('chapter')} `}
                                                        <Link
                                                            to={(location) => ({ ...location, hash: '#1.4' })}
                                                        >
                                                            {t('educational-programs-development-results')}
                                                        </Link>
                                                        {`, ${t('block-2')})`}
                                                    </span>,
                                                    t('digit-tech-competence-achievement-indicator-code-and-name'),
                                                )}
                                                {...handleFieldEnableStatus(
                                                    !!selectedCommonCompCodeAndName,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                            "${t('digit-tech-comp-code-and-name')}" 
                                                            (${t('block-1')})`}
                                                    </span>,
                                                    t('digit-tech-competence-achievement-indicator-code-and-name'),
                                                )}
                                                {...restField}
                                            >
                                                <Select
                                                    className="form-select"
                                                    size="large"
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    placeholder={t(
                                                        'digit-tech-select-competence-achievement-indicator-code-and-name'
                                                    )}
                                                    options={idkList}
                                                    disabled={isPsNameDisabled}
                                                />
                                            </Form.Item>

                                            <DisciplineNameSubformBlock
                                                fieldName={name}
                                                parentIndex={fieldName}
                                                disciplineNameBlockEmptyObj={
                                                    codAndNameOfIdkEmptyObj.disciplineNameBlock[0]
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                    <Row wrap={false}>
                        <Col flex="1" align="right">
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(codAndNameOfIdkEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-IDK-code-and-name')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col flex="33px" />
                    </Row>
                </>
            )}
        </Form.List>
    );
};

export default IdkCodeAndNameSubformBlock;
