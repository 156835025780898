import * as c from '../constants';


const initialState = {
    isError: false,
    errorMessage: undefined,
    isAuthChecking: false,
    isAuth: false,
    accessToken: undefined,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case c.getOIDCInfo.tries:
        case c.getUserOIDC.tries:
        case c.getUserInfoOdata.tries:
        case c.loginUser.tries:
        case c.checkUser.tries:
        case c.loginAndCheckUser.tries:
        case c.logoutUser.tries: {
            return {
                ...initialState,
                isAuthChecking: true
            };
        }

        case c.getOIDCInfo.success:
        case c.getUserOIDC.success:
        case c.getUserInfoOdata.success:
        case c.checkUser.success: {
            return {
                ...initialState,
                isAuth: true,
            };
        }

        case c.loginUser.success: {
            const { accessToken } = action.payload;

            return {
                ...initialState,
                accessToken,
            };
        }

        case c.logoutUser.success: {
            return {
                ...initialState
            };
        }

        case c.getOIDCInfo.failure:
        case c.getUserOIDC.failre:
        case c.getUserInfoOdata.failre:
        case c.checkUser.failure:
        case c.loginUser.failure:
        case c.logoutUser.failure: {
            const { message, preventCheckUser } = action.payload;

            if (preventCheckUser) return { ...state, isAuthChecking: false };

            return {
                ...initialState,
                isError: true,
                errorMessage: message,
            };
        }

        default:
            return state;
    }
};

export default reducer;
