import * as c from './constants';


const initialState = {
    user: [],
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case c.setUser.success: {
            return {
                ...state,
                user: action.payload
            };
        }
        case c.setUserDoc.tries: {
            return {
                ...state,
                user: {
                    ...state.user,
                    policies: state.user.policies.map((policy) => {
                        if (policy.name === 'AccessToOwnListPolicy') {
                            return { ...policy, resources: [...policy.resources, action.payload] };
                        }
                        return policy;
                    })
                }
            };
        }
        case c.setMainUser2.tries: {
            const { opop, addUsers, user } = action.payload;
            return {
                ...state,
                user: {
                    ...state.user,
                    policies: state.user.policies.map((policy) => {
                        if (policy.name === 'AccessToOwnListPolicy') {
                            return { ...policy, resources: policy.resources.filter((x) => x !== opop?.id) };
                        }
                        if (policy.name === 'AccessToListPolicy') {
                            policy.resources.push(opop?.id);
                            return { ...policy, resources: policy.resources };
                        }
                        return policy;
                    })
                }
            };
        }
        default:
            return state;
    }
};

export default reducers;
