/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import React, { ReactNode } from 'react';
import * as XLSX from 'xlsx';
import uniq from 'lodash/uniq';
import { getDirectoryListMock } from 'models/documents/api';
import { emptyDocDetails } from '../../../components/opop-constructor/components/dev-educational-program/constants';


const profiles = [
    'направленность (профиль) программы', 'направленность (профиль)', 'профиль', 'специализация', 'профиль подготовки'
];
const allText = {
    ze: 'з.е.',
    fact: 'Факт',
    disciplinesModulesText: 'Блок 1.Дисциплины (модули) ',
    mandatoryPartText: 'Обязательная часть ',
    partFormedByParticipantsInEduRelationsText: 'Часть, формируемая участниками образовательных отношений ',
    practiceText: 'Блок 2.Практика ',
    block2: 'Блок 2',
    stateFinalCertificationText: 'Блок 3.Государственная итоговая аттестация ',
    name: 'Наименование',
    contentText: 'Содержание',
    competence: 'компетенции',
    secondCompetence: 'компетенции(2)',
    plan: 'план',
    specialties: 'Специальности',
    directionOfTraining: 'Специальности',
};

export const XLSXParser = () => {
    // xlsx data
    let titlePage: Array<any> = [];
    let planPage: Array<any> = [];
    let competencePage: Array<any> = [];
    let secondCompetencePage: Array<any> = [];
    let mockDirectory: any = [];

    const loadParsingData = async (xlsxData: string, details: any = {}) => {
        reset();
        try {
            const readedData = XLSX.read(xlsxData, { type: 'binary' });
            const newObj = Object.fromEntries(
                Object.entries(readedData.Sheets).map(([k, v]) => [k.toLowerCase(), v])
            );

            const wsname = readedData.SheetNames[0];
            const title = readedData.Sheets[wsname];
            const comp = newObj[allText.competence];
            const secondComp = newObj[allText.secondCompetence];
            const plan = newObj[allText.plan];
            mockDirectory = await getDirectoryListMock();

            titlePage = XLSX.utils.sheet_to_json(title, { header: 1 });
            competencePage = XLSX.utils.sheet_to_json(comp, { header: 1 });
            planPage = XLSX.utils.sheet_to_json(plan, { header: 1 });
            secondCompetencePage = XLSX.utils.sheet_to_json(secondComp, { header: 1 });
            const founder = getFounderOfTheUniversity();
            const { directionCodeOrName, by } = getDirectionCodeAndName();
            const { qualification, formOfEducation } = getQualificationAndFormEdu();
            const accessYear = parseInt(getYearOfAdmission(), 10);
            const direction = getDirectionProfileSpecialization();

            const modulesCredits = getDisciplinesModules();
            const modulesRequiredCredits = getMandatoryPart();
            const modulesFormedCredits = getPartFormedByParticipantsInEduRelations();
            const practiceCredits = getPractice();
            const practiceRequiredCredits = getB2mandatoryPart();
            const practiceFormedCredits = getB2partFormedByParticipantsInEduRelations();
            const examCredits = getStateFinalCertification();
            const programByCourse = getCourseCU1();

            const obj = {
                1.1: {
                    ...emptyDocDetails[1.1],
                    ...details[1.1],
                    founder,
                    directionCodeOrName,
                    by,
                    qualification,
                    studyForm: formOfEducation,
                    accessYear,
                    direction
                },
                1.5: {
                    ...emptyDocDetails[1.5],
                    ...details[1.5],
                    modulesCredits,
                    modulesRequiredCredits,
                    modulesFormedCredits,
                    practiceFormedCredits,
                    practiceCredits,
                    practiceRequiredCredits,
                    examCredits,
                    programByCourse,
                },
                xlsxData: {
                    fileType: 'excel',
                    titlePage,
                    competencePage,
                    planPage,
                    secondCompetencePage,
                },
            };
            return obj;
        } catch (e) {
            console.error('error', e);
        }
    };

    const setPagesData = (data: any = {}) => {
        titlePage = data.titlePage || [];
        planPage = data.planPage || [];
        competencePage = data.competencePage || [];
        secondCompetencePage = data.secondCompetencePage || [];
    };

    const reset = () => {
        titlePage = [];
        planPage = [];
        competencePage = [];
        secondCompetencePage = [];
    };

    const clearForm = (docDetails: any) => {
        const {
            1.1: titleInfo = {},
            1.3: profCharacteristic = {},
            1.4: resultEduPrograms = {},
            1.5: structureEduPrograms = {},
            '2.0': compentenceModel = {},
            5.1: titleInfoRpd = {},
            5.3: planningResultDiscipline = {},
            5.5: volumeDiscipline = {},
            6.1: titleInfoRpp = {},
            6.2: generalCharacteristic = {},
            6.3: planningLearnEdu = {},
        } = docDetails;

        const founder = getFounderOfTheUniversity();
        const { directionCodeOrName, by } = getDirectionCodeAndName();
        const { qualification, formOfEducation } = getQualificationAndFormEdu();
        const accessYear = parseInt(getYearOfAdmission(), 10);
        const direction = getDirectionProfileSpecialization();

        // 1.5
        const modulesCredits = getDisciplinesModules();
        const modulesRequiredCredits = getMandatoryPart();
        const modulesFormedCredits = getPartFormedByParticipantsInEduRelations();
        const practiceCredits = getPractice();
        const practiceRequiredCredits = getB2mandatoryPart();
        const practiceFormedCredits = getB2partFormedByParticipantsInEduRelations();
        const examCredits = getStateFinalCertification();

        // 5.1, 5.5, 5.3
        let newVolumeDiscipline = { ...volumeDiscipline };
        const newTitleInfoRpd = { ...titleInfoRpd };
        const disciplineModuleList = getCodeAndNameDisciplineModule();
        const hasDiscipline = disciplineModuleList.some(
            (item: any) => item.value === titleInfoRpd.disciplineCodeAndName
        );

        if (hasDiscipline) {
            newTitleInfoRpd.disciplineCodeAndName = '';
            planningResultDiscipline.results = planningResultDiscipline.results.map((item: any) => {
                const list = item.list.map((codeIdk: any) => ({ ...codeIdk, codeAndNameIndicator: '' }));
                return { list, codeAndName: '' };
            });
            newVolumeDiscipline = { ...emptyDocDetails[5.5] };
        }

        // 6.1, 6.2, 6.3
        let newGeneralCharacteristic = { ...generalCharacteristic };
        const newTitleInfoRpp = { ...titleInfoRpp };
        const typeOfPracticeList = getTypeOfPractice();
        const hasPractice = typeOfPracticeList.some((item: any) => item.practiceType === titleInfoRpp.type);

        if (hasPractice) {
            newTitleInfoRpp.type = '';
            newTitleInfoRpp.workProgram = '';
            planningLearnEdu.tasks = planningLearnEdu.tasks.map((item: any) => {
                const endToEndDigitlTech = item.endToEndDigitlTech.map((tech: any) => ({ ...tech, idkCode: '' }));
                return {
                    pickCode: '',
                    endToEndDigitlTech,
                };
            });
            newGeneralCharacteristic = { ...emptyDocDetails[6.2] };
        }

        return {
            ...docDetails,
            1.1: {
                ...titleInfo,
                founder: isEqualNumber(founder, titleInfo.founder),
                direction: isEqualNumber(direction, titleInfo.direction),
                by: isEqualNumber(by, titleInfo.by),
                directionCodeOrName: isEqualNumber(directionCodeOrName, titleInfo.directionCodeOrName),
                qualification: isEqualNumber(qualification, titleInfo.qualification),
                formOfEducation: isEqualNumber(formOfEducation, titleInfo.formOfEducation),
                accessYear: isEqualNumber(accessYear, titleInfo.accessYear),
            },
            1.3: {
                ...profCharacteristic,
                activityAreaSphere: isEqualArray(
                    getProfArea(), profCharacteristic.activityAreaSphere, 'activityAreaSphere', '1.3'
                ),
                activityTasks: isEqualArray(
                    getProfActivity(), profCharacteristic.activityTasks, 'activityTasks', '1.3'
                ),
                activityMainAreas: isEqualArray(
                    getCodeAndNameOfProf(), profCharacteristic.activityMainAreas, 'activityMainAreas', '1.3'
                ),
                activityLaborFunc: isEqualArray(
                    getProfSpheres(), profCharacteristic.activityLaborFunc, 'activityLaborFunc', '1.3'
                ),
            },
            1.4: {
                ...resultEduPrograms,
                universalComp: isEqualArray(
                    getNamesCategoryUK(), resultEduPrograms.universalComp, 'universalComp', '1.4'
                ),
                profComp: isEqualArray(getNamesCategoryPK(), resultEduPrograms.profComp, 'profComp', '1.4'),
                commonComp: isEqualArray(getNamesCategoryOPK(), resultEduPrograms.commonComp, 'commonComp', '1.4'),
            },
            1.5: {
                ...structureEduPrograms,
                modulesCredits: isEqualNumber(modulesCredits, structureEduPrograms.modulesCredits),
                modulesRequiredCredits: isEqualNumber(
                    modulesRequiredCredits, structureEduPrograms.modulesRequiredCredits
                ),
                modulesFormedCredits: isEqualNumber(modulesFormedCredits, structureEduPrograms.modulesFormedCredits),
                practiceFormedCredits: isEqualNumber(practiceFormedCredits, structureEduPrograms.practiceFormedCredits),
                practiceCredits: isEqualNumber(practiceCredits, structureEduPrograms.practiceCredits),
                practiceRequiredCredits: isEqualNumber(
                    practiceRequiredCredits, structureEduPrograms.practiceRequiredCredits
                ),
                examCredits: isEqualNumber(examCredits, structureEduPrograms.examCredits),
            },
            '2.0': {
                ...emptyDocDetails['2.0'],
                competenceCommon: isEqualCompetenceModelOfGraduate(
                    getDisciplineModules(), compentenceModel, 'competenceCommon'
                ),
                competenceProf: isEqualCompetenceModelOfGraduate(
                    getDisciplineModules(), compentenceModel, 'competenceProf'
                ),
            },
            5.1: newTitleInfoRpd,
            5.3: planningResultDiscipline,
            5.5: newVolumeDiscipline,
            6.1: newTitleInfoRpp,
            6.2: newGeneralCharacteristic,
            6.3: planningLearnEdu,
        };
    };

    const isEqualNumber = (a: any, b: any): any => {
        if (a === b) {
            return '';
        }
        return b;
    };

    const isEqualCompetenceModelOfGraduate = (initialArray: any, receivedObject: any, fileName: string) => {
        const set = new Set();
        for (const receivedData of initialArray) set.add(receivedData);
        return receivedObject[fileName]?.map((item: any) => {
            item.disciplinesForStudying = item.disciplinesForStudying
                .filter((discipline: any) => !set.has(discipline.disciplines || discipline.discipline));
            if (!item.disciplinesForStudying.length) {
                item.disciplinesForStudying.push({ disciplines: '' });
            }
            return item;
        });
    };

    const isEqualArray = (options: any, details: any, arrName: string, key: string) => {
        const set = new Set();
        for (const { label } of options) set.add(label);
        const intersection = details?.filter(({ name, namePc }: any) => !set.has(name || namePc));
        if (intersection?.length) {
            return intersection;
        }
        return [emptyDocDetails[key]?.[arrName]];
    };

    const getFounderOfTheUniversity = () => {
        let res = '';
        for (const data of titlePage) {
            res = data.find((item: any) => item && typeof item === 'string') || '';
            if (res) break;
        }
        return res;
    };

    const getDirectionCodeAndName = () => {
        const regexWithStr = /[а-я]*\s*\d{2}.\d{2}.\d{2} /;
        const regex = /\d{2}.\d{2}.\d{2}$/;
        let res = '';
        for (const data of titlePage) {
            for (const j of data) {
                if (j && typeof j === 'string') {
                    const arr = j.split(/\r\n|\r|\n/g);
                    for (const item of arr) {
                        if (regexWithStr.test(item)) {
                            const right = (RegExp.rightContext).split(' ');
                            const arrItem = item.split(' ');
                            arrItem.splice(arrItem.length - right.length, right.length);
                            res = `${arrItem.at(-1)} ${RegExp.rightContext}`;
                            break;
                        }
                    }
                    if (res) break;
                }
            }
            if (res) break;
        }
        if (res) {
            const by = res.split('.')[1] == '05' ? allText.specialties : allText.directionOfTraining;
            return { directionCodeOrName: res, by };
        }
        for (const data of titlePage) {
            const codeDirectory = data.find((item: any) => item && typeof item === 'string' && regex.test(item)) || '';
            if (codeDirectory) {
                const directory = mockDirectory.find((item: any) => item.code === codeDirectory);
                if (directory) {
                    res = `${directory.code} ${directory.direction}`;
                }
                break;
            }
        }
        if (res) {
            const by = res.split('.')[1] == '05' ? allText.specialties : allText.directionOfTraining;
            return { directionCodeOrName: res, by };
        }
        return {};
    };

    const getDirectionProfileSpecialization = () => {
        let res = '';
        let direction = '';
        for (const cols of titlePage) {
            res = cols.find((item: any, index: number) => {
                let bool = false;
                if (item && typeof item === 'string') {
                    for (const prof of profiles) {
                        if (item.toLocaleLowerCase()?.includes(prof)) {
                            const text = item.split(prof).at(-1)?.split(' ') || [];
                            direction = text?.length > 1 ? text.slice(1).join(' ') : cols[index + 1];
                            bool = true;
                            break;
                        }
                    }
                }
                return bool;
            }) || '';
            if (res) {
                break;
            }
        }
        return direction || '';
    };

    const getQualificationAndFormEdu = () => {
        const qualificationText = 'квалификация: ';
        const formOfEducationText = 'форма обучения: ';
        let qualification = '';
        let formOfEducation = '';
        for (const cols of titlePage) {
            if (!qualification) {
                const res = cols.find((item: any) => (
                    item
                    && typeof item === 'string'
                    && item.toLocaleLowerCase()?.includes(qualificationText)
                ));
                if (res) {
                    qualification = res.split(qualificationText).at(-1);
                }
            }

            if (!formOfEducation) {
                const res = cols.find((item: any) => (
                    item
                    && typeof item === 'string'
                    && item.toLocaleLowerCase().includes(formOfEducationText)
                ));
                if (res) {
                    formOfEducation = res.split(formOfEducationText).at(-1);
                }
            }

            if (qualification && formOfEducation) break;
        }
        return { qualification, formOfEducation };
    };

    const getYearOfAdmission = () => {
        const text1YearOfAdmission = 'год приема';
        const text2YearOfAdmission = 'год начала подготовки (по учебному плану)';

        let index = -1;
        let res: any = '';

        for (const cols of titlePage) {
            const arrCols = Object.values(cols);
            if (index === -1) {
                index = arrCols.findIndex((item) => (
                    item
                    && typeof item === 'string'
                    && (item.toLocaleLowerCase().includes(text1YearOfAdmission)
                        || item.toLocaleLowerCase().includes(text2YearOfAdmission))
                ));
            }
            if (index > -1) {
                res = arrCols[index + 1];
                break;
            }
        }
        return res;
    };

    const getDisciplineModules = () => {
        const newData = [...secondCompetencePage];
        const array: string[] = [];
        const name = newData[0].indexOf('Наименование');
        newData.map((item) => {
            const index = item.findIndex((el: any) => Boolean(el));
            if (item[index]?.startsWith('ФТД') && item[index]?.split('.')?.length - 1 > 0) {
                array.push(`${item[index]} ${item[name]}`);
            } else if (item[index]?.split('.')?.length - 1 > 1) {
                array.push(`${item[index]} ${item[name]}`);
            }
        });
        return array;
    };

    // 5.1
    const getCodeAndNameDisciplineModule = () => {
        const data = getDisciplineModules();
        const newData = data?.map((item) => item.split(' '));
        const array: any[] = [];
        newData.forEach((item) => {
            if (!item[0].includes('(')) {
                array.push(item.join(' '));
            }
        });
        return array.map((item: string) => ({
            label: item,
            value: item,
        }));
    };

    // 6.1
    const getTypeOfPractice = () => {
        const data = getDisciplineModules();
        const newData = data?.map((item) => item.split(' '));
        const array: any[] = [];
        newData.map((item) => {
            if (item[0].includes('(')) {
                array.push({ practiceType: item.join(' ') });
            }
        });
        return array;
    };

    // 6.2
    const getWorkingProgram = (data: any) => {
        if (data?.includes('(У)')) {
            return 'учебной практики';
        } if (data?.includes('(П)')) {
            return 'производственной практики';
        }
    };

    // 6.3
    const getCompetencyAndNameRpp = (disciplineName: string) => {
        const searchingCompetence = disciplineName.split(' ')?.[0];
        let foundedCompetence = [];
        for (let i = 0; i < secondCompetencePage.length; i += 1) {
            if (secondCompetencePage?.[i]?.find((item) => item === searchingCompetence)) {
                foundedCompetence = secondCompetencePage?.[i];
            }
        }
        const competenceAr = foundedCompetence?.[foundedCompetence.length - 1];
        const formedCompetencies = competenceAr?.replaceAll('; ', ';').split(';');

        const newFormedCompetencies = formedCompetencies?.map((item) => {
            if (item.includes('.')) {
                return item.substring(0, item.indexOf('.'));
            }
            return item;
        });
        return uniq(newFormedCompetencies || []);
    };

    // 5.3
    const getCompetencyAndName = (disciplineName: string) => {
        const searchingCompetence = disciplineName.split(' ')?.[0];
        let foundedCompetence = [];
        for (let i = 0; i < secondCompetencePage.length; i += 1) {
            if (secondCompetencePage?.[i]?.find((item) => item === searchingCompetence)) {
                foundedCompetence = secondCompetencePage?.[i];
            }
        }
        const competenceAr = foundedCompetence?.[foundedCompetence.length - 1];
        const formedCompetencies = competenceAr?.replaceAll('; ', ';').split(';');

        const newFormedCompetencies = formedCompetencies?.map((item) => {
            if (item.includes('.')) {
                return item.substring(0, item.indexOf('.'));
            }
            return item;
        });
        return uniq(newFormedCompetencies || []);
    };

    const getCompetencyAndName6 = (data: string, value: string) => {
        const searchingCompetence = data.split(' ')?.[0];
        let foundedCompetence = [];
        for (let i = 0; i < secondCompetencePage.length; i += 1) {
            if (secondCompetencePage?.[i]?.find((item) => item === searchingCompetence)) {
                foundedCompetence = secondCompetencePage?.[i];
            }
        }
        const competenceAr = foundedCompetence?.[foundedCompetence.length - 1];
        const formedCompetencies = competenceAr?.replaceAll('; ', ';').split(';');
        const newFormedCompetencies = formedCompetencies?.filter(
            (item) => item.includes('.') && item.startsWith(value)
        );
        return uniq(newFormedCompetencies).map((item: any) => ({
            label: item,
            value: item,
        })) || [];
    };

    // 5.5
    const getCoursesAndSemesters = (data: any) => {
        const searchingDisciplineCodeAndName = data?.split(' ')?.[0];
        const dataString = planPage.find(
            (item) => item?.[1] === searchingDisciplineCodeAndName || item?.[0] === searchingDisciplineCodeAndName
        );
        const foundedZEIndexes: any[] = [];
        const foundedLekIndexes: any[] = [];
        const foundedPrIndexes: any[] = [];
        const foundedLabIndexes: any[] = [];
        const foundedSrIndexes: any[] = [];
        const foundedSemIndexes: any[] = [];
        const foundedKontIndexes: any[] = [];
        const foundedCourses = [];
        const indexName = 'Индекс';
        for (let i = 0; i < planPage?.length; i += 1) {
            const id = planPage[i].findIndex((item) => item === indexName);
            if (id !== -1) {
                const dataString = planPage.find((item) => item?.[id] === searchingDisciplineCodeAndName);
                const cell = planPage?.[i];
                cell?.map((item, id) => {
                    if (item === 'з.е.') {
                        foundedZEIndexes.push(id);
                    } else if (item === 'Лек') {
                        foundedLekIndexes.push(id);
                    } else if (item === 'Пр') {
                        foundedPrIndexes.push(id);
                    } else if (item === 'Лаб') {
                        foundedLabIndexes.push(id);
                    } else if (item === 'Сем') {
                        foundedSemIndexes.push(id);
                    } else if (item === 'СР') {
                        foundedSrIndexes.push(id);
                    } else if (item === 'Конт роль') {
                        foundedKontIndexes.push(id);
                    }
                });
                for (let j = 0; j < foundedZEIndexes.length; j++) {
                    const course = dataString?.[foundedZEIndexes?.[j]];
                    const lecture = dataString?.[foundedLekIndexes?.find((item) => item > foundedZEIndexes?.[j])] || 0;
                    const labsHours = dataString?.[
                        foundedLabIndexes?.find((item) => item > foundedZEIndexes?.[j])
                    ] || 0;
                    const practikum = dataString?.[foundedPrIndexes?.find((item) => item > foundedZEIndexes?.[j])] || 0;
                    const seminar = dataString?.[foundedSemIndexes?.find((item) => item > foundedZEIndexes?.[j])] || 0;
                    const practicesHours = parseInt(practikum, 10) + parseInt(seminar, 10);
                    const independetWork = dataString?.[
                        foundedSrIndexes?.find((item) => item > foundedZEIndexes?.[j])
                    ] || 0;
                    const intermediateCertification = dataString?.[
                        foundedKontIndexes?.find((item) => item > foundedZEIndexes?.[j])
                    ] || 0;
                    const semester = j + 1;
                    let currentCourse;
                    if (course && j < 2) {
                        currentCourse = 1;
                    } else if (course && j >= 2 && j <= 3) {
                        currentCourse = 2;
                    } else if (course && j > 3 && j <= 5) {
                        currentCourse = 3;
                    } else if (course && j > 5 && j <= 7) {
                        currentCourse = 4;
                    }
                    currentCourse && foundedCourses.push({
                        course: currentCourse, semester, lecture, practicesHours,
                        labsHours, independetWork, intermediateCertification
                    });
                }
                break;
            }
        }
        return uniq(foundedCourses);
    };

    const getCoursesAndSemesters6 = (data: any) => {
        const searchingDisciplineCodeAndName = data?.split(' ')?.[0];
        const dataString = planPage.find(
            (item) => item?.[1] === searchingDisciplineCodeAndName || item?.[0] === searchingDisciplineCodeAndName
        );
        const foundedZEIndexes: any[] = [];
        const foundedCourses = [];
        const cell = planPage?.[2];
        cell?.map((item, id) => {
            if (item === 'з.е.') {
                foundedZEIndexes.push(id);
            }
        });
        for (let i = 0; i < foundedZEIndexes.length; i++) {
            const course = dataString?.[foundedZEIndexes?.[i]];
            const semester = i + 1;
            if (course && i < 2) {
                foundedCourses.push({ course: 1, semester });
            } else if (course && i >= 2 && i <= 3) {
                foundedCourses.push({ course: 2, semester });
            } else if (course && i > 3 && i <= 5) {
                foundedCourses.push({ course: 3, semester });
            } else if (course && i > 5 && i <= 7) {
                foundedCourses.push({ course: 4, semester });
            }
        }
        return uniq(foundedCourses);
    };

    const getFormOfIntermediateCertification = (data: any) => {
        const formName = 'форма контроля';
        const ZE = 'з.е.';
        const formIndexes = [];
        const foundedNames = [];
        const searchingDisciplineCodeAndName = data?.split(' ')?.[0];
        const dataString = planPage.find((item) => item?.[1] === searchingDisciplineCodeAndName
            || item?.[0] === searchingDisciplineCodeAndName);
        const stringName = Object.entries(planPage?.[0] || {});
        for (const item of stringName) {
            if (item?.[1]?.toLowerCase() === formName) {
                formIndexes.push(item?.[0]);
            }
            if (item?.[1]?.toLowerCase() === ZE) {
                formIndexes.push(item?.[0]);
            }
        }
        for (let i = Number(formIndexes?.[0]); i < Number(formIndexes?.[1]); i += 1) {
            if (dataString?.[i]) {
                foundedNames.push(planPage?.[2]?.[i]);
            }
        }
        const changedFoundedNames = foundedNames?.map((item) => {
            if (item.startsWith('Экза')) return 'Экзамен';
            if (item.startsWith('Зачет с оц')) return 'Зачет с оценкой';
            return item;
        });
        const response = changedFoundedNames?.map((value) => ({ value, label: value }));
        return response;
    };

    // 1.3
    const getProfArea = () => {
        const regeExp = /^КОД/gmi;
        const regexNum = /^[0-9]{2}$/gm;
        let colIndex = -1;
        let rowIndex = -1;
        const data = [];

        for (let i = 0; i < titlePage.length; i++) {
            for (let k = 0; k < titlePage[i].length; k++) {
                if (regeExp.test(titlePage[i][k])) {
                    colIndex = k;
                    rowIndex = i;
                    break;
                }
            }
            if (colIndex > -1 && rowIndex > -1) {
                break;
            }
        }

        for (let i = rowIndex + 1; i < titlePage.length; i++) {
            if (!titlePage[i].length) {
                break;
            }
            if (regexNum.test(titlePage[i][colIndex])) {
                data.push({
                    label: `${titlePage[i][colIndex]} ${titlePage[i][colIndex + 1]}`,
                    value: `${titlePage[i][colIndex]} ${titlePage[i][colIndex + 1]}`,
                });
            }
        }
        return data;
    };

    const getProfSpheres = () => {
        const regeExp = /^КОД/gmi;

        let rowIndex = -1;
        let colIndex = -1;
        const data = [];

        for (let i = 0; i < titlePage.length; i++) {
            for (let j = 0; j < titlePage[i].length; j++) {
                if (regeExp.test(titlePage[i][j])) {
                    colIndex = j;
                    rowIndex = i;
                    break;
                }
            }
            if (rowIndex > -1 && colIndex > -1) {
                break;
            }
        }

        if (rowIndex === -1 && colIndex === -1) {
            return [];
        }

        for (let i = rowIndex + 1; i < titlePage.length; i++) {
            if (!titlePage[i].length) {
                break;
            }
            if (!titlePage[i][colIndex] && titlePage[i][colIndex + 1]) {
                data.push({
                    label: titlePage[i][colIndex + 1],
                    value: titlePage[i][colIndex + 1],
                });
            }
        }
        return data;
    };

    const getCodeAndNameOfProf = () => {
        const regeExp = /^КОД/gmi;
        const regexNum = /\d{2}\.\d{3}/m;
        let rowIndex = -1;
        let colIndex = -1;
        const data = [];

        for (let i = 0; i < titlePage.length; i++) {
            for (let k = 0; k < titlePage[i].length; k++) {
                if (regeExp.test(titlePage[i][k])) {
                    colIndex = k;
                    rowIndex = i;
                    break;
                }
            }
            if (colIndex > -1 && rowIndex > -1) {
                break;
            }
        }

        if (rowIndex === -1 && colIndex === -1) {
            return [];
        }

        for (let i = rowIndex + 1; i < titlePage.length; i++) {
            if (!titlePage[i].length) {
                break;
            }
            if (regexNum.test(titlePage[i])) {
                data.push({
                    label: `${titlePage[i][colIndex]} ${titlePage[i][colIndex + 1]}`,
                    value: `${titlePage[i][colIndex + 1]}`,
                    code: titlePage[i][colIndex],
                });
            }
        }
        return data;
    };

    const getProfActivity = () => {
        const regeExp = /^Типы задач профессиональной деятельности/gmi;
        let rowIndex = -1;
        let colIndex = -1;
        const data = [];

        for (let i = 0; i < titlePage.length; i++) {
            for (let k = 0; k < titlePage[i].length; k++) {
                if (regeExp.test(titlePage[i][k])) {
                    colIndex = k;
                    rowIndex = i;
                    break;
                }
            }
            if (colIndex > -1 && rowIndex > -1) {
                break;
            }
        }

        if (rowIndex === -1 && colIndex === -1) {
            return [];
        }

        for (let i = rowIndex + 1; i < titlePage.length; i++) {
            if (!titlePage[i].length) {
                break;
            }
            data.push({
                label: titlePage[i][colIndex],
                value: titlePage[i][colIndex],
            });
        }
        return data;
    };

    // 1.4
    const getIndexOfContent = () => {
        const contentText = 'содержание';
        let contentColIdx = -1;

        for (const item of competencePage) {
            if (contentColIdx <= -1) {
                contentColIdx = item.findIndex(
                    (text: any) => typeof text === 'string' && text.toLowerCase() === contentText
                );
            }
            if (contentColIdx > -1) break;
        }
        return contentColIdx;
    };

    const getXlsNamesCategories = (regex: any) => {
        const data = [];
        const contentColIdx = getIndexOfContent();

        for (const [parentIndex, item] of competencePage.entries()) {
            for (const [index, str] of item.entries()) {
                if (regex.test(str)) {
                    data.push({
                        label: `${str}`,
                        value: `${str}`,
                        name: item[contentColIdx],
                        index,
                        parentIndex,
                    });
                }
            }
        }
        return data;
    };

    const getNamesCategoryUK = () => {
        const regex = /^УК-\d+$/m;
        return getXlsNamesCategories(regex);
    };

    const getNamesCategoryOPK = () => {
        const regex = /^ОПК-\d+$/m;
        return getXlsNamesCategories(regex);
    };

    const getNamesCategoryPK = () => {
        const regex = /^ПК-\d+$/m;
        const data = [];
        const contentColIdx = getIndexOfContent();

        for (const [parentIndex, item] of competencePage.entries()) {
            for (const [index, str] of item.entries()) {
                if (regex.test(str)) {
                    data.push({
                        label: `${str} ${item[contentColIdx]}`,
                        value: `${str} ${item[contentColIdx]}`,
                        code: str,
                        index,
                        parentIndex,
                    });
                }
            }
        }
        return data;
    };

    const getCodeAndNameOfIndicator = (code: string, index: number, parentIndex: number) => {
        const data = [];
        const contentIndex = getIndexOfContent();
        for (let i = parentIndex + 1; i < competencePage.length; i++) {
            const isCompetence = competencePage[i]?.[index + 1]?.includes(code)
                && competencePage[i][index + 1].split('').filter((item: any) => item === '.').length === 1;
            if (isCompetence) {
                data.push({
                    indicatorCode: `${competencePage[i][index + 1]}`,
                    indicatorName: `${competencePage[i][contentIndex]}`,
                });
            }
            if (competencePage[i]?.[index]) {
                break;
            }
        }
        return data;
    };

    // 1.5
    const getDisciplinesModules = () => {
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i <= planPage.length; i++) {
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (planPage[i]?.includes(allText.disciplinesModulesText)) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getMandatoryPart = () => {
        let indxState = -1;
        let validate: any = [];
        let arr = [];

        for (let i = 0; i <= planPage.length; i++) {
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (planPage[i]?.includes(allText.mandatoryPartText)) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getPartFormedByParticipantsInEduRelations = () => {
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i <= planPage.length; i++) {
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (
                planPage[i]?.includes(allText.partFormedByParticipantsInEduRelationsText)
            ) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getPractice = () => {
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i <= planPage.length; i++) {
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (planPage[i]?.includes(allText.practiceText)) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getB2mandatoryPart = () => {
        let blockValidate = false;
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i < planPage.length; i++) {
            if (new RegExp(allText.block2).test(planPage[i])) {
                blockValidate = true;
            }
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (blockValidate && planPage[i]?.includes(allText.mandatoryPartText)) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getB2partFormedByParticipantsInEduRelations = () => {
        let blockValidate = false;
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i < planPage.length; i++) {
            if (new RegExp(allText.block2).test(planPage[i])) {
                blockValidate = true;
            }
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (
                blockValidate
                && planPage[i]?.includes(allText.partFormedByParticipantsInEduRelationsText)
            ) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getStateFinalCertification = () => {
        let indxState = -1;
        let validate: any = [];
        let arr = [];
        for (let i = 0; i <= planPage.length; i++) {
            if (indxState === -1 && planPage[i]?.includes(allText.ze)) {
                indxState = planPage[i]?.indexOf(allText.ze);
            }

            if (planPage[i]?.includes(allText.fact)) {
                validate = [true, planPage[i].indexOf(allText.fact)];
            }

            if (planPage[i]?.includes(allText.stateFinalCertificationText)) {
                arr = planPage[i];
                break;
            }
        }

        if (!arr?.length || !arr[indxState + 1 || indxState - 1]) {
            return '';
        }

        if (validate[0]) {
            return arr[validate[1]];
        }

        return arr[indxState];
    };

    const getCourseCU1 = () => {
        const indexNum = [];
        const arr: any = [];

        for (let i = 0; i <= planPage.length; i++) {
            const item = planPage[i];
            if (item?.includes(allText.ze && allText.name)) {
                const obj = item.reduce(
                    (acc: any, v: any, i: any) => (v in acc ? acc[v].push(i) : (acc[v] = [i]), acc),
                    {}
                );
                const dup = Object.entries(obj).filter(
                    (e) => e[1]?.length > 1 && e.includes(allText.ze)
                );
                const size = 2;
                for (let i = 0; i < Math.ceil(dup[0][1].length / size); i++) {
                    indexNum[i] = dup[0][1].slice(i * size, i * size + size);
                }
            }

            if (item?.includes(allText.disciplinesModulesText)) {
                indexNum.forEach((com, i) => {
                    if (arr[i]) {
                        arr[i].courseCredit += (+item[com[0]] || 0) + (+item[com[1]] || 0);
                    } else {
                        arr.push({
                            course: i + 1,
                            courseCredit: (+item[com[0]] || 0) + (+item[com[1]] || 0),
                        });
                    }
                    return com;
                }, 0);
            }

            if (item?.includes(allText.practiceText)) {
                indexNum.forEach((com, i) => {
                    if (arr[i]) {
                        arr[i].courseCredit += (+item[com[0]] || 0) + (+item[com[1]] || 0);
                    } else {
                        arr.push({
                            course: i + 1,
                            courseCredit: (+item[com[0]] || 0) + (+item[com[1]] || 0),
                        });
                    }
                    return com;
                }, 0);
            }

            if (item?.includes(allText.stateFinalCertificationText)) {
                indexNum.forEach((com, i) => {
                    if (arr[i]) {
                        arr[i].courseCredit += (+item[com[0]] || 0) + (+item[com[1]] || 0);
                    } else {
                        arr.push({
                            course: i + 1,
                            courseCredit: (+item[com[0]] || 0) + (+item[com[1]] || 0),
                        });
                    }
                    return com;
                }, 0);
                break;
            }
        }

        return arr;
    };

    // 1.7
    const getNameOfDicipline = (name: string) => {
        const subStr = [];
        const allData = [];
        const data: any = {};
        let rowIndex = -1;
        let colIndex = -1;
        let contentIndex = -1;
        let key = '';
        let endIndex = -1;

        for (let i = 0; i < competencePage.length; i++) {
            for (let k = 0; k < competencePage[i].length; k++) {
                if (competencePage[i][k] === allText.contentText) {
                    contentIndex = k;
                }
                if (competencePage[i][k] === name) {
                    rowIndex = i;
                    colIndex = k;
                    break;
                }
            }
            if (rowIndex > -1 && colIndex > -1) {
                break;
            }
        }
        if (rowIndex > -1 && colIndex > -1) {
            for (let i = rowIndex + 1; i < competencePage.length; i++) {
                if (competencePage[i][colIndex]) {
                    endIndex = i;
                    break;
                }
                if (
                    competencePage[i][colIndex + 1]
                    && (
                        competencePage[i][colIndex + 1].includes('УК')
                        || competencePage[i][colIndex + 1].includes('ПК')
                        || competencePage[i][colIndex + 1].includes('ОПК')
                    )
                ) {
                    subStr.push(competencePage[i][colIndex + 1]);
                }
            }

            if (!subStr.length) {
                for (let i = rowIndex + 1; i < endIndex; i++) {
                    allData.push(`${competencePage[i][colIndex + 1]} ${competencePage[i][contentIndex]}`);
                }
            } else {
                for (let i = rowIndex + 1; i < endIndex; i++) {
                    if (competencePage[i][colIndex + 1]) {
                        key = competencePage[i][colIndex + 1];
                        data[key] = [];
                    }
                    if (competencePage[i][colIndex + 2]) {
                        const value = `${competencePage[i][colIndex + 2]} ${competencePage[i][contentIndex]}`;
                        data[key].push({
                            label: value,
                            value,
                        });
                        allData.push(value);
                    }
                }
            }
        }
        return {
            data,
            allData: uniq(allData).map((item: string) => ({
                label: item,
                value: item,
            }))
        };
    };

    const isLoaded = () => !!titlePage?.length && !!planPage?.length
        && !!competencePage?.length && !!secondCompetencePage?.length;

    return {
        loadParsingData,
        reset,
        isLoaded,
        getNamesCategoryUK,
        getNamesCategoryOPK,
        getNamesCategoryPK,
        getDisciplineModules,
        getCodeAndNameDisciplineModule,
        getTypeOfPractice,
        getWorkingProgram,
        getFormOfIntermediateCertification,
        getCoursesAndSemesters,
        getCoursesAndSemesters6,
        getCompetencyAndName,
        getCompetencyAndNameRpp,
        getCompetencyAndName6,
        getCodeAndNameOfIndicator,
        getProfArea,
        getProfSpheres,
        getProfActivity,
        getCodeAndNameOfProf,
        getNameOfDicipline,
        setPagesData,
        clearForm,
    };
};
