import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { InputV2 } from 'components';
import notFoundImg from 'assets/images/not-found.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ModalV2 } from '../index';
import { setSearchedUserAction, setUserRoleAction } from '../../models/admin/actions';
import { getUser } from '../../models/admin/selectors';


const EditModal = ({ editModalVisible, setEditModalVisible, isEdit }) => {
    const { t } = useTranslation('all-opop-modal');
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const user = useSelector(getUser);
    const [role, setNewRole] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const handleSearch = () => {
        setIsDirty(true);
        dispatch(setSearchedUserAction(searchValue));
    };
    useEffect(() => {
        if (user) {
            const newRole = user.roles?.[0];
            setNewRole(newRole);
            if (newRole !== 'replication-developer') {
                setIsChecked(false);
            }
        }
    }, [user, setNewRole]);
    const handleChange = (role) => {
        if (role) {
            setIsChecked(true);
            setNewRole('replication-developer');
        } else {
            setIsChecked(false);
            setNewRole('opop-developer');
        }
    };
    const handleOk = () => {
        setEditModalVisible(false);
        dispatch(setUserRoleAction(searchValue, role));
        setSearchValue('');
        setIsDirty(false);
    };
    const handleCancel = () => {
        setEditModalVisible(false);
        setIsDirty(false);
        setSearchValue('');
    };


    return (
        <ModalV2
            width={800}
            cancelText={t('cancelText')}
            okText={t('okText')}
            title={isEdit
                ? 'Редактирование роли главного разработчика ОПОП для тиражирования'
                : 'Назначение основного экперта'}
            visible={editModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div className="search">
                <InputV2
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={t('edit-modal-placeholder')}
                    size="large"
                    value={searchValue}
                    suffix={(
                        <SearchIcon
                            className="search-icon"
                            onClick={handleSearch}
                        />
                    )}
                />
            </div>
            {
                isDirty && searchValue === user.id && user.id
                    ? (
                        <div>
                            <div className="modal">
                                <p className="modal-title">{ }</p>
                                <p>{`${user.lastName} ${user.firstName}`}</p>
                                <p className="modal-title">{t('code-and-name-opop')}</p>
                                <p className="info-label">{t('program-description')}</p>
                            </div>
                            <Divider />
                            <h2>{t('role')}</h2>
                            {
                                isEdit ? (
                                    <>
                                        <Checkbox
                                            onChange={(e) => handleChange(e.target.checked)}
                                            value="replication-developer"
                                            checked={role === 'replication-developer'}
                                        >
                                            {t('replication-developer')}
                                        </Checkbox>
                                    </>
                                )
                                    : (
                                        <Radio.Group
                                            className="radio"
                                            onChange={(e) => setNewRole(e.target.value)}
                                            value={role}
                                        >
                                            <Radio value="admin">{t('admin')}</Radio>
                                            <Radio value="admin-expert">{t('admin-expert')}</Radio>
                                            <Radio value="main-expert">{t('main-expert')}</Radio>
                                            <Radio value="opop-developer">{t('opop-dev')}</Radio>
                                            <Radio value="main-developer">{t('main-developer')}</Radio>
                                            <Radio value="replication-developer">{t('replication-developer')}</Radio>
                                        </Radio.Group>
                                    )
                            }
                        </div>
                    )
                    : (
                        <div className="not-found">
                            <img src={notFoundImg} alt="" />
                            <p>{t('not-found')}</p>
                        </div>
                    )
            }
        </ModalV2>
    );
};

export default EditModal;
