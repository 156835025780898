import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { ButtonV2, InputV2 } from '../../../../../index';
import Next from '../../../../../next';
import useDebouncedCallback from '../../../../../../utils/use-debounce-callback';

import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '7.1';
const emptyObj = emptyDocDetails[tabKey];


const AttestationTitle = ({ onNextClick }) => {
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);


    return (
        <div className="constructor-form">
            <h4 className="title">{t('work-title-info')}</h4>
            <p className="title2">Будет утверждено:</p>
            <Form
                form={form}
                onValuesChange={onValuesChange}
                initialValues={initialValues}
                layout="vertical"
            >
                <section>
                    <Row
                        gutter={[32, 16]}
                        align="bottom"
                    >
                        <Col flex="1 0 200px">
                            <Form.Item
                                name="workPosition"
                                label="Должность"
                            >
                                <InputV2
                                    placeholder="Введите должность"
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="1 0 331px">
                            <Form.Item
                                name="workerName"
                                label="ФИО"
                            >
                                <InputV2
                                    placeholder="Введите ФИО"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle subtitle-with-desc">
                        Разработчики программы ГИА
                    </h5>
                    <p className="section-desc">Укажите авторов документа</p>
                    <Form.List name="developers">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({ key, name, ...restField }) => (
                                    <div className="form-items-group" key={key}>
                                        <Row
                                            gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                    align="bottom"
                                                    wrap
                                                >
                                                    <Col flex="1 0 200px">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'position']}
                                                            className="space"
                                                            label="Должность"
                                                        >
                                                            <InputV2
                                                                placeholder="Введите должность"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col flex="1 0 275px">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'fullName']}
                                                            label="ФИО"
                                                        >
                                                            <InputV2
                                                                placeholder="Введите ФИО"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Row align="middle">
                                        <ButtonV2
                                            className="item-plus-action"
                                            onClick={() => add(emptyObj.developers[0])}
                                            type="link"
                                            icon={<PlusOutlined />}
                                        >
                                            Добавить еще разработчика
                                        </ButtonV2>
                                    </Row>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </section>
            </Form>

            <Divider />
            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                    ...importDocDetails(importedTabDetails, tabKey)
                }, dispatch)}
                onNextClick={() => onNextClick()}
            />
        </div>
    );
};

export default AttestationTitle;
