import axios from 'axios';
import qs from 'qs';


const url = process.env.REACT_APP_BACKEND_SERVISE;
const statisticUrl = process.env.REACT_APP_BACKEND_STAT_SERVISE;

export const getRegistryList = (unioneToken) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${unioneToken}`
        }
    };
    return axios.get(`${url}/list?status=completed`, config);
};

export const getRegistryDetails = (id, unioneToken) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${unioneToken}`
        }
    };
    return axios.get(`${url}/list/${id}`, config);
};
export const getProgramList = (query = {}) => {
    const defaultParams = {
        ...query,
        pagination: {
            page: 1,
            pageSize: 100
        },
        populate: '*',
    };
    return axios.get(`${url}/work-programs?${qs.stringify(defaultParams)}`);
};

export const getCompletedRegistryApi = () => axios.get(`${url}/registry-list/`);


export const getRegistryListStatisticApi = (unioneToken) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${unioneToken}`
        }
    };
    return axios.get(`${statisticUrl}/stat/list?by=opop`, config);
};

export const setDownloadStatisticApi = (params, id, unioneToken) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${unioneToken}`,
        }
    };
    return axios.post(`${statisticUrl}/stat/sync/${id}`, params, config);
};

export const getTableDataApi = (direction) => axios.get(`${statisticUrl}/stat/list?direction=${direction}`);
