/* eslint-disable max-len */
import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import bem from 'easy-bem';
import {
    Menu, Tabs, Spin, Button
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    getDirectoryListMock,
    setDocumentViewerVisible,
    updateDocById,
    getDocById,
    createDoc,
    setRpdList,
    setRppList,
    setCurrentRpdIndex,
    setCurrentRppIndex,
    setCurrentDocDetails,
    setDocLoadingStatus,
} from 'models/documents/actions';
import { getDocDetails } from 'models/documents/selectors';
import { ReactComponent as ArrowIcon } from 'assets/icons/double-arrow.svg';
import _pick from 'lodash/pick';
import _throttle from 'lodash/throttle';
import {
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowUpOutlined,
    LoadingOutlined,
} from '@ant-design/icons';

import DocumentViewerModal from 'components/document-viewer-modal';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { XLSXParserProvider, useXlsxParser } from 'utils/excel-parse';
import TitleInfoTab from './components/title-information-tab';
import CharacteristicGeneralInfoTab from './components/characteristic-general-info-tab';
import CharacteristicActivityTab from './components/characteristic-activity-tab';
import CharacteristicOpResultsTab from './components/characteristic-op-results-tab';
import CharacteristicOpStructureTab from './components/characteristic-op-structure-tab';
import CharacteristicOpRealizationTab from './components/characteristic-op-realization-tab';
import DigitalTechAppCompTab from './components/digital-tech-app-сomp/component';
import CompetenceModelTab from './components/competence-model-tab';
import CalendarScheduleTab from './components/calendar-schedule-tab';
import AcademicPlanTab from './components/academic-plan-tab';
import WorkSavedProgramsTab from './components/work-saved-programs-tab';
import WorkTitleTab from './components/work-title-tab';
import WorkCharacteristicTab from './components/work-characteristic-tab';
import WorkPlannedResultsTab from './components/work-planned-results-tab';
import WorkDisciplinePlaceTab from './components/work-discipline-place-tab';
import WorkDisciplineSizeTab from './components/work-discipline-size-tab';
import WorkDisciplineStructureTab from './components/work-discipline-structure-tab';
import WorkDisciplineEducationalTab from './components/work-discipline-educational-tab';
import WorkDisciplineLogisticsTab from './components/work-discipline-logistics-tab';
import WorkDisciplineEvaluationTab from './components/work-discipline-evaluation-tab';
import WorkDisciplineMaterialTab from './components/work-discipline-materials-tab';
import WorkSavedPracticesTab from './components/work-saved-practices-tab';
import WorkProgramTitle from './components/work-programm-title-tab';
import WorkProgramCharacteristics from './components/work-programm-characteristics-tab';
import WorkProgramLearning from './components/work-programm-learning-tab';
import WorkProgramPlace from './components/work-programm-place-tab';
import WorkProgramStructure from './components/work-programm-structure-tab';
import WorkProgramEvaluation from './components/work-programm-evaluation-tab';
import WorkProgramMaterial from './components/work-programm-material-tab';
import WorkProgramEducational from './components/work-programm-educational-tab';
import WorkProgramLogistics from './components/work-programm-logistics-tab';
import AttestationTitle from './components/attestation-title-tab';
import AttestationGenProvisions from './components/attestation-gen-provisions-tab';
import AttestationProgramResult from './components/attestation-program-results-tab';
import AttestationProgramExam from './components/attestation-program-exam-tab';
import AttestationProgramFinal from './components/attestation-program-final-tab';
import './style.less';
import { setUserDoc } from 'models/auth/actions';
import { getUser } from 'models/auth/selectors';


const { SubMenu } = Menu;
const { TabPane } = Tabs;

const rootSubmenuKeys = [
    'sub1',
    'sub2',
    'sub3',
    'sub4',
    'sub5',
    'sub6',
    'sub7',
];
const mainKeys = ['1.1', '5.1', '6.1'];
const initialAccess = [
    '1.2',
    '1.3',
    '1.4',
    '1.5',
    '1.6',
    '1.7',
    '2.0',
    '3.0',
    '4.0',
    '5.0',
    // '5.1',
    '6.0',
    // '6.1',
    '7.1',
    '7.2',
    '7.3',
    '7.4',
    '7.5',
];
const rpdModuleKeys = [
    '5.1',
    '5.2',
    '5.3',
    '5.4',
    '5.5',
    '5.6',
    '5.7',
    '5.8',
    '5.9',
    '5.10',
];
const rppModuleKeys = [
    '6.1',
    '6.2',
    '6.3',
    '6.4',
    '6.5',
    '6.6',
    '6.7',
    '6.8',
    '6.9',
];

const allKeys = new Set([
    ...mainKeys,
    ...initialAccess,
    ...rpdModuleKeys,
    ...rppModuleKeys,
]);

const rpdTabAccess = rpdModuleKeys.slice(1);
const rppTabAccess = rppModuleKeys.slice(1);
const newPropgramRoute = 'new-program';

const DevEducationalProgram = () => {
    const history = useHistory();
    const { id: detailsId } = useParams();
    const [openKeys, setOpenKeys] = useState(
        [`sub${history.location.hash[1]}`] || ['sub1']
    );
    const [tab, setTab] = useState(history.location.hash.slice(1) || '1.1');
    const [checkedTabs, setCheckedTabs] = useState([]);
    const [enabledTabs, setEnabledTabs] = useState(['1.1']);
    const [formData, setFormData] = useState({});
    const [docDetailsId, setDocDeatilsId] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [onTop, setOnTop] = useState(true);
    const [onBottom, setOnBottom] = useState(false);
    const { hash } = useLocation();

    const b = bem('dev-educational-program');
    const { t } = useTranslation('dev-educational-program');
    const dispatch = useDispatch();
    const docViewerVisible = useSelector(
        (state) => state.documents.docViewerVisible
    );
    const xlsxParser = useXlsxParser();
    const docDetails = useSelector(getDocDetails);
    const docLoaded = useSelector((state) => state.documents.docListLoaded);
    const documentOwner = useSelector((state) => state.documents.documentOwner);
    const rpdList = useSelector((state) => state.documents.rpdList || []);
    const rppList = useSelector((state) => state.documents.rppList || []);
    const currentRpdIndex = useSelector(
        (state) => state.documents.currentRpdIndex
    );
    const currentRppIndex = useSelector(
        (state) => state.documents.currentRppIndex
    );
    const user = useSelector(getUser);
    const docDetailsRef = useRef(null);
    
    const query = useMemo(
        () => new URLSearchParams(history.location.search),
        [history.location.search]
    );

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const scrollToBottom = () => {
        window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };
    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    const onScroll = _throttle(() => {
        const { scrollHeight } = document.body;
        if (window.scrollY > 300) {
            setOnBottom(true);
        } else {
            setOnBottom(false);
        }

        if (scrollHeight - (window.scrollY + window.innerHeight) > 300) {
            setOnTop(true);
        } else {
            setOnTop(false);
        }
    }, 200);

    const getCheckedClass = (tabKey) => (checkedTabs.includes(tabKey) ? 'checked' : 'not-checked');

    const getDisabledClass = (tabKey) => !enabledTabs.includes(tabKey);

    const onBackBtnClick = () => {
        if(xlsxParser?.isLoaded()) {
            xlsxParser?.reset();
        }
        history.push('/opop-constructor');
    };

    const changeStructure = () => {
        const details = JSON.parse(JSON.stringify(docDetails));
        docDetails['1.3']?.activityAreaSphere?.forEach((element, index) => {
            let spheres = [];
            if (element.spheres) {
                spheres = [...element.spheres];
            }
            if (element.sphere || element.sphere === '') {
                spheres.push({ name: element.sphere });
                delete details['1.3'].activityAreaSphere[index].sphere;
            }
            details['1.3'].activityAreaSphere[index].spheres = spheres;
        });

        docDetails['1.3']?.activityTasks?.forEach((element, index) => {
            let tasks = [];
            if (element.tasks) {
                tasks = [...element.tasks];
            }
            if (element.task || element.task === '') {
                tasks.push({ name: element.task });
                delete details['1.3'].activityTasks[index].task;
            }
            details['1.3'].activityTasks[index].tasks = tasks;
        });
        dispatch(setCurrentDocDetails(details));
    };

    const checkFormForEmpty = (obj) => {
        if (typeof obj !== 'object' && obj !== '') return true;
        if (!obj || isEmpty(obj)) return false;
        for (const key in obj) {
            if (obj[key] === '' || obj[key] === null) {
                return false;
            }
            if (Array.isArray(obj[key])) {
                if(obj[key].length === 0) return false;
                for (const item of obj[key]) {
                    if (!item || !checkFormForEmpty(item)) return false;
                }
            }
        }
        return true;
    };

    useEffect(() => {
        if (!docDetails) return;
        const newDocDetails = JSON.parse(JSON.stringify(docDetails));
        const cloneRpdList = JSON.parse(JSON.stringify(rpdList));
        const cloneRppList = JSON.parse(JSON.stringify(rppList));
        let filteredEnableTabs = [...enabledTabs];
        let filteredCheckedTabs = [...checkedTabs];
        let search = '';
        if (currentRpdIndex <= -1) {
            rpdModuleKeys.forEach((item) => {
                delete newDocDetails[item];
            });
            let keys = rpdModuleKeys;
            // if (rpdList?.length <= 99) {
            //     keys = rpdTabAccess;
            // }
            filteredEnableTabs = filteredEnableTabs.filter(
                (item) => !keys.includes(item)
            );
            filteredCheckedTabs = filteredCheckedTabs.filter(
                (item) => !rpdModuleKeys.includes(item)
            );
            setCheckedTabs(filteredCheckedTabs);
        } else {
            search = `?rpdId=${rpdList[currentRpdIndex]?.id}`;

            filteredEnableTabs = [...filteredEnableTabs, ...rpdModuleKeys];

        }

        if (currentRppIndex <= -1) {
            rppModuleKeys.forEach((item) => {
                delete newDocDetails[item];
            });
            let keys = rppModuleKeys;
            // if (rppList?.length <= 19) {
            //     keys = rppTabAccess;
            // }
            filteredEnableTabs = filteredEnableTabs.filter((item) => !keys.includes(item));
            filteredCheckedTabs = filteredCheckedTabs.filter(item => !rppModuleKeys.includes(item));
            setCheckedTabs(filteredCheckedTabs);
        } else if (rppList[currentRppIndex]?.id) {
            search = search
                ? `${search}&rppId=${rppList[currentRppIndex]?.id}`
                : `?rppId=${rppList[currentRppIndex]?.id}`;
            filteredEnableTabs = [...filteredEnableTabs, ...rppModuleKeys];
        }
        history.location.search = search;
        history.push({ search, hash: `#${tab}` });
        dispatch(
            setCurrentDocDetails({
                ...newDocDetails,
            })
        );
        let data = [...checkedTabs];

        rpdModuleKeys.forEach((tab) => {
            if (newDocDetails[tab] && checkFormForEmpty(newDocDetails[tab])) {
                data.push(tab);
            } else {
                data = data.filter((item) => item !== tab);
            }
        });
    
        rppModuleKeys.forEach((tab) => {
            if (newDocDetails[tab] && checkFormForEmpty(newDocDetails[tab])) {
                data.push(tab);
            } else {
                data = data.filter((item) => item !== tab);
            }
        });

        setCheckedTabs(data);
        setEnabledTabs(filteredEnableTabs);
        if (docDetails?.id && docLoaded) {
            dispatch(updateDocById(docDetails?.id, newDocDetails, [...cloneRpdList], [...cloneRppList]));
        }
    }, [currentRpdIndex, currentRppIndex]);

    const setRpdRppLists = (
        rpd = null,
        rpp = null,
        details = null,
        documentTab = '',
        rpdIndex,
        rppIndex
    ) => {
        let rpdStatus = true;
        let rppStatus = true;
        const cloneRpdList = rpd || JSON.parse(JSON.stringify(rpdList));
        const cloneRppList = rpp || JSON.parse(JSON.stringify(rppList));
        const cloneDocDetails = details || JSON.parse(JSON.stringify(docDetails));
        const key = documentTab || tab;
        const selectedRpdIndex = rpdIndex || currentRpdIndex;
        const selectedRppIndex = rppIndex || currentRppIndex;

        if (rpdModuleKeys.includes(key) && selectedRpdIndex > -1) {
            cloneRpdList[selectedRpdIndex] = {
                ...rpdList[selectedRpdIndex],
                ..._pick(cloneDocDetails, rpdModuleKeys),
            };
            for (const item of rpdModuleKeys) {
                if (!checkedTabs.includes(item)) {
                    rpdStatus = false;
                    break;
                }
            }
            cloneRpdList[selectedRpdIndex].status = rpdStatus ? 'done' : 'draft';
            dispatch(setRpdList(cloneRpdList));
        }

        if (rppModuleKeys.includes(key) && selectedRppIndex > -1) {
            cloneRppList[selectedRppIndex] = {
                ...rppList[selectedRppIndex],
                ..._pick(cloneDocDetails, rppModuleKeys),
                updatedAt: dayjs().format(),
            };
            for (const item of rppModuleKeys) {
                if (!checkedTabs.includes(item)) {
                    rppStatus = false;
                    break;
                }
            }
            cloneRppList[selectedRppIndex].status = rppStatus ? 'done' : 'draft';
            dispatch(setRppList(cloneRppList));
        }
        return { rpd: cloneRpdList, rpp: cloneRppList };
    };

    const updateDocList = (rpd, rpp) => {
        const clone = JSON.parse(JSON.stringify(docDetails));
        const cloneRpdList = rpd || JSON.parse(JSON.stringify(rpdList));
        const cloneRppList = rpp || JSON.parse(JSON.stringify(rppList));
        if (clone?.id) {
            delete clone.id;
            if (
                !(currentRpdIndex === -1 && docDetails?.['5.1'] && tab === '5.1')
                && !(currentRppIndex === -1 && docDetails?.['6.1'] && tab === '6.1')
            ) {
            dispatch(updateDocById(docDetails?.id, clone, [...cloneRpdList], [...cloneRppList]));
            }
        } else {
            if (docDetails['1.1'] === null) {
                return;
            }

            const requiredFieldKeys = [
                'fullAndShortNameOfUniversity',
                'founder',
                'by',
                'directionCodeOrName',
                'direction',
                'program',
                'accessYear',
                'studyForm',
            ];

            const requiredFieldsFilled = requiredFieldKeys.every((item) => docDetails['1.1']?.[item])
            if (requiredFieldsFilled) {
                dispatch(createDoc(clone));
            }
        }
    };


    const updateWithValidation = (form, key) => {
        if (!form) return;
        form
            .validateFields()
            .then(() => {
                const { rpd, rpp } = setRpdRppLists();

                if (key) {
                    const stepSubmenu = `sub${key.split('.')[0]}`;
                    setOpenKeys([stepSubmenu]);
                    setTab(key);
                    const { pathname, search } = history.location;
                    history.push({ pathname, search, hash: `#${key}` });
                }
                switch (tab) {
                    case '1.1':
                        setEnabledTabs(() => [...enabledTabs, ...initialAccess]);
                        break;
                    case '5.1':
                        setEnabledTabs(() => [...enabledTabs, ...rpdTabAccess]);
                        break;
                    case '6.1':
                        setEnabledTabs(() => [...enabledTabs, ...rppTabAccess]);
                        break;
                    default:
                        break;
                }
                updateDocList(rpd, rpp);
            })
            .catch(({ errorFields }) => {
                if (
                    key === '1.1'
          || (tab === '5.1' && !rpdTabAccess.includes(key))
          || (tab === '6.1' && !rppTabAccess.includes(key))
                ) {
                    setTab(key);
                } else {
                    form.scrollToField(errorFields?.[0]?.name);
                    const { pathname, search } = history.location;
                    history.push({ pathname, search, hash: `#${tab}` });
                }
            });
    };

    const onNextBtnClick = (form, nextStep, currentStep = '') => {
        if (checkFormForEmpty(docDetails[tab])) {
            setCheckedTabs([...checkedTabs, tab]);
        } else {
            setCheckedTabs(checkedTabs.filter((item) => item !== tab));
        }

        if (!form) {
            if (tab !== '5.1' && tab !== '6.1') {
                const { rpd, rpp } = setRpdRppLists();
                updateDocList(rpd, rpp);
            }
            if (nextStep) {
                const path = history.location;
                const stepSubmenu = `sub${nextStep.split('.')[0]}`;
                setOpenKeys([stepSubmenu]);
                setTab(nextStep);
                history.push({ ...path, hash: `#${nextStep}` });
            }

            switch (currentStep) {
                case '5.0':
                    setEnabledTabs(() => [...enabledTabs, ...rpdTabAccess]);
                        break;
                case '6.0':
                    setEnabledTabs(() => [...enabledTabs, ...rppTabAccess]);
                        break;
                default:
                    break;
            }
            return;
        }
        updateWithValidation(form, nextStep);
    };

    const setFormByIndex = (form) => {
        const data = { ...formData };
        data[tab] = form;
        setFormData(data);
    };

    const onModalClose = () => {
        dispatch(setDocumentViewerVisible(null));
    };

    useEffect(() => {
        if (!rppList[currentRppIndex]?.id && !rpdList[currentRppIndex]?.id && docDetails?.id && detailsId !== newPropgramRoute) {
            const { search } = history.location;
            history.push({ pathname: `/opop-constructor/${docDetails.id}`, search, hash: `${tab}` });
        }
    }, [tab]);

    useEffect(() => {
        if (docDetails?.id && docDetailsId === newPropgramRoute) {
            const { search } = history.location;
            history.push({ pathname: `/opop-constructor/${docDetails?.id}`, search, hash: `${tab}` });
        }
        if (docDetails && detailsId === newPropgramRoute) {
            dispatch(setCurrentRpdIndex(-1));
            dispatch(setCurrentRppIndex(-1));
        }
    }, [docDetails]);
    useEffect(() => {
        if (docDetails?.id && docDetailsId === newPropgramRoute) {
            dispatch(setUserDoc(docDetails?.id));
        };
    }, [docDetails?.id]);

    useEffect(() => {
        if (docLoaded && docDetails) {
            if (documentOwner?.id !== user.id) {
                history.push('/opop-constructor');
            }
            changeStructure();
            let initTabs = [...enabledTabs, ...initialAccess];
            if (rppList?.length > 19) {
                initTabs = initTabs.filter((item) => item !== '6.1');
            }
            if (rpdList?.length > 99) {
                initTabs = initTabs.filter((item) => item !== '5.1');
            }
            // setEnabledTabs(() => [...initTabs]);
            const tabs = [...checkedTabs];
            const rpdId = query.get('rpdId');
            const rppId = query.get('rppId');
            for (const key in docDetails) {
                if (
                    ![...rpdModuleKeys, ...rppModuleKeys].includes(key)
                    && checkFormForEmpty(docDetails[key])
                ) {
                    tabs.push(key);
                }
            }
            
            if (rpdId) {
                const index = rpdList.findIndex((item) => item?.id === rpdId);
                dispatch(setCurrentRpdIndex(index));
                rpdModuleKeys.forEach((item) => {
                    if (rpdList[index]?.[item]) {
                        if (checkFormForEmpty(rpdList[index][item])) {
                            tabs.push(item);
                        }
                    }
                });
                
                initTabs = [...initTabs, ...rpdModuleKeys];
            }
            if (rppId) {
                const index = rppList.findIndex((item) => item?.id === rppId);
                dispatch(setCurrentRppIndex(index));
                rppModuleKeys.forEach((item) => {
                    if (rppList[index]?.[item]) {
                        if (checkFormForEmpty(rppList[index][item])) {
                            tabs.push(item);
                        }
                    }
                });
                
                initTabs = [...initTabs, ...rppModuleKeys];
            }
            setCheckedTabs([...tabs]);
                        setEnabledTabs(() => [...initTabs]);
        }
    }, [docLoaded]);

    const setTabItem = (key) => {
        if (checkFormForEmpty(docDetails?.[tab])) {

            setCheckedTabs([...checkedTabs, tab]);

        } else {
            (checkedTabs.filter((item) => item !== tab));
        }
        if (mainKeys.includes(tab)) {
            updateWithValidation(formData[tab], key);
        } else {
            const { pathname, search } = history.location;
            history.push({ pathname, search, hash: `#${key || ''}` });
            setTab(key);
            const { rpd, rpp } = setRpdRppLists();
            updateDocList(rpd, rpp);
        }
    };

    useEffect(() => {
        const hash = history.location.hash.slice(1);
        if (![...allKeys].includes(hash)) {
            history.location.hash = '#1.1';
        } else if (hash !== tab && docDetails) {
            const stepSubmenu = `sub${hash.split('.')[0]}`;
            setOpenKeys([stepSubmenu]);
            setTabItem(hash);
        }
        window.scrollTo(0, 0);
    }, [history.location.hash]);

    useEffect(() => {
        if (docDetails?.id) {
            docDetailsRef.current = {
                id: docDetails?.id,
                documentDetails: JSON.parse(JSON.stringify(docDetails)),
                rpdList: JSON.parse(JSON.stringify(rpdList)),
                rppList: JSON.parse(JSON.stringify(rppList)),
                key: tab,
                rpdIndex: currentRpdIndex,
                rppIndex: currentRppIndex,
            };
        }
    }, [docDetails]);

    useEffect(() => {
        const { pathname, search } = history.location;
        const hash = history.location.hash || '#1.1';
        if (detailsId !== newPropgramRoute) {
            dispatch(getDocById(detailsId));
            history.push({ pathname, search, hash });
        } else {
            setDocDeatilsId(newPropgramRoute);
            history.push({ pathname, search: '', hash });
            dispatch(setCurrentDocDetails(null));
        }
        const stepSubmenu = `sub${hash.slice(1).split('.')[0]}`;
        setOpenKeys([stepSubmenu]);
        dispatch(getDirectoryListMock());
        document.addEventListener('scroll', onScroll);
        return () => {
            const {
                documentDetails, id, rpdList, rppList, key, rppIndex, rpdIndex
            } = docDetailsRef.current || {};
            if (id) {
                const { rpd, rpp } = setRpdRppLists(
                    rpdList,
                    rppList,
                    documentDetails,
                    key,
                    rpdIndex,
                    rppIndex
                );
                dispatch(updateDocById(id, documentDetails, rpd, rpp));
            }
            document.removeEventListener('scroll', onScroll);
            dispatch(setDocLoadingStatus(false));
        };
    }, [dispatch]);

    return (
        <XLSXParserProvider>
            <>
                {docViewerVisible ? (
                    <DocumentViewerModal
                        visible={!!docViewerVisible}
                        onClose={onModalClose}
                        url={docViewerVisible}
                    />
                ) : null}
                <div className={b()}>
                    {docLoaded || docDetailsId === newPropgramRoute ? (
                        <>
                            <h2 className="program-title">{t('dev-educational-program')}</h2>
                            <h4 className="program-title-name">
                                {docDetails?.[1.1]?.directionCodeOrName
                                    ? `${t('bachelor-program')} ${
                                        docDetails?.[1.1]?.directionCodeOrName
                                    } ${docDetails?.[1.1]?.direction}`
                                    : ''}
                            </h4>
                            <div className="scroll-container">
                                <Button
                                    className={`scroll-btn ${onTop ? 'on-top' : ''}`}
                                    onClick={scrollToBottom}
                                >
                                    <div className="scroll-btn-content">
                                        Вниз
                                        {' '}
                                        <ArrowDownOutlined />
                                    </div>
                                </Button>
                                <Button
                                    className={`scroll-btn ${onBottom ? 'on-bottom' : ''}`}
                                    onClick={scrollToTop}
                                >
                                    <div className="scroll-btn-content">
                                        Вверх
                                        {' '}
                                        <ArrowUpOutlined />
                                    </div>
                                </Button>
                            </div>
                            <div
                                className={`program-content ${
                                    collapsed ? 'collapsed' : 'active'
                                }`}
                            >
                                <div>
                                    <Menu
                                        className="program-menu"
                                        onSelect={({ key }) => setTabItem(key)}
                                        mode="inline"
                                        openKeys={openKeys}
                                        defaultOpenKeys={['sub1']}
                                        defaultSelectedKeys={['1.1']}
                                        selectedKeys={[tab]}
                                        onOpenChange={onOpenChange}
                                        inlineCollapsed={collapsed}
                                    >
                                        <Menu.Item
                                            className={`return-btn ${
                                                !collapsed ? 'return-btn-open' : ''
                                            }`}
                                            onClick={onBackBtnClick}
                                        >
                                            <div className="my-opop">
                                                <ArrowLeftOutlined />
                                                {collapsed ? (
                                                    ''
                                                ) : (
                                                    <div className="my-opop-text">{t('my-opop')}</div>
                                                )}
                                            </div>
                                        </Menu.Item>
                                        <SubMenu
                                            key="sub1"
                                            title={collapsed ? ['1.'] : t('program-list.0.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('1.1')}
                                                key="1.1"
                                                disabled={getDisabledClass('1.1')}
                                            >
                                                {t('program-list.0.sublist.0')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.2')}
                                                key="1.2"
                                                disabled={getDisabledClass('1.2')}
                                            >
                                                {t('program-list.0.sublist.1')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.3')}
                                                key="1.3"
                                                disabled={getDisabledClass('1.3')}
                                            >
                                                {t('program-list.0.sublist.2')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.4')}
                                                key="1.4"
                                                disabled={getDisabledClass('1.4')}
                                            >
                                                {t('program-list.0.sublist.3')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.5')}
                                                key="1.5"
                                                disabled={getDisabledClass('1.5')}
                                            >
                                                {t('program-list.0.sublist.4')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.6')}
                                                key="1.6"
                                                disabled={getDisabledClass('1.6')}
                                            >
                                                {t('program-list.0.sublist.5')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('1.7')}
                                                key="1.7"
                                                disabled={getDisabledClass('1.7')}
                                            >
                                                {t('program-list.0.sublist.6')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub2"
                                            title={collapsed ? ['2.'] : t('program-list.1.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('2.0')}
                                                key="2.0"
                                                disabled={getDisabledClass('2.0')}
                                            >
                                                {t('program-list.1.sublist.0')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub3"
                                            title={collapsed ? ['3.'] : t('program-list.3.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('3.0')}
                                                key="3.0"
                                                disabled={getDisabledClass('3.0')}
                                            >
                                                Учебный план
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub4"
                                            title={collapsed ? ['4.'] : t('program-list.2.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('4.0')}
                                                key="4.0"
                                                disabled={getDisabledClass('4.0')}
                                            >
                                                {t('program-list.2.sublist.0')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub5"
                                            title={collapsed ? ['5.'] : t('program-list.4.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('5.0')}
                                                key="5.0"
                                                disabled={getDisabledClass('5.0')}
                                            >
                                                {t('program-list.4.sublist.0')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.1')}
                                                key="5.1"
                                                disabled={getDisabledClass('5.1')}
                                            >
                                                {t('program-list.4.sublist.1')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.2')}
                                                key="5.2"
                                                disabled={getDisabledClass('5.2')}
                                            >
                                                {t('program-list.4.sublist.2')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.3')}
                                                key="5.3"
                                                disabled={getDisabledClass('5.3')}
                                            >
                                                {t('program-list.4.sublist.3')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.4')}
                                                key="5.4"
                                                disabled={getDisabledClass('5.4')}
                                            >
                                                {t('program-list.4.sublist.4')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.5')}
                                                key="5.5"
                                                disabled={getDisabledClass('5.5')}
                                            >
                                                {t('program-list.4.sublist.5')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.6')}
                                                key="5.6"
                                                disabled={getDisabledClass('5.6')}
                                            >
                                                {t('program-list.4.sublist.6')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.7')}
                                                key="5.7"
                                                disabled={getDisabledClass('5.7')}
                                            >
                                                {t('program-list.4.sublist.7')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.8')}
                                                key="5.8"
                                                disabled={getDisabledClass('5.8')}
                                            >
                                                {t('program-list.4.sublist.8')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.9')}
                                                key="5.9"
                                                disabled={getDisabledClass('5.9')}
                                            >
                                                {t('program-list.4.sublist.9')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('5.10')}
                                                key="5.10"
                                                disabled={getDisabledClass('5.10')}
                                            >
                                                {t('program-list.4.sublist.10')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub6"
                                            title={collapsed ? ['6.'] : t('program-list.5.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('6.0')}
                                                key="6.0"
                                                disabled={getDisabledClass('6.0')}
                                            >
                                                {t('program-list.5.sublist.0')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.1')}
                                                key="6.1"
                                                disabled={getDisabledClass('6.1')}
                                            >
                                                {t('program-list.5.sublist.1')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.2')}
                                                key="6.2"
                                                disabled={getDisabledClass('6.2')}
                                            >
                                                {t('program-list.5.sublist.2')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.3')}
                                                key="6.3"
                                                disabled={getDisabledClass('6.3')}
                                            >
                                                {t('program-list.5.sublist.3')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.4')}
                                                key="6.4"
                                                disabled={getDisabledClass('6.4')}
                                            >
                                                {t('program-list.5.sublist.4')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.5')}
                                                key="6.5"
                                                disabled={getDisabledClass('6.5')}
                                            >
                                                {t('program-list.5.sublist.5')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.6')}
                                                key="6.6"
                                                disabled={getDisabledClass('6.6')}
                                            >
                                                {t('program-list.5.sublist.6')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.7')}
                                                key="6.7"
                                                disabled={getDisabledClass('6.7')}
                                            >
                                                {t('program-list.5.sublist.7')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.8')}
                                                key="6.8"
                                                disabled={getDisabledClass('6.8')}
                                            >
                                                {t('program-list.5.sublist.8')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('6.9')}
                                                key="6.9"
                                                disabled={getDisabledClass('6.9')}
                                            >
                                                {t('program-list.5.sublist.9')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            key="sub7"
                                            title={collapsed ? ['7.'] : t('program-list.6.name')}
                                        >
                                            <Menu.Item
                                                className={getCheckedClass('7.1')}
                                                key="7.1"
                                                disabled={getDisabledClass('7.1')}
                                            >
                                                {t('program-list.6.sublist.0')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('7.2')}
                                                key="7.2"
                                                disabled={getDisabledClass('7.2')}
                                            >
                                                {t('program-list.6.sublist.1')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('7.3')}
                                                key="7.3"
                                                disabled={getDisabledClass('7.3')}
                                            >
                                                {t('program-list.6.sublist.2')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('7.4')}
                                                key="7.4"
                                                disabled={getDisabledClass('7.4')}
                                            >
                                                {t('program-list.6.sublist.3')}
                                            </Menu.Item>
                                            <Menu.Item
                                                className={getCheckedClass('7.5')}
                                                key="7.5"
                                                disabled={getDisabledClass('7.5')}
                                            >
                                                {t('program-list.6.sublist.4')}
                                            </Menu.Item>
                                        </SubMenu>
                                        <div
                                            className={`menu-footer ${
                                                collapsed && 'menu-footer-closed'
                                            }`}
                                        >
                                            <ArrowIcon
                                                className={collapsed ? 'arrow-open' : 'arrow-closed'}
                                                onClick={() => setCollapsed(!collapsed)}
                                            />
                                        </div>
                                    </Menu>
                                </div>
                                <div
                                    className={`tabs-container ${
                                        collapsed ? 'tabs-container-open' : 'tabs-container-closed'
                                    }`}
                                >
                                    <Tabs activeKey={tab} destroyInactiveTabPane>
                                        <TabPane key="1.1">
                                            <TitleInfoTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.2')}
                                                setFormData={(form) => setFormByIndex(form)}
                                            />
                                        </TabPane>
                                        <TabPane key="1.2">
                                            <CharacteristicGeneralInfoTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.3')}
                                            />
                                        </TabPane>
                                        <TabPane key="1.3">
                                            <CharacteristicActivityTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.4')}
                                            />
                                        </TabPane>
                                        <TabPane key="1.4">
                                            <CharacteristicOpResultsTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.5')}
                                            />
                                        </TabPane>
                                        <TabPane key="1.5">
                                            <CharacteristicOpStructureTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.6')}
                                            />
                                        </TabPane>
                                        <TabPane key="1.6">
                                            <CharacteristicOpRealizationTab
                                                onNextClick={(form) => onNextBtnClick(form, '1.7')}
                                            />
                                        </TabPane>
                                        <TabPane key="1.7">
                                            <DigitalTechAppCompTab
                                                onNextClick={(form) => onNextBtnClick(form, '2.0')}
                                            />
                                        </TabPane>
                                        <TabPane key="2.0">
                                            <CompetenceModelTab
                                                onNextClick={(form) => onNextBtnClick(form, '3.0')}
                                            />
                                        </TabPane>
                                        <TabPane key="3.0">
                                            <AcademicPlanTab
                                                onNextClick={() => onNextBtnClick(null, '4.0')}
                                            />
                                        </TabPane>
                                        <TabPane key="4.0">
                                            <CalendarScheduleTab
                                                onNextClick={() => onNextBtnClick(null, '5.0')}
                                                previewDoc={null}
                                            />
                                        </TabPane>
                                        <TabPane key="5.0">
                                            <WorkSavedProgramsTab
                                                onNextClick={(key) => onNextBtnClick(null, '5.1', key)}
                                            />
                                        </TabPane>
                                        <TabPane key="5.1">
                                            <WorkTitleTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.2')}
                                                setFormData={(form) => setFormByIndex(form)}
                                            />
                                        </TabPane>
                                        <TabPane key="5.2">
                                            <WorkCharacteristicTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.3')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.3">
                                            <WorkPlannedResultsTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.4')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.4">
                                            <WorkDisciplinePlaceTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.5')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.5">
                                            <WorkDisciplineSizeTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.6')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.6">
                                            <WorkDisciplineStructureTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.7')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.7">
                                            <WorkDisciplineEducationalTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.8')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.8">
                                            <WorkDisciplineLogisticsTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.9')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.9">
                                            <WorkDisciplineEvaluationTab
                                                onNextClick={(form) => onNextBtnClick(form, '5.10')}
                                            />
                                        </TabPane>
                                        <TabPane key="5.10">
                                            <WorkDisciplineMaterialTab
                                                onNextClick={(form) => onNextBtnClick(form, '6.0')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.0">
                                            <WorkSavedPracticesTab
                                                onNextClick={(key) => onNextBtnClick(null, '6.1', key)}
                                            />
                                        </TabPane>
                                        <TabPane key="6.1">
                                            <WorkProgramTitle
                                                onNextClick={(form) => onNextBtnClick(form, '6.2')}
                                                setFormData={(form) => setFormByIndex(form)}
                                            />
                                        </TabPane>
                                        <TabPane key="6.2">
                                            <WorkProgramCharacteristics
                                                onNextClick={(form) => onNextBtnClick(form, '6.3')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.3">
                                            <WorkProgramLearning
                                                onNextClick={(form) => onNextBtnClick(form, '6.4')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.4">
                                            <WorkProgramPlace
                                                onNextClick={(form) => onNextBtnClick(form, '6.5')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.5">
                                            <WorkProgramStructure
                                                onNextClick={(form) => onNextBtnClick(form, '6.6')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.6">
                                            <WorkProgramEvaluation
                                                onNextClick={(form) => onNextBtnClick(form, '6.7')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.7">
                                            <WorkProgramMaterial
                                                onNextClick={(form) => onNextBtnClick(form, '6.8')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.8">
                                            <WorkProgramEducational
                                                onNextClick={(form) => onNextBtnClick(form, '6.9')}
                                            />
                                        </TabPane>
                                        <TabPane key="6.9">
                                            <WorkProgramLogistics
                                                onNextClick={(form) => onNextBtnClick(form, '7.1')}
                                            />
                                        </TabPane>
                                        <TabPane key="7.1">
                                            <AttestationTitle
                                                onNextClick={() => onNextBtnClick(null, '7.2')}
                                            />
                                        </TabPane>
                                        <TabPane key="7.2">
                                            <AttestationGenProvisions
                                                onNextClick={() => onNextBtnClick(null, '7.3')}
                                            />
                                        </TabPane>
                                        <TabPane key="7.3">
                                            <AttestationProgramResult
                                                onNextClick={() => onNextBtnClick(null, '7.4')}
                                            />
                                        </TabPane>
                                        <TabPane key="7.4">
                                            <AttestationProgramExam
                                                onNextClick={() => onNextBtnClick(null, '7.5')}
                                            />
                                        </TabPane>
                                        <TabPane key="7.5">
                                            <AttestationProgramFinal />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="spinner">
                            <Spin indicator={<LoadingOutlined spin />} />
                        </div>
                    )}
                </div>
            </>
        </XLSXParserProvider>
    );
};

export default DevEducationalProgram;
