import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import * as Sentry from '@sentry/react';
import PageWrapper from '../page-wrapper';
import i18n from '../../i18n/i18n';

import store from '../../store/store';

import 'antd/dist/antd.css';
import './style.less';


const App = () => (
    <Wrapper>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BrowserRouter>
                    <PageWrapper />
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </Wrapper>
);

export default Sentry.withProfiler(App);
