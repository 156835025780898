import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import he from 'he';
import {
    Form,
    Divider,
    Col,
    Row,
    Radio
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';

import useDebouncedCallback from 'utils/use-debounce-callback';
import { uniqueId } from 'utils/uniqueId';

import { usePlxParser } from 'utils/plx';
import Next from 'components/next';
import { updateTabDetails } from 'utils/dispatches';
import { useLocation } from 'react-router-dom';
import { getUserWorkPlace } from '../../../../../../models/auth/selectors';
import Range from '../../../range';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import './style.less';
import UploadModal from './uploadModal';
import { useXlsxParser } from 'utils/excel-parse';


const tabKey = '1.1';
const emptyObj = emptyDocDetails[tabKey];

const stringRules = {
    rules: [{ type: 'string', required: true, message: 'Поле обязательно' }],
};

const numberRules = {
    rules: [{ type: 'number', required: true, message: 'Поле обязательно' }],
};

const currentYear = new Date().getFullYear();
const dateArray = Range(currentYear, currentYear + 5);

// Форматирование списка под AntdSelectItemProps({value,label,key}), удаление дубликатов по направлению
const listFormatter = (list) => list.map((item) => {
    const obj = {
        value: `${item.code} ${item.direction}`,
        label: `${item.code} ${item.direction}`,
        key: uniqueId()
    };
    return obj;
}).filter((v, i, a) => a.findIndex((t) => (t.value === v.value)) === i) || [];

const calculateTabDetails = ({ by = '', directionCodeOrName = '' } = {}, t) => {
    const getDirectionTwo = (by = '') => {
        switch (by) {
            case t('(by)-direction').toLowerCase():
                return t('(by)-program-direction');
            case t('(by)-specialty').toLowerCase():
                return t('(by)-specialization');
            default: return '';
        }
    };

    const getProgram = (dir = '') => {
        const code = dir?.split('.')[1];
        switch (code) {
            case '03':
                return t('bachelor');
            case '04':
                return t('master');
            case '05':
                return t('specialty');
            default:
                return '';
        }
    };

    return {
        directionTwo: getDirectionTwo(by),
        program: getProgram(directionCodeOrName),
    };
};

const TitleInfoTab = ({ onNextClick, setFormData }) => {
    const [form] = Form.useForm();
    const b = bem('title-information-tab');
    const { t } = useTranslation('dev-educational-program');
    const dispatch = useDispatch();
    const codeDirectoryList = useSelector((state) => listFormatter(state.documents.directoryList));
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const userWorkPlace = useSelector(getUserWorkPlace);
    const { pathname } = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(true);

    const initialValues = tabDetails || {
        ...emptyObj,
        approvedPosition: t('rector'),
        fullAndShortNameOfUniversity: userWorkPlace
    };
    useEffect(() => {
        updateTabDetails(tabKey, initialValues, dispatch);
        setFormData(form);
    }, []);

    const { isLoaded } = useXlsxParser();

    const branches = useMemo(() => {
        if (!isLoaded()) {
            return null;
        }
        const list = [];

        return list?.length ? list.map((item) => ({ value: he.decode(item['_Полное_название'] || ''), label: he.decode(item['_Полное_название'] || '') })) : null;
    }, [isLoaded()]);

    const byOptions = [t('(by)-direction'), t('(by)-specialty')].map((v, i) => ({
        value: v.toLowerCase(), label: v, key: i
    }));

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all, t),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            {
                pathname.includes('new-program')
                    ? (
                        <UploadModal
                            setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                            form={form}
                        />
                    ) : null
            }
            <div className="constructor-form">
                <h4 className="title">{t('title-information')}</h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <h5 className="subtitle">{t('name-ed-program')}</h5>
                        <Form.Item
                            name="fullAndShortNameOfUniversity"
                            label={t('full-and-abbreviated-university-name')}
                            {...stringRules}
                        >
                            {branches ? (
                                <Select
                                    className="form-select"
                                    size="large"
                                    placeholder="Выберите наименование ВУЗа"
                                    showSearch
                                    notFoundContent={t('not-found-content-text')}
                                    options={branches}
                                />
                            ) : <InputV2 placeholder={t('enter-full-and-abbreviated-university-name')} />}
                        </Form.Item>
                        <Form.Item
                            name="founder"
                            label={t('university-founder')}
                            {...stringRules}
                        >
                            <InputV2 placeholder={t('enter-university-founder')} />
                        </Form.Item>

                        <div className="ant-form-item-label">
                            <label htmlFor="by" className="item-label ant-form-item-required">{t('by')}</label>
                        </div>
                        <Row
                            gutter={[{ lg: 24, xl: 32 }, 16]}
                            wrap
                        >
                            <Col xs={24} lg={7}>
                                <Form.Item
                                    name="by"
                                    {...stringRules}
                                >
                                    <Select
                                        options={byOptions}
                                        className="form-select"
                                        size="large"
                                        placeholder={t('select')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={17}>
                                <Form.Item
                                    name="directionCodeOrName"
                                    {...stringRules}
                                >
                                    <Select
                                        className="form-select"
                                        size="large"
                                        showSearch
                                        notFoundContent={t('not-found-content-text')}
                                        placeholder={t('name-and-code-direction')}
                                        options={codeDirectoryList}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item {...stringRules} name="direction" label={t('specialization')}>
                            <InputV2 placeholder={t('enter-specialization')} />
                        </Form.Item>
                        <Form.Item {...stringRules} name="qualification" label={t('qualification')}>
                            <InputV2 placeholder={t('enter-qualification')} />
                        </Form.Item>
                        <Form.Item {...stringRules} name="studyForm" label={t('form-of-study')}>
                            <Radio.Group name="studyFormRadioGroup">
                                <Radio value={t('full-time')}>{t('full-time')}</Radio>
                                <Radio value={t('extramural')}>{t('extramural')}</Radio>
                                <Radio value={t('part-time')}>{t('part-time')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item {...numberRules} name="accessYear" label={t('year-admission')}>
                            <Radio.Group name="accessYearRadioGroup">
                                {dateArray.map((year) => <Radio key={year} value={year}>{year}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </section>

                    <Divider />

                    <section>
                        <h5 className="subtitle subtitle-with-desc">{t('ed-program-devs')}</h5>
                        <p className="section-desc">{t('document-authors')}</p>
                        <Form.List className="developers" name="developers">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({
                                            key, name, ...restField
                                        }) => (
                                            <div className="form-items-group" key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <Row
                                                            gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                            align="bottom"
                                                            wrap
                                                        >
                                                            <Col flex="1 0 200px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'position']}
                                                                    label={t('position')}
                                                                    {...stringRules}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('enter-position')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 0 275px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fullName']}
                                                                    label={t('full-name')}
                                                                    {...stringRules}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('enter-full-name')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn
                                                                onClick={() => remove(name)}
                                                                disabled={fields?.length === 1}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.developers[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-another-dev')}
                                        </ButtonV2>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <section>
                        <h5 className="subtitle">{t('employers-representatives')}</h5>
                        <Form.List className="developers" name="employersRepresentatives">
                            {(fields, { add, remove }) => (
                                <>
                                    <div className="form-items-list">
                                        {fields.map(({
                                            key, name, ...restField
                                        }) => (
                                            <div className="form-items-group" key={key}>
                                                <Row
                                                    gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                    align="bottom"
                                                    wrap={false}
                                                >
                                                    <Col flex="1">
                                                        <Row
                                                            gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                            align="bottom"
                                                            wrap
                                                        >
                                                            <Col flex="1 0 200px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'position']}
                                                                    label={t('position')}
                                                                >
                                                                    <InputV2 placeholder={t('enter-position')} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 0 275px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fullName']}
                                                                    label={t('full-name')}
                                                                >
                                                                    <InputV2 placeholder={t('enter-full-name')} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex="1 0 400px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'employeOrg']}
                                                                    label={t('employers-org')}
                                                                >
                                                                    <InputV2 placeholder={t('enter-employers-org')} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <DeleteBtn
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.employersRepresentatives[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-another-empl-repr')}
                                        </ButtonV2>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </section>

                    <Divider />

                    <section>
                        <h5 className="footer-title confirm-subtitle">{t('will-be-approved')}</h5>
                        <Row
                            gutter={[32, 16]}
                            align="bottom"
                        >
                            <Col flex="1 0 200px">
                                <Form.Item
                                    name="approvedPosition"
                                    label={t('approved-position')}
                                >
                                    <InputV2 placeholder={t('enter-position')} />
                                </Form.Item>
                            </Col>
                            <Col flex="1 0 331px">
                                <Form.Item
                                    name="approvedFullName"
                                    label={t('approved-full-name')}
                                >
                                    <InputV2 placeholder={t('enter-full-name')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="collegialOrg"
                            label={t('opop-is-approved-by-decision')}
                        >
                            <InputV2 placeholder={t('enter-collegiate-bodys-name')} />
                        </Form.Item>
                    </section>

                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                            ...calculateTabDetails(tabDetails, t),
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default TitleInfoTab;
