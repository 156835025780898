/* eslint-disable max-len */
import React, { useMemo } from 'react';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputV2 } from 'components';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import expandArr from 'utils/expandArr';
import { GenBlock } from 'components/generated-block/generatedBlock';
import { ButtonV2 } from '../../../../../../index';


const DisciplineCriteria = ({ criteriaEmptyObj }) => { // 5.9, блок 3
    const b = bem('work-discipline-criteria');
    const { t } = useTranslation('dev-educational-program');
    const { results = [] } = useSelector((state) => state.documents.documentDetails?.['5.3'] || {});

    const criteriaCodeName = useMemo(
        () => (results || [])
            .filter(({ codeAndName }) => codeAndName)
            .map(({ codeAndName }) => ({
                label: codeAndName,
                value: codeAndName
            })), [results]
    );

    const knowledgeData = useMemo(
        () => expandArr(results || [], [
            'list',
            'knowledge'
        ])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name
            })), [results]
    );

    const possessionData = useMemo(
        () => expandArr(results || [], [
            'list',
            'possession'
        ])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name
            })), [results]
    );

    const skillData = useMemo(
        () => expandArr(results || [], [
            'list',
            'skill'
        ])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name
            })), [results]
    );

    return (
        <section>
            <h5 className="subtitle">
                {t('block-3')}
                : Критерии оценивания результатов обучения по дисциплине (модулю) при проведении промежуточной аттестации
            </h5>
            <Form.List name="criteria">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    label={t('digit-tech-comp-code-and-name')}
                                                    {...handleFieldEnableStatus(
                                                        !!criteriaCodeName?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first-form')} 
                                                            "${t('digit-tech-comp-code-and-name')}" 
                                                            {${t('in-chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.3' })}
                                                            >
                                                                {t('work-planned-result-module')}
                                                            </Link>
                                                        </span>,
                                                        t('digit-tech-comp-code-and-name')
                                                    )}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        options={criteriaCodeName}
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Выберите одну из ранее введенных компетенций"
                                                        disabled={!criteriaCodeName?.length}
                                                    />
                                                </Form.Item>
                                                <Divider />

                                                <GenBlock
                                                    blockFieldName={name}
                                                    deleteIcon
                                                    blockName="toKnow"
                                                    initialValueAdd={criteriaEmptyObj.toKnow[0]}
                                                    {...restField}
                                                >
                                                    <div className="item-block-group">
                                                        <h5 className="items-group-title">
                                                            Знать
                                                        </h5>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'plans']}
                                                            label={t('planned-result-knowledge')}
                                                            {...handleFieldEnableStatus(
                                                                !!knowledgeData?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first-form')} 
                                                                            "${t('planned-result-knowledge')}" 
                                                                            ${t('in-chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#5.3'
                                                                        })}
                                                                    >
                                                                        {t('work-planned-result-module')}
                                                                    </Link>
                                                                </span>,
                                                                t('planned-result-knowledge')
                                                            )}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                options={knowledgeData}
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder="Выберите один из ранее введенных результатов"
                                                                disabled={!knowledgeData?.length}
                                                            />
                                                        </Form.Item>
                                                        <p className="text">
                                                            Критерии оценивания результатов обучения:
                                                        </p>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'unsatisfactorily']}
                                                            label="«неудовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'satisfactorily']}
                                                            label="«удовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'good']}
                                                            label="«хорошо»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'excellent']}
                                                            label="«отлично»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </GenBlock>
                                                <Divider />

                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="toBeAble"
                                                    deleteIcon
                                                    initialValueAdd={criteriaEmptyObj.toBeAble[0]}
                                                    {...restField}
                                                >
                                                    <div className="item-block-group">
                                                        <h5 className="items-group-title">
                                                            Уметь
                                                        </h5>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'plans']}
                                                            label={t('planned-result-skill')}
                                                            {...handleFieldEnableStatus(
                                                                !!skillData?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first-form')}
                                                                            "${t('planned-result-skill')}"
                                                                            ${t('in-chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#5.3'
                                                                        })}
                                                                    >
                                                                        {t('work-planned-result-module')}
                                                                    </Link>
                                                                </span>,
                                                                t('planned-result-skill')
                                                            )}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                options={skillData}
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder="Выберите один из ранее введенных результатов"
                                                                disabled={!skillData?.length}
                                                            />
                                                        </Form.Item>
                                                        <p className="text">
                                                            Критерии оценивания результатов обучения:
                                                        </p>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'unsatisfactorily']}
                                                            label="«неудовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'satisfactorily']}
                                                            label="«удовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'good']}
                                                            label="«хорошо»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'excellent']}
                                                            label="«отлично»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </GenBlock>
                                                <Divider />

                                                <GenBlock
                                                    blockFieldName={name}
                                                    blockName="toPossess"
                                                    deleteIcon
                                                    initialValueAdd={criteriaEmptyObj.toPossess[0]}
                                                    {...restField}
                                                >
                                                    <div className="item-block-group">
                                                        <h5 className="items-group-title">
                                                            Владеть
                                                        </h5>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'plans']}
                                                            label={t('planned-result-possession')}
                                                            {...handleFieldEnableStatus(
                                                                !!possessionData?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first-form')} 
                                                                            "${t('planned-result-possession')}" 
                                                                            ${t('in-chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#5.3'
                                                                        })}
                                                                    >
                                                                        {t('work-planned-result-module')}
                                                                    </Link>
                                                                </span>,
                                                                t('planned-result-possession')
                                                            )}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                options={possessionData}
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder="Выберите один из ранее введенных результатов"
                                                                disabled={!possessionData?.length}
                                                            />
                                                        </Form.Item>
                                                        <p className="text">
                                                            Критерии оценивания результатов обучения:
                                                        </p>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'unsatisfactorily']}
                                                            label="«неудовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'satisfactorily']}
                                                            label="«удовлетворительно»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'good']}
                                                            label="«хорошо»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'excellent']}
                                                            label="«отлично»"
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder="Введите критерии"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </GenBlock>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    className="item-plus-action"
                                    onClick={() => add(criteriaEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    Добавить еще блок полей для заполнения
                                </ButtonV2>
                            </Form.Item>
                        </div>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineCriteria;
