import React, { useEffect, useMemo } from 'react';
import bem from 'easy-bem';
import { Divider, Form } from 'antd';
import Next from 'components/next';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useTranslation } from 'react-i18next';
import { usePlxParser } from 'utils/plx';
import DisciplineLicensed from './components/discipline-licensed';
import DisciplineAvailability from './components/discipline-availability';
import DisciplineLogistic from './components/discipline-logistic';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';


const tabKey = '5.8';
const emptyObj = emptyDocDetails[tabKey];


const WorkDisciplineLogisticsTab = ({ onNextClick }) => {
    const b = bem('work-discipline-logistics-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const titleInfo = useSelector((state) => state.documents.documentDetails?.[5.1] || {});
    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);

    const plxParser = usePlxParser();
    const plxLoaded = plxParser?.isLoaded();
  
    const lessonsTypeList = useMemo(() => (plxLoaded ? plxParser?.getTypeOfTrainingSession(titleInfo?.directionCodeOrName, '1', '1') : []), [plxLoaded]);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title m-0">{t('work-logistics-educational')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <DisciplineLogistic
                        logisticsEmptyObj={emptyObj.logistics[0]}
                        lessonsTypeList={lessonsTypeList}
                    />
                    <Divider />

                    <DisciplineAvailability
                        availabilityEmptyObj={emptyObj.availability[0]}
                        lessonsTypeList={lessonsTypeList}
                    />
                    <Divider />

                    <DisciplineLicensed
                        licensesEmptyObj={emptyObj.licenses[0]}
                    />
                </Form>

                <Divider />

                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                    }, dispatch)}
                    onNextClick={() => onNextClick(form)}
                />
            </div>
        </div>
    );
};

export default WorkDisciplineLogisticsTab;
