import React, { useEffect, useState } from 'react';
import {
    Col, Divider, Modal, Row, Form
} from 'antd';
import { ButtonV2 } from '../../../../../../index';


const DeleteModal = ({
    isModalVisible, handleCancel, handleOk, b, directionCodeOrName, content
}) => {
    const [width, setWidth] = useState('');
    useEffect(() => {
        if (window.innerWidth < 1300) {
            setWidth('500px');
        } else {
            setWidth('700px');
        }
    }, [window.innerWidth]);
    return (
        <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            className="delete-modal"
            closable={false}
            footer={null}
            width={width}
        >
            <p className={b('title-modal')}>{content.title}</p>
            <Divider />
            <div className={b('confirm-text')}>
                <p>
                    {content.confirmText}
                    <br />
                    {' '}
                    {directionCodeOrName}
                    ?
                </p>
                <p>{content.confirmSubText}</p>
            </div>
            <Divider />
            <Row align="middle" gutter={32}>
                <Col flex="1" align="right">
                    <Form.Item>
                        <ButtonV2
                            className="next-btn"
                            htmlType="submit"
                            type="link"
                            onClick={handleOk}
                        >
                            Удалить РПП
                        </ButtonV2>
                    </Form.Item>
                </Col>
                <Col flex="0 0 97px" align="right">
                    <Form.Item>
                        <ButtonV2
                            className="form-next-btn"
                            type="primary"
                            onClick={handleCancel}
                        >
                            Отменить
                        </ButtonV2>
                    </Form.Item>
                </Col>
            </Row>
        </Modal>
    );
};

export default DeleteModal;
