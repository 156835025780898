import React, { useEffect, useMemo, useState } from 'react';
import {
    Button, Row, Upload, UploadProps
} from 'antd';
import DeleteBtn from 'components/delete-btn';
import { ReactComponent as Files } from 'assets/icons/files.svg';
import { ReactComponent as Danger } from 'assets/icons/danger.svg';
import { ReactComponent as Atach } from 'assets/icons/atach.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useXlsxParser } from 'utils/excel-parse';
import { uploadFile } from 'utils/upload';
import { ModalV2 } from '../../../../../index';
import '../academic-plan-tab/style.less';
import { setCurrentDocDetails } from '../../../../../../models/documents/actions';
import { emptyDocDetails } from '../../constants';


const { Dragger } = Upload;

const readFileAsText = (file) => new Promise((resolve) => {
    try {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(file);
    } catch {
        return null;
    }
});

const UploadModal = ({
    isModalVisible, setIsModalVisible, form
}) => {
    const docDetails = useSelector((state) => state.documents.documentDetails);
    const [fileList, setFileList] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const xlsxParser = useXlsxParser();
    // @ts-ignore

    const accept = useMemo(() => {
        if (fileList?.length === 0) {
            return '.xls, .xlsx, .plx';
        }
        const isPlx = fileList.some((item) => item.name?.includes('.plx'));
        if (isPlx) {
            return '.xls, .xlsx';
        }
        return '.plx';
    }, [fileList]);

    const beforeUpload = (file) => {
        const extension = file.name.split('.').pop();
        if (!accept.split(', ').includes(`.${extension}`)) {
            setError(true);
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    useEffect(() => {
        const listener = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        window.addEventListener('dragover', listener);
        window.addEventListener('drop', listener);

        return () => {
            window.removeEventListener('dragover', listener);
            window.removeEventListener('drop', listener);
        };
    }, []);

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        accept,
        showUploadList: false,
        maxCount: 1,
        onChange(info) {
            if (fileList?.length !== 1) {
                setFileList((prev) => [...prev, info.file]);
                setError(false);
                return;
            }
            setError(true);
        }
    };

    useEffect(() => {
        if (fileList?.length < 1) {
            setDisabled(true);
        } else if (error) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [fileList]);

    const handleDelete = (id) => {
        if (fileList?.length === 1) {
            setFileList([]);
        } else {
            setFileList(fileList.filter((file) => file.uid !== id));
        }
        setError(false);
    };

    const handleUpload = async () => {
        const res = await Promise.all(fileList.map((file) => uploadFile(file)));
        const details = { ...docDetails };
        fileList.forEach(async (file) => {
            if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const result = e?.target?.result || '';
                    const data = await xlsxParser.loadParsingData(result, details, 'excel');
                    if (data) {
                        form.setFieldsValue(data[1.1]);
                        details[1.1] = { ...(docDetails?.[1.1] || {}), ...data[1.1] };
                        details[1.5] = data[1.5];
                        details['3.0'] = { academicPlans: [res?.[0]?.data[0]], parsingData: data?.xlsxData || [] };
                        dispatch(
                            setCurrentDocDetails({ ...details })
                        );
                        setIsModalVisible(false);
                    }
                };
                reader.readAsBinaryString(file);
            } else if (file.name.includes('.plx')) {
                const text = await readFileAsText(file);
                if (!text) return;
                const data = await xlsxParser.loadParsingData(text, details, 'plx');
                if (data) {
                    form.setFieldsValue(data[1.1]);
                    details[1.1] = data[1.1];
                    details[1.2] = data[1.2];
                    details['3.0'] = { academicPlans: [res?.[0]?.data[0]], parsingData: data?.plxData };
                    dispatch(
                        setCurrentDocDetails({ ...details })
                    );
                    setIsModalVisible(false);
                }
            } else {
                dispatch(
                    setCurrentDocDetails(details)
                );
            }
        });
    };

    return (
        <>
            <ModalV2
                width={800}
                title={(
                    <>
                        <p className="title">Загрузка учебного плана</p>
                        <CloseIcon className="close-btn" onClick={() => setIsModalVisible(false)} />
                    </>
                )}
                visible={isModalVisible}
                onCancel={(() => setIsModalVisible(false))}
                footer={[
                    <Button
                        ghost
                        type="primary"
                        key="close"
                        onClick={(() => setIsModalVisible(false))}
                    >
                        Отменить
                    </Button>,
                    <Button
                        disabled={isDisabled}
                        type="primary"
                        key="upload"
                        onClick={handleUpload}
                    >
                        Загрузить файл
                    </Button>
                ]}
            >
                <div className="download-body">
                    <p className="download-notice">
                        Для дальнейшей разработки ОПОП необходимо загрузить учебный план в формате
                        .plx/.xlsx./.xls,
                        составленный в программе АС "Учебные планы”.
                    </p>
                    <Dragger
                        {...props}
                        beforeUpload={beforeUpload}
                        className={error ? 'danger' : ''}
                        disabled={fileList.length === 1}
                    >
                        <Row justify="center" align="middle">
                            {
                                error ? (
                                    <p className="download-text danger">
                                        <Danger />
                                        {' Загрузить можно только 1 PLX файл'}
                                    </p>
                                ) : fileList.length === 1 ? (
                                    <p className="download-text limit">
                                        <Atach />
                                        Вы достигли лимита файлов
                                        <p>1 PLX, XLS, XLSX файл</p>
                                    </p>
                                )
                                    : (
                                        <p className="download-text">
                                            <Atach />
                                            Переместите файл сюда или
                                            <span>выберите файл</span>
                                        </p>
                                    )
                            }
                        </Row>
                    </Dragger>
                    <p className="download-sub">
                        При необходимости в разделе "Учебный план" можно удалить файл и прикрепить другой
                    </p>
                    {
                        fileList?.map((file) => (
                            <div className="file-list">
                                <p className="file-list-item">
                                    <Files className="file" />
                                    {file.name}
                                </p>
                                <DeleteBtn width onClick={() => handleDelete(file.uid)} />
                            </div>
                        ))
                    }
                </div>
            </ModalV2>
        </>
    );
};
export default UploadModal;
