/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row, AutoComplete
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import { GenBlock } from 'components/generated-block/generatedBlock';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import expandArr from 'utils/expandArr';
import { Link } from 'react-router-dom';
import { useXlsxParser } from 'utils/excel-parse';
import { digitalTechnologies } from '../../constants';


const digitalTechnologiesList = digitalTechnologies.map((i) => ({ label: i, value: i }));


const CompetenceCommonFormBlock = ({ // 2.0, блок 1
    competenceCommonEmptyObj, competenceCommon = [], universalComp = [], commonComp = [], generalProfCompFunc = []
}) => {
    const b = bem('competence-common-form-block');
    const { t } = useTranslation('dev-educational-program');
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser.isLoaded();

    const compList = useMemo(() => [
        ...(universalComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list = [] }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
        ...(commonComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list = [] }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            }))
    ], [universalComp, commonComp]);
    const [isCodeAndNameDisabled, setCodeAndNameDisabled] = useState(false);
    useEffect(() => setCodeAndNameDisabled(!compList?.length), [compList]);

    const digitTechsList = useMemo( // списки технологий только для ОПК и ПК, выбранных в 1.7
        () => (generalProfCompFunc || [])
            .filter(({ codeAndName }) => codeAndName)
            .map(({ codeAndName, codAndNameOfIdk }) => ({
                label: codeAndName,
                value: codeAndName,
                option: expandArr(codAndNameOfIdk || [], [
                    'disciplineNameBlock',
                    'digitTechNameMasteredByDisciplineStudyInternship',
                ])
                    .filter(({ digitTechName }) => digitTechName)
                    .map(({ digitTechName }) => ({
                        value: digitTechName,
                        label: digitTechName,
                    }))
            })),
        [generalProfCompFunc]
    );

    const disciplineModuleList = useMemo(() => {
        if (xlsxLoaded) {
            const disciplines = xlsxParser?.getDisciplineModules?.() || [];
            return disciplines.map((discipline) => ({
                label: discipline,
                value: discipline,
            }));
        }
        return [];
    }, [xlsxLoaded]);

    const idkLists = useMemo(
        () => competenceCommon
            .map(({ codeAndName }) => compList
                .find(({ value }) => value === codeAndName)?.option || []),
        [competenceCommon, compList]
    );
    const [isCodeAndNameIndicatorDisabled, setCodeAndNameIndicatorDisabled] = useState(
        new Array(competenceCommon?.length).fill(false)
    );
    useEffect(() => setCodeAndNameIndicatorDisabled(
        competenceCommon.map(({ codeAndName }, i) => !codeAndName || !idkLists?.[i]?.length)
    ), [idkLists]);

    const digitTechOptionsLists = useMemo(
        () => competenceCommon
            .map(({ codeAndName }) => (
                codeAndName.startsWith('УК')
                    ? digitalTechnologiesList // списки технологий для УК
                    : digitTechsList.find(({ value }) => value === codeAndName)?.option || []
            )),
        [competenceCommon, digitTechsList]
    );
    const [isEndToEndDigitlTechDisabled, setEndToEndDigitlTechDisabled] = useState(
        new Array(competenceCommon?.length).fill(false)
    );
    useEffect(() => setEndToEndDigitlTechDisabled(
        competenceCommon
            .map(({ codeAndName }, i) => !codeAndName || !digitTechOptionsLists?.[i]?.length)
    ), [digitTechOptionsLists, competenceCommon]);


    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-1')}: ${t('competence-common')}`}
            </h5>
            <Form.List name="competenceCommon">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('item')} form-items-group`} key={key}>
                                    <Row gutter={32} align="bottom" wrap={false}>
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('comp-code-and-name')}
                                                            name={[name, 'codeAndName']}
                                                            {...handleFieldEnableStatus(
                                                                !!compList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('uc-name')}" 
                                                                    (${t('block-1')}) 
                                                                    и/или 
                                                                    "${t('cc-name')}" 
                                                                    (${t('block-2')}) 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('comp-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t('select-comp-code-and-name')}
                                                                options={compList}
                                                                disabled={isCodeAndNameDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t(
                                                                'competence-achievement-indicator-code-and-name'
                                                            )}
                                                            name={[name, 'codeAndNameIndicator']}
                                                            {...handleFieldEnableStatus(
                                                                !!idkLists?.[name]?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('uc-achievement-indicator-сode-and-name')}" 
                                                                    (${t('block-1')}) 
                                                                    и/или 
                                                                    "${t('cc-achievement-indicator-сode-and-name')}" 
                                                                    (${t('block-2')}) 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('competence-achievement-indicator-code-and-name'),
                                                            )}
                                                            {...handleFieldEnableStatus(
                                                                !!competenceCommon?.[name]?.codeAndName,
                                                                <span>
                                                                    Предварительно необходимо выбрать "Код и наименование компетенции"
                                                                </span>,
                                                                t('competence-achievement-indicator-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'choose-competence-achievement-indicator-code-and-name'
                                                                )}
                                                                mode="multiple"
                                                                options={idkLists?.[name]}
                                                                disabled={isCodeAndNameIndicatorDisabled[name]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={t('end-to-end-digital-technology')}
                                                            name={[name, 'digitalTechnologies']}
                                                            {...handleFieldEnableStatus(
                                                                !!digitTechOptionsLists[name]?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('digit-tech-name-mastered-by-discipline-study-internship')}" 
                                                                    (${t('block-1')}) 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.7'
                                                                        })}
                                                                    >
                                                                        {t('comp-in-digital-technologies-application')}
                                                                    </Link>
                                                                    )
                                                                </span>,
                                                                t('end-to-end-digital-technology')
                                                            )}
                                                            {...handleFieldEnableStatus(
                                                                !!competenceCommon?.[name]?.codeAndName,
                                                                <span>
                                                                    Предварительно необходимо выбрать "Код и наименование компетенции"
                                                                </span>,
                                                                t('end-to-end-digital-technology')
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={t(
                                                                    'select-end-to-end-digital-technologies'
                                                                )}
                                                                options={digitTechOptionsLists[name]}
                                                                disabled={isEndToEndDigitlTechDisabled[name]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <div className="item-block-group-3">
                                                    <p className="item-label">
                                                        {t('disciplines-for-studying-end-to-end-digital-technology')}
                                                    </p>
                                                    <GenBlock
                                                        blockFieldName={name}
                                                        blockName="disciplinesForStudying"
                                                        initialValueAdd={
                                                            competenceCommonEmptyObj.disciplinesForStudying[0]
                                                        }
                                                        {...restField}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'disciplines']}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder={t('enter-discipline-for-studying-end-to-end-digital-technology')}
                                                                size="large"
                                                                showSearch
                                                                options={disciplineModuleList}
                                                            />
                                                        </Form.Item>
                                                    </GenBlock>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(competenceCommonEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default CompetenceCommonFormBlock;
