/* eslint-disable max-len */
import React from 'react';
import {
    Col, Form, Radio, Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineLicensed = ({ licensesEmptyObj }) => { // 5.8, блок 3
    const { t } = useTranslation('dev-educational-program');
    const b = bem('work-discipline-logistics-tab');

    return (
        <section>
            <h5 className="subtitle">
                {t('block-3')}
                : Лицензионное и свободно распространяемое программное обеспечение, в том числе отечественного производства
            </h5>
            <Form.List name="licenses">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({ key, name, ...restField }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    name={[name, 'name']}
                                                    label="Наименование ПО"
                                                    {...restField}
                                                >
                                                    <InputV2
                                                        placeholder="Введите наименование"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, 'user']}
                                                    label="Правообладатель ПО (наименование владельца ПО, страна)"
                                                    {...restField}
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder="Введите владельца ПО и страну"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Доступность"
                                                    name={[name, 'availability']}
                                                    {...restField}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="лицензионное">
                                                            лицензионное
                                                        </Radio>
                                                        <Radio value="свободно распространяемое">
                                                            свободно распространяемое
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, 'link']}
                                                    label="Ссылка на Единый реестр российских программ для ЭВМ и БД (при наличии)"
                                                    {...restField}
                                                >
                                                    <InputV2
                                                        placeholder="Введите ссылку"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <Row>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(licensesEmptyObj)}
                                    className="item-plus-action"
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-block')}
                                </ButtonV2>
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineLicensed;
