import axios from 'axios';
import qs from 'qs';


// const url = process.env.REACT_APP_BACKEND_SERVISE;

const url = process.env.REACT_APP_STRAPY_SERVISE;


export const getRegistryList = (query = {}) => {
    const defaultParams = {
        pagination: {
            page: 1,
            pageSize: 25
        },
        ...query
    };

    return axios.get(`${url}/opop-registries?${qs.stringify(defaultParams)}`);
};

export const getRegistryDetails = (id) => {
    const query = qs.stringify({
        populate: ['documents', 'workPrograms.files'],
    }, {
        encodeValuesOnly: true,
    });

    return axios.get(`${url}/opop-registries/${id}?${query}`);
};

// export const getRegistryList = (status) => {
//     return axios.get(`${url}/list?status=${status}`);
// };

// export const getRegistryDetails = (id) => {

//     return axios.get(`${url}/list/${id}`);
// };
export const getProgramList = (query = {}) => {
    const defaultParams = {
        ...query,
        pagination: {
            page: 1,
            pageSize: 100
        },
        populate: '*',
    };
    return axios.get(`${url}/work-programs?${qs.stringify(defaultParams)}`);
};

export const getCompletedRegistryApi = () => axios.get(`${url}/registry-list/`);


