import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
    exact
    key="opop-constructor"
    path={['/opop-constructor', '/opop-constructor/:id']}
    component={Page}
/>;
