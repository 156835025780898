/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Radio, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DeleteBtn from 'components/delete-btn';
import bem from 'easy-bem';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import expandArr from 'utils/expandArr';
import { useSelector } from 'react-redux';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineAvailability = ({ availabilityEmptyObj, lessonsTypeList = [] }) => { // 5.8, блок 2
    const b = bem('work-discipline-logistics-tab');
    const { t } = useTranslation('dev-educational-program');
    
    const {
        task, seminarTypeClasses, laboratoryStudies, independentWork
    } = useSelector((state) => state.documents.documentDetails?.[5.6] || {});
    const digitTechList = useMemo(
        () => [
            ...expandArr(task || [], [
                'list', 'endToEndDigitlTech'
            ])
                .filter(({ dot }) => dot)
                .map(({ dot }) => ({
                    label: dot,
                    value: dot,
                })),
            ...expandArr(seminarTypeClasses || [], [
                'list', 'endToEndDigitlTech'
            ])
                .filter(({ dot }) => dot)
                .map(({ dot }) => ({
                    label: dot,
                    value: dot,
                })),
            ...expandArr(laboratoryStudies || [], [
                'list', 'endToEndDigitlTechy'
            ])
                .filter(({ dot }) => dot)
                .map(({ dot }) => ({
                    label: dot,
                    value: dot,
                })),
            ...expandArr(independentWork || [], [
                'list', 'endToEndDigitlTech'
            ])
                .filter(({ dot }) => dot)
                .map(({ dot }) => ({
                    label: dot,
                    value: dot,
                })),
        ],
        [task, seminarTypeClasses, laboratoryStudies, independentWork]
    );

    const {
        disciplineResources, electronicLibrarySys, profDatabases
    } = useSelector((state) => state.documents.documentDetails?.[5.7] || {});
    const webResourceList = useMemo(
        () => [
            ...(disciplineResources || [])
                .filter(({ name }) => name)
                .map(({ name }) => ({
                    label: name,
                    value: name,
                })),
            ...(electronicLibrarySys || [])
                .filter(({ name }) => name)
                .map(({ name }) => ({
                    label: name,
                    value: name,
                })),
            ...(profDatabases || [])
                .filter(({ name }) => name)
                .map(({ name }) => ({
                    label: name,
                    value: name,
                })),
        ],
        [disciplineResources, electronicLibrarySys, profDatabases]
    );
    const [isEduResourceNameDisabled, setIsEduResourceNameDisabled] = useState(false);
    useEffect(() => setIsEduResourceNameDisabled(!digitTechList?.length && !webResourceList?.length),
        [digitTechList, webResourceList]);

    return (
        <section>
            <h5 className="subtitle">
                {t('block-2')}
                : Наличие доступа к цифровым образовательным ресурсам, цифровым инструментам и сервисам, необходимым для образовательного процесса по дисциплине (модулю)
            </h5>
            <Form.List name="availability">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({ key, name, ...restField }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'lessons']}
                                                    label="Вид учебных занятий"
                                                >
                                                    {lessonsTypeList?.length ? (
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            placeholder="Выберите ведите вид учебных занятий"
                                                            options={lessonsTypeList.map((x: any) => ({ label: x, value: x }))}
                                                        />
                                                    ) : (
                                                        <InputV2
                                                            placeholder="Введите вид учебных занятий"
                                                        />
                                                    ) }
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, 'name']}
                                                    label="Наименование цифрового образовательного ресурса, доступ к которому необходим для изучения дисциплины (модуля)"
                                                    {...handleFieldEnableStatus(
                                                        !!digitTechList?.length || !!webResourceList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first')} 
                                                            "Применение ЭО и ДОТ" 
                                                            (${t('chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.6' })}
                                                            >
                                                                {t('work-discipline-structure')}
                                                            </Link>
                                                            {`, блоки 2-5) или 
                                                            "Наименование" 
                                                            (${t('chapter')} `}
                                                            <Link
                                                                to={(location) => ({ ...location, hash: '#5.7' })}
                                                            >
                                                                {t('work-discipline-educational')}
                                                            </Link>
                                                            {`, блок "Ресурсы сети Интернет, необходимые 
                                                            для изучения дисциплины" `}
                                                        </span>,
                                                        'Наименование цифрового образовательного ресурса, доступ к которому необходим для изучения дисциплины (модуля)',
                                                    )}
                                                    {...restField}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        placeholder="Выберите наименование"
                                                        options={[
                                                            ...digitTechList,
                                                            ...webResourceList,
                                                        ]}
                                                        disabled={isEduResourceNameDisabled}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'availability']}
                                                    label="Доступность"
                                                >
                                                    <Radio.Group>
                                                        <Radio value="свободный доступ">свободный доступ</Radio>
                                                        <Radio value="ограниченный доступ">ограниченный доступ</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <Row
                                align="middle"
                                justify="space-between"
                            >
                                <ButtonV2
                                    type="link"
                                    className="item-plus-action"
                                    onClick={() => add(availabilityEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-block')}
                                </ButtonV2>
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineAvailability;
