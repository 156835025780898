// @ts-nocheck
import axios, { AxiosResponse } from 'axios';


const url = process.env.REACT_APP_BACKEND_SERVISE;

export const uploadFile = async (file: any): Promise<AxiosResponse> => {
    const token = await window.unione.getUnioneToken();

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `${token}`,
        }
    };
    const fmData = new FormData();
    fmData.append('file', file);
    return axios.post(`${url}/attachment/files`, fmData, config);
};

