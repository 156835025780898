import React from 'react';
import { Button } from 'antd';
import './style.less';
import bem from 'easy-bem';


const ButtonV3 = ({ value, icon, ...rest }) => {
    const b = bem('ooc-button-v3');
    if (value) {
        return (
            <Button className={b()} icon={icon} {...rest}>
                {value}
            </Button>
        );
    }
    return (
        <Button className={b()} {...rest}>
            Блок заполнен. Проверить
        </Button>
    );
};

export default ButtonV3;
