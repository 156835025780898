import React from 'react';
import './style.less';
import errorIcon from 'assets/icons/erroricon.svg';
import successIcon from 'assets/icons/successicon.svg';


const Notification = ({ notifications }) => (
    notifications?.map(({ type, message }, key) => {
        if (type === 'error') {
            return (
                <div className="message" key={key}>
                    <img className="icon" src={errorIcon} alt="" />
                    <span>{message}</span>
                </div>
            );
        }

        if (type === 'success') {
            return (
                <div className="message" key={key}>
                    <img className="icon" src={successIcon} alt="" />
                    <span>{message}</span>
                </div>
            );
        }

        return <></>;
    })
);

export default Notification;
