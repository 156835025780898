import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Col, Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ButtonV2 from 'components/button-v2';

import { PlusOutlined } from '@ant-design/icons';
import InputV2 from 'components/input-v2';
import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '5.2';
const emptyObj = emptyDocDetails[tabKey];


const WorkCharacteristicTab = ({ onNextClick }) => {
    const b = bem('working-characteristic-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-characteristic')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <Form.Item name="goal" label="Цель дисциплины (модуля)">
                            <InputV2
                                textarea
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                placeholder={t('enter-goal')}
                            />
                        </Form.Item>

                        <Form.List name="task">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className={`${b('task-item')} form-item`} key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'task']}
                                                        label="Задача дисциплины (модуля)"
                                                    >
                                                        <InputV2
                                                            textarea
                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                            placeholder="Введите задачу"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.task[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            добавить еще задачу
                                        </ButtonV2>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item
                            name="implementationFeatures"
                            label="Особенности реализации дисциплины (модуля)"
                        >
                            <InputV2
                                textarea
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                placeholder="Введите особенности реализации"
                            />
                        </Form.Item>
                    </section>

                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>

    );
};

export default WorkCharacteristicTab;
