import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Popover } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { InputV2 } from 'components';


interface ParamsProps {
    placeholder?: string;
    state: [string | undefined | null, Dispatch<SetStateAction<string | undefined | null>>]
}


const SearchFilter = ({ state, placeholder }: ParamsProps) => {
    const [v, set] = state;

    const debounced = useDebouncedCallback(
        (value: any) => {
            set(value);
        },
        1000
    );
    const onChange = useCallback((e: any) => debounced(e.target.value), [debounced]);


    return (
        <Popover
            placement="bottomRight"
            content={(
                <InputV2
                    placeholder={placeholder}
                    onChange={onChange}
                    allowClear
                />
            )}
            trigger="click"
        >
            <SearchIcon color={v?.length ? '#0071CE' : ''} />
        </Popover>
    );
};

export default SearchFilter;


