/* eslint-disable max-len */
import React from 'react';
import {
    Col, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import Select from 'components/select';
import OOCTextEditor from '../../../../../../text-editor';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineLogistic = ({ logisticsEmptyObj, lessonsTypeList }) => { // 5.8, блок 1
    const { t } = useTranslation('dev-educational-program');
    const b = bem('work-discipline-logistics-tab');

    return (
        <section>
            <h5 className="subtitle">
                {t('block-1')}
                : Учебное оборудование, технические средства обучения, необходимые для образовательного процесса по дисциплине
            </h5>
            <Form.List name="logistics">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({ key, name, ...restField }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group-horizontal">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'lessonsView']}
                                                    label="Вид учебных занятий"
                                                >
                                                    {lessonsTypeList?.length ? (
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            placeholder="Выберите ведите вид учебных занятий"
                                                            options={lessonsTypeList.map((x: any) => ({ label: x, value: x }))}
                                                        />
                                                    ) : (
                                                        <InputV2
                                                            placeholder="Введите вид учебных занятий"
                                                        />
                                                    ) }
                                                   
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'programName']}
                                                    label={t('program-name')}
                                                >
                                                    <OOCTextEditor
                                                        height="230px"
                                                        placeholder="Введите наименование учебного оборудования"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <Row align="middle">
                                <ButtonV2
                                    className="item-plus-action"
                                    onClick={() => add(logisticsEmptyObj)}
                                    type="link"
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-view-block')}
                                </ButtonV2>
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineLogistic;
