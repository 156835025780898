import React from 'react';
import { Select } from 'antd';


export default function index(props) {
    const { value } = props;
    const newVal = value === '' || (value?.length === 1 && value?.[0] === '') ? undefined : value;
    return (
        <Select {...props} style={{ maxWidth: '700px' }} value={newVal} />
    );
}
