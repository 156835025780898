import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import './style.less';
import { ButtonV2 } from 'components';
import uniqId from 'utils/uniqueId';


const hostUrl = 'https://api.ep.unionepro.ru';
const viewerUrlOffice = 'https://view.officeapps.live.com/op/embed.aspx';
const viewerUrlGoogle = 'https://drive.google.com/viewer?embedded=true';

interface DocumentViewerModalProps {
    url: string | null;
    visible: boolean;
    onClose: any;
}

const DocumentViewerModal = ({ url, visible, onClose }: DocumentViewerModalProps) => {
    const iframeRef = useRef<HTMLImageElement | any>(undefined);
    const [frameKey, setFrameKey] = useState(uniqId());

    const renderFooter = () => (
        <div className="modal-footer">
            <ButtonV2 type="primary" onClick={onClose}>
                Закрыть
            </ButtonV2>
        </div>
    );
    useEffect(() => {
        setFrameKey(uniqId());
    }, [url]);
    // useEffect(() => {
    //     const intervalId = setInterval(
    //         updateIframeSrc, 1000 * 3
    //     );
    //     setIframeTimeoutId(intervalId);
    // }, []);

    // function iframeLoaded() {
    //     clearInterval(iframeTimeoutId);
    // }

    // function closed() {
    //     iframeRef.current.remove();
    // }

    // function updateIframeSrc() {
    //     if (iframeRef.current) {
    //         iframeRef.current.src = `${url?.includes('.pdf')
    //             ? `&${viewerUrlGoogle}`
    //             : `${viewerUrlOffice}`}${url?.includes('.pdf') ? 'url' : 'src'}=${url}`;
    //     }
    // }

    return (
        <Modal
            footer={renderFooter()}
            width={900}
            onCancel={onClose}
            zIndex={1040}
            visible={visible}
            closable={false}
            maskClosable
        >
            {!!url && (
                <iframe
                    key={frameKey}
                    src={`${url?.includes('.pdf')
                        ? `${viewerUrlGoogle}&`
                        : `${viewerUrlOffice}?`}${url?.includes('.pdf') ? 'url' : 'src'}=${url}`}
                    title="file"
                    ref={iframeRef}
                    width="100%"
                    height="600"
                />
            )}

        </Modal>
    );
};
export default DocumentViewerModal;
