import React, { useEffect, useState } from 'react';
import { TableV2, PaginationV2 } from 'components';
import { ReactComponent as ShareSvg } from 'assets/icons/share.svg';
import { useDispatch } from 'react-redux';
import { Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from '../../store/use-selector';
import { getUserRole } from '../../models/auth/selectors';
import { getDockListAction } from '../../models/opop-status/actions';
import { getDocsList } from '../../models/opop-status/selectors';
import StatusBtn from './status-btn';


const ApprovalApplication = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dataForApproval = useSelector(getDocsList);
    const role = useSelector(getUserRole);
    const path = useLocation();

    useEffect(() => {
        dispatch(getDockListAction('onInspection'));
    }, [dispatch, path]);

    const pushHandler = () => {
        if (role === 'main-expert') {
            history.push('/all-replication/programs');
        }
    };

    const columns = [
        {
            key: 'square',
            dataIndex: 'square',
            render: () => (
                <ShareSvg />
            )
        },
        {
            title: 'Код и наименование ОПОП',
            key: 'name',
            dataIndex: 'data',
            render: (dt) => (
                <Space>
                    <p className="expert__codeAndName" onClick={pushHandler}>
                        {dt?.['1.1']?.directionCodeOrName}
                    </p>
                </Space>
            )

        },
        {
            title: 'Отправитель, дата отправки',
            key: 'sender',
            dataIndex: 'sender',
            render: () => (
                <div>
                    <h3 className="expert__sender--name">Ситникова Кармелитта Никитевна</h3>
                    <span className="expert__sender--date">12 марта 2022</span>
                </div>
            ),
        },
        {
            title: 'Наименование организации',
            key: 'organizationName',
            dataIndex: 'sender',
            render: () => (
                <div>
                    <h3 className="expert__organizationName--title">
                        Федеральное Государственное Бюджетное Образовательное
                        Учреждение Высшего Образования "Башкирский
                        Государственный Университет

                    </h3>
                    <span className="expert__organizationName--info">ФГБОУ ВО БАШКИРСКИЙ ГАУ</span>
                </div>
            )
        },
        {
            title: 'Статус',
            key: 'status',
            render: (item) => (
                <StatusBtn item={item} />
            )
        }
    ];


    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (value, pageSize) => {
        setCurrentPage(value);
        setPageSize(pageSize);
    };


    return (
        <div className="list-item hover">
            <h2 className="page-title">Заявки на согласование ОПОП</h2>
            <PaginationV2
                total={dataForApproval?.length}
                showLeftTotal
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={onPageChange}
            />
            <TableV2
                columns={columns}
                dataSource={dataForApproval}
                pagination={false}
                loading={false}
            />
        </div>
    );
};

export default ApprovalApplication;
