// @ts-nocheck
import React, { ReactNode, useEffect, useState } from 'react';
import {
    ModalV2, ButtonV2, InputV2, PaginationV2
} from 'components';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg';
import { Divider } from 'antd';
import { useDispatch } from 'react-redux';
import './style.less';
import { useDebounce } from 'react-use';
import { setDocumentViewerVisible } from 'models/documents/actions';
import { setDownloadStatisticAction } from '../../models/registry/actions';


const hostUrl = 'https://api.ep.unionepro.ru';

export interface WorkProgramModalProps {
    visible: boolean;
    onClose: () => void;
    title?: string | ReactNode;
    type: string;
    programs: any[];
    setDocument: any;
}

const labels = {
    discipline: 'дисциплин',
    practice: 'практик'
};


const ListItem = ({
    title, url, setDocument, details, titleLabel, item
}) => {
    const onItemClick = (url) => () => setDocument(url);
    const dispatch = useDispatch();
    const previewDoc = async (url: any) => {
        onItemClick(url);
        dispatch(setDocumentViewerVisible(`${hostUrl}${url}`));
    };
    const downloadFile = async (item: any) => {
        if (!item?.url) return;
        const element = document.createElement('a');
        element.setAttribute('href', `${hostUrl}${item.url}`);
        element.setAttribute('download', `${item.name}.docx`);
        document.body.appendChild(element);
        element.click();
        setTimeout(() => document.body.removeChild(element), 0);
        setStat();
    };
    const setStat = () => {
        const res = {
            moduleName: title,
            name: titleLabel,
            direction: details?.directionCodeOrName
        };
        dispatch(setDownloadStatisticAction(res, details?.id));
    };
    return (
        <div className="list-item">
            <span>{title}</span>
            <div className="item-actions">
                <ArrowRightIcon onClick={() => previewDoc(url)} />
                <div onClick={() => downloadFile(item)}>
                    <DownloadFileIcon />
                </div>
            </div>
        </div>
    );
};

export const WorkProgramModal = ({
    visible, type, onClose, title, programs, setDocument, details, titleLabel
}: WorkProgramModalProps) => {
    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [localList, setLocalList] = useState([]);

    const [search, setSearch] = useState(null);


    useEffect(() => setLocalList(programs.filter((item) => item.type === type)), [programs, type]);

    useDebounce(() => {
        if (search === '') {
            setLocalList(programs.filter((item) => item.type === type));
        } else {
            setLocalList(programs.filter((item) => (
                search === null
                    ? type === item.type
                    : type === item.type && item.name.toLowerCase().includes(search.toLowerCase())
            )));
        }
    }, 500, [search, type]);

    const onSearch = (e) => setSearch(e.target.value);
    const onPageChange = (value: number, sizePage: number) => {
        setCurrentPage(value);
        setPageSize(sizePage);
    };
    const onReset = () => { setPageSize(20); setCurrentPage(1); };

    return (
        <ModalV2
            width={800}
            centered
            destroyOnClose
            visible={visible}
            onCancel={onClose}
            footer={<ButtonV2 onClick={onClose} ghost type="primary">Закрыть</ButtonV2>}
            className="work-program-modal"
        >
            <h3 className="modal-title">
                Рабочие программы
                {' '}
                {labels[type]}
            </h3>
            <p className="modal-subtitle">
                {title}
            </p>
            <Divider />
            <InputV2
                onChange={onSearch}
                size="large"
                placeholder={`Начните вводить код или наименование ${type === 'discipline' ? 'РПД' : 'РПП'}`}
            />
            <PaginationV2
                total={localList?.length}
                showLeftTotal
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={onPageChange}
            />
            <div className="programs-list">
                <div className="list-header">
                    <span>Наименование РПД</span>
                </div>
                {localList.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((record) => (
                    <ListItem
                        titleLabel={titleLabel}
                        details={details}
                        setDocument={setDocument}
                        key={record?.id}
                        title={record?.name}
                        url={record?.url}
                        item={record}
                    />
                ))}
            </div>
            <PaginationV2
                total={localList?.length}
                showLeftTotal
                onReset={onReset}
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={onPageChange}
            />
        </ModalV2>
    );
};

export default WorkProgramModal;
