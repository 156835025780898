import React from 'react';


export const handleFieldEnableStatus = (
    isSourceFieldFilled,
    fieldPromptMessage,
    fieldLabelText,
) => {
    if (isSourceFieldFilled) return {};

    return {
        label: fieldLabelText && <span className="label disabled">{fieldLabelText}</span>,
        help: <span className="prompt-message">{fieldPromptMessage}</span>,
    };
};

export default handleFieldEnableStatus;
