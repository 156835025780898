import * as c from './constants';


const initialState = {
    documentList: [],
    documentDetails: null,
    directoryList: [],
    docsLoaded: false,
    programList: [],
    docViewerVisible: null,
    currentRpdIndex: -1,
    currentRppIndex: -1,
    rpdList: [],
    rppList: [],
    academicPlans: [],
    docListLoaded: false,
    studyPlan: [],
    documentOwner: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case c.getDocsListByUserId.tries: {
            return { ...state, docsLoaded: false };
        }
        case c.getDocsListByUserId.success: {
            return { ...state, ...action.payload, docsLoaded: true };
        }
        case c.getDocsList.success: {
            return { ...state, documentList: action.payload.documentList, docsLoaded: true };
        }
        case c.getDocById.success: {
            const {
                data, rpdList, rppList, id, user
            } = action.payload;
            return {
                ...state,
                documentDetails: { ...data, id },
                rppList,
                rpdList,
                documentOwner: user,
                docListLoaded: true,
            };
        }
        case c.setMainUser.tries: {
            const { opop, addUsers, user } = action.payload;
            const addUsersCopy = addUsers.filter((x) => x.id !== user.id);
            addUsersCopy.push(opop.user);
            return {
                ...state,
                documentList: state.documentList.map((item) => {
                    if (item.id === opop?.id) {
                        return ({ ...item, additionalUsers: addUsersCopy, user });
                    }
                    return item;
                })
            };
        }
        case c.getDirectoryListMock.success: {
            return { ...state, ...action.payload };
        }
        case c.setAcademicPlans.tries: {
            return { ...state, academicPlans: action.payload };
        }
        case c.setCurrentDocDetails.tries: {
            return { ...state, documentDetails: action.payload };
        }
        case c.setCurrentTabDetails.tries: {
            return {
                ...state,
                documentDetails: {
                    ...(state.documentDetails || {}),
                    [action.payload.key]: action.payload.data
                }
            };
        }
        case c.setRpdList.tries: {
            return { ...state, rpdList: action.payload };
        }
        case c.setCurrentRpdIndex.tries: {
            return { ...state, currentRpdIndex: action.payload };
        }
        case c.setRppList.tries: {
            return { ...state, rppList: action.payload };
        }
        case c.setCurrentRppIndex.tries: {
            return { ...state, currentRppIndex: action.payload };
        }
        case c.setDocumentViewerVisible.tries: {
            return { ...state, docViewerVisible: action.payload };
        }
        case c.updateDocUsersById.tries: {
            return {
                ...state,
                documentList: state.documentList.map((item) => {
                    if (item.id === action.payload.opopId) {
                        return ({ ...item, additionalUsers: action.payload.addUsers });
                    }
                    return item;
                })
            };
        }
        case c.createDoc.success: {
            return {
                ...state, rpdList: [], rppList: [], documentDetails: { ...action.payload.data, id: action.payload.id }
            };
        }
        case c.removeDocById.success: {
            const { payload } = action.payload;
            return { ...state, documentList: state.documentList.filter((x) => x?.id !== payload.id) };
        }
        case c.setDocLoadingStatus.tries: {
            const { payload } = action.payload;
            return { ...state, docListLoaded: payload };
        }
        case c.setUploadStudyPlan.tries: {
            const { payload } = action.payload;
            return { ...state, studyPlan: payload };
        }
        case c.createAcademicPlan.success: {
            const { payload } = action;
            const data = state.documentDetails || {};
            data['3.0'] = {
                ...data?.['3.0'] || {},
                academicPlans: [...data?.['3.0']?.academicPlans || [], ...payload]
            };
            return { ...state, documentDetails: data };
        }
        case c.removeAcademicPlan.tries: {
            const { id } = action.payload;
            return { ...state, academicPlans: state.academicPlans.filter((item) => item?.id !== id) };
        }
        default:
            return state;
    }
};

export default reducer;
