import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import MaskedInput from 'antd-mask-input';
import bem from 'easy-bem';

import './style.less';


const OOCInput = (props) => {
    const b = bem('ooc-input-v2');
    const {
        className: classNameProp,
        textarea = false,
        number = false,
        search = false,
        type = undefined,
        label = undefined,
        withPassword = false,
        name,
        required = false,
        validateStatus,
        help,
        mask,
        rules = [],
        children,
        ...rest
    } = props;

    const ch = !!children ? undefined : undefined;

    const cn = classNameProp ? `${classNameProp} ${b()}` : b();

    if (textarea && label) {
        return (
            <div className={`${cn} ${b('textarea')}`}>
                <span>
                    {label}
                </span>
                <Input.TextArea {...rest} children={ch} />
            </div>
        );
    }

    if (textarea) {
        return (
            <Input.TextArea className={`${cn} ${b('textarea')}`} {...rest} children={ch} />
        );
    }

    if (search) {
        return (
            <div>
                <Input.Search
                    {...rest}
                    children={ch}
                />
            </div>
        );
    }

    if (type === 'form' && label && !mask) {
        return (
            <div className={`${cn} ${b('form-input')} `}>
                <Form.Item
                    name={name}
                    label={label}
                    required={required}
                    validateStatus={validateStatus}
                    help={help}
                    rules={rules}
                >
                    {
                        withPassword
                            ? <Input {...rest} type="password" children={ch} />
                            : <Input {...rest} children={ch} />
                    }
                </Form.Item>
            </div>
        );
    }

    if (type === 'form' && label && mask) {
        return (
            <div className={`${cn} ${b('form-input')} `}>
                <Form.Item
                    name={name}
                    label={label}
                    required={required}
                    validateStatus={validateStatus}
                    help={help}
                    rules={rules}
                >
                    <MaskedInput mask={mask} {...rest} children={ch} />
                </Form.Item>
            </div>
        );
    }

    if (label) {
        return (
            <div className={cn}>
                {label}
                <Input {...rest} children={ch} />
            </div>
        );
    }

    if (number) {
        return (
            <InputNumber
                controls={false}
                className={cn}
                children={ch}
                {...rest}
            />
        );
    }

    return (
        <Input className={cn} {...rest} children={ch} />
    );
};

export default OOCInput;
