import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import reducers from './reducers';
import options from './options';
import saga from './saga';
import * as selectors from './selectors';


const model = {
    actions,
    api,
    constants,
    reducers,
    options,
    saga,
    selectors
};

export default model;
export type { User } from './types';
