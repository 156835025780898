/* eslint-disable react/no-array-index-key */
import React from 'react';
import bem from 'easy-bem';
import { Select } from 'antd';

import './style.less';


const { Option } = Select;

const OOCDropdown = (props) => {
    const b = bem('ooc-dropdown');

    const {
        data,
        groups,
        className: classNameProp,
        isNavigation = false,
        ...rest
    } = props;

    const mappedOptions = [];

    if (isNavigation) {
        // маппим список Реакт нод

        data.forEach(({ node, title }, idx) => {
            mappedOptions.push(
                <Option key={idx} value={idx} label={title}>{node}</Option>
            );
        });
    } else {
        data.forEach((option, idx) => {
            mappedOptions.push(
                <Option key={idx} value={option}>{option}</Option>
            );
        });
    }

    const cn = classNameProp ? `${classNameProp} ${b()}` : b();

    return (
        <div className={cn}>
            <Select dropdownClassName={cn} optionLabelProp="label" {...rest}>
                { mappedOptions }
            </Select>
        </div>
    );
};

export default OOCDropdown;
