import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { Menu, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDirectoryListMock, setDocumentViewerVisible, } from 'models/documents/actions';
import { ReactComponent as ArrowIcon } from 'assets/icons/double-arrow.svg';
import { ReactComponent as DotGreen } from 'assets/icons/dot-green.svg';
import DocumentViewerModal from '../../document-viewer-modal';
import CommonInfo from './common-info/common-info';
import ButtonV3 from '../../button-v3';
import './style.less';


const { SubMenu } = Menu;
const { TabPane } = Tabs;

const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7'];

const EducationalProgram = () => {
    const history = useHistory();
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const [isApproved, setApproved] = useState(false);
    const [tab, setTab] = useState('1.2');
    const [checkedTabs, setCheckedTabs] = useState([]);
    const b = bem('dev-educational-program');
    const { t } = useTranslation('dev-educational-program');
    const dispatch = useDispatch();
    const docViewerVisible = useSelector((state) => state.documents.docViewerVisible);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const getCheckedClass = (tabKey) => (checkedTabs.includes(tabKey) ? 'checked' : 'not-checked');


    const onBackBtnClick = () => {
        history.push('/opop-constructor');
    };

    const onNextBtnClick = (nextStep) => {
        setCheckedTabs([...checkedTabs, tab]);
        const stepSubmenu = `sub${nextStep.split('.')[0]}`;
        setOpenKeys([stepSubmenu]);
        setTab(nextStep);
    };

    const onModalClose = () => {
        dispatch(setDocumentViewerVisible(null));
    };

    useEffect(() => {
        dispatch(getDirectoryListMock());
    }, [dispatch]);

    return (
        <>
            <DocumentViewerModal visible={!!docViewerVisible} onClose={onModalClose} url={docViewerVisible} />
            <div className={b()}>
                <div className="program-header">
                    <div className="program-description">
                        <h2 className="program-title">{t('title')}</h2>
                        <p>
                            Программа бакалавриата
                            01.03.02 Прикладная математика и информатика
                            Программно-технические средства информатизации

                        </p>
                    </div>
                    {
                        isApproved ? (
                            <div>
                                <DotGreen />
                                Одобрена
                            </div>
                        ) : (
                            <ButtonV3 value="Одобрить ОПОП" />
                        )
                    }
                </div>
                <div className="program-content">
                    <Menu
                        className="program-menu"
                        onSelect={({ key }) => setTab(key)}
                        mode="inline"
                        openKeys={openKeys}
                        defaultOpenKeys="sub1"
                        defaultSelectedKeys="1.2"
                        selectedKeys={[tab]}
                        onOpenChange={onOpenChange}
                    >
                        <SubMenu key="sub1" title={t('program-list')[0].name}>
                            <Menu.Item className={getCheckedClass('1.1')} key="1.1">
                                {t('program-list')[0].sublist[0]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.2')} key="1.2">
                                {t('program-list')[0].sublist[1]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.3')} key="1.3">
                                {t('program-list')[0].sublist[2]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.4')} key="1.4">
                                {t('program-list')[0].sublist[3]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.5')} key="1.5">
                                {t('program-list')[0].sublist[4]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.6')} key="1.6">
                                {t('program-list')[0].sublist[5]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('1.7')} key="1.7">
                                {t('program-list')[0].sublist[6]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" title={t('program-list')[1].name}>
                            <Menu.Item className={getCheckedClass('2.0')} key="2.0">
                                {t('program-list')[1].sublist[0]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title={t('program-list')[2].name}>
                            <Menu.Item className={getCheckedClass('3.0')} key="3.0">
                                {t('program-list')[2].sublist[0]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" title={t('program-list')[3].name}>
                            <Menu.Item className={getCheckedClass('4.0')} key="4.0">
                                {t('program-list')[3].sublist[0]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub5" title={t('program-list')[4].name}>
                            <Menu.Item className={getCheckedClass('5.0')} key="5.0">
                                {t('program-list')[4].sublist[0]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.1')} key="5.1">
                                {t('program-list')[4].sublist[1]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.2')} key="5.2">
                                {t('program-list')[4].sublist[2]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.3')} key="5.3">
                                {t('program-list')[4].sublist[3]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.4')} key="5.4">
                                {t('program-list')[4].sublist[4]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.5')} key="5.5">
                                {t('program-list')[4].sublist[5]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.6')} key="5.6">
                                {t('program-list')[4].sublist[6]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.7')} key="5.7">
                                {t('program-list')[4].sublist[7]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.8')} key="5.8">
                                {t('program-list')[4].sublist[8]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.9')} key="5.9">
                                {t('program-list')[4].sublist[9]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('5.10')} key="5.10">
                                {t('program-list')[4].sublist[10]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub6" title={t('program-list')[5].name}>
                            <Menu.Item className={getCheckedClass('6.0')} key="6.0">
                                {t('program-list')[5].sublist[0]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.1')} key="6.1">
                                {t('program-list')[5].sublist[1]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.2')} key="6.2">
                                {t('program-list')[5].sublist[2]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.3')} key="6.3">
                                {t('program-list')[5].sublist[3]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.4')} key="6.4">
                                {t('program-list')[5].sublist[4]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.5')} key="6.5">
                                {t('program-list')[5].sublist[5]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.6')} key="6.6">
                                {t('program-list')[5].sublist[6]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.7')} key="6.7">
                                {t('program-list')[5].sublist[7]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.8')} key="6.8">
                                {t('program-list')[5].sublist[8]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('6.9')} key="6.9">
                                {t('program-list')[5].sublist[9]}
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub7" title={t('program-list')[6].name}>
                            <Menu.Item className={getCheckedClass('7.0')} key="7.0">
                                {t('program-list')[6].sublist[0]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('7.1')} key="7.1">
                                {t('program-list')[6].sublist[1]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('7.2')} key="7.2">
                                {t('program-list')[6].sublist[2]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('7.3')} key="7.3">
                                {t('program-list')[6].sublist[3]}
                            </Menu.Item>
                            <Menu.Item className={getCheckedClass('7.4')} key="7.4">
                                {t('program-list')[6].sublist[4]}
                            </Menu.Item>
                        </SubMenu>
                        <div className="menu-footer">
                            <ArrowIcon onClick={onBackBtnClick} />
                        </div>
                    </Menu>
                    <Tabs activeKey={tab}>
                        <TabPane key="1.2">
                            <CommonInfo />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default EducationalProgram;
