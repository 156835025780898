import React from 'react';
import dayjs from 'dayjs';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';


const OPOPDeveloper = ({
    item, status, t, opopTitle, updatedDate, createdDate, openDetails
}) => {
    const showUsers = () => {
        const users = [];
        let usersString;
        let string;
        let count;
        users.push(`${item.user.lastName} ${item.user.firstName.substring(0, 1)}.`);
        for (let i = 0; i < item.additionalUsers?.length; i++) {
            users.push(`${item.additionalUsers[i]?.lastName} ${item.additionalUsers[i]?.firstName.substring(0, 1)}.`);
        }
        if (users?.length > 3) {
            string = users.slice(0, 3);
            count = `еще ${users?.length - 3}`;
            string.push(count);
            return usersString = string.join(', ');
        }
        return usersString = users.join(', ');
    };
    return (
        <div key={`item-${item.id}`} className="list-item">
            <div className="item-status">
                <div className={`circle ${status}`} />
                {status === 'draft' ? t('draft') : t('done')}
            </div>
            <div className="item-content">
                <div className="content-info">
                    <h5 className="direction-name">
                        {`Программа ${opopTitle}`}
                    </h5>
                    <p>
                        {t('last-change-date', { date: dayjs(updatedDate || createdDate).format('DD.MM.YYYY') })}
                    </p>
                    <p>
                        <UsersIcon />
                        {showUsers()}
                    </p>
                </div>
                <div className="content-actions">
                    <EditIcon onClick={() => openDetails({ ...item })} />
                </div>
            </div>
        </div>
    );
};

export default OPOPDeveloper;
