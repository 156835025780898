import { getConstant } from '../../utils/constant-factory';


export const loginUser = getConstant('LOGIN_USER');
export const checkUser = getConstant('CHECK_USER');
export const fetch = getConstant('FETCH_USER');
export const logoutUser = getConstant('LOGOUT_USER');
export const loginAndCheckUser = getConstant('LOGIN_AND_CHECK_USER');
export const setUserOrgOData = getConstant('SET_USER_ORGANIZATION_ODATA');
export const removeUserOrgOData = getConstant('REMOVE_USER_ORGANIZATION_ODATA');
export const getCurrentUserOrgAppStatus = getConstant(
    'GET_CURRENT_USER_ORGANIZATION_APPLICATION_STATUS'
);
export const checkUserV2 = getConstant('CHECK_USER_V2');
export const getOIDCInfo = getConstant('GET_OIDC_INFO');
export const getUserInfoOdata = getConstant('GET_USER_INFO_ODATA');
export const saveOidcUserData = getConstant('SAVE_OIDC_USER_DATA');
export const loginUserOIDC = getConstant('LOGIN_USER_OIDC');
export const logoutUserOIDC = getConstant('LOGOUT_USER_OIDC');
export const getUserOIDC = getConstant('GET_USER_OIDC');
export const rolesListObj = {
    Individual: { name: 'Слушатель', description: 'Физическое лицо- Преподаватель, проходящий обучение' },
    WorkingGroupMember: {
        name: 'Руководитель организации', description: 'Член рабочей группы (Ректор ОО, член консорциума и индустрия)'
    },
    Administrator: { name: 'Администратор', description: 'Администратор - сотрудники УИ администрирующие ЕМОП' },
    Industry: { name: 'Индустрия', description: 'Индустрия - компании, лидеры отрасли' },
    Department: { name: 'Ведомство', description: 'Ведомство - Минцифра, Минобр' }
};

/** planned roles */

// export const rolesListObj = {
//     Individual: 'Физическое лицо- Преподаватель, проходящий обучение',
//     Department: 'Ведомство - Минцифра, Минобр',
//     Rector: 'Ректор / Директор образовательной организации - участницы консорциума)',
//     Contractor: 'Подрядчик (Образовательная организация, занимающаяся разработко ООП по ТЗ)',
//     WorkingGroupMember: 'Член рабочей группы (Ректор ОО, член консорциума и индустрия)',
//     CuratorFP: 'Кураторы ФП (Минобр и Минцифра)',
//     FounderEducationalOrganization: (
//          'Учредитель образовательной организации (Министрество культуры, Правительство РТ)'
//      ),
//     Administrator: 'Администратор - сотрудники УИ администрирующие ЕМОП',
//     ROIV: 'РОИВ (Правительство РТ, Минобр РТ)',
//     Industry: 'Индустрия - компании, лидеры отрасли',
// };
