/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider, Col, Row, Radio
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ButtonV2 from 'components/button-v2';
import InputV2 from 'components/input-v2';
import DeleteBtn from 'components/delete-btn';
import { PlusOutlined } from '@ant-design/icons';

import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import { updateTabDetails } from 'utils/dispatches';
import StructureModulesFormBlock from '../structure-modules-form-block';
import StructurePracticeFormBlock from '../structure-practice-form-block';
import StructureExamFormBlock from '../structure-exam-form-block';

import './style.less';
import { emptyDocDetails } from '../../constants';
import { importDocDetails, previewDoc } from '../../utils';


const tabKey = '1.5';
const emptyObj = emptyDocDetails[tabKey];

const calculateTabDetails = ({ modulesCredits = '', practiceCredits = '', examCredits = '' }) => ({
    scopeOfProgram: typeof modulesCredits === 'number'
        && typeof practiceCredits === 'number'
        && typeof examCredits === 'number'
        ? modulesCredits + practiceCredits + examCredits
        : '',
});


const OpStructureTab = ({ onNextClick }) => {
    const b = bem('characteristic-op-structure-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const {
        duration: { durationInYears = 0, durationInMonths = 0 } = {}
    } = useSelector((state) => state.documents.documentDetails?.['1.2'] || {});

    const maxCourses = useMemo(
        () => Math.ceil(+durationInYears + durationInMonths / 12),
        [durationInMonths, durationInYears]
    );

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            <div className="constructor-form constructor-form-tight">
                <h4 className="title">
                    {t('educational-programs-structure-and-scope')}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <h5 className="subtitle">{t('program-size-in-credits')}</h5>

                        <StructureModulesFormBlock
                            modulesCredits={tabDetails?.modulesCredits}
                            modulesRequiredCredits={tabDetails?.modulesRequiredCredits}
                            modulesFormedCredits={tabDetails?.modulesFormedCredits}
                        />
                        <Divider />

                        <StructurePracticeFormBlock
                            practiceCredits={tabDetails?.practiceCredits}
                            practiceRequiredCredits={tabDetails?.practiceRequiredCredits}
                            practiceFormedCredits={tabDetails?.practiceFormedCredits}
                        />
                        <Divider />

                        <StructureExamFormBlock />
                    </section>

                    <Divider />

                    <section>
                        <h5 className="subtitle">{t('program-scope-by-course')}</h5>

                        <Row gutter={[16, 4]} align="top">
                            <Col flex="492px">
                                <label
                                    className="item-label item-label-big"
                                    htmlFor="hoursType"
                                >
                                    {t('1-credit-corresponds-to')}
                                </label>
                                <Form.Item name="hoursType">
                                    <Radio.Group>
                                        <Radio value={t('academic-hours.lowercase')}>
                                            {t('academic-hours.first-capital')}
                                        </Radio>
                                        <Radio value={t('astronomical-hours.lowercase')}>
                                            {t('astronomical-hours.first-capital')}
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col flex="1">
                                <Row gutter={8} align="top">
                                    <Col span={24}>
                                        <label
                                            className="item-label item-label-big"
                                            htmlFor="hoursValue"
                                        >
                                            {t('in-quantity')}
                                        </label>
                                    </Col>
                                    <Col flex="84px">
                                        <Form.Item name="hoursValue">
                                            <InputV2 number placeholder={t('hours')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={[32, 8]}>
                            <Col flex="492px">
                                <p className="item-label item-label-big">
                                    {t(
                                        'program-volume-implemented-in-academic-year-in-given-education-is'
                                    )}
                                </p>
                            </Col>
                            <Col flex="1">
                                <Form.List name="programByCourse">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <div className="item-block-group-3">
                                                <div className="form-items-list">
                                                    {fields.map(
                                                        ({
                                                            key, name, ...restField
                                                        }) => (
                                                            <div
                                                                className={`${b('item')} form-items-group`}
                                                                key={key}
                                                            >
                                                                <Row gutter={8} align="bottom" wrap={false}>
                                                                    <Col flex="0 1 104px">
                                                                        <p className="item-label">
                                                                            {`${name + 1} ${t('course')}`}
                                                                        </p>
                                                                        <Row gutter={8} align="middle">
                                                                            <Col flex="84px">
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'courseCredit']}
                                                                                >
                                                                                    <InputV2
                                                                                        number
                                                                                        placeholder={t('credit-units')}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item>
                                                                            <DeleteBtn onClick={() => remove(name)} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                {fields?.length < maxCourses && (
                                                    <Form.Item>
                                                        <ButtonV2
                                                            type="link"
                                                            onClick={() => add(emptyObj.programByCourse[0])}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {t('add-another-course')}
                                                        </ButtonV2>
                                                    </Form.Item>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </section>

                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                            ...calculateTabDetails(tabDetails),
                            ...importDocDetails(importedTabDetails, tabKey)
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default OpStructureTab;
