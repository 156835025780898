import React, { useEffect } from 'react';
import bem from 'easy-bem';
import { Divider, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import { useTranslation } from 'react-i18next';
import expandArr from 'utils/expandArr';
import { updateTabDetails } from 'utils/dispatches';
import DisciplineStructure from './components/discipline-structure';
import DisciplineContent from './components/discipline-content';
import DisciplineLesson from './components/discipline-lesson';
import DisciplineLaboratory from './components/discipline-laboratory';
import DisciplineIndependent from './components/discipline-independent';
import DisciplineLearning from './components/discipline-learning';
import DisciplineTechnology from './components/discipline-technology';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '5.6';
const emptyObj = emptyDocDetails[tabKey];

const getModuleIndex = (sourceBlock = [], consumerBlockItem) => consumerBlockItem?.moduleName
    && sourceBlock.find(
        (sourceBlockItem) => sourceBlockItem?.moduleName === consumerBlockItem?.moduleName
    )?.moduleIndex;

const calculateTabDetails = ({
    structure = [], task = [], seminarTypeClasses = [], laboratoryStudies = [], independentWork = []
}) => ({
    structure: structure.map((structureItem, index) => ({
        ...structureItem,
        moduleIndex: index + 1
    })),
    structureLectureTotal: structure.reduce(
        (acc, { lecture }) => (typeof lecture === 'number' ? +acc + lecture : acc), ''
    ),
    structureSeminarHoursTotal: structure.reduce(
        (acc, { seminarHours }) => (typeof seminarHours === 'number' ? +acc + seminarHours : acc), ''
    ),
    structureLabHoursTotal: structure.reduce(
        (acc, { labHours }) => (typeof labHours === 'number' ? +acc + labHours : acc), ''
    ),
    structureIndependentHoursTotal: structure.reduce(
        (acc, { independentHours }) => (typeof independentHours === 'number' ? +acc + independentHours : acc), ''
    ),

    task: task.map((taskItem) => ({
        ...taskItem,
        moduleIndex: getModuleIndex(structure, taskItem),
    })),
    taskLectureTotal: expandArr(task, ['list']).reduce(
        (acc, { lecture }) => (typeof lecture === 'number' ? +acc + lecture : acc), ''
    ),

    seminarTypeClasses: seminarTypeClasses.map((seminarTypeClassesItem) => ({
        ...seminarTypeClassesItem,
        moduleIndex: getModuleIndex(structure, seminarTypeClassesItem),
    })),
    seminarLectureTotal: expandArr(seminarTypeClasses, ['list']).reduce(
        (acc, { lectureHourse }) => (typeof lectureHourse === 'number' ? +acc + lectureHourse : acc), ''
    ),
    seminarPracticeTotal: expandArr(seminarTypeClasses, ['list']).reduce(
        (acc, { practiceHourse }) => (typeof practiceHourse === 'number' ? +acc + practiceHourse : acc), ''
    ),

    laboratoryStudies: laboratoryStudies.map((laboratoryStudiesItem) => ({
        ...laboratoryStudiesItem,
        moduleIndex: getModuleIndex(structure, laboratoryStudiesItem),
    })),
    laboratoryLectureTotal: expandArr(laboratoryStudies, ['list']).reduce(
        (acc, { lectureHourse }) => (typeof lectureHourse === 'number' ? +acc + lectureHourse : acc), ''
    ),
    laboratoryPracticeTotal: expandArr(laboratoryStudies, ['list']).reduce(
        (acc, { practiceHourse }) => (typeof practiceHourse === 'number' ? +acc + practiceHourse : acc), ''
    ),

    independentWork: independentWork.map((independentWorkItem) => ({
        ...independentWorkItem,
        moduleIndex: getModuleIndex(structure, independentWorkItem),
    })),
    independentWorkLectureTotal: expandArr(independentWork, ['list']).reduce(
        (acc, { lectureHourse }) => (typeof lectureHourse === 'number' ? +acc + lectureHourse : acc), ''
    ),
});


const WorkDisciplineStructureTab = ({ onNextClick }) => {
    const b = bem('work-discipline-structure-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const {
        structure,
        task,
        seminarTypeClasses,
        laboratoryStudies,
        independentWork,
        structureSeminarHoursTotal,
        structureIndependentHoursTotal,
        seminarPracticeTotal,
        laboratoryPracticeTotal,
    } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);


    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-discipline-structure')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <DisciplineStructure
                        structureEmptyObj={emptyObj.structure[0]}
                        structure={structure}
                    />
                    <Divider />

                    <DisciplineContent
                        taskEmptyObj={emptyObj.task[0]}
                        structure={structure}
                        task={task}
                    />
                    <Divider />

                    <DisciplineLesson
                        seminarTypeClassesEmptyObj={emptyObj.seminarTypeClasses[0]}
                        structure={structure}
                        structureSeminarHoursTotal={structureSeminarHoursTotal}
                        seminarTypeClasses={seminarTypeClasses}
                    />
                    <Divider />

                    <DisciplineLaboratory
                        laboratoryStudiesEmptyObj={emptyObj.laboratoryStudies[0]}
                        structure={structure}
                        laboratoryStudies={laboratoryStudies}
                        seminarPracticeTotal={seminarPracticeTotal}
                        laboratoryPracticeTotal={laboratoryPracticeTotal}
                        seminarTypeClasses={seminarTypeClasses}
                    />
                    <Divider />

                    <DisciplineIndependent
                        independentWorkEmptyObj={emptyObj.independentWork[0]}
                        structure={structure}
                        structureIndependentHoursTotal={structureIndependentHoursTotal}
                        independentWork={independentWork}
                    />
                    <Divider />

                    <DisciplineLearning
                        contentOfSectionsEmptyObj={emptyObj.contentOfSections[0]}
                        task={task}
                        seminarTypeClasses={seminarTypeClasses}
                        laboratoryStudies={laboratoryStudies}
                        independentWork={independentWork}
                        structure={structure}
                    />
                    <Divider />

                    <DisciplineTechnology
                        technologicalExpEmptyObj={emptyObj.technologicalExp[0]}
                    />
                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                            ...calculateTabDetails(tabDetails),
                            ...importDocDetails(importedTabDetails, tabKey),
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default WorkDisciplineStructureTab;
