import * as c from './constants';


export const setDrawerVisible = (value) => ({
    type: c.setDrawerVisible.success,
    payload: value
});

export const setIsMobile = (value) => ({
    type: c.setIsMobile.success,
    payload: value
});
export const setOpkForm = (value) => ({
    type: c.setOpkForm.success,
    payload: value
});

export const setСharacteristicForm = (value) => ({
    type: c.setСharacteristicForm.success,
    payload: value
});

export const setDigitalForm = (value) => ({
    type: c.setDigitalForm.success,
    payload: value
});

