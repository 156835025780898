import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form,
    Divider,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import ActivityAreaSphereFormBlock from '../activity-area-sphere-form-block';
import ActivityTasksFormBlock from '../activity-tasks-form-block';
import ActivityMainAreasFormBlock from '../activity-main-areas-form-block';
import ActivityLaborFuncFormBlock from '../activity-labor-func-form-block';
import ActivityCharacteristicFormBlock from '../activity-characteristic-form-block';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';


const tabKey = '1.3';
const emptyObj = emptyDocDetails[tabKey];


const CharacteristicTab = ({ onNextClick }) => {
    const b = bem('characteristic-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('characteristic-prof-activity')}</h4>
                <Form
                    onValuesChange={onValuesChange}
                    initialValues={initialValues}
                    form={form}
                    layout="vertical"
                >
                    <ActivityAreaSphereFormBlock
                        activityAreaSphereEmptyObj={emptyObj?.activityAreaSphere?.[0]}
                    />
                    <Divider />

                    <ActivityTasksFormBlock
                        activityTasksEmptyObj={emptyObj?.activityTasks?.[0]}
                    />
                    <Divider />

                    <ActivityMainAreasFormBlock
                        activityMainAreasEmptyObj={emptyObj?.activityMainAreas?.[0]}
                    />
                    <Divider />

                    <ActivityLaborFuncFormBlock
                        activityAreaSphere={tabDetails?.activityAreaSphere}
                        activityLaborFuncEmptyObj={emptyObj?.activityLaborFunc?.[0]}
                        activityLaborFunc={tabDetails?.activityLaborFunc}
                        form={form}
                    />
                    <Divider />

                    <ActivityCharacteristicFormBlock
                        activityCharacteristicEmptyObj={emptyObj?.activityCharacteristic?.[0]}
                        activityCharacteristic={tabDetails?.activityCharacteristic}
                        activityLaborFunc={tabDetails?.activityLaborFunc}
                        activityTasks={tabDetails?.activityTasks}
                        form={form}
                    />
                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>

    );
};

export default CharacteristicTab;
