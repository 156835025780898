import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import {
    Col, Row, Divider
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDebounce } from 'react-use';
import _pick from 'lodash/pick';
import { omit } from 'lodash';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import {
    ReactComponent as NoResultsImage,
} from 'assets/images/opop-constructor/no-results.svg';

import ButtonV2 from 'components/button-v2';
import InputV2 from 'components/input-v2';

import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDocDetails, setCurrentRppIndex, setRppList } from 'models/documents/actions';
import uniqueId from 'utils/uniqueId';
import { getDocDetails } from 'models/documents/selectors';
import { Link } from 'react-router-dom';
import DeleteModal from './components/deleteModal';
import { generateAndDownloadDocument } from '../../utils';
import DeleteBtn from '../../../../../delete-btn';
import DownloadBtn from '../../../../../download-btn';
import { emptyDocDetails, importDependencies } from '../../constants';


const tabKey = '6.0';

const has = (str, query) => str.toLowerCase().search(query) !== -1;

const content = {
    title: 'Удаление рабочей программы практики',
    confirmText: 'Вы действительно хотите удалить рабочую программу практики',
    confirmSubText: 'РПП будет навсегда удалена.'
};

const moduleKeys = ['6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9'];

const emptyTitleObject = {
    id: null,
    ...emptyDocDetails['6.1'],
    ...importDependencies['6.1'],
    accessYear: new Date().getFullYear(),
    status: 'draft'
};


const WorkSavedPracticesTab = ({ onNextClick }) => {
    const b = bem('work-saved-practices-tab');
    const { t } = useTranslation('dev-educational-program');
    const [searchValue, setSearchValue] = useState('');
    const [practicesList, setPracticesList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [indexofModal, setIndexofModal] = useState(0);
    const rppList = useSelector((state) => state.documents.rppList);
    const docDetails = useSelector(getDocDetails);
    const dispatch = useDispatch();

    const showModal = (e, index) => {
        setIsModalVisible(true);
        setIndexofModal(index);
        e.stopPropagation();
    };

    const download = async (e, index) => {
        e.stopPropagation();
        const newDocDetails = { ...omit(docDetails, moduleKeys), ...rppList[index] };
        generateAndDownloadDocument(
            newDocDetails,
            moduleKeys,
            `РПП ${newDocDetails?.['6.1']?.type?.replaceAll('.', '_')?.replaceAll('/', '-')}`
        );
    };

    const cleaningFromsData = () => {
        const newDocDetails = JSON.parse(JSON.stringify(docDetails));
        moduleKeys.forEach((item) => {
            delete newDocDetails[item];
        });
        dispatch(
            setCurrentDocDetails({
                ...newDocDetails,
                6.1: { ...emptyTitleObject },
            })
        );
    };

    const handleOk = () => {
        const list = [...rppList];
        list.splice(indexofModal, 1);
        cleaningFromsData();
        dispatch(setRppList(list));
        dispatch(setCurrentRppIndex(-1));
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useDebounce(
        () => {
            if (searchValue) {
                const trimQuery = searchValue.trim().toLowerCase();

                if (trimQuery?.length > 0) {
                    setPracticesList(
                        rppList.filter((rdp) => (rdp[6.1]?.type
                            ? has(rdp[6.1]?.type, trimQuery)
                            : false))
                    );
                }
            } else {
                setPracticesList(rppList);
            }
        },
        500,
        [searchValue]
    );

    // const sortedPracticeList = useMemo(
    //     () => practicesList.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    //     [practicesList]
    // );

    useEffect(() => setPracticesList(rppList), [rppList]);

    const onItemClick = (item, index) => () => {
        dispatch(setCurrentRppIndex(index));
        const newDocDetails = JSON.parse(JSON.stringify(docDetails));
        moduleKeys.forEach((moduleKey) => {
            delete newDocDetails[moduleKey];
        });
        dispatch(setCurrentDocDetails({
            ...newDocDetails,
            ..._pick(rppList[index], moduleKeys)
        }));
        onNextClick(tabKey);
    };
    const createNew = () => {
        cleaningFromsData();
        dispatch(setCurrentRppIndex(-1));
        onNextClick();
    };

    if (!rppList?.length) {
        return (
            <div className={b()}>
                <div className={b('header')}>
                    <h4 className={b('title')}>{t('work-saved-practices')}</h4>
                </div>

                <Row gutter={32} justify="space-between">
                    <Col span={12}>
                        <h5 className={b('subtitle')}>
                            В данной образовательной программе еще нет сохраненных РПП.
                        </h5>
                        <h5 className={b('subtitle')}>
                            {'РПП отобразится на этой странице после заполнения и сохранения '}
                            <Link
                                to={(location) => ({ ...location, hash: '#6.1' })}
                            >
                                Титульной информации
                            </Link>
                        </h5>
                    </Col>
                    <Col span={12}>
                        <NoResultsImage />
                    </Col>
                </Row>

            </div>
        );
    }

    return (
        <div className={b()}>
            <Row className={b('header')} justify="space-between" align="middle">
                <h4 className={b('title')}>{t('work-saved-practices')}</h4>
                {rppList?.length < 20 ? (
                    <ButtonV2 onClick={createNew} type="link" icon={<PlusOutlined style={{ fontSize: '16px' }} />}>
                        Создать новую РПП
                    </ButtonV2>
                ) : null}
            </Row>

            <div className={b('search-block')}>
                <InputV2
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Поиск по наименованию сохраненных РПП"
                    size="large"
                    suffix={<SearchIcon className="search-icon" />}
                />
            </div>

            <div className={b('')}>
                {practicesList.map((item, index) => (
                    <>
                        <div
                            onClick={onItemClick(item, index)}
                            aria-hidden="true"
                            key={`item-${uniqueId()}`}
                            className={b('program-list-item')}
                        >
                            <div className={b('item-status')}>
                                <div className={`${b('status-icon')} circle ${item?.status}`} />
                                <div className={b('status-text')}>{t(item?.status)}</div>
                            </div>
                            <Row gutter={24} align="middle" wrap={false}>
                                <Col flex="1">
                                    <div className={b('item-title')}>
                                        {item?.['6.1']?.type}
                                    </div>
                                </Col>
                                <Col>
                                    <DownloadBtn
                                        onClick={(e) => download(e, index)}
                                    />
                                </Col>
                                <Col>
                                    <DeleteBtn
                                        onClick={(e) => showModal(e, index)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Divider />
                    </>
                ))}
            </div>
            <DeleteModal
                isModalVisible={isModalVisible}
                directionCodeOrName={practicesList[indexofModal]?.['6.1']?.type}
                handleCancel={handleCancel}
                content={content}
                handleOk={handleOk}
                b={b}
            />
        </div>
    );
};

export default WorkSavedPracticesTab;
