/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import {
    Col,
    List,
    Pagination,
    Row,
} from 'antd';

import ButtonV2 from 'components/button-v2';
import Modal from 'components/modal-v2';
import InputV2 from 'components/input-v2';
import { ReactComponent as ArrowRightUpIcon } from 'assets/icons/arrow-right-up.svg';

import { useTranslation } from 'react-i18next';

import './style.less';


const data = [
    {
        key: 0,
        docName: 'Федеральный закон «Об образовании в Российской Федерации» от 29.12.2012 № 273-ФЗ',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201212300007'
    },
    {
        key: 1,
        docName: 'Указ Президента Российской Федерации от 01.12.2016 № 642 «Стратегии научно-технологического развития Российской Федерации»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201612010007'
    },
    {
        key: 2,
        docName: 'Распоряжение Правительства РФ от 21.12.2021 N 3759-р «Об утверждении стратегического направления в области цифровой трансформации науки и высшего образования»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001202112250002?index=0&rangeSize=1'
    },
    {
        key: 3,
        docName: 'Приказ Министерства образования и науки Российской Федерации от 23.08.2017 № 816 «Об утверждении Порядка применения организациями, осуществляющими образовательную деятельность, электронного обучения, дистанционных образовательных технологий при реализации образовательных программ»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201709200016'
    },
    {
        key: 4,
        docName: 'Приказ Министерства образования и науки Российской Федерации от 06.04.2021 N 245 «Об утверждении Порядка организации и осуществления образовательной деятельности по образовательным программам высшего образования – программам бакалавриата, программам специалитета, программам магистратуры»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001202108160022'
    },
    {
        key: 5,
        docName: 'Приказ Министерства образования и науки Российской Федерации и Министерства просвещения Российской Федерации от 05.08.2020 № 885/390 «О практической подготовке обучающихся»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001202009110053'
    },
    {
        key: 6,
        docName: 'Приказ Министерства образования и науки Российской Федерации от 29.06.2015 № 636 «Об утверждении Порядка проведения государственной итоговой аттестации по образовательным программам высшего образования – программам бакалавриата, программам специалитета и программам магистратуры»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201507240021'
    },
    {
        key: 7,
        docName: 'Постановление Правительства РФ от 16.11.2020 N 1836 «О государственной информационной системе «Современная цифровая образовательная среда» (вместе с Положением о государственной информационной системе «Современная цифровая образовательная среда»)',
        link: 'http://publication.pravo.gov.ru/Document/View/0001202011190005?index=0&rangeSize=1'
    },
    {
        key: 8,
        docName: 'Указ Президента Российской Федерации от 7 мая 2018 года № 204 «О национальных целях и стратегических задачах развития Российской Федерации на период до 2024 года»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201805070038'
    },
    {
        key: 9,
        docName: 'Указ Президента Российской Федерации от 21 июля 2020 года № 474 «О национальных целях развития Российской Федерации на период до 2030 года»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001202007210012'
    },
    {
        key: 10,
        docName: 'Паспорт национального проекта «Национальная программа «Цифровая экономика Российской Федерации» от 04.06.2019 N 7 президиума Совета при Президенте Российской Федерации по стратегическому развитию и национальным проектам',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201903070015'
    },
    {
        key: 11,
        docName: 'Паспорт федерального проекта «Кадры для цифровой экономики», утвержденный протоколом от 28.05.2019 № 9 президиума Правительственной комиссии по цифровому развитию, использованию информационных технологий для улучшения качества жизни и условий ведения предпринимательской деятельности',
        link: ''
    },
    {
        key: 12,
        docName: 'Постановление Правительства РФ от 03.05.2019 N 551 (ред. от 19.12.2019) «О государственной поддержке программ деятельности лидирующих исследовательских центров, реализуемых российскими организациями в целях обеспечения разработки и реализации дорожных карт развития перспективных «сквозных» цифровых технологий»',
        link: 'http://publication.pravo.gov.ru/Document/View/0001201905070012'
    },
];

const pageSizeOptions = [
    '10',
    '20',
    '50',
    '100',
];

const minPageSizeOption = Number(pageSizeOptions[0]);

const setElementsForCurrentPage = (allElements, page, pageSize) => {
    const offset = (page - 1) * pageSize;
    return allElements.slice(offset, offset + pageSize);
};

function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return originalElement;
    }
    if (type === 'next') {
        return originalElement;
    }
    return originalElement;
}

const defaultPageSize = 20;

const defaultPaginationState = {
    allElements: data,
    totalElementsCount: data?.length,
    offset: 0,
    currentPageElements: setElementsForCurrentPage(data, 1, defaultPageSize),
    pageSize: defaultPageSize,
};


const RegulatoryDocumentsModal = ({ visible, onClose }) => {
    const b = bem('regulatory-documents-modal');
    const { t } = useTranslation('regulatory-documents-modal');

    const [paginationState, setPaginationState] = useState(defaultPaginationState);
    const [searchValue, setSearchValue] = useState();

    const handleSearch = (value) => {
        if (!value) {
            setPaginationState(defaultPaginationState);
            return;
        }

        const allElements = defaultPaginationState.allElements.filter(
            (doc) => doc.docName.toLowerCase().includes(value.toLowerCase())
        );
        const totalElementsCount = allElements?.length;
        const currentPageElements = setElementsForCurrentPage(allElements, 1, defaultPageSize);

        setPaginationState({
            ...paginationState,
            allElements,
            totalElementsCount,
            currentPageElements,
        });
    };

    const handleChange = (event) => {
        setSearchValue(event.target.value);
        handleSearch(event.target.value);
    };

    useEffect(() => {
        if (!searchValue) {
            setPaginationState(defaultPaginationState);
        }
    }, [searchValue]);

    const handlePaginationChange = (page, pageSize) => {
        setPaginationState({
            ...paginationState,
            currentPageElements: setElementsForCurrentPage(paginationState.allElements, page, pageSize),
            pageSize,
        });
    };

    const onCloseBtnClick = () => {
        onClose(false);
    };

    return (
        <Modal
            footer={[
                <ButtonV2
                    onClick={onCloseBtnClick}
                    type="link"
                >
                    {t('close')}
                </ButtonV2>,
            ]}
            closable={false}
            className={b()}
            onCancel={onCloseBtnClick}
            title={t('title')}
            visible={visible}
            destroyOnClose
            maskClosable
            width="100%"
            centered
        >
            <section>
                <InputV2
                    search
                    allowClear
                    placeholder={t('enter-name-search')}
                    className={b('search')}
                    onSearch={handleSearch}
                    value={searchValue}
                    onChange={handleChange}
                />

                <Row gutter={32} justify="space-between">
                    <Col flex="1">
                        <div className={b('search-stats')}>
                            <div>
                                {t('stats-all-records', {
                                    count: paginationState.totalElementsCount
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        {paginationState.totalElementsCount > minPageSizeOption && (
                            <Pagination
                                total={paginationState.totalElementsCount}
                                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                                showSizeChanger
                                pageSizeOptions={pageSizeOptions}
                                defaultPageSize={paginationState.pageSize}
                                defaultCurrent={1}
                                itemRender={itemRender}
                                onChange={handlePaginationChange}
                                locale={{ items_per_page: '' }}
                            />
                        )}
                    </Col>
                </Row>

                <List
                    className={b('list')}
                    size="large"
                    header={<div>{t('header-name')}</div>}
                    bordered
                    locale={{ emptyText: 'Данные отсутствуют' }}
                    dataSource={paginationState.currentPageElements}
                    renderItem={(item) => (
                        <List.Item key={item.key}>
                            <a
                                href={item.link}
                                rel="noreferrer"
                                target="_blank"
                                title={t('open-doc-in-new-window')}
                                className="doc-item"
                            >
                                <div className="doc-name">
                                    {item.docName}
                                </div>
                                <div className="ref-icon">
                                    <ArrowRightUpIcon />
                                </div>
                            </a>
                        </List.Item>
                    )}
                />
            </section>
        </Modal>
    );
};

export default RegulatoryDocumentsModal;
