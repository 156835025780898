/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
import { combineReducers } from 'redux';

import user from './user';
// import heatmap from './heatmap';
import common from './common';
import registry from './registry';
import registryStrapi from './registry-strapi';
import documents from './documents';
import auth from './auth';
import admin from './admin';
import status from './opop-status';


const modules = [
    user,
    // heatmap,
    common,
    documents,
    registry,
    registryStrapi,
    auth,
    admin,
    status,
];

export default modules;

export const actions = modules.reduce(
    (previousValue, { actions }) => ({ ...previousValue, ...actions }), {}
);

export const constants = modules.reduce(
    (previousValue, { constants }) => ({ ...previousValue, ...constants }), {}
);

export const reducers = combineReducers(
    modules.reduce(
        (previousValue, { reducers, options }) =>
            (reducers
                ? ({ ...previousValue, [options.name]: reducers })
                : previousValue
            ), {}
    )
);

export const saga = modules.reduce(
    (previousValue, { saga }) =>
        (saga ? [...previousValue, saga] : previousValue
        ), []
);

export const selectors = modules.reduce(
    (previousValue, { selectors }) => ({ ...previousValue, ...selectors }), {}
);
