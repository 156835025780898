import React from 'react';
import { Form } from 'antd';
import { InputV2 } from '../../../../../../index';


const Criterias = ({ t }) => ( // 7.4, блок 4
    <section>
        <p className="items-group-title">{t('criteria')}</p>
        <Form.Item
            className="space"
            name="excellentCriteria"
            label={t('great')}
        >
            <InputV2
                textarea
                autoSize={{ minRows: 2, maxRows: 4 }}
                placeholder={t('type-criteria')}
            />
        </Form.Item>
        <Form.Item
            className="space"
            name="goodCriteria"
            label={t('good')}
        >
            <InputV2
                textarea
                autoSize={{ minRows: 2, maxRows: 4 }}
                placeholder={t('type-criteria')}
            />
        </Form.Item>
        <Form.Item
            className="space"
            name="satisfactoryCriteria"
            label={t('satisfactory')}
        >
            <InputV2
                textarea
                autoSize={{ minRows: 2, maxRows: 4 }}
                placeholder={t('type-criteria')}
            />
        </Form.Item>
        <Form.Item
            name="unsatisfactoryCriteria"
            label={t('unsatisfactory')}
            className="space"
        >
            <InputV2
                textarea
                autoSize={{ minRows: 2, maxRows: 4 }}
                placeholder={t('type-criteria')}
            />
        </Form.Item>
    </section>
);

export default Criterias;
