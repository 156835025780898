/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import {
    Col, Divider, Form, Radio, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import Next from '../../../../../next';
import { ButtonV2, InputV2 } from '../../../../../index';
import OOCTextEditor from '../../../../../text-editor';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '6.9';
const emptyObj = emptyDocDetails[tabKey];


const WorkProgramLogistics = ({ onNextClick }) => {
    const b = bem('work-logistics');
    const { t } = useTranslation('work-logistics');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const workProgram = useSelector((state) => state.documents.documentDetails?.['6.1']?.workProgram);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className="constructor-form">
            <h4 className="title">{t('title')}</h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1 ? '' : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <section>
                    <h5 className="subtitle">{t('rooms')}</h5>
                    <Form.List name="internships">
                        {(fields, { add, remove }) => (
                            <>
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className={`${b('item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1 1 861px">
                                                    <Row
                                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 16]}
                                                        wrap
                                                    >
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label={t('the-list')}
                                                                {...restField}
                                                                name={[name, 'lern']}
                                                            >
                                                                <OOCTextEditor
                                                                    height="230px"
                                                                    placeholder={t('enter-training')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label="Перечень технических средств, необходимых для прохождения практики в образовательной организации"
                                                                {...restField}
                                                                name={[name, 'tech']}
                                                            >
                                                                <OOCTextEditor
                                                                    height="230px"
                                                                    placeholder="Введите технические средства"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(emptyObj.internships[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        Добавить еще учебное оборудование и технические средства
                                    </ButtonV2>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle">{t('access-availability')}</h5>
                    <Form.List name="resources">
                        {(fields, { add, remove }) => (
                            <>
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className={`${b('item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1 1 861px">
                                                    <div className="item-block-group">
                                                        <Form.Item
                                                            label={t('work-type')}
                                                            {...restField}
                                                            name={[name, 'workType']}
                                                        >
                                                            <InputV2 placeholder={t('enter-work-type')} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={t('digital-name')}
                                                            {...restField}
                                                            name={[name, 'digitalName']}
                                                        >
                                                            <InputV2 placeholder={t('type-name')} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={t('availability')}
                                                            {...restField}
                                                            name={[name, 'availability']}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value={t('free-availability')}>
                                                                    {t('free-availability')}
                                                                </Radio>
                                                                <Radio value={t('limited-availability')}>
                                                                    {t('limited-availability')}
                                                                </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(emptyObj.resources[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-more-block')}
                                    </ButtonV2>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle">{t('licensed')}</h5>
                    <Form.List name="licensed">
                        {(fields, { add, remove }) => (
                            <>
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className={`${b('item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1 1 861px">
                                                    <div className="item-block-group">
                                                        <Form.Item
                                                            label={t('po-name')}
                                                            {...restField}
                                                            name={[name, 'poName']}
                                                        >
                                                            <InputV2 placeholder={t('type-name')} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={t('holder')}
                                                            {...restField}
                                                            name={[name, 'holder']}
                                                        >
                                                            <InputV2
                                                                textarea
                                                                autoSize={{ minRows: 2, maxRows: 4 }}
                                                                placeholder={t('type-holder')}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={t('availability')}
                                                            {...restField}
                                                            name={[name, 'availability']}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value={t('license-fee')}>
                                                                    {t('license-fee')}
                                                                </Radio>
                                                                <Radio value={t('freeware')}>{t('freeware')}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={t('link')}
                                                            {...restField}
                                                            name={[name, 'link']}
                                                        >
                                                            <InputV2 placeholder={t('type-link')} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(emptyObj.licensed[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-more-block')}
                                    </ButtonV2>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </section>
            </Form>
            <Divider />

            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick(form)}
            />
        </div>
    );
};

export default WorkProgramLogistics;
