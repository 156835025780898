import React, { useEffect, useState } from 'react';
import { notification, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import notFoundImg from 'assets/images/not-found.svg';
import './style.less';
import { Option } from 'antd/es/mentions';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
// import { setRole } from '../../models/admin/api';
import { InputV2 } from '../index';
import { setSearchedUserAction, setUserRoleAction } from '../../models/admin/actions';
import { getUser } from '../../models/admin/selectors';


const AdminPanel = () => {
    const { t } = useTranslation('admin-panel-page');
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [role, setNewRole] = useState('');
    const openNotificationWithIcon = () => {
        notification.success({
            message: t('changed-role')
        });
    };
    useEffect(() => {
        if (user) {
            const newRole = user.roles?.[0] || '';
            setNewRole(newRole);
        }
    }, [user]);

    const handleSearch = () => {
        dispatch(setSearchedUserAction(searchValue));
    };

    const handleChange = (newRole) => {
        setNewRole(newRole);
        dispatch(setUserRoleAction(searchValue, newRole));
        openNotificationWithIcon();
    };

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text) => <p>{text}</p>,
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text) => <p>{text}</p>,
        },
        {
            title: 'Роль',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags) => (
                <>
                    <Select
                        style={{ width: 200 }}
                        onSelect={handleChange}
                        value={role}
                    >
                        <Option value="opop-developer">{t('opop-dev')}</Option>
                        <Option value="main-developer">{t('main-opop-dev')}</Option>
                        <Option value="replication-developer">{t('replication-opop-dev')}</Option>
                        <Option value="main-expert">{t('main-expert')}</Option>
                        <Option value="admin-expert">{t('admin-expert')}</Option>
                        <Option value="admin">{t('admin')}</Option>
                    </Select>
                </>
            ),
        }
    ];


    return (
        <div className="main-admin-panel">
            <Title level={3} type="secondary">{t('setRole')}</Title>
            <div className="search">
                <InputV2
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="large"
                    placeHolder={t('enter-id')}
                    suffix={<SearchIcon className="search-icon" onClick={handleSearch} />}
                />
            </div>
            {
                user.id
                    ? (
                        <>
                            <Table columns={columns} dataSource={[user]} pagination={false} />
                        </>
                    )
                    : (
                        <div className="not-found">
                            <img src={notFoundImg} alt="" />
                            <p>{t('not-found')}</p>
                        </div>
                    )
            }
        </div>
    );
};

export default AdminPanel;
