import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
    exact
    key="admin"
    path={['/admin']}
    component={Page}
/>;
