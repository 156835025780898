import React, { useState } from 'react';
import { ReactComponent as DotSvg } from 'assets/icons/dot.svg';
import { ReactComponent as DotGreen } from 'assets/icons/dot-green.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setCompletedAction, updateStatusAction } from '../../models/opop-status/actions';


const StatusBtn = ({ item }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('onInspection');
    const dispatch = useDispatch();
    const closeLoader = () => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    };

    const confirmHandler = (item, status, confirm) => {
        setLoading(confirm);
        closeLoader();
        setStatus(status);
        dispatch(setCompletedAction(item));
        dispatch(updateStatusAction(item._id, status));
    };
    return (
        <>
            {
                status === 'onInspection' && !loading && status !== 'completed'
                    ? (
                        <div className="expert-status">
                            <div className="status-condition">
                                <DotSvg className="expert__status--icon" />
                                <span className="expert__status--process">на согласовании</span>
                            </div>
                            <p
                                onClick={() => confirmHandler(item, 'completed', false)}
                                className="expert__status--confirm"
                            >
                                Одобрить

                            </p>
                        </div>
                    )
                    : loading
                        ? (
                            <div className="expert-status loading">
                                <span className="expert__status--approved">ОПОП одобрена</span>
                                <Spin
                                    indicator={(
                                        <LoadingOutlined
                                            style={{ fontSize: 24, color: '#fff' }}
                                            spin
                                        />
                                    )}
                                />
                                <p
                                    onClick={() => confirmHandler(item, 'onInspection', false)}
                                    className="expert__status--cancel"
                                >
                                    Отменить

                                </p>
                            </div>
                        )
                        : (
                            <div className="expert-status-approved">
                                <DotGreen className="expert__status--iconGreen" />
                                <span className="expert__status--process">Одобрена</span>
                            </div>
                        )
            }
        </>
    );
};

export default StatusBtn;
