/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import {
    Col, Divider, Form, Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/select';
import DeleteBtn from 'components/delete-btn';
import expandArr from 'utils/expandArr';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import Next from '../../../../../next';
import { ButtonV2 } from '../../../../../index';
import useDebouncedCallback from '../../../../../../utils/use-debounce-callback';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '7.3';
const emptyObj = emptyDocDetails[tabKey];


const AttestationProgramResult = ({ onNextClick }) => {
    const b = bem('attestation-title');
    const { t } = useTranslation('attestation-title');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const {
        activityMainAreas = [], activityTasks = []
    } = useSelector((state) => state.documents.documentDetails?.['1.3'] || {});
    const {
        universalComp = [], commonComp = [], profComp = []
    } = useSelector((state) => state.documents.documentDetails?.['1.4'] || {});

    const ucGroupnameList = (universalComp || [])
        .filter(({ name }) => name)
        .map(({ name }) => ({
            label: name,
            value: name,
        }));
    const [isUcGroupNameDisabled, setIsUcGroupNameDisabled] = useState(false);
    useEffect(() => setIsUcGroupNameDisabled(!ucGroupnameList?.length), [ucGroupnameList]);

    const { ucCodeNameList, ucCodeList } = (universalComp || [])
        .filter(({ codeName }) => codeName)
        .reduce(({ ucCodeNameList, ucCodeList }, { codeName, codeUc }) => ({
            ucCodeNameList: [
                ...ucCodeNameList,
                {
                    label: codeName,
                    value: codeName,
                }],
            ucCodeList: [
                ...ucCodeList,
                {
                    label: codeUc,
                    value: codeUc
                }],
        }), { ucCodeNameList: [], ucCodeList: [] });
    const [isUcCodeNameDisabled, setIsUcCodeNameDisabled] = useState(false);
    useEffect(() => setIsUcCodeNameDisabled(!ucCodeNameList?.length), [ucCodeNameList]);

    const ucIdkCodeNameList = useMemo(
        () => expandArr(universalComp || [], ['list'])
            .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
            .map(({ indicatorCode, indicatorName }) => ({
                value: `${indicatorCode}. ${indicatorName}`,
                label: `${indicatorCode}. ${indicatorName}`,
            })),
        [universalComp]
    );
    const [isUcIdkCodeNameDisabled, setIsUcIdkCodeNameDisabled] = useState(false);
    useEffect(() => setIsUcIdkCodeNameDisabled(!ucIdkCodeNameList?.length), [ucIdkCodeNameList]);

    const opkGroupnameList = (commonComp || [])
        .filter(({ name }) => name)
        .map(({ name }) => ({
            label: name,
            value: name,
        }));
    const [isOpkGroupnameDisabled, setIsOpkGroupnameDisabled] = useState(false);

    useEffect(() => setIsOpkGroupnameDisabled(!opkGroupnameList?.length), [opkGroupnameList]);

    const { opkCodeNameList, opkCodeList } = (commonComp || [])
        .filter(({ codeName }) => codeName)
        .reduce(({ opkCodeNameList, opkCodeList }, { codeName, codeCc }) => ({
            opkCodeNameList: [
                ...opkCodeNameList,
                {
                    label: codeName,
                    value: codeName,
                }],
            opkCodeList: [
                ...opkCodeList,
                {
                    label: codeCc,
                    value: codeCc
                }],
        }), { opkCodeNameList: [], opkCodeList: [] });
    const [isOpkCodeNameDisabled, setIsOpkCodeNameDisabled] = useState(false);
    useEffect(() => setIsOpkCodeNameDisabled(!opkCodeNameList?.length), [opkCodeNameList]);

    const opkIdkCodeNameList = useMemo(
        () => expandArr(commonComp || [], ['list'])
            .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
            .map(({ indicatorCode, indicatorName }) => ({
                value: `${indicatorCode}. ${indicatorName}`,
                label: `${indicatorCode}. ${indicatorName}`,
            })),
        [commonComp]
    );
    const [isOpkIdkCodeNameDisabled, setIsOpkIdkCodeNameDisabled] = useState(false);
    useEffect(() => setIsOpkIdkCodeNameDisabled(!opkIdkCodeNameList?.length), [opkIdkCodeNameList]);

    const pcTaskList = useMemo(
        () => expandArr(activityTasks || [], ['tasks'])
            .filter(({ name }) => name)
            .map(({ name }) => ({
                label: name,
                value: name,
            })),
        [activityTasks]
    );
    const [isPcTaskDisabled, setIsPcTaskDisabled] = useState(false);
    useEffect(() => setIsPcTaskDisabled(!pcTaskList?.length), [pcTaskList]);

    const pcAreaList = (activityMainAreas || [])
        .filter(({ area }) => area)
        .map(({ area }) => ({
            label: area,
            value: area,
        }));
    const [isPcAreaDisabled, setIsPcAreaDisabled] = useState(false);
    useEffect(() => setIsPcAreaDisabled(!pcAreaList?.length), [pcAreaList]);

    const { pcCodeNameList, pcCodeList } = (profComp || [])
        .filter(({ codeName }) => codeName)
        .reduce(({ pcCodeNameList, pcCodeList }, { codeName, codePc }) => ({
            pcCodeNameList: [
                ...pcCodeNameList,
                {
                    label: codeName,
                    value: codeName,
                }],
            pcCodeList: [
                ...pcCodeList,
                {
                    label: codePc,
                    value: codePc
                }],
        }), { pcCodeNameList: [], pcCodeList: [] });
    const [isPcCodeNameDisabled, setIsPcCodeNameDisabled] = useState(false);
    useEffect(() => setIsPcCodeNameDisabled(!pcCodeNameList?.length), [pcCodeNameList]);

    const pcIdkCodeNameList = useMemo(
        () => expandArr(profComp || [], ['list'])
            .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
            .map(({ indicatorCode, indicatorName }) => ({
                value: `${indicatorCode}. ${indicatorName}`,
                label: `${indicatorCode}. ${indicatorName}`,
            })),
        [profComp]
    );
    const [isPcIdkCodeNameDisabled, setIsPcIdkCodeNameDisabled] = useState(false);
    useEffect(() => setIsPcIdkCodeNameDisabled(!pcIdkCodeNameList?.length), [pcIdkCodeNameList]);

    const [isStateExamCompDisabled, setIsStateExamCompDisabled] = useState(false);
    useEffect(() => setIsStateExamCompDisabled(
        !ucCodeList?.length && !opkCodeList?.length && !pcCodeList?.length
    ), [ucCodeList, opkCodeList, pcCodeList]);

    const [isFinalQualificationWorkCompDisabled, setIsFinalQualificationWorkCompDisabled] = useState(false);
    useEffect(() => setIsFinalQualificationWorkCompDisabled(
        !ucCodeList?.length && !opkCodeList?.length && !pcCodeList?.length
    ), [ucCodeList, opkCodeList, pcCodeList]);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);


    return (
        <div className="constructor-form">
            <h4 className="title">{t('title')}</h4>
            <p>{t('in-gia')}</p>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <section>
                    <h5 className="subtitle">{t('universal')}</h5>
                    <Form.List name="universalComp">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        label={t('uk-groupname')}
                                                        name={[name, 'groupName']}
                                                        {...handleFieldEnableStatus(
                                                            !!ucGroupnameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('uk-groupname')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-1')})`}
                                                            </span>,
                                                            t('uk-groupname'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-name')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={ucGroupnameList}
                                                            disabled={isUcGroupNameDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('uk-code-name')}
                                                        name={[name, 'codeName']}
                                                        {...handleFieldEnableStatus(
                                                            !!ucCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('uk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-1')})`}
                                                            </span>,
                                                            t('uk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-name')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={ucCodeNameList}
                                                            disabled={isUcCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('uk-idk-code-name')}
                                                        name={[name, 'idkCodeNames']}
                                                        {...handleFieldEnableStatus(
                                                            !!ucIdkCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('uk-idk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-1')})`}
                                                            </span>,
                                                            t('uk-idk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-idk')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            mode="multiple"
                                                            options={ucIdkCodeNameList}
                                                            disabled={isUcIdkCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.universalComp[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-block')}
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle">{t('common')}</h5>
                    <Form.List name="commonComp">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        label={t('opk-groupname')}
                                                        name={[name, 'groupName']}
                                                        {...handleFieldEnableStatus(
                                                            !!opkGroupnameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('opk-groupname')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-2')})`}
                                                            </span>,
                                                            t('opk-groupname'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-name')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={opkGroupnameList}
                                                            disabled={isOpkGroupnameDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('opk-code-name')}
                                                        name={[name, 'codeName']}
                                                        {...handleFieldEnableStatus(
                                                            !!opkCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('opk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-2')})`}
                                                            </span>,
                                                            t('opk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-name')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={opkCodeNameList}
                                                            disabled={isOpkCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('opk-idk-code-name')}
                                                        name={[name, 'idkCodeNames']}
                                                        {...handleFieldEnableStatus(
                                                            !!opkIdkCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('opk-idk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-2')})`}
                                                            </span>,
                                                            t('opk-idk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-idk')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            mode="multiple"
                                                            options={opkIdkCodeNameList}
                                                            disabled={isOpkIdkCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.commonComp[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-block')}
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <h5 className="subtitle">{t('professional')}</h5>
                    <Form.List name="profComp">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-items-group`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <div className="item-block-group">
                                                    <Form.Item
                                                        label={t('professional-task')}
                                                        name={[name, 'tasks']}
                                                        {...handleFieldEnableStatus(
                                                            !!pcTaskList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('professional-task')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.3' })}
                                                                >
                                                                    Характеристика профессиональной деятельности выпускников
                                                                </Link>
                                                                {`, ${t('block-2')})`}
                                                            </span>,
                                                            t('professional-task'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-name')}
                                                            showSearch
                                                            mode="multiple"
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={pcTaskList}
                                                            disabled={isPcTaskDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('professional-object')}
                                                        name={[name, 'areas']}
                                                        {...handleFieldEnableStatus(
                                                            !!pcAreaList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('professional-object')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.3' })}
                                                                >
                                                                    {t('characteristic-prof-activity')}
                                                                </Link>
                                                                {`, ${t('block-3')})`}
                                                            </span>,
                                                            t('professional-object'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-professional-object')}
                                                            showSearch
                                                            mode="multiple"
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={pcAreaList}
                                                            disabled={isPcAreaDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('pk-code-name')}
                                                        name={[name, 'codeName']}
                                                        {...handleFieldEnableStatus(
                                                            !!pcCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('pk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-3')})`}
                                                            </span>,
                                                            t('pk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-name')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            options={pcCodeNameList}
                                                            disabled={isPcCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t('pk-idk-code-name')}
                                                        name={[name, 'idkCodeNames']}
                                                        {...handleFieldEnableStatus(
                                                            !!pcIdkCodeNameList?.length,
                                                            <span>
                                                                {`${t('fill-in-field-first')} 
                                                                "${t('pk-idk-code-name')}" 
                                                                (${t('chapter')} `}
                                                                <Link
                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                >
                                                                    {t('educational-programs-development-results')}
                                                                </Link>
                                                                {`, ${t('block-3')})`}
                                                            </span>,
                                                            t('pk-idk-code-name'),
                                                        )}
                                                        {...restField}
                                                    >
                                                        <Select
                                                            className="form-select"
                                                            size="large"
                                                            placeholder={t('choose-code-idk')}
                                                            showSearch
                                                            notFoundContent={t('not-found-content-text')}
                                                            mode="multiple"
                                                            options={pcIdkCodeNameList}
                                                            disabled={isPcIdkCodeNameDisabled}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col span={22}>
                                        <Form.Item>
                                            <Row
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <ButtonV2
                                                    type="link"
                                                    onClick={() => add(emptyObj.profComp[0])}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('add-more-block')}
                                                </ButtonV2>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <Row gutter={32}>
                        <Col flex="1">
                            <p className="title2">{t('as-part')}</p>
                        </Col>
                        <Col flex="0 0 258px">
                            <Form.Item
                                name="stateExamComps"
                                {...handleFieldEnableStatus(
                                    !!ucCodeList?.length || !!opkCodeList?.length || !!pcCodeList?.length,
                                    <span>
                                        {`${t('fill-in-field-first')} 
                                        "${t('uk-code-name')}", 
                                        "${t('opk-code-name')}" ${t('or')} 
                                        "${t('pk-code-name')}" 
                                        (${t('chapter')} `}
                                        <Link
                                            to={(location) => ({ ...location, hash: '#1.4' })}
                                        >
                                            {t('educational-programs-development-results')}
                                        </Link>
                                        )
                                    </span>,
                                )}
                            >
                                <Select
                                    className="form-select"
                                    size="large"
                                    placeholder={t('code')}
                                    showSearch
                                    notFoundContent={t('not-found-content-text')}
                                    mode="multiple"
                                    options={[
                                        ...ucCodeList,
                                        ...opkCodeList,
                                        ...pcCodeList,
                                    ]}
                                    disabled={isStateExamCompDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="55px" />
                    </Row>
                    <Row gutter={32}>
                        <Col flex="1">
                            <p className="title2">{t('based-on')}</p>
                        </Col>
                        <Col flex="0 0 258px">
                            <Form.Item
                                name="finalQualificationWorkComps"
                                {...handleFieldEnableStatus(
                                    !!ucCodeList?.length || !!opkCodeList?.length || !!pcCodeList?.length,
                                    <span>
                                        {`${t('fill-in-field-first')} 
                                        "${t('uk-code-name')}", 
                                        "${t('opk-code-name')}" ${t('or')} 
                                        "${t('pk-code-name')}" 
                                        (${t('chapter')} `}
                                        <Link
                                            to={(location) => ({ ...location, hash: '#1.4' })}
                                        >
                                            {t('educational-programs-development-results')}
                                        </Link>
                                        )
                                    </span>,
                                )}
                            >
                                <Select
                                    className="form-select"
                                    size="large"
                                    placeholder={t('code')}
                                    showSearch
                                    notFoundContent={t('not-found-content-text')}
                                    mode="multiple"
                                    options={[
                                        ...ucCodeList,
                                        ...opkCodeList,
                                        ...pcCodeList,
                                    ]}
                                    disabled={isFinalQualificationWorkCompDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="55px" />
                    </Row>
                </section>
            </Form>

            <Divider />

            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick()}
            />
        </div>
    );
};

export default AttestationProgramResult;
