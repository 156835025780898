/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
    Form, Divider, Row, Col
} from 'antd';
import { ButtonV2 } from 'components';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/download-file.svg';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { updateTabDetails } from 'utils/dispatches';
import DigitalTechAppGeneralProfCompTab from '../digital-tech-general-prof-comp';
import DigitalTechAppProfCompTab from '../digital-tech-prof-comp';
import { generateAndDownloadDocument, importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '1.7';
const emptyObj = emptyDocDetails[tabKey];


const DigitalTechAppCompTab = ({ onNextClick }) => {
    const b = bem('digital-technologies-application-сomp');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const moduleDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1']),
        1.2: useSelector((state) => state.documents.documentDetails?.['1.2']),
        1.3: useSelector((state) => state.documents.documentDetails?.['1.3']),
        1.4: useSelector((state) => state.documents.documentDetails?.['1.4']),
        1.5: useSelector((state) => state.documents.documentDetails?.['1.5']),
        1.6: useSelector((state) => state.documents.documentDetails?.['1.6']),
        1.7: useSelector((state) => state.documents.documentDetails?.['1.7']),
    };
    const tabDetails = moduleDetails[tabKey];

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedModuleDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1']),
    };
    const importedTabDetails = {
        1.1: moduleDetails[1.1]
    };

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'generalProfCompFunc' && name[2] === 'codeAndName') {
                newTabDetails.generalProfCompFunc[name[1]].codAndNameOfIdk = (newTabDetails.generalProfCompFunc[name[1]].codAndNameOfIdk || [])
                    .map(({ psName, ...rest }) => ({
                        ...rest,
                        psName: ''
                    }));
            }
            if (name[0] === 'profCompFunc' && name[2] === 'codeAndName') {
                newTabDetails.profCompFunc[name[1]].codAndNameOfIdk = (newTabDetails.profCompFunc[name[1]].codAndNameOfIdk || [])
                    .map(({ psName, ...rest }) => ({
                        ...rest,
                        psName: ''
                    }));
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('digital-technologies-application-сomp')}</h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <DigitalTechAppGeneralProfCompTab
                        generalProfCompFuncEmptyObj={emptyObj.generalProfCompFunc[0]}
                        generalProfCompFunc={tabDetails?.generalProfCompFunc}
                    />
                    <Divider />

                    <DigitalTechAppProfCompTab
                        profCompFuncEmptyObj={emptyObj.profCompFunc[0]}
                        profCompFunc={tabDetails?.profCompFunc}
                    />
                    <Divider />

                    <Row gutter={[40, 24]} align="middle" justify="end">
                        <Col flex="1">
                            <Form.Item>
                                <ButtonV2
                                    className="download-btn"
                                    onClick={() => generateAndDownloadDocument(
                                        {
                                            ...moduleDetails,
                                            ...importedModuleDetails
                                        },
                                        Object.keys(moduleDetails),
                                        'Общая характеристика ОПОП'
                                    )}
                                    type="primary"
                                    icon={<FileDownloadIcon />}
                                >
                                    {t('download-OPOP-general-characteristics')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <ButtonV2
                                    onClick={() => previewDoc(tabKey, {
                                        ...emptyObj,
                                        ...tabDetails,
                                        ...importDocDetails(importedTabDetails, tabKey)
                                    }, dispatch)}
                                    type="link"
                                >
                                    {t('preview')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <ButtonV2
                                    className="form-next-btn"
                                    onClick={() => onNextClick(form)}
                                    type="primary"
                                >
                                    {t('next')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default DigitalTechAppCompTab;
