import React, { useMemo } from 'react';
import bem from 'easy-bem';
import {
    Form, Row, Col, AutoComplete
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { InputV2, ButtonV2 } from 'components';

import { useTranslation } from 'react-i18next';
import DeleteBtn from 'components/delete-btn';
import { useXlsxParser } from 'utils/excel-parse';
import { useSelector } from 'react-redux';
import OtfTfCodeAndNameSubformBlock from './otf-tf-code-and-name-subform-block';


const tabKey = '1.3';

const PsOtfTfCodeAndNameSubformBlock = ({ fieldName, codeAndNameOfPsOtfTfEmptyObj, form }) => {
    const b = bem('ps-otf-tf-code-and-name-subform-block');
    const { t } = useTranslation('dev-educational-program');
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const listCodeAndNameofProf = useMemo(() => {
        if (!xlsxLoaded) return [];
        return xlsxParser?.getCodeAndNameOfProf?.() || [];
    }, [xlsxLoaded]);

    const onChangePsName = (name, target, index) => {
        const details = { ...tabDetails };
        if (!details?.activityLaborFunc?.[fieldName]?.codeAndNameOfPsOtfTf?.[index]) {
            return;
        }
        details.activityLaborFunc[fieldName].codeAndNameOfPsOtfTf[index].psName = name;
        details.activityLaborFunc[fieldName].codeAndNameOfPsOtfTf[index].psCode = target.code;
        form.setFieldsValue(details);
    };

    return (
        <Form.List name={[fieldName, 'codeAndNameOfPsOtfTf']}>
            {(fields, { add, remove }) => (
                <>
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('activity-item')} form-item`} key={key}>
                                <Row gutter={{ md: 4, lg: 8 }} align="bottom" wrap={false}>
                                    <Col flex="1">
                                        <div className="item-block-group-2">
                                            <p className="items-group-title">{t('ps-name')}</p>
                                            <Row
                                                gutter={[32, 16]}
                                                justify="space-between"
                                            >
                                                <Col flex="1 0 197px">
                                                    <Form.Item
                                                        name={[name, 'psName']}
                                                        {...restField}
                                                    >
                                                        <AutoComplete
                                                            className="form-select"
                                                            placeholder={t('enter-ps-name')}
                                                            size="large"
                                                            showSearch
                                                            onChange={(value, target) => onChangePsName(
                                                                value, target, name
                                                            )}
                                                            options={listCodeAndNameofProf}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex="0 1 197px">
                                                    <Form.Item
                                                        name={[name, 'psCode']}
                                                        {...restField}
                                                    >
                                                        <InputV2
                                                            placeholder={t('ps-code')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <OtfTfCodeAndNameSubformBlock
                                                fieldName={name}
                                                codeAndNameOfOtfTfEmptyObj={
                                                    codeAndNameOfPsOtfTfEmptyObj.codeAndNameOfOtfTf[0]
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                    <Row wrap={false}>
                        <Col flex="1" align="right">
                            <Form.Item>
                                <ButtonV2
                                    type="link"
                                    onClick={() => add(codeAndNameOfPsOtfTfEmptyObj)}
                                    icon={<PlusOutlined />}
                                >
                                    {t('add-more-PS-OTF-TF')}
                                </ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col flex="33px" />
                    </Row>
                </>
            )}
        </Form.List>
    );
};

export default PsOtfTfCodeAndNameSubformBlock;
