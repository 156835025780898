import { combineReducers } from 'redux';

import data from './data';
import oidcState from './oidc-state';
import oidcUser from './oidc-user';
import state from './state';


export default combineReducers({
    data,
    oidcState,
    oidcUser,
    state
});
