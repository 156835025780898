import React, { useEffect } from 'react';
import bem from 'easy-bem';
import { Divider, Form } from 'antd';
import Next from 'components/next';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useTranslation } from 'react-i18next';
import DisciplineControl from './components/discipline-control';
import DisciplineAttestation from './components/discipline-attestation';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';


const tabKey = '5.10';
const emptyObj = emptyDocDetails[tabKey];


const WorkDisciplineMaterialTab = ({ onNextClick }) => {
    const b = bem('work-discipline-material-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('work-discipline-materials')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <DisciplineControl
                        disciplineControlEmptyObj={emptyObj.disciplineControl[0]}
                    />
                    <Divider />

                    <DisciplineAttestation
                        disciplineAttestationEmptyObj={emptyObj.disciplineAttestation[0]}
                    />
                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>
    );
};

export default WorkDisciplineMaterialTab;
