import React from 'react';
import {
    Col, Divider, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';


const Structure = ({ // 7.4, блок 1
    t, b, compulsoryDisciplinesEmptyObj, formedDisciplinesEmptyObj
}) => (
    <section>
        <h5 className="subtitle">{t('structure')}</h5>
        <p className="item-label">{t('discipline')}</p>
        <Form.List name="compulsoryDisciplines">
            {(fields, { add, remove }) => (
                <div className="form-items-list">
                    {fields.map(({
                        key, name, ...restField
                    }) => (
                        <div className={`${b('item')} form-item`} key={key}>
                            <Row
                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                align="bottom"
                                wrap={false}
                            >
                                <Col flex="1">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                    >
                                        <InputV2
                                            size="large"
                                            placeholder={t('enter-discipline')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <DeleteBtn
                                            onClick={() => remove(name)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Form.Item>
                        <ButtonV2
                            type="link"
                            onClick={() => add(compulsoryDisciplinesEmptyObj)}
                            icon={<PlusOutlined />}
                        >
                            {t('add-discipline')}
                        </ButtonV2>
                    </Form.Item>
                </div>
            )}
        </Form.List>
        <Divider />

        <p className="item-label">{t('discipline-module')}</p>
        <Form.List name="formedDisciplines">
            {(fields, { add, remove }) => (
                <div className="form-items-list">
                    {fields.map(({
                        key, name, ...restField
                    }) => (
                        <div className={`${b('item')} form-item`} key={key}>
                            <Row
                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                align="bottom"
                                wrap={false}
                            >
                                <Col flex="1">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                    >
                                        <InputV2
                                            size="large"
                                            placeholder={t('enter-discipline')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <DeleteBtn
                                            onClick={() => remove(name)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Form.Item>
                        <ButtonV2
                            type="link"
                            onClick={() => add(formedDisciplinesEmptyObj)}
                            icon={<PlusOutlined />}
                        >
                            {t('add-discipline')}
                        </ButtonV2>
                    </Form.Item>
                </div>
            )}
        </Form.List>
    </section>
);

export default Structure;
