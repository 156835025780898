function splitStackTrace(stackTrace, separator = '\r\n') {
    if (typeof stackTrace !== 'string') return stackTrace;
    return stackTrace.split(separator);
}

function simpleOutput(err) {
    const { url, status } = err;
    console.error(`Request ${url} returned with status ${status}`);
    console.error('Server Error', err);
}

function printJsonError(err) {
    const { status } = err;
    if (typeof err === 'object' && typeof err.body === 'object') {
        err.json()
            .then((result) => {
                const {
                    message, innerError = {}, errorCode, messageLanguage
                } = result;
                const {
                    typeName,
                    stackTrace = '',
                    message: messageInner,
                    innerError: secondInnerError
                } = innerError;
                if (stackTrace || message) {
                    const errorMessage = {
                        message,
                        errorCode,
                        messageLanguage,
                        status,
                        innerError: {
                            typeName,
                            stackTraceConverted: splitStackTrace(stackTrace),
                            stackTraceRaw: stackTrace,
                            message: messageInner,
                            innerError: secondInnerError
                        }
                    };
                    console.error('Server Error', errorMessage);
                } else simpleOutput(err);
            })
            .catch(() => simpleOutput(err));
    } else simpleOutput(err);
}

export const serverErrorsLogger = (err = {}) => {
    const { status, url } = err;

    switch (status) {
        case 401:
            console.warn('user unauthorized');
            break;
        case 404:
            console.error(`Not found: ${url}`);
            break;
        case 500:
            printJsonError(err);
            break;
        default:
            printJsonError(err);
            break;
    }
};
