/* eslint-disable camelcase */
import * as c from '../constants';


const initialState = {
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case c.checkUserV2.tries:
        case c.getOIDCInfo.tries: {
            return {
                isLoading: true
            };
        }

        case c.saveOidcUserData.success:
        case c.getOIDCInfo.success: {
            const { access_token, expires_at, profile } = action.payload;

            return {
                isLoading: false,
                access_token,
                expires_at,
                role: profile.role
            };
        }

        case c.getUserOIDC.success: {
            return {
                ...action.payload,
                isLoading: false,
            };
        }

        case c.logoutUser.success: {
            return {
                ...initialState,
                isLoading: false,
            };
        }

        case c.checkUserV2.failure:
        case c.getOIDCInfo.failure: {
            return {
                isLoading: false
            };
        }

        default:
            return state;
    }
};

export default reducer;
