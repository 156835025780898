import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Radio, Row, Space
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentRppIndex,
    setRppList,
} from 'models/documents/actions';
import { PLXParserProvider, usePlxParser } from 'utils/plx';
import _pick from 'lodash/pick';
import uniqueId from 'utils/uniqueId';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useXlsxParser } from 'utils/excel-parse';
import Next from '../../../../../next';
import { ButtonV2, InputV2 } from '../../../../../index';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';
import DeleteBtn from '../../../../../delete-btn';


const tabKey = '6.1';
const emptyObj = emptyDocDetails[tabKey];

const stringRules = {
    rules: [{ type: 'string', required: true, message: 'Поле обязательно' }],
};


const WorkProgramTitle = ({ onNextClick, setFormData }) => {
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser.isLoaded();
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const moduleDetails = {
        6.1: useSelector((state) => state.documents.documentDetails?.['6.1']),
        6.2: useSelector((state) => state.documents.documentDetails?.['6.2']),
        6.3: useSelector((state) => state.documents.documentDetails?.['6.3']),
        6.4: useSelector((state) => state.documents.documentDetails?.['6.4']),
        6.5: useSelector((state) => state.documents.documentDetails?.['6.5']),
        6.6: useSelector((state) => state.documents.documentDetails?.['6.6']),
        6.7: useSelector((state) => state.documents.documentDetails?.['6.7']),
        6.8: useSelector((state) => state.documents.documentDetails?.['6.8']),
        6.9: useSelector((state) => state.documents.documentDetails?.['6.9']),
    };
    const tabDetails = moduleDetails[tabKey];
    const initialValues = tabDetails || emptyObj;
    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
    };
    const rppData = useRef(tabDetails);
    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);
    const [value, setValue] = useState('');

    const query = useMemo(
        () => new URLSearchParams(history.location.search),
        [history.location.search]
    );
    useEffect(() => { //  onComponentDidMount
        form.setFieldsValue(tabDetails);
        setFormData(form);
        updateTabDetails(tabKey, initialValues, dispatch);
    }, []);

    const onPracticeChange = (typeOfPractice, target) => {
        try {
            const { code } = target;
            if (!xlsxLoaded || !typeOfPractice) return;
            const fileType = xlsxParser.getFileType();
            if (fileType === 'excel') {
                const tasks = xlsxParser.getCoursesAndSemesters6(typeOfPractice);
                const workProgram = xlsxParser?.getWorkingProgram(typeOfPractice);
                setValue(workProgram);
                form.setFieldsValue({ workProgram });
                updateTabDetails('6.2', { ...moduleDetails[6.2], tasks }, dispatch);
            }

            if (fileType === 'plx') {
                const practiceTask = xlsxParser?.getTypeOfPracticeRpp();
                if (!practiceTask.length) return;
                const courseAndSemester = xlsxParser?.getRppCourse(code);
                if (!courseAndSemester.length) {
                    updateTabDetails('6.2', { ...emptyDocDetails[6.2] }, dispatch);
                    return;
                }
                const duartionWeek = xlsxParser?.getDurationInWeeks(code);
                const creditUnits = xlsxParser?.getGeneralCreditUnits(code);
                const totalHours = xlsxParser?.getTotalScopeInHours(code);
                const contactWork = xlsxParser?.getContactWorkExRPP(code);
                const intermediateAttestation = xlsxParser?.getIntermediateCertificationRPP(code);

                const tasks = courseAndSemester.map(({ course, semester }, index) => ({
                    course,
                    semester,
                    weeks: duartionWeek || '',
                    contactWorkVolume: contactWork?.[index] || '',
                    fullPracticeVolume: totalHours,
                    creditUnits,
                    otherWorkVolume: totalHours - (contactWork?.[index] || 0),
                    attestationForm: intermediateAttestation,
                }));
                updateTabDetails('6.2', { ...moduleDetails[6.2], tasks }, dispatch);
            }
        } catch (e) {
            console.error(e);
        }
    };


    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            type: all?.type?.split(' ').filter((word) => word !== '').join(' '),
        };
        if (xlsxLoaded) {
            newTabDetails.workProgram = value;
        }
        updateTabDetails(tabKey, newTabDetails, dispatch);
        rppData.current = tabDetails;
    }, 500);

    const onNextBtnClick = () => {
        if (currentRppIndex > -1) {
            const newRppList = JSON.parse(JSON.stringify(rppList));
            newRppList[currentRppIndex] = {
                ...newRppList[currentRppIndex],
                ..._pick(moduleDetails, Object.keys(moduleDetails)),
            };
            dispatch(setRppList(newRppList));
        }
        onNextClick(form);
    };

    useEffect(() => {
        const newObj = moduleDetails?.[tabKey]
            ? { ...moduleDetails?.[tabKey] }
            : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    useEffect(() => {
        const rppId = query.get('rppId');
        if (currentRppIndex === -1 && tabDetails?.type.length > 0 && !rppId) {
            const newRppList = {
                ...emptyObj,
                ...rppData.current,
                status: 'draft',
                id: uniqueId(),
                [tabKey]: { ...rppData.current },
                ...importDocDetails(importedTabDetails, tabKey),
                updatedAt: dayjs().format()
            };
            dispatch(setRppList([...rppList, { ...newRppList }]));
            dispatch(setCurrentRppIndex(rppList?.length));
        }
    }, [tabDetails]);

    return (
        <div className="constructor-form">
            <h4 className="title m-24">{t('work-title-info')}</h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1
                    ? ''
                    : `${t('rpp')} ${tabDetails?.workProgram} ${rppList[currentRppIndex]?.[6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <Space direction="vertical" size={25}>
                    <Form.Item label="Рабочая программа" name="workProgram">
                        <Radio.Group value={value}>
                            <Radio value="учебной практики">
                                Учебной практики
                            </Radio>
                            <Radio value="производственной практики">
                                Производственной практики
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        {...stringRules}
                        name="type"
                        label="Тип практики"
                    >
                        {xlsxLoaded ? (
                            <Select
                                className="form-select"
                                placeholder="Введите код и наименование"
                                size="large"
                                showSearch
                                onChange={(value, target) => onPracticeChange(value, target)}
                                notFoundContent={t('not-found-content-text')}
                                options={xlsxParser
                                    .getTypeOfPractice()
                                    .map(({ practiceType, code }) => ({
                                        label: `${practiceType}`,
                                        value: `${practiceType}`,
                                        code,
                                    }))}
                            />
                        ) : (
                            <InputV2 placeholder="Введите тип практики" />
                        )}
                    </Form.Item>
                </Space>
                <Divider />

                <h5 className="subtitle">Разработчики РПП</h5>
                <p className="text">Укажите авторов прикрепляемого документа</p>
                <Form.List name="developers">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row key={key} align="bottom" gutter={24}>
                                    <Col span={11}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'position']}
                                            label="Должность"
                                        >
                                            <InputV2 placeholder="Введите должность" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'fullName']}
                                            label="ФИО"
                                        >
                                            <InputV2 placeholder="Введите ФИО" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <DeleteBtn
                                            onClick={() => remove(name)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Row align="middle">
                                    <ButtonV2
                                        className="item-plus-action m-20"
                                        onClick={() => add(emptyObj.developers[0])}
                                        type="link"
                                        icon={<PlusOutlined />}
                                    >
                                        Добавить еще разработчика
                                    </ButtonV2>
                                </Row>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Divider />

                <h5 className="subtitle">Руководитель рабочей группы</h5>
                <Row align="bottom" gutter={32}>
                    <Col span={12}>
                        <Form.Item name="workPosition" label="Должность">
                            <InputV2 placeholder="Введите должность" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="workerName" label="ФИО">
                            <InputV2 placeholder="Введите ФИО" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider />

            {rppList?.length <= 20
                ? (
                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                            ...importDocDetails(importedTabDetails, tabKey)
                        }, dispatch)}
                        onNextClick={() => onNextBtnClick(form)}
                    />
                ) : null}
        </div>
    );
};

export default WorkProgramTitle;
