// needed for: adding of empty dynamic fields, generation of a doc if data is empty
export const emptyDocDetails = {
    1.1: {
        fullAndShortNameOfUniversity: '',
        founder: '',
        by: '',
        directionCodeOrName: '',
        program: '', // calculated
        direction: '',
        directionTwo: '', // calculated
        qualification: '',
        studyForm: '',
        accessYear: '',
        developers: [{
            position: '',
            fullName: '',
        }],
        employersRepresentatives: [{
            position: '',
            fullName: '',
            employeOrg: '',
        }],
        approvedPosition: '',
        approvedFullName: '',
        collegialOrg: '',
    },
    1.2: {
        goal: '',
        tasks: [{
            task: '',
        }],
        programImplementedBy: '',
        duration: {
            durationInYears: '',
            durationInMonths: '',
        },
        regulatoryDocuments: [{
            name: '',
        }],
        directionOrder: '', // calculated
    },
    1.3: {
        activityAreaSphere: [{
            area: '',
            spheres: [{
                name: '',
            }]
        }],
        activityTasks: [{
            type: '',
            tasks: [{
                name: '',
            }]
        }],
        activityMainAreas: [{
            area: '',
        }],
        activityLaborFunc: [{
            area: '',
            codeAndNameOfPsOtfTf: [{
                psName: '',
                psCode: '',
                codeAndNameOfOtfTf: [{
                    commonName: '',
                    commonСode: '',
                    commonLevel: '',
                    laborFunctions: [{
                        name: '',
                        code: '',
                        level: '',
                    }]
                }]
            }]
        }],
        activityCharacteristic: [{
            code: '',
            professionalActivityTypePs: [{
                type: '',
            }],
            task: [],
            commonLaborFuncAndActions: [{
                commonLaborFunc: '',
                laborFuncAndActions: [{
                    laborFunc: '',
                    laborActions: [{
                        action: '',
                    }],
                }]
            }]
        }]
    },
    1.4: {
        universalComp: [{
            name: '',
            codeUc: '', // calculated
            nameUc: '',
            codeName: '', // calculated
            list: [{
                indicatorCode: '',
                indicatorName: '',
            }]
        }],
        commonComp: [{
            name: '',
            codeCc: '', // calculated
            nameCc: '',
            codeName: '', // calculated
            list: [{
                indicatorCode: '',
                indicatorName: '',
            }]
        }],
        profComp: [{
            tasks: [],
            codePc: '', // calculated
            namePc: '',
            codeName: '', // calculated
            list: [{
                indicatorCode: '',
                indicatorName: '',
            }],
            baseDefinitionPc: [{
                name: '',
                codeTf: '',
            }]
        }]
    },
    1.5: {
        modulesCredits: '',
        modulesRequiredCredits: '',
        modulesFormedCredits: '',
        practiceCredits: '',
        practiceRequiredCredits: '',
        practiceFormedCredits: '',
        examCredits: '',
        scopeOfProgram: '', // calculated
        hoursType: '',
        hoursValue: '',
        programByCourse: [{
            courseCredit: '',
        }]
    },
    1.6: {
        realizationСonditions: '',
    },
    1.7: {
        generalProfCompFunc: [{
            codeAndName: '',
            codAndNameOfIdk: [{
                psName: '',
                disciplineNameBlock: [{
                    disciplineName: '',
                    toKnow: [{
                        type: '',
                    }],
                    toBeAble: [{
                        type: '',
                    }],
                    toPossess: [{
                        type: '',
                    }],
                    digitTechNameMasteredByDisciplineStudyInternship: [{
                        digitTechName: '',
                    }],
                    courseAndSemester: [{
                        courseNumber: '',
                        semester: [{
                            name: '',
                        }],
                    }]
                }],
            }],
        }],
        profCompFunc: [{
            codeAndName: '',
            codAndNameOfIdk: [{
                psName: '',
                disciplineNameBlock: [{
                    disciplineName: '',
                    toKnow: [{
                        type: '',
                    }],
                    toBeAble: [{
                        type: '',
                    }],
                    toPossess: [{
                        type: '',
                    }],
                    digitTechNameMasteredByDisciplineStudyInternship: [{
                        digitTechName: '',
                    }],
                    compNecessaryForPSLaborFuncImplementation: [{
                        psCode: '',
                        tfCode: '',
                    }],
                    inputLevelForCompForm: [{
                        inputLevelForComp: '',
                    }],
                    courseAndSemester: [{
                        courseNumber: '',
                        semester: [{
                            name: '',
                        }],
                    }]
                }]
            }]
        }]
    },
    '2.0': {
        competenceCommon: [{
            codeAndName: '',
            codeAndNameIndicator: [],
            digitalTechnologies: [],
            disciplinesForStudying: [{
                disciplines: '',
            }]
        }],
        competenceProf: [{
            area: '',
            taskType: '',
            tasks: [],
            standard: '',
            commonLaborFunc: '',
            laborFunc: [],
            laborAction: [],
            competenceCodeAndName: '',
            indicatorCodeAndName: [],
            digitalTechnologies: [],
            disciplinesForStudying: [{
                discipline: '',
            }]
        }]
    },
    3.0: { academicPlans: [] },
    5.1: {
        developmentType: '',
        disciplineCodeAndName: '',
        author: [{
            position: '',
            fullName: '',
        }],
        headPosition: '',
        headFullName: '',
    },
    5.2: {
        goal: '',
        task: [{
            task: '',
        }],
        implementationFeatures: '',
    },
    5.3: {
        results: [{
            codeAndName: '',
            list: [{
                codeAndNameIndicator: '',
                knowledge: [{
                    name: '',
                }],
                skill: [{
                    name: '',
                }],
                possession: [{
                    name: '',
                }]
            }]
        }]
    },
    5.4: {
        isElective: '',
        disciplineType: '',
        requiredDisciplines: [{
            name: '',
        }],
        dependentDisciplines: [{
            name: '',
        }]
    },
    5.5: {
        disciplineHours: '',
        disciplineCredits: '', // calculated properties (needed for doc generation)
        sizeByTypes: [{
            course: '',
            semester: '',
            contactWorkAudit: '', // calculated properties (needed for doc generation)
            lecturesHours: '',
            seminarTypeStudy: '', // calculated properties (needed for doc generation)
            practicesHours: '',
            colloquiumHours: '',
            labsHours: '',
            otherHours: '',
            otherAuditHours: '',
            independentWork: '', // calculated properties (needed for doc generation)
            nonAuditHours: '',
            selfLearningTheory: '',
            selfLearningHomework: '',
            selfLearningCoursework: '',
            selfLearningOther: '',
            intermediateAttestation: '',
            contactWork: '', // calculated properties (needed for doc generation)
            disciplineSize: '',
            intermediateAttestationForm: '',
            disciplineModuleVolume: '', // calculated properties (needed for doc generation)
        }],
        // calculated properties (needed for doc generation):
        semesters: [],
        calculatedSizeByTypes: {
            contactWorkAudit: {
                total: '',
            },
            lecturesHours: {
                total: '',
            },
            seminarTypeStudy: {
                total: '',
            },
            practicesHours: {
                total: '',
            },
            colloquiumHours: {
                total: '',
            },
            labsHours: {
                total: '',
            },
            otherHours: {
                total: '',
            },
            otherAuditHours: {
                total: '',
            },
            nonAuditHours: {
                total: '',
            },
            independentWork: {
                total: '',
            },
            selfLearningTheory: {
                total: '',
            },
            selfLearningHomework: {
                total: '',
            },
            selfLearningCoursework: {
                total: '',
            },
            selfLearningOther: {
                total: '',
            },
            intermediateAttestation: {
                total: '',
            },
            contactWork: {
                total: '',
            },
            disciplineSize: {
                total: '',
            },
            disciplineModuleVolume: {
                total: '',
            },
        }
    },
    5.6: {
        structure: [{
            moduleName: '',
            moduleIndex: '', // calculated property
            lecture: '',
            seminarHours: '',
            labHours: '',
            independentHours: '',
            idkCodes: []
        }],
        task: [{
            moduleName: '',
            moduleIndex: '', // calculated property
            list: [{
                lectureTheme: '',
                endToEndDigitlTech: [{
                    dot: '',
                }],
                lecture: '',
            }]
        }],
        seminarTypeClasses: [{
            moduleName: '',
            moduleIndex: '', // calculated property
            list: [{
                goal: '',
                endToEndDigitlTech: [{
                    dot: '',
                }],
                lectureHourse: '',
                practiceHourse: '',
            }]
        }],
        laboratoryStudies: [{
            moduleName: '',
            moduleIndex: '', // calculated property
            list: [{
                goal: '',
                endToEndDigitlTechy: [{
                    dot: '',
                }],
                lectureHourse: '',
                practiceHourse: '',
            }]
        }],
        independentWork: [{
            moduleName: '',
            moduleIndex: '', // calculated property
            list: [{
                goal: '',
                endToEndDigitlTech: [{
                    dot: '',
                }],
                lectureHourse: '',
            }]
        }],
        contentOfSections: [{
            technologies: [],
            moduleName: '',
            endToEndDigitlTech: [{
                dot: '',
            }],
            code: [],
            list: [{
                name: '',
                number: '',
            }]
        }],
        technologicalExp: [{
            eduTech: '',
            explanation: '',
        }],
        // calculated properties (needed for doc generation):
        structureLectureTotal: '',
        structureSeminarHoursTotal: '',
        structureLabHoursTotal: '',
        structureIndependentHoursTotal: '',
        taskLectureTotal: '',
        seminarLectureTotal: '',
        seminarPracticeTotal: '',
        laboratoryLectureTotal: '',
        laboratoryPracticeTotal: '',
        independentWorkLectureTotal: '',
    },
    5.7: {
        disciplineEducation: [{
            eduLiterature: '',
        }],
        disciplineResources: [{
            name: '',
            resourceLink: '',
            availability: '',
        }],
        electronicLibrarySys: [{
            name: '',
            resourseLink: '',
            availability: '',
        }],
        profDatabases: [{
            name: '',
            resourceLink: '',
            availability: '',
        }]
    },
    5.8: {
        logistics: [{
            lessonsView: '',
            programName: '',
        }],
        availability: [{
            lessons: '',
            name: '',
            availability: '',
        }],
        licenses: [{
            name: '',
            user: '',
            availability: '',
            link: '',
        }]
    },
    5.9: {
        evaluation: [{
            name: '',
            list: [{
                form: '',
                eval: '',
            }],
            idkCode: []
        }],
        block2: [{
            course: '',
            semester: '',
            certification: '',
            method: '',
            register: '',
        }],
        criteria: [{
            name: '',
            toKnow: [{
                plans: '',
                unsatisfactorily: '',
                satisfactorily: '',
                good: '',
                excellent: ''
            }],
            toBeAble: [{
                plans: '',
                unsatisfactorily: '',
                satisfactorily: '',
                good: '',
                excellent: ''
            }],
            toPossess: [{
                plans: '',
                unsatisfactorily: '',
                satisfactorily: '',
                good: '',
                excellent: ''
            }]
        }]
    },
    '5.10': {
        disciplineControl: [{
            material: '',
            byTheme: '',
            count: '',
            appNumber: '',
        }],
        disciplineAttestation: [{
            material: '',
            form: '',
            count: '',
            appNumber: '',
        }]
    },
    6.1: {
        workProgram: '',
        type: '',
        developers: [{
            position: '',
            fullName: '',
        }],
        workPosition: '',
        workerName: '',
    },
    6.2: {
        practiceAim: '',
        practiceTask: [{
            practiceTask: '',
        }],
        tasks: [{
            course: '',
            semester: '',
            weeks: '',
            fullPracticeVolume: '',
            contactWorkVolume: '',
            otherWorkVolume: '',
            attestationForm: '',
            creditUnits: 0, // calculated
        }],
        // calculated:
        practiceType: '',
        typeOfPractice: '',
        tasksWeeksTotal: 0,
        tasksCreditUnitsTotal: 0,
        tasksFullPracticeVolumeTotal: 0,
        tasksContactWorkVolumeTotal: 0,
        tasksOtherWorkVolumeTotal: 0,
    },
    6.3: {
        tasks: [{
            pickCode: '',
            endToEndDigitlTech: [{
                idkCode: '',
                toKnow: '',
                toAble: '',
                toOwn: '',
            }]
        }]
    },
    6.4: {
        practiceIncludes: '',
        disciplines: [{
            basicDiscipline: '',
        }],
        internships: [{
            internship: '',
        }]
    },
    6.5: {
        list: [{
            practiceIndex: '', // calculated
            course: '', // calculated
            semester: '', // calculated
            structure: [{
                mainStage: '',
                name: [],
                structureIndex: '', // calculated
                educationalWork: '', // calculated
                contactWork: '',
                otherForms: '',
                currentForms: '',
                codeIdk: [],
                needAddForm: '',
                workFormData: [{
                    workForm: '',
                    name: [],
                    workFormDataIndex: '', // calculated
                    educationalWork: '', // calculated
                    contactWork: '',
                    otherForms: '',
                    currentForms: '',
                    codeIdk: []
                }]
            }],
            // calculated:
            contactWork: '',
            otherForms: '',
            educationalWork: '',
        }]
    },
    6.6: {
        typeForm: '',
        requirements: '',
        criteria: [{
            codeName: '',
            plannedResults: '',
            unsatisfactory: '',
            satisfactory: '',
            good: '',
            great: '',
            plannedResultsAble: '',
            unsatisfactory2: '',
            satisfactory2: '',
            good2: '',
            great2: '',
            plannedResultsOwn: '',
            unsatisfactory3: '',
            satisfactory3: '',
            good3: '',
            great3: '',
        }]
    },
    6.7: {
        requirements: '',
    },
    6.8: {
        internships: [{
            literature: '',
        }],
        resources: [{
            name: '',
            link: '',
            availability: '',
        }],
        electronic: [{
            name: '',
            link: '',
            availability: '',
        }],
        data: [{
            name: '',
            link: '',
            availability: '',
        }]
    },
    6.9: {
        internships: [{
            lern: '',
            tech: '',
        }],
        resources: [{
            workType: '',
            digitalName: '',
            availability: '',
        }],
        licensed: [{
            poName: '',
            holder: '',
            availability: '',
            link: '',
        }]
    },
    7.1: {
        workPosition: '',
        workerName: '',
        developers: [{
            position: '',
            fullName: '',
        }]
    },
    7.2: {
        workProgram: [],
        type: '',
        workInclude: '',
        volumeVkr: '',
        vkrWeeks: '',
        geInclude: '',
        geWeeks: '',
        certificationTestsVolumeTotal: '', // calculated
        certificationTestsWeeksTotal: '', // calculated
        title: [{
            area: '',
            sphere: '',
            resources: [{
                taskType: '',
                task: [{
                    chosenTask: '',
                    objects: []
                }]
            }]
        }]
    },
    7.3: {
        universalComp: [{
            groupName: '',
            codeName: '',
            idkCodeNames: []
        }],
        commonComp: [{
            groupName: '',
            codeName: '',
            idkCodeNames: []
        }],
        profComp: [{
            tasks: [],
            areas: [],
            codeName: '',
            idkCodeNames: []
        }],
        stateExamComps: [],
        finalQualificationWorkComps: []
    },
    7.4: {
        compulsoryDisciplines: [{
            name: '',
        }],
        formedDisciplines: [{
            name: '',
        }],
        stateExamContents: [{
            discipline: '',
            didacticUnits: [{
                name: '',
            }],
            mainLiteratureSources: [{
                author: '',
                placeAndYear: '',
                name: '',
            }],
            extraLiteratureSources: [{
                author: '',
                placeAndYear: '',
                name: '',
            }]
        }],
        examQuestions: [],
        theoreticalQuestions: [{
            name: '',
        }],
        practicalTasks: [{
            name: '',
        }],
        otherRequirements: '',
        stateExamForm: '',
        stateExamDescription: '',
        allowedLiteratureSources: [{
            name: '',
        }],
        excellentCriteria: '',
        goodCriteria: '',
        satisfactoryCriteria: '',
        unsatisfactoryCriteria: '',
    },
    7.5: {
        fqwForm: '',
        fqwStructureAndRequirements: '',
        fqwTopics: '',
        fqwExcellentCriteria: '',
        fqwGoodCriteria: '',
        fqwSatisfactoryCriteria: '',
        fqwUnsatisfactoryCriteria: '',
    }
};

// needed for generation of a doc to preview and download
export const importDependencies = {
    1.2: {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'collegialOrg',
        ]
    },
    1.5: {
        1.1: [
            'studyForm',
        ]
    },
    1.7: {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'collegialOrg',
            'directionTwo',
            'fullAndShortNameOfUniversity',
            'program',
        ]
    },
    '2.0': {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'collegialOrg',
            'directionTwo',
            'fullAndShortNameOfUniversity',
            'program',
        ]
    },
    5.1: {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'collegialOrg',
            'directionTwo',
            'fullAndShortNameOfUniversity',
            'program',
            'founder',
            'studyForm',
            'accessYear',
        ]
    },
    5.6: {
        1.1: [
            'fullAndShortNameOfUniversity',
        ]
    },
    6.1: {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'collegialOrg',
            'directionTwo',
            'fullAndShortNameOfUniversity',
            'program',
            'founder',
            'studyForm',
            'accessYear',
        ]
    },
    6.2: {
        6.1: [
            'type'
        ]
    },
    7.1: {
        1.1: [
            'by',
            'direction',
            'directionCodeOrName',
            'directionTwo',
            'fullAndShortNameOfUniversity',
            'program',
            'founder',
            'qualification',
            'studyForm',
            'accessYear',
        ]
    },
    7.2: {
        1.1: [
            'by',
            'directionCodeOrName',
            'directionTwo',
            'direction',
            'program',
        ],
        1.2: [
            'directionOrder',
        ]
    },
    7.4: {
        7.2: [
            'workProgram',
        ]
    },
    7.5: {
        7.2: [
            'workProgram',
        ]
    },
};

export const digitalTechnologies = [
    'большие данные',
    'интернет вещей',
    'искусственный интеллект',
    'квантовые вычисления',
    'квантовые коммуникации',
    'квантовые сенсоры',
    'мобильные сети связи пятого поколения (цифровые сервисы)',
    'новые коммуникационные интернет-технологии',
    'новые производственные технологии',
    'робототехника и сенсорика',
    'технологии виртуальной и дополненной реальности',
    'технологии распределенных реестров',
];
