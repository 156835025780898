import React from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row
} from 'antd';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';


const StructureExamFormBlock = () => {
    const b = bem('structure-exam-form-block');
    const { t } = useTranslation('dev-educational-program');

    return (
        <div className={b()}>
            <Row gutter={[32, 24]}>
                <Col flex="99px">
                    <h5 className="subtitle">
                        {t('block-3')}
                    </h5>
                </Col>
                <Col flex="1 1 625px" className="item-block-group-horizontal">
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="examCredits"
                                className="item-label item-label-big"
                            >
                                {t('state-final-certification')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="examCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default StructureExamFormBlock;
