import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import { updateTabDetails } from 'utils/dispatches';
import ResultsUniversalCompFormBlock from '../results-universal-comp-form-block';
import ResultsCommonCompFormBlock from '../results-common-comp-form-block';
import ResultsProfCompFormBlock from '../results-prof-comp-form-block';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '1.4';
const emptyObj = emptyDocDetails[tabKey];

const calculateTabDetails = ({
    universalComp = [], commonComp = [], profComp = []
}) => {
    const updateCompData = (compData = [], code = '', name = '', prefix = '') => compData
        .map((comp, i) => {
            const compCode = comp[name] && `${prefix}-${i + 1}`;
            let data = [];
            if (comp.list && Array.isArray(comp.list)) {
                data = comp.list;
            } else if (comp.list) {
                data.push(comp.list);
            }
            return {
                ...comp,
                [code]: compCode,
                codeName: comp[name] && `${compCode}. ${comp[name]}`,
            };
        });

    return {
        universalComp: updateCompData(universalComp, 'codeUc', 'nameUc', 'УК'),
        commonComp: updateCompData(commonComp, 'codeCc', 'nameCc', 'ОПК'),
        profComp: updateCompData(profComp, 'codePc', 'namePc', 'ПК'),
    };
};


const OpResultsTab = ({ onNextClick }) => {
    const b = bem('characteristic-op-results-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    
    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        let newTabDetails = { ...form.getFieldsValue() };
        for (let changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'profComp' && name[2] === 'baseDefinitionPc' && name[4] === 'name') {
                newTabDetails.profComp[name[1]].baseDefinitionPc[name[3]].codeTf = '';
            }
        }
        form.setFieldsValue(newTabDetails);
        newTabDetails = {
            ...newTabDetails,
            ...calculateTabDetails(newTabDetails),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('educational-programs-development-results')}</h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <ResultsUniversalCompFormBlock
                        universalCompEmptyObj={emptyObj.universalComp[0]}
                        form={form}
                    />
                    <Divider />

                    <ResultsCommonCompFormBlock
                        commonCompEmptyObj={emptyObj.commonComp[0]}
                        form={form}
                    />
                    <Divider />

                    <ResultsProfCompFormBlock
                        profCompEmptyObj={emptyObj.profComp[0]}
                        profComp={tabDetails?.profComp}
                        form={form}
                    />
                    <Divider />

                    <Next
                        onPreview={() => previewDoc(tabKey, {
                            ...emptyObj,
                            ...tabDetails,
                            ...calculateTabDetails(tabDetails),
                        }, dispatch)}
                        onNextClick={() => onNextClick(form)}
                    />
                </Form>
            </div>
        </div>

    );
};

export default OpResultsTab;
