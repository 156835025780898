export function range(start, stop, step) {
    const a = [start]; let
        b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return a;
}

export default range;
