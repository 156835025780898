import React from 'react';
import bem from 'easy-bem';
import { Switch, Route } from 'react-router-dom';
import './style.less';
import AllOpop from '../../components/all-opop';
import EducationalProgram from '../../components/all-opop/educatonal-program/educational-program';


const OPOPConstructorPage = () => {
    const b = bem('approval-application-page');

    return (
        <div className={b()}>
            <Route>
                <Switch>
                    <Route exact path="/all-replication">
                        <AllOpop />
                    </Route>
                    <Route exact path="/all-replication/programs">
                        <EducationalProgram />
                    </Route>
                </Switch>
            </Route>
        </div>
    );
};

export default OPOPConstructorPage;
