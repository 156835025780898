/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Col, Divider, Form, Row
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import expandArr from 'utils/expandArr';
import ButtonV3 from '../../../../../../button-v3';
import { ButtonV2, InputV2, Notification } from '../../../../../../index';
import { GenBlock } from '../../../../../../generated-block/generatedBlock';


const DisciplineLesson = ({ // 5.6, блок 3
    structure = [], structureSeminarHoursTotal, seminarTypeClasses = [], seminarTypeClassesEmptyObj
}) => {
    const { t } = useTranslation('dev-educational-program');
    const b = bem('dev-educational-program');

    const moduleNameList = (structure || [])
        .filter(({ moduleName }) => moduleName)
        .map(({ moduleName }) => ({
            label: moduleName,
            value: moduleName,
        }));
    const [isModuleNameDisabled, setisModuleNameDisabled] = useState(false);
    useEffect(() => setisModuleNameDisabled(!moduleNameList?.length), [moduleNameList]);

    const [isCheckFormDataBtnVisible, setIsCheckFormDataBtnVisible] = useState(false);
    useEffect(() => setIsCheckFormDataBtnVisible( // отображение кнопки проверки данных, если хотя бы одно поле в блоке на странице заполнено
        expandArr((seminarTypeClasses || []), ['list']).some(({ lectureHourse }) => typeof lectureHourse === 'number')
    ), [seminarTypeClasses]);

    const [notification, setNotification] = useState([{}]);
    const checkFormData = () => {
        if (!structureSeminarHoursTotal && structureSeminarHoursTotal !== 0) return;
        const seminarLectureTotal = expandArr((seminarTypeClasses || []), ['list']).reduce(
            (prev, curr) => (prev + curr?.lectureHourse || 0), 0
        );
        const reducedStructureSeminarHoursTotal = (structure || []).reduce(
            (prev, curr) => (prev + curr?.seminarHours || 0), 0
        );
        if (+seminarLectureTotal !== +reducedStructureSeminarHoursTotal) {
            setNotification([{
                type: 'error',
                message: 'Количество часов занятий, внесенное в поля "Объем занятия в часах" и поля "Семинары, практические занятия и др." (Блок 1), не совпадает',
            }]);
            return;
        }
        setNotification([{
            type: 'success',
            message: 'Данные совпадают',
        }]);
    };
    useEffect(() => setNotification([]), [seminarTypeClasses, structure]);

    return (
        <section>
            <h5 className="subtitle">
                Блок 3: Занятия семинарского типа. Семинары, практические занятия, коллоквиумы
                и другие формы занятий
            </h5>

            <Form.List name="seminarTypeClasses">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    name={[name, 'moduleName']}
                                                    label="Наименование раздела дисциплины (модуля)"
                                                    {...handleFieldEnableStatus(
                                                        !!moduleNameList?.length,
                                                        <span>
                                                            {`${t('fill-in-field-first')} 
                                                            "Наименование раздела дисциплины (модуля)" 
                                                            (блок "Структура дисциплины (модуля) и объем 
                                                            тематических разделов по видам учебных занятий, 
                                                            индикаторы достижения формируемых компетенций, 
                                                            соотнесенные с разделами дисциплины (модуля)")`}
                                                        </span>,
                                                        'Наименование раздела дисциплины (модуля)',
                                                    )}
                                                    {...restField}
                                                >
                                                    <Select
                                                        className="form-select"
                                                        size="large"
                                                        showSearch
                                                        notFoundContent={t('not-found-content-text')}
                                                        options={moduleNameList}
                                                        placeholder="Выберите из ранее введенных наименований"
                                                        disabled={isModuleNameDisabled}
                                                    />
                                                </Form.Item>
                                                <Divider />

                                                <Form.List name={[name, 'list']}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            <div className="form-items-list">
                                                                {fields.map(({
                                                                    key, name, ...restField
                                                                }) => (
                                                                    <div
                                                                        className={`${b('activity-item')} form-items-group`}
                                                                        key={key}
                                                                    >
                                                                        <Row
                                                                            gutter={{ md: 4, lg: 8 }}
                                                                            align="bottom"
                                                                            wrap={false}
                                                                        >
                                                                            <Col flex="1">
                                                                                <div className="item-block-group-2">
                                                                                    <Form.Item
                                                                                        name={[name, 'goal']}
                                                                                        label="Тема занятия. Краткое содержание"
                                                                                    >
                                                                                        <InputV2
                                                                                            textarea
                                                                                            autoSize={{
                                                                                                minRows: 4,
                                                                                                maxRows: 6
                                                                                            }}
                                                                                            placeholder="Введите тему и краткое содержание"
                                                                                            className="textarea_80h"
                                                                                        />
                                                                                    </Form.Item>

                                                                                    <div className="item-block-group-3">
                                                                                        <p className="item-label">
                                                                                            Применение ЭО и ДОТ
                                                                                        </p>
                                                                                        <GenBlock
                                                                                            blockFieldName={name}
                                                                                            blockName="endToEndDigitlTech"
                                                                                            addText={t('add-more-app')}
                                                                                            {...restField}
                                                                                        >
                                                                                            <Form.Item
                                                                                                name={[name, 'dot']}
                                                                                            >
                                                                                                <InputV2
                                                                                                    textarea
                                                                                                    autoSize={{
                                                                                                        minRows: 1,
                                                                                                        maxRows: 4
                                                                                                    }}
                                                                                                    placeholder="Введите применение ЭО и ДОТ"
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </GenBlock>
                                                                                    </div>

                                                                                    <Row
                                                                                        align="middle"
                                                                                        justify="space-between"
                                                                                    >
                                                                                        <Col className="item-label">
                                                                                            Объем занятия в часах
                                                                                        </Col>
                                                                                        <Col flex="0 0 120px">
                                                                                            <Form.Item
                                                                                                name={[name, 'lectureHourse']}
                                                                                            >
                                                                                                <InputV2
                                                                                                    number
                                                                                                    placeholder="часов"
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row
                                                                                        align="middle"
                                                                                        justify="space-between"
                                                                                    >
                                                                                        <Col>
                                                                                            <p className="item-label">
                                                                                                В т.ч. в форме практической подготовки
                                                                                            </p>
                                                                                        </Col>
                                                                                        <Col flex="0 0 120px">
                                                                                            <Form.Item
                                                                                                name={[name, 'practiceHourse']}
                                                                                            >
                                                                                                <InputV2
                                                                                                    number
                                                                                                    placeholder="часов"
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item>
                                                                                    <DeleteBtn
                                                                                        onClick={() => remove(name)}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <Row wrap={false}>
                                                                <Col flex="1" align="right">
                                                                    <Form.Item>
                                                                        <ButtonV2
                                                                            type="link"
                                                                            onClick={() => add(
                                                                                seminarTypeClassesEmptyObj.list[0]
                                                                            )}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            добавить еще занятие
                                                                        </ButtonV2>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col flex="33px" />
                                                            </Row>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Row>
                                <Col span={22}>
                                    <Form.Item>
                                        <Row align="middle" justify="space-between">
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(seminarTypeClassesEmptyObj)}
                                                icon={<PlusOutlined />}
                                            >
                                                {t('add-more-block')}
                                            </ButtonV2>
                                            {isCheckFormDataBtnVisible && <ButtonV3 onClick={checkFormData} />}
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </Form.List>
            <Notification notifications={notification} />
        </section>
    );
};

export default DisciplineLesson;
