import React from 'react';
import {
    Switch,
    // Redirect
} from 'react-router-dom';

import * as Pages from '../pages';


export default (
    <Switch>
        {/* <Redirect exact from="/" to="/opop-registry" /> */}
        {Object.values(Pages)}
    </Switch>
);
