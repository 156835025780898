import React, { useState, useEffect } from 'react';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { getDocsListByUserId } from 'models/documents/actions';
import {
    getUser
} from 'models/auth/selectors';
import MyEducationalPrograms from './components/my-educational-programs';


const OPOPContstructor = () => {
    const b = bem('opop-constructor');
    const [, setActive] = useState(null);
    const dispatch = useDispatch();
    const docList = useSelector((state) => state.documents.documentList);
    const user = useSelector(getUser);

    useEffect(() => {
        if (!user?.id) {
            return;
        }

        (async () => {
            dispatch(getDocsListByUserId(user._id));
        })();
    }, [dispatch, !!user]);


    return (
        <div className={b()}>
            <MyEducationalPrograms docs={docList} setActive={setActive} />
        </div>
    );
};

export default OPOPContstructor;
