import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Table } from 'antd';
import bem from 'easy-bem';

import './style.less';


const OOCTable = React.forwardRef((props, ref) => {
    const { t } = useTranslation('ooc-table');
    const b = bem('ooc-table');

    const {
        className: propsClass,
        columns = [],
        dataSource = [],
        ...rest
    } = props;

    const cn = propsClass ? `${b()} ${propsClass}` : b();

    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            {/* <SmileOutlined style={{ fontSize: 20 }} /> */}
            {/* <p>Data Not Found</p> */}
        </div>
    );

    const locale = {
        triggerDesc: t('press-for-desc-sort'),
        triggerAsc: t('press-for-asc-sort'),
        cancelSort: t('press-for-cancel-sort'),
    };

    return (
        <div className={cn} ref={ref}>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    locale={locale}
                    {...rest}
                />
            </ConfigProvider>
        </div>
    );
});

export default OOCTable;
