
import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Row
} from 'antd';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonV2 } from 'components';
import DeleteBtn from 'components/delete-btn';
import { useSelector } from 'react-redux';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import IdkCodeAndNameSubformBlock from './idk-code-and-name-subform-block';


const DigitalTechAppGeneralProfCompTab = ({ // 1.7, блок 1
    generalProfCompFuncEmptyObj, generalProfCompFunc = []
}) => {
    const b = bem('digital-tech-general-prof-comp');
    const { t } = useTranslation('dev-educational-program');
    const { commonComp } = useSelector((state) => state.documents.documentDetails?.[1.4] || {});

    const opkList = useMemo(
        () => (commonComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list = [] }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        label: `${indicatorCode}. ${indicatorName}`,
                        value: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
        [commonComp]
    );
    const [isCodeAndNameDisabled, setCodeAndNameDisabled] = useState(false);
    useEffect(() => setCodeAndNameDisabled(!opkList?.length), [opkList]);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                {`${t('block-1')}: ${t('general-prof-comp-in-digital-technologies-application')}`}
            </h5>
            <Form.List name="generalProfCompFunc">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('digit-tech-comp-code-and-name')}
                                                            name={[name, 'codeAndName']}
                                                            {...handleFieldEnableStatus(
                                                                !!opkList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                        "${t('cc-name')}" 
                                                                        (${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    {`, ${t('block-2')})`}
                                                                </span>,
                                                                t('digit-tech-comp-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={
                                                                    t('digit-tech-select-comp-code-and-name')
                                                                }
                                                                options={opkList}
                                                                disabled={isCodeAndNameDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <IdkCodeAndNameSubformBlock
                                                    fieldName={name}
                                                    codAndNameOfIdkEmptyObj={
                                                        generalProfCompFuncEmptyObj.codAndNameOfIdk[0]
                                                    }
                                                    selectedCommonCompCodeAndName={
                                                        generalProfCompFunc?.[name]?.codeAndName
                                                    }
                                                    opkList={opkList}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(generalProfCompFuncEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DigitalTechAppGeneralProfCompTab;
