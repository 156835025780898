import React from 'react';
import bem from 'easy-bem';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import ButtonV2 from 'components/button-v2';


const tabKey = '4.0';


const CalendarScheduleTab = ({ onNextClick }) => {
    const b = bem('calendar-schedule-tab');

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">Календарный учебный график</h4>
                <p className="block-main-text">
                    {`Мы еще работаем над интерфейсом данного раздела. 
                    Вы можете просмотреть прикрепленные файлы в разделе `}
                    <Link
                        to={(location) => ({ ...location, hash: '#3.0' })}
                    >
                        Учебный план
                    </Link>
                </p>
                <Divider />

                <ButtonV2
                    className="form-next-btn"
                    onClick={() => onNextClick()}
                    type="primary"
                >
                    Сохранить. Перейти далее
                </ButtonV2>
            </div>
        </div>
    );
};

export default CalendarScheduleTab;
