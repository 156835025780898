export const getUserRole = (state) => state.user.oidcState.profile?.role;

export const getUserOrgShortName = (state) => {
    const [org] = state.user.oidcUser.orgsList || [];
    return org ? org.organization.shortName : undefined;
};

export const getPhotoUrl = (state) => state.user.data.photoUrl;

export const getUserName = (state) => state.user.oidcUser.userName;

export const getAuthState = (state) => !!state.user.oidcUser.userName;

export const getUserId = (state) => state.user.oidcUser.userName;

export const getRealUserId = (state) => state.user.oidcUser.id;

export const getCurrentUserOrgConsId = (state) => {
    const [org] = state.user.oidcUser.orgsList || [];
    return org ? org.organization.consortiumId : undefined;
};
