import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AutoComplete,
    Col, Divider, Form, Radio, Row
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedCallback from 'utils/use-debounce-callback';
import DeleteBtn from 'components/delete-btn';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import { usePlxParser } from 'utils/plx';
import { useXlsxParser } from 'utils/excel-parse';
import { importDocDetails, previewDoc } from '../../utils';
import { ButtonV2, InputV2 } from '../../../../../index';
import Next from '../../../../../next';
import { emptyDocDetails } from '../../constants';


const tabKey = '6.2';
const emptyObj = emptyDocDetails[tabKey];

const getNewCreditUnits = (hoursValue = 0, fullPracticeVolume = 0) => (
    hoursValue > 0
        ? +fullPracticeVolume / +hoursValue
        : ''
);

const calculateTabDetails = ({ tasks = [] }, hoursValue = 0) => ({
    tasks: tasks.map(({ fullPracticeVolume = 0, ...rest }) => ({
        creditUnits: getNewCreditUnits(hoursValue, fullPracticeVolume),
        ...rest,
        fullPracticeVolume
    })),
    ...tasks.reduce((acc, curr) => ({
        tasksWeeksTotal: acc.tasksWeeksTotal + +curr.weeks,
        tasksCreditUnitsTotal: acc.tasksCreditUnitsTotal + +curr.creditUnits,
        tasksFullPracticeVolumeTotal: acc.tasksFullPracticeVolumeTotal + +curr.fullPracticeVolume,
        tasksContactWorkVolumeTotal: acc.tasksContactWorkVolumeTotal + +curr.contactWorkVolume,
        tasksOtherWorkVolumeTotal: acc.tasksOtherWorkVolumeTotal + +curr.otherWorkVolume,
    }), {
        tasksWeeksTotal: 0,
        tasksCreditUnitsTotal: 0,
        tasksFullPracticeVolumeTotal: 0,
        tasksContactWorkVolumeTotal: 0,
        tasksOtherWorkVolumeTotal: 0,
    }),
});

const getPracticeType = (workProgram) => {
    switch (workProgram) {
        case 'учебной практики':
            return 'учебная';
        case 'производственной практики':
            return 'производственная';
        default:
            return '';
    }
};


const WorkProgramCharacteristics = ({ onNextClick }) => {
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();
    const b = bem('dev-educational-program');
    const { t } = useTranslation('general-characteristics');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { tasks = [] } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        6.1: useSelector((state) => state.documents.documentDetails?.['6.1'] || {}),
    };
    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const {
        duration: { durationInYears = 0, durationInMonths = 0 } = {}
    } = useSelector((state) => state.documents.documentDetails?.['1.2'] || {});
    const courseMaxNumber = useMemo(
        () => Math.ceil(+durationInYears + durationInMonths / 12),
        [durationInMonths, durationInYears]
    );

    const { hoursValue = 0 } = useSelector((state) => state.documents.documentDetails?.['1.5'] || {});
    const { workProgram = '' } = useSelector((state) => state.documents.documentDetails?.['6.1'] || {});
    useEffect(() => {
        updateTabDetails(tabKey, {
            ...tabDetails,
            tasks: tasks.map(({ fullPracticeVolume = 0, ...rest }) => ({
                creditUnits: getNewCreditUnits(hoursValue, fullPracticeVolume),
                ...rest,
                fullPracticeVolume
            })),
            practiceType: getPracticeType(workProgram),
        }, dispatch);
    }, [hoursValue, workProgram]);
    const onFormChange = useDebouncedCallback((value, index) => {
        const details = { ...tabDetails };
        details.tasks[index].attestationForm = value;
        form.setFieldsValue(details);
        updateTabDetails(tabKey, details, dispatch);
    }, 500);
    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all, hoursValue),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);
    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('title')}</h4>
                <h4 className="program-title-name">
                    {currentRppIndex === -1
                        ? ''
                        : `${t('rpp')} ${importedTabDetails?.['6.1']?.workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <section>
                        <Form.Item label="Цель практики" name="practiceAim">
                            <InputV2
                                textarea
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                placeholder="Введите цель"
                            />
                        </Form.Item>

                        <p className="item-label">Задачи практики</p>
                        <Form.List name="practiceTask">
                            {(fields, { add, remove }) => (
                                <div className="form-items-list">
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div className={`${b('task-item')} form-item`} key={key}>
                                            <Row
                                                gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'practiceTask']}
                                                    >
                                                        <InputV2
                                                            textarea
                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                            placeholder="Введите задачу"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Row align="middle">
                                            <ButtonV2
                                                className="item-plus-action"
                                                onClick={() => add(emptyObj.practiceTask[0])}
                                                type="link"
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить еще задачу
                                            </ButtonV2>
                                        </Row>
                                    </Form.Item>
                                </div>
                            )}
                        </Form.List>

                        <h5 className="subtitle">Объем и продолжительность практики</h5>
                        <Form.List name="tasks">
                            {(fields, { add, remove }) => (
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }, index) => (
                                        <div className={`${b('item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <div className="item-block-group">
                                                        <Form.Item
                                                            name={[name, 'course']}
                                                            label={t('select-course-number')}
                                                            {...restField}
                                                        >
                                                            {courseMaxNumber > 0 ? (
                                                                <Radio.Group>
                                                                    {Array.from(
                                                                        { length: courseMaxNumber },
                                                                        (_, i) => (
                                                                            <Radio
                                                                                key={`${i + 1} ${t('course')}`}
                                                                                value={i + 1}
                                                                            >
                                                                                {`${i + 1} курс`}
                                                                            </Radio>
                                                                        )
                                                                    )}
                                                                </Radio.Group>
                                                            ) : (
                                                                <span className="prompt-message">
                                                                    {`${t('fill-in-field-first')} 
                                                                    "${t('ed-duration')}" 
                                                                    (${t('module')} 
                                                                    "${t('opop-general-characteristics')}", 
                                                                    ${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.2'
                                                                        })}
                                                                    >
                                                                        {t('general-info')}
                                                                    </Link>
                                                                    )
                                                                </span>
                                                            )}
                                                        </Form.Item>

                                                        <Row
                                                            gutter={4}
                                                            align="bottom"
                                                            wrap={false}
                                                        >
                                                            <Col flex="68px">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'semester']}
                                                                    label="Семестр"
                                                                >
                                                                    <InputV2 number />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Divider />

                                                        <Row gutter={32}>
                                                            <Col span={16}>
                                                                <h5 className="text">{t('weeks')}</h5>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Form.Item {...restField} name={[name, 'weeks']}>
                                                                    <InputV2
                                                                        placeholder="недель"
                                                                        className="form-select"
                                                                        size="large"
                                                                        number
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={32}>
                                                            <Col span={16}>
                                                                <h5 className="text">{t('hours')}</h5>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fullPracticeVolume']}
                                                                >
                                                                    <InputV2
                                                                        placeholder="часов"
                                                                        className="form-select"
                                                                        size="large"
                                                                        number
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <h5 className="text">{t('scope')}</h5>
                                                        <Row gutter={[32, 16]}>
                                                            <Col
                                                                offset={1}
                                                                span={15}
                                                            >
                                                                <p className="text">{t('contact')}</p>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'contactWorkVolume']}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('hour')}
                                                                        className="form-select"
                                                                        size="large"
                                                                        number
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                offset={1}
                                                                span={15}
                                                            >
                                                                <p className="text">{t('other')}</p>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'otherWorkVolume']}
                                                                >
                                                                    <InputV2
                                                                        placeholder={t('hour')}
                                                                        className="form-select"
                                                                        size="large"
                                                                        number
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Divider />
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'attestationForm']}
                                                            label={t('form-attestation')}
                                                        >
                                                            <AutoComplete
                                                                className="form-select"
                                                                placeholder="Выберите форму"
                                                                size="large"
                                                                showSearch
                                                                onChange={(value) => onFormChange(value, index)}
                                                                options={xlsxParser?.getFormOfIntermediateCertification?.(importedTabDetails?.['6.1']?.type)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                            disabled={fields?.length === 1}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Row>
                                        <Col span={22}>
                                            <Form.Item>
                                                <Row align="middle" justify="space-between">
                                                    <ButtonV2
                                                        type="link"
                                                        className={b('btn-indent')}
                                                        onClick={() => add(emptyObj.tasks[0])}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        {t('add-more')}
                                                    </ButtonV2>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.List>
                    </section>
                </Form>
                <Divider />
                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                        ...calculateTabDetails(tabDetails, hoursValue),
                        ...importDocDetails(importedTabDetails, tabKey)
                    }, dispatch)}
                    onNextClick={() => onNextClick(form)}
                />
            </div>
        </div>
    );
};

export default WorkProgramCharacteristics;
