import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row
} from 'antd';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import openNotification from 'utils/notification';


const StructureModulesFormBlock = ({
    practiceCredits = 0, practiceRequiredCredits = 0, practiceFormedCredits = 0
}) => {
    const b = bem('structure-practice-form-block');
    const { t } = useTranslation('dev-educational-program');

    useEffect(() => {
        if ((typeof practiceCredits === 'number'
            && typeof practiceRequiredCredits === 'number'
            && typeof practiceFormedCredits === 'number')
            && (practiceCredits !== practiceRequiredCredits + practiceFormedCredits)) {
            openNotification('warning', t('practice-credits-correlation-warn-msg'));
        }
    }, [practiceCredits, practiceRequiredCredits, practiceFormedCredits]);

    return (
        <div className={b()}>
            <Row gutter={[32, 24]}>
                <Col flex="99px">
                    <h5 className="subtitle">
                        {t('block-2')}
                    </h5>
                </Col>
                <Col flex="1 1 625px" className="item-block-group-horizontal">
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="practiceCredits"
                                className="item-label item-label-big"
                            >
                                {t('practice')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="practiceCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="practiceRequiredCredits"
                                className="item-label item-label-big"
                            >
                                {t('mandatory-part')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="practiceRequiredCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 4]}>
                        <Col flex="1">
                            <label
                                htmlFor="practiceFormedCredits"
                                className="item-label item-label-big"
                            >
                                {t('part-formed-by-participants-in-educational-relations')}
                            </label>
                        </Col>
                        <Col flex="232px">
                            <Form.Item name="practiceFormedCredits">
                                <InputV2 number placeholder={t('enter-volume-in-cu')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default StructureModulesFormBlock;
