import React from 'react';
import bem from 'easy-bem';
import {
    Form, Col, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import InputV2 from 'components/input-v2';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';


const ActivityMainAreasFormBlock = ({ activityMainAreasEmptyObj }) => {
    const b = bem('activity-main-areas-form-block');
    const { t } = useTranslation('dev-educational-program');

    return (
        <section className={b()}>
            <h5 className="subtitle subtitle-with-desc">{`${t('block-3')}: ${t('activity-main-areas')}`}</h5>
            <p className="section-desc">{t('activity-main-areas-desc')}</p>
            <Form.List name="activityMainAreas">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className="activity-item form-item" key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="0 1 393px">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'area']}
                                            >
                                                <InputV2
                                                    placeholder={t('enter-object-knowledge-field')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(activityMainAreasEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-main-area')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default ActivityMainAreasFormBlock;
