import React from 'react';
import bem from 'easy-bem';
import { Switch, Route } from 'react-router-dom';

import OPOPContstructor from 'components/opop-constructor';
import DevEducationalProgram from 'components/opop-constructor/components/dev-educational-program';

import './style.less';


const OPOPConstructorPage = () => {
    const b = bem('opop-constructor-page');

    return (
        <div className={b()}>
            <Route>
                <Switch>
                    <Route exact path="/opop-constructor">
                        <OPOPContstructor />
                    </Route>
                    <Route exact path="/opop-constructor/:id">
                        <DevEducationalProgram />
                    </Route>
                </Switch>
            </Route>
        </div>
    );
};

export default OPOPConstructorPage;
