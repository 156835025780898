import React, { useState, useEffect, ReactNode } from 'react';
import bem from 'easy-bem';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as FileIcon } from 'assets/icons/files.svg';
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg';
import WorkProgramModal from 'components/work-program-modal';
import { getRegistryDetails } from 'models/registry-strapi/api';
import { Divider } from 'antd';
import numeralize from 'utils/numiralize';
import { ButtonV2 } from 'components';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import DocumentViewerModal from 'components/document-viewer-modal';
import detailsBg from 'assets/images/opop-registry/details-bg.png';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import './style.less';
// import _pick from 'lodash/pick';
import { setDocumentViewerVisible } from 'models/documents/actions';
import { adaptListStrapi } from 'models/registry-strapi/converters';
import { stat } from 'utils/stat';
import { adaptWorkPrograms } from './converters';
// import {
//     generateAndMergeTemplate,
//     generateTemplate,
// } from '../opop-constructor/components/dev-educational-program/utils';


interface ParamsProps {
    id: string;
}

interface OPOPContentItemProps {
    icon: ReactNode;
    title: string;
    subContent: ReactNode | string;
    onClick?: any;
    onTitleClick?: any;

}

const DownloadDockButton = ({ onClick }: { onClick?: any }) => (
    <div onClick={onClick} className="download-dock-btn">
        <DownloadFileIcon color="#909EBB" />
        <span className="download-dock-btn-text">Скачать документ</span>
    </div>
);

const getPeriodLabel = (years: number) => `${years / 12} ${numeralize(years / 12, ['год', 'года', 'лет'])}`;

const getFilesLabel = (files: number) => numeralize(files, ['файл', 'файла', 'файлов']);

const studyTypes: Record<string, string> = {
    fulltime: 'Очная',
    correspondence: 'Очно-заочная',
    parttime: 'Заочная'
};

const studyForms: Record<string, string> = {
    undergraduate: 'бакалавриата',
    specialty: 'специалитета',
    magistracy: 'магистратуры'
};

export const OPOPContentItem = React.memo(
    ({
        icon, title, subContent, onClick, onTitleClick
    }: OPOPContentItemProps) => (
        <div onClick={onClick} className="opop-content-item">
            <div className="item-icon">{icon}</div>
            <div className="item-content">
                <div className="content-header">
                    <h5 onClick={onTitleClick}>{title}</h5>
                </div>
                <div className="content-footer">{subContent}</div>
            </div>
        </div>
    )
);
const HOST = 'https://api.ep.unionepro.ru';

const OPOPDetailsStrapi = () => {
    const b = bem('opop-details');
    const dispatch = useDispatch();
    const { id } = useParams<ParamsProps>();
    const [details, setDetails] = useState<any>(null);
    const [visablePrev, setVisablePrev] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState('practice');
    const [docUrl, setDocUrl] = useState(null);
    const history = useHistory();
    // @ts-ignore
    const docViewerVisible = useSelector((state) => state.documents.docViewerVisible);
    const destinationObj = {};
    Object.assign(destinationObj, details);
    const titleLabel = `Программа ${studyForms[details?.program?.toLowerCase()]} 
    по ${details?.by ? details?.by : ''} 
    ${details?.directionCodeOrName}`;

    useEffect(() => {
        if (!id) {
            return;
        }
        getRegistryDetails(id)
            .then((res) => {
                setDetails({ id: res.data.data.id, ...res.data.data.attributes });
                setLoading(false);
            })
            .catch((err) => {
                history.replace('/opop-registry-strapi');
            });
    }, [id, dispatch, history, docViewerVisible]);

    const previewDoc = async (item: any) => {
        // const keys = Object.keys(destinationObj).filter((el) => el.includes(key));
        // let url;
        // if (keys?.length === 1) {
        //     url = await generateTemplate(keys[0], details[keys[0]]);
        // } else {
        //     url = await generateAndMergeTemplate(
        //         _pick(details, keys)
        //     );
        // }

        dispatch(setDocumentViewerVisible(`${HOST}${item.url}`));
    };


    const downloadFile = async (item: any) => {
        if (!item?.url) return;
        stat(details.id, {
            name: `Программа ${studyForms[details?.program?.toLowerCase()]} 
            по ${details?.by ? details?.by : ''} 
            ${details?.directionCodeOrName}`,
            direction: details?.directionCodeOrName,
            moduleName: item.caption?.split('.')?.[0] || ''
        });

        const element = document.createElement('a');
        element.setAttribute('href', `${HOST}${item.url}`);
        element.setAttribute('download', `${item.name}.docx`);
        document.body.appendChild(element);
        element.click();
        setTimeout(() => document.body.removeChild(element), 0);
    };
    function uniq() {
        const arr: any[] = [];
        Object.keys(destinationObj)
            .map((el) => arr.push(el[0]))
            .filter((el) => el != undefined);
        return arr.sort().filter((item, pos, ary) => !pos || item != ary[pos - 1]);
    }

    const programList = adaptWorkPrograms(details?.workPrograms?.data || []);
    const disciplineCount = programList.filter(
        (item: any) => item.type === 'discipline'
    )?.length;
    const practiceCount = programList?.length - disciplineCount;

    const openModal = (programType: string) => () => {
        setModalType(programType);
        setModalVisible(true);
    };
    const toggleModal = () => setModalVisible((v) => !v);
    const onModalClose = () => {
        dispatch(setDocumentViewerVisible(null));
    };

    return (
        <div className={b()}>
            <DocumentViewerModal
                visible={!!docViewerVisible}
                onClose={onModalClose}
                url={docViewerVisible}
            />
            <WorkProgramModal
                programs={programList}
                visible={modalVisible}
                type={modalType}
                setDocument={setDocUrl}
                onClose={toggleModal}
                title={details?.name}
                // @ts-ignore
                titleLabel={titleLabel}
                // @ts-ignore
                details={details}
            />
            <div className="details-info">
                <div className="info-nav">
                    <h2 className="info-title">Образовательная программа</h2>
                    <Link to="/opop-registry">
                        <ButtonV2
                            className="back-button"
                            icon={<ArrowRightIcon />}
                            type="link"
                        >
                            Назад
                        </ButtonV2>
                    </Link>
                </div>
                <h3 className="info-subtitle">Детальные сведения</h3>
                <div className="info-panel">
                    <div>
                        <span className="label">Наименование ОПОП</span>
                        <p className="text">
                            {titleLabel}
                        </p>
                        <span className="label">Наименование организации</span>
                        <p className="text">{details?.orgName}</p>
                        <div className="panel-row">
                            <div>
                                <span className="label">Дата утверждения</span>
                                <p className="text">
                                    {details
                                        ? dayjs(details?.updatedAt).format('DD MMMM YYYY')
                                        : ''}
                                </p>
                            </div>
                            <div>
                                <span className="label">Форма обучения</span>
                                <p className="text">
                                    {details?.studyType ? studyTypes[details?.studyType] : ''}
                                </p>
                            </div>
                            <div>
                                <span className="label">Нормативный срок обучения</span>
                                <p className="text">
                                    {details ? getPeriodLabel(details?.trainingPeriod) : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="info-bg">
                        <img alt="details img" src={detailsBg} />
                    </div>
                </div>
                <Divider />
                <h3 className="info-subtitle">Содержание ОПОП</h3>
                <div className="opop-content">
                    <OPOPContentItem
                        onClick={openModal('discipline')}
                        title="Рабочие программы дисциплин"
                        icon={<FolderIcon color="#73AAFF" />}
                        subContent={`${disciplineCount} ${getFilesLabel(disciplineCount)}`}
                    />
                    <OPOPContentItem
                        onClick={openModal('practice')}
                        title="Рабочие программы практик"
                        icon={<FolderIcon color="#73AAFF" />}
                        subContent={`${practiceCount} ${getFilesLabel(practiceCount)}`}
                    />
                    {!loading && details?.documents?.data && adaptListStrapi(details?.documents?.data)
                        .map((item: any, index: number) => (
                            <OPOPContentItem
                                key={index}
                                title={item.caption}
                                onTitleClick={() => previewDoc(item)}
                                icon={<FileIcon color="#73AAFF" />}
                                subContent={(
                                    <div onClick={() => downloadFile(item)}>
                                        <DownloadDockButton />
                                    </div>
                                )}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default OPOPDetailsStrapi;
