import React, { useEffect, useMemo, useState } from 'react';
import {
    Col, Form, Row
} from 'antd';
import Select from 'components/select';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonV2 } from 'components';
import DeleteBtn from 'components/delete-btn';
import { useSelector } from 'react-redux';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import IdkCodeAndNameSubformBlock from './idk-code-and-name-subform-block';


const DigitalTechAppProfCompTab = ({ // 1.7, блок 2
    profCompFuncEmptyObj, profCompFunc = []
}) => {
    const b = bem('digital-tech-prof-comp');
    const { t } = useTranslation('dev-educational-program');
    const { profComp } = useSelector((state) => state.documents.documentDetails?.['1.4'] || {});

    const profCompList = useMemo(
        () => (profComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list = [] }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        value: `${indicatorCode}. ${indicatorName}`,
                        label: `${indicatorCode}. ${indicatorName}`,
                    }))
            })),
        [profComp]
    );
    const [isCodeAndNameDisabled, setCodeAndNameDisabled] = useState(false);
    useEffect(() => setCodeAndNameDisabled(!profCompList?.length), [profCompList]);

    return (
        <section className={b()}>
            <h5 className="subtitle">{`${t('block-2')}: ${t('prof-comp-in-digital-technologies-application')}`}</h5>
            <Form.List name="profCompFunc">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('activity-item')} form-items-group`} key={key}>
                                    <Row
                                        gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Row>
                                                    <Col flex="1">
                                                        <Form.Item
                                                            label={t('digit-tech-comp-code-and-name')}
                                                            name={[name, 'codeAndName']}
                                                            {...handleFieldEnableStatus(
                                                                !!profCompList?.length,
                                                                <span>
                                                                    {`${t('fill-in-field-first')} 
                                                                        "${t('pc-name')}" 
                                                                        (${t('chapter')} `}
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            hash: '#1.4'
                                                                        })}
                                                                    >
                                                                        {t('educational-programs-development-results')}
                                                                    </Link>
                                                                    {`, ${t('block-3')})`}
                                                                </span>,
                                                                t('digit-tech-comp-code-and-name'),
                                                            )}
                                                            {...restField}
                                                        >
                                                            <Select
                                                                className="form-select"
                                                                size="large"
                                                                showSearch
                                                                notFoundContent={t('not-found-content-text')}
                                                                placeholder={
                                                                    t('digit-tech-select-comp-code-and-name')
                                                                }
                                                                options={profCompList}
                                                                disabled={isCodeAndNameDisabled}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <IdkCodeAndNameSubformBlock
                                                    fieldName={name}
                                                    codAndNameOfIdkEmptyObj={
                                                        profCompFuncEmptyObj.codAndNameOfIdk[0]
                                                    }
                                                    selectedProfCompCodeAndName={
                                                        profCompFunc?.[name]?.codeAndName
                                                    }
                                                    profCompList={profCompList}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.Item>
                            <ButtonV2
                                type="link"
                                onClick={() => add(profCompFuncEmptyObj)}
                                icon={<PlusOutlined />}
                            >
                                {t('add-more-block')}
                            </ButtonV2>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DigitalTechAppProfCompTab;
