/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import {
    Col,
    Divider,
    Form,
    Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DeleteBtn from 'components/delete-btn';
import bem from 'easy-bem';
import { useSelector } from 'react-redux';
import Select from 'components/select';
import { Link } from 'react-router-dom';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import expandArr from 'utils/expandArr';
import { ButtonV2, InputV2, Notification } from '../../../../../../index';
import ButtonV3 from '../../../../../../button-v3';


const DisciplineStructure = ({ structure = [], structureEmptyObj }) => { // 5.6, блок 1
    const { t } = useTranslation('dev-educational-program');
    const b = bem('work-discipline-structure-tab');

    const { results = [] } = useSelector((state) => state.documents.documentDetails?.[5.3] || {});
    const idkList = useMemo(
        () => expandArr(results || [], ['list'])
            .filter(({ codeAndNameIndicator }) => codeAndNameIndicator)
            .map(({ codeAndNameIndicator }) => ({
                value: codeAndNameIndicator,
                label: codeAndNameIndicator,
            })),
        [results]
    );
    const [isIdkDisabled, setIsIdkDisabled] = useState(false);
    useEffect(() => setIsIdkDisabled(!idkList?.length), [idkList]);

    const [isCheckFormDataBtnVisible, setIsCheckFormDataBtnVisible] = useState(false);
    useEffect(() => setIsCheckFormDataBtnVisible( // отображение кнопки проверки данных, если хотя бы одно поле в блоке на странице заполнено
        (structure || []).some(({ lecture }) => typeof lecture === 'number')
        || (structure || []).some(({ seminarHours }) => typeof seminarHours === 'number')
        || (structure || []).some(({ labHours }) => typeof labHours === 'number')
        || (structure || []).some(({ independentHours }) => typeof independentHours === 'number')
    ), [structure]);

    const {
        calculatedSizeByTypes: { lecturesHours = {}, seminarTypeStudy = {}, independentWork = {} } = {}
    } = useSelector((state) => state.documents.documentDetails?.[5.5] || {});
    const [notification, setNotification] = useState([{}]);
    const checkFormData = () => {
        const messages = [];
        const totalLecturesHours = lecturesHours?.total || 0;
        const structureLectureTotal = (structure || []).reduce(
            (prev, curr) => (prev + curr?.lecture), 0
        );
        const structureSeminarHoursTotal = (structure || []).reduce(
            (prev, curr) => (prev + curr?.seminarHours), 0
        );
        const structureLabHoursTotal = (structure || []).reduce(
            (prev, curr) => (prev + curr?.labHours), 0
        );
        const structureIndependentHoursTotal = (structure || []).reduce(
            (prev, curr) => (prev + curr?.independentHours), 0
        );
        if (+structureLectureTotal !== +totalLecturesHours) {
            messages.push({
                type: 'error',
                message: 'Количество лекций в данном разделе и разделе "Объем дисциплины (модуля)" не совпадают.'
            });
        }

        const totalSeminarTypeStudy = seminarTypeStudy?.total || 0;
        if (+structureSeminarHoursTotal + +structureLabHoursTotal !== +totalSeminarTypeStudy) {
            messages.push({
                type: 'error',
                message: 'Количество занятий семинарского типа в данном разделе и разделе "Объем дисциплины (модуля)" не совпадают.'
            });
        }

        const totalIndependentWork = independentWork?.total || 0;
        if (+structureIndependentHoursTotal !== +totalIndependentWork) {
            messages.push({
                type: 'error',
                message: 'Количество самостоятельных работ студентов в данном разделе и разделе "Объем дисциплины (модуля)" не совпадают.'
            });
        }

        if (messages?.length) {
            setNotification(messages);
            return;
        }
        setNotification([{
            type: 'success',
            message: 'Все данные совпадают'
        }]);
    };
    //  useEffect(() => setNotification([]), [lecturesHours, structure]);

    return (
        <section className={b()}>
            <h5 className="subtitle">
                Блок 1: Структура дисциплины (модуля) и объем тематических разделов по видам
                учебных занятий, индикаторы достижения формируемых компетенций,
                соотнесенные с разделами дисциплины (модуля)
            </h5>
            <Form.List name="structure">
                {(fields, { add, remove }) => (
                    <div className="form-items-list">
                        {fields.map(({
                            key, name, ...restField
                        }) => (
                            <div className={`${b('activity-item')} form-items-group`} key={key}>
                                <Row
                                    gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                    align="bottom"
                                    wrap={false}
                                >
                                    <Col flex="1">
                                        <div className="item-block-group">
                                            <Form.Item
                                                name={[name, 'moduleName']}
                                                label="Наименование раздела дисциплины (модуля)"
                                                {...restField}
                                            >
                                                <InputV2
                                                    textarea
                                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                                    placeholder="Введите наименование"
                                                />
                                            </Form.Item>

                                            <Divider />

                                            <Row align="middle" justify="space-between">
                                                <Col>
                                                    Лекции
                                                </Col>
                                                <Col flex="0 0 120px">
                                                    <Form.Item
                                                        name={[name, 'lecture']}
                                                        {...restField}
                                                    >
                                                        <InputV2 number placeholder="часов" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row align="middle">
                                                <Col>Занятия семинарского типа</Col>
                                            </Row>

                                            <Row align="middle" justify="space-between">
                                                <Col span={17} offset={2}>
                                                    <p className="item-label">
                                                        Семинары, практические занятия и др.
                                                    </p>
                                                </Col>
                                                <Col flex="0 0 120px">
                                                    <Form.Item
                                                        name={[name, 'seminarHours']}
                                                        {...restField}
                                                    >
                                                        <InputV2 number placeholder="часов" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row align="middle" justify="space-between">
                                                <Col span={17} offset={2}>
                                                    <p className="item-label">
                                                        Лабораторные работы, лабораторные практикумы
                                                    </p>
                                                </Col>
                                                <Col flex="0 0 120px">
                                                    <Form.Item
                                                        name={[name, 'labHours']}
                                                        {...restField}
                                                    >
                                                        <InputV2 number placeholder="часов" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row align="middle" justify="space-between">
                                                <Col span={19}>
                                                    СРС (самостоятельная работа студентов)
                                                </Col>
                                                <Col flex="0 0 120px">
                                                    <Form.Item
                                                        name={[name, 'independentHours']}
                                                        {...restField}
                                                    >
                                                        <InputV2 number placeholder="часов" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Divider />

                                            <Form.Item
                                                name={[name, 'idkCodes']}
                                                label="Код ИДК"
                                                {...handleFieldEnableStatus(
                                                    !!idkList?.length,
                                                    <span>
                                                        {`${t('fill-in-field-first')} 
                                                        "Код ИДК" 
                                                        (${t('chapter')} `}
                                                        <Link
                                                            to={(location) => ({ ...location, hash: '#5.3' })}
                                                        >
                                                            Планируемые результаты обучения по дисциплине (модулю), соотнесенные с планируемыми результатами освоения образовательной программы
                                                        </Link>
                                                        )
                                                    </span>,
                                                    'Код ИДК',
                                                )}
                                                {...restField}
                                            >
                                                <Select
                                                    className="form-select"
                                                    size="large"
                                                    showSearch
                                                    notFoundContent={t('not-found-content-text')}
                                                    placeholder="Выберите один или несколько кодов ИДК"
                                                    mode="multiple"
                                                    options={idkList}
                                                    disabled={isIdkDisabled}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <DeleteBtn
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Row>
                            <Col span={22}>
                                <Form.Item>
                                    <Row align="middle" justify="space-between">
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(structureEmptyObj)}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('add-more-block')}
                                        </ButtonV2>
                                        {isCheckFormDataBtnVisible && <ButtonV3 onClick={checkFormData} />}
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )}
            </Form.List>
            <Notification notifications={notification} />
        </section>
    );
};

export default DisciplineStructure;
