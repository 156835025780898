import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Radio, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useDispatch, useSelector } from 'react-redux';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import { previewDoc } from '../../utils';
import { ButtonV2, InputV2 } from '../../../../../index';
import Next from '../../../../../next';
import { emptyDocDetails } from '../../constants';


const tabKey = '6.4';
const emptyObj = emptyDocDetails[tabKey];


const WorkProgramPlace = ({ onNextClick }) => {
    const { t } = useTranslation('planned-practice-place');
    const b = bem('planned-practice-place');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const workProgram = useSelector((state) => state.documents.documentDetails?.['6.1']?.workProgram);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className="constructor-form">
            <h4 className="title m-24">{t('title')}</h4>
            <h4 className="program-title-name">
                {currentRppIndex === -1 ? '' : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
            </h4>
            <Form
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
            >
                <section>
                    <Form.Item name="practiceIncludes" label={t('practice-includes')}>
                        <Radio.Group>
                            <Radio value={t('obligatory-part')}>{t('obligatory-part')}</Radio>
                            <Radio value={t('formed-part')}>{t('formed-part')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </section>
                <Divider />

                <section>
                    <p className="item-label">{t('basic-discipline')}</p>
                    <Form.List name="disciplines">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-item`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'basicDiscipline']}
                                                >
                                                    <InputV2
                                                        placeholder={t('type-discipline')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(emptyObj.disciplines[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-discipline')}
                                    </ButtonV2>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </section>
                <Divider />

                <section>
                    <p className="item-label">{t('internship')}</p>
                    <Form.List name="internships">
                        {(fields, { add, remove }) => (
                            <div className="form-items-list">
                                {fields.map(({
                                    key, name, ...restField
                                }) => (
                                    <div className={`${b('item')} form-item`} key={key}>
                                        <Row
                                            gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                            align="bottom"
                                            wrap={false}
                                        >
                                            <Col flex="1">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'internship']}
                                                >
                                                    <InputV2
                                                        placeholder={t('type-internship')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <DeleteBtn
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(emptyObj.internships[0])}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-discipline')}
                                    </ButtonV2>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </section>
            </Form>
            <Divider />
            <Next
                onPreview={() => previewDoc(tabKey, {
                    ...emptyObj,
                    ...tabDetails,
                }, dispatch)}
                onNextClick={() => onNextClick(form)}
            />
        </div>
    );
};

export default WorkProgramPlace;
