import * as c from './constants';


export const getRegistryList = (query) => ({
    type: c.getRegistryList.tries,
    query
});

export const getCompletedRegistryAction = () => ({
    type: c.getCompletedRegistry.tries
});

export const getProgramList = (query) => ({
    type: c.getProgramList.tries,
    payload: { query }
});
