import * as c from './constants';


/** OData */

export const getDocsListByUserId = (userId) => ({
    type: c.getDocsListByUserId.tries,
    payload: userId
});

export const getDocsList = (perPage = 20, page = 1) => ({
    type: c.getDocsList.tries,
    payload: { perPage, page }
});

export const updateDocById = (id, data, rpdList = [], rppList = []) => ({
    type: c.updateDocById.tries,
    payload: {
        id, data, rpdList, rppList
    }
});

export const updateDocUsersById = (opopId, addUsers = []) => ({
    type: c.updateDocUsersById.tries,
    payload: { opopId, addUsers }
});

export const setMainUser = (opop, addUsers = [], user) => ({
    type: c.setMainUser.tries,
    payload: { opop, addUsers, user }
});

export const setCurrentRpdIndex = (index) => ({
    type: c.setCurrentRpdIndex.tries,
    payload: index
});

export const setCurrentRppIndex = (index) => ({
    type: c.setCurrentRppIndex.tries,
    payload: index
});

export const setDocLoadingStatus = (bool) => ({
    type: c.setDocLoadingStatus.tries,
    payload: bool
});

export const removeDocById = (id) => ({
    type: c.removeDocById.tries,
    payload: { id }
});

export const getDocById = (orgId) => ({
    type: c.getDocById.tries,
    payload: { id: orgId }
});

export const createAcademicPlan = (file) => ({
    type: c.createAcademicPlan.tries,
    payload: file
});

export const removeAcademicPlan = (id) => ({
    type: c.removeAcademicPlan.tries,
    payload: { id }
});

export const setAcademicPlans = (list) => ({
    type: c.setAcademicPlans.tries,
    payload: list
});

export const createDoc = (data) => ({
    type: c.createDoc.tries,
    payload: data
});

export const getDirectoryListMock = () => ({
    type: c.getDirectoryListMock.tries,
});

export const setCurrentDocDetails = (data) => ({
    type: c.setCurrentDocDetails.tries,
    payload: data
});

export const setCurrentTabDetails = (key, data) => ({
    type: c.setCurrentTabDetails.tries,
    payload: { key, data }
});

export const setRpdList = (list) => ({
    type: c.setRpdList.tries,
    payload: list
});

export const setRppList = (list) => ({
    type: c.setRppList.tries,
    payload: list
});

export const setDocumentViewerVisible = (visible) => ({
    type: c.setDocumentViewerVisible.tries,
    payload: visible
});

export const setUploadAction = (file) => ({
    type: c.setUploadStudyPlan.tries,
    payload: file
});
