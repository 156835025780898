import React, { useEffect } from 'react';
import bem from 'easy-bem';
import {
    Form, Divider
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './style.less';
import useDebouncedCallback from 'utils/use-debounce-callback';
import Next from 'components/next';
import OOCTextEditor from 'components/text-editor';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';


const tabKey = '1.6';
const emptyObj = emptyDocDetails[tabKey];


const CharacteristicOpRealizationTab = ({ onNextClick }) => {
    const b = bem('characteristic-op-realization-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || {
        ...emptyObj,
        realizationСonditions: t('characteristic-op-realization-initial-text')
            .map(
                (paragraph) => `<p class="ql-align-justify">${paragraph}</p>`
            ).join('')
    };
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    return (
        <>
            <div className={b()}>
                <div className="constructor-form">
                    <h4 className="title">{t('characteristic-op-realization')}</h4>
                    <Form
                        form={form}
                        initialValues={initialValues}
                        onValuesChange={onValuesChange}
                        layout="vertical"
                    >
                        <Form.Item
                            name="realizationСonditions"
                            label={t('characteristic-op-realization')}
                        >
                            <OOCTextEditor
                                height="570px"
                                placeholder={t('enter-characteristic-op-realization')}
                            />
                        </Form.Item>

                        <Divider />

                        <Next
                            onPreview={() => previewDoc(tabKey, {
                                ...emptyObj,
                                ...tabDetails,
                            }, dispatch)}
                            onNextClick={() => onNextClick(form)}
                        />
                    </Form>
                </div>
            </div>
        </>
    );
};

export default CharacteristicOpRealizationTab;
