import * as actions from './actions';
import * as constants from './constants';
import reducers from './reducers';
import options from './options';
import saga from './saga';


const model = {
    actions,
    constants,
    reducers,
    options,
    saga
};

export default model;
