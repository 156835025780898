// @ts-ignore
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import bem from 'easy-bem';

import './style.less';


const modulesDefault = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        [
            'align',
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
        ],
        ['clean'],
    ],
};

const formatsDefault = [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'align',
];

// @ts-ignore
const OOCTextEditor = (props) => {
    const b = bem('ooc-text-editor');
    const {
        value,
        onChange,
        theme,
        modules,
        formats,
        className,
        placeholder,
        height,
        ...rest
    } = props;

    const cn = className ? `${className} ${b()}` : b();

    const toolbar = document.querySelector('.ql-toolbar');
    // @ts-ignore
    toolbar?.querySelector('button.ql-bold')?.setAttribute('title', 'Полужирный');
    // @ts-ignore
    toolbar?.querySelector('button.ql-italic')?.setAttribute('title', 'Курсив');
    // @ts-ignore
    toolbar?.querySelector('button.ql-underline')?.setAttribute('title', 'Подчеркнутый');
    // @ts-ignore
    toolbar?.querySelector('button.ql-strike')?.setAttribute('title', 'Зачеркнутый');
    // @ts-ignore
    toolbar?.querySelector('button.ql-list[value="ordered"]')?.setAttribute('title', 'Нумерация');
    // @ts-ignore
    toolbar?.querySelector('button.ql-list[value="bullet"]')?.setAttribute('title', 'Маркеры');
    // @ts-ignore
    toolbar?.querySelector('button.ql-indent[value="-1"]')?.setAttribute('title', 'Уменьшить отступ');
    // @ts-ignore
    toolbar?.querySelector('button.ql-indent[value="+1"]')?.setAttribute('title', 'Увеличить отступ');
    // @ts-ignore
    toolbar?.querySelector('button.ql-align')?.setAttribute('title', 'Выровнять по левому краю');
    // @ts-ignore
    toolbar?.querySelector('button.ql-align[value="center"]')?.setAttribute('title', 'Выровнять по центру');
    // @ts-ignore
    toolbar?.querySelector('button.ql-align[value="right"]')?.setAttribute('title', 'Выровнять по правому краю');
    // @ts-ignore
    toolbar?.querySelector('button.ql-align[value="justify"]')?.setAttribute('title', 'Выровнять по ширине');
    // @ts-ignore
    toolbar?.querySelector('button.ql-clean')?.setAttribute('title', 'Очистить форматирование');

    return (
        <ReactQuill
            {...rest}
            style={{ height }}
            className={cn}
            placeholder={placeholder}
            theme={theme ?? 'snow'}
            value={value || ''}
            modules={modules ?? modulesDefault}
            formats={formats ?? formatsDefault}
            onChange={onChange}
        />
    );
};

export default OOCTextEditor;
