/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Radio, Checkbox, Row
} from 'antd';
import Select from 'components/select';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import DeleteBtn from 'components/delete-btn';
import bem from 'easy-bem';
import { updateTabDetails } from 'utils/dispatches';
import { ButtonV2, InputV2 } from '../../../../../index';
import Next from '../../../../../next';
import useDebouncedCallback from '../../../../../../utils/use-debounce-callback';
import { importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '7.2';
const emptyObj = emptyDocDetails[tabKey];

const stateExam = 'государственный экзамен (ГЭ)';
const finalQualifyingWork = 'защита выпускной квалификационной работы (ВКР)';

const calculateTabDetails = ({
    volumeVkr, vkrWeeks, geInclude, geWeeks
}) => ({
    certificationTestsVolumeTotal: +volumeVkr + +geInclude,
    certificationTestsWeeksTotal: +vkrWeeks + +geWeeks,
});


const AttestationGenProvisions = ({ onNextClick }) => {
    const b = bem('characteristic-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const { workProgram, title } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedTabDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1'] || {}),
        1.2: useSelector((state) => state.documents.documentDetails?.['1.2'] || {}),
    };
    const {
        activityTasks, activityAreaSphere, activityMainAreas
    } = useSelector((state) => state.documents.documentDetails?.['1.3'] || {});

    const showFirstForm = workProgram?.includes(stateExam);
    const showSecondForm = workProgram?.includes(finalQualifyingWork);

    const onFieldsChange = useDebouncedCallback((changedFields) => {
        const newTabDetails = { ...form.getFieldsValue() };
        // eslint-disable-next-line no-restricted-syntax
        for (const changedField of changedFields) {
            const { name } = changedField;
            if (name[0] === 'title') {
                if (name[2] === 'area') {
                    newTabDetails.title[name[1]].sphere = '';
                }
                if (name[2] === 'resources' && name[4] === 'taskType') {
                    newTabDetails.title[name[1]].resources[name[3]].task = newTabDetails
                        .title[name[1]].resources[name[3]].task
                        .map(({ chosenTask, ...rest }) => ({
                            chosenTask: '',
                            ...rest
                        }));
                }
            }
        }
        form.setFieldsValue(newTabDetails);
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    const areaList = useMemo(
        () => (activityAreaSphere || [])
            .filter(({ area }) => area)
            .map(({ area, spheres = [] }) => ({
                value: area,
                label: area,
                option: spheres
                    .filter(({ name }) => name)
                    .map(({ name }) => ({
                        value: name,
                        label: name,
                    }))
            })),
        [activityAreaSphere]
    );
    const [isAreaDisabled, setAreaDisabled] = useState(false);
    useEffect(() => setAreaDisabled(!areaList?.length), [areaList]);

    const sphereLists = useMemo(
        () => (title || [])
            .map(({ area }) => areaList
                .find(({ value }) => value === area)?.option || []),
        [areaList, title]
    );
    const [isSpheresDisabled, setSpheresDisabled] = useState(
        new Array(title?.length).fill(false)
    );
    useEffect(() => setSpheresDisabled(
        (title || []).map(({ area }, i) => !area || !sphereLists?.[i]?.length)
    ), [sphereLists, title]);

    const taskTypeList = useMemo(
        () => (activityTasks || [])
            .filter(({ type }) => type)
            .map(({ type, tasks = [] }) => ({
                label: type,
                value: type,
                option: tasks
                    .filter(({ name }) => name)
                    .map(({ name }) => ({
                        value: name,
                        label: name,
                    }))
            })),
        [activityTasks]
    );
    const [isTaskTypeDisabled, setTaskTypeDisabled] = useState(false);
    useEffect(() => setTaskTypeDisabled(!taskTypeList?.length), [taskTypeList]);

    const taskLists = useMemo(
        () => (title || [])
            .map(({ resources }) => resources
                .map(({ taskType }) => taskTypeList
                    .find(({ value }) => value === taskType)?.option || [])),
        [taskTypeList, title]
    );
    const [isTasksDisabled, setTasksDisabled] = useState(
        (title || []).map(({ resources }) => new Array(resources?.length).fill(false))
    );
    useEffect(() => setTasksDisabled(
        (title || [])
            .map(({ resources }, titleIndex) => resources
                .map(({ taskType }, resourcesIndex) => (
                    !taskType || !taskLists?.[titleIndex]?.[resourcesIndex]?.length
                )))
    ), [taskLists, title]);

    const objectList = useMemo(
        () => (activityMainAreas || [])
            .filter(({ area }) => area)
            .map(({ area }) => ({
                value: area,
                label: area,
            })),
        [activityMainAreas]
    );
    const [isObjectDisabled, setObjectDisabled] = useState(false);
    useEffect(() => setObjectDisabled(!objectList?.length), [objectList]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">Общие положения</h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                >
                    <section>
                        <Form.Item
                            label="ГИА включает следующие виды аттестационных испытаний"
                            name="workProgram"
                        >
                            <Checkbox.Group>
                                <Row>
                                    <Col>
                                        <Checkbox className="title2" value={stateExam}>
                                            государственный экзамен (ГЭ)
                                        </Checkbox>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Checkbox className="title2" value={finalQualifyingWork}>
                                            защита выпускной квалификационной работы (ВКР)
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        {showSecondForm && (
                            <Form.Item
                                name="type"
                                label="Защита ВКР по одной из тем, отражающих актуальную проблематику профессиональной деятельности в сфере"
                            >
                                <InputV2
                                    placeholder="Введите тему"
                                />
                            </Form.Item>
                        )}

                        {workProgram?.length
                            ? (
                                <>
                                    <Divider />
                                    <p className="title2">Объем ГИА:</p>
                                </>
                            )
                            : null}

                        {showSecondForm && (
                            <Form.Item
                                label="ВКР, включая"
                                name="workInclude"
                            >
                                <Radio.Group>
                                    <Row>
                                        <Col>
                                            <Radio
                                                className="title2"
                                                value="подготовку к защите и защиту ВКР / выполнение ВКР"
                                            >
                                                подготовку к защите и защиту ВКР / выполнение ВКР
                                            </Radio>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Radio
                                                className="title2"
                                                value="подготовку к защите и защиту ВКР"
                                            >
                                                подготовку к защите и защиту ВКР
                                            </Radio>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        )}

                        {(showSecondForm || showFirstForm) && (
                            <Row>
                                {showSecondForm && (
                                    <Col span={12}>
                                        <Form.Item
                                            label="Объем ВКР"
                                        >
                                            <Row
                                                gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="0 0 196px">
                                                    <Form.Item name="volumeVkr">
                                                        <InputV2
                                                            className="form-select"
                                                            size="large"
                                                            placeholder="зачетных единиц"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex="0 0 196px">
                                                    <Form.Item name="vkrWeeks">
                                                        <InputV2
                                                            className="form-select"
                                                            size="large"
                                                            placeholder="недель"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                )}

                                {showFirstForm && (
                                    <Col span={12}>
                                        <Form.Item
                                            label="ГЭ, включая подготовку к экзамену и сдачу экзамена"
                                        >
                                            <Row
                                                gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="0 0 196px">
                                                    <Form.Item name="geInclude">
                                                        <InputV2
                                                            className="form-select"
                                                            size="large"
                                                            placeholder="зачетных единиц"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col flex="0 0 196px">
                                                    <Form.Item name="geWeeks">
                                                        <InputV2
                                                            className="form-select"
                                                            size="large"
                                                            placeholder="недель"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </section>
                    <Divider />

                    <section>
                        <h5 className="subtitle">
                            Характеристика профессиональной деятельности выпускников
                        </h5>
                        <Form.List name="title">
                            {(fields, { add, remove }) => (
                                <div className="form-items-list">
                                    {fields.map(({
                                        key, name, ...restField
                                    }) => (
                                        <div className={`${b('activity-item')} form-items-group`} key={key}>
                                            <Row
                                                gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                align="bottom"
                                                wrap={false}
                                            >
                                                <Col flex="1">
                                                    <div className="item-block-group">
                                                        <Row gutter={32}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={t('activity-area')}
                                                                    name={[name, 'area']}
                                                                    {...handleFieldEnableStatus(
                                                                        !!areaList?.length,
                                                                        <span>
                                                                            {`${t('fill-in-field-first')} 
                                                                            "${t('activity-area')}" 
                                                                            (${t('chapter')} `}
                                                                            <Link
                                                                                to={(location) => ({
                                                                                    ...location,
                                                                                    hash: '#1.3'
                                                                                })}
                                                                            >
                                                                                {t('characteristic-prof-activity')}
                                                                            </Link>
                                                                            {`, ${t('block-1')})`}
                                                                        </span>,
                                                                        t('activity-area')
                                                                    )}
                                                                >
                                                                    <Select
                                                                        className="form-select"
                                                                        size="large"
                                                                        showSearch
                                                                        notFoundContent="Не найдено"
                                                                        placeholder="Выберите одну область из ранее введенных"
                                                                        options={areaList}
                                                                        disabled={isAreaDisabled}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={32}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Сфера профессиональной деятельности"
                                                                    name={[name, 'sphere']}
                                                                    {...handleFieldEnableStatus(
                                                                        !!sphereLists?.[name]?.length,
                                                                        <span>
                                                                            {`${t('fill-in-field-first')} 
                                                                            "${t('activity-sphere')}" 
                                                                            (${t('chapter')} `}
                                                                            <Link
                                                                                to={(location) => ({
                                                                                    ...location,
                                                                                    hash: '#1.3'
                                                                                })}
                                                                            >
                                                                                {t('characteristic-prof-activity')}
                                                                            </Link>
                                                                            {`, ${t('block-1')})`}
                                                                        </span>,
                                                                        'Сфера профессиональной деятельности'
                                                                    )}
                                                                    {...handleFieldEnableStatus(
                                                                        !!title?.[name]?.area,
                                                                        <span>
                                                                            Предварительно необходимо выбрать "Область профессиональной деятельности"
                                                                        </span>,
                                                                        'Сфера профессиональной деятельности'
                                                                    )}
                                                                >
                                                                    <Select
                                                                        className="form-select"
                                                                        size="large"
                                                                        showSearch
                                                                        notFoundContent="Не найдено"
                                                                        placeholder="Выберите одну сферу из ранее введенных"
                                                                        options={sphereLists?.[name]}
                                                                        disabled={isSpheresDisabled[name]}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Form.List name={[name, 'resources']}>
                                                            {(fields, { add, remove }) => (
                                                                <div className="form-items-list">
                                                                    {fields.map(({
                                                                        key, name: resourcesName, ...restField
                                                                    }) => (
                                                                        <Row
                                                                            gutter={{ md: 4, lg: 8 }}
                                                                            align="bottom"
                                                                            wrap={false}
                                                                        >
                                                                            <Col flex="1">
                                                                                <div className="item-block-group-2">
                                                                                    <Row gutter={32}>
                                                                                        <Col span={24}>
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                label="Типы задач профессиональной деятельности"
                                                                                                name={[resourcesName, 'taskType']}
                                                                                                {...handleFieldEnableStatus(
                                                                                                    !!taskTypeList?.length,
                                                                                                    <span>
                                                                                                        {`${t('fill-in-field-first')} 
                                                                                                    "${t('professional-activity-tasks-type')}" 
                                                                                                    (${t('chapter')} `}
                                                                                                        <Link
                                                                                                            to={(location) => ({
                                                                                                                ...location,
                                                                                                                hash: '#1.3'
                                                                                                            })}
                                                                                                        >
                                                                                                            {t('characteristic-prof-activity')}
                                                                                                        </Link>
                                                                                                        {`, ${t('block-2')})`}
                                                                                                    </span>,
                                                                                                    'Типы задач профессиональной деятельности'
                                                                                                )}
                                                                                            >
                                                                                                <Select
                                                                                                    className="form-select"
                                                                                                    size="large"
                                                                                                    showSearch
                                                                                                    notFoundContent="Не найдено"
                                                                                                    placeholder="Выберите один тип задачи из ранее введенных"
                                                                                                    options={taskTypeList}
                                                                                                    disabled={isTaskTypeDisabled}
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Form.List name={[resourcesName, 'task']}>
                                                                                        {(fields, { add, remove }) => (
                                                                                            <div className="form-items-list">
                                                                                                {fields.map(({
                                                                                                    key, name: taskName, ...restField
                                                                                                }) => (
                                                                                                    <div
                                                                                                        className={`${b('activity-item')} form-item`}
                                                                                                        key={key}
                                                                                                    >
                                                                                                        <Row
                                                                                                            gutter={{ md: 4, lg: 8 }}
                                                                                                            align="bottom"
                                                                                                            wrap={false}
                                                                                                        >
                                                                                                            <Col flex="1">
                                                                                                                <div className="item-block-group-2">
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[taskName, 'chosenTask']}
                                                                                                                        label="Задачи профессиональной деятельности"
                                                                                                                        {...handleFieldEnableStatus(
                                                                                                                            !!taskLists?.[name]?.[resourcesName]?.length,
                                                                                                                            <span>
                                                                                                                                {`${t('fill-in-field-first')} 
                                                                                                                                "${t('activity-prof-task')}" 
                                                                                                                                (${t('chapter')} `}
                                                                                                                                <Link
                                                                                                                                    to={(location) => ({
                                                                                                                                        ...location,
                                                                                                                                        hash: '#1.3'
                                                                                                                                    })}
                                                                                                                                >
                                                                                                                                    {t('characteristic-prof-activity')}
                                                                                                                                </Link>
                                                                                                                                {`, ${t('block-2')})`}
                                                                                                                            </span>,
                                                                                                                            'Задачи профессиональной деятельности'
                                                                                                                        )}
                                                                                                                        {...handleFieldEnableStatus(
                                                                                                                            !!title?.[name]?.resources?.[resourcesName]?.taskType,
                                                                                                                            <span>
                                                                                                                                Предварительно необходимо выбрать "Типы задач профессиональной деятельности"
                                                                                                                            </span>,
                                                                                                                            'Задачи профессиональной деятельности'
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <Select
                                                                                                                            className="form-select"
                                                                                                                            size="large"
                                                                                                                            showSearch
                                                                                                                            notFoundContent="Не найдено"
                                                                                                                            placeholder="Выберите одну задачу из ранее введенных"
                                                                                                                            options={taskLists?.[name]?.[resourcesName]}
                                                                                                                            disabled={isTasksDisabled?.[name]?.[resourcesName]}
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[taskName, 'objects']}
                                                                                                                        label="Объекты профессиональной деятельности или области знаний"
                                                                                                                        {...handleFieldEnableStatus(
                                                                                                                            !!objectList?.length,
                                                                                                                            <span>
                                                                                                                                {`${t('fill-in-field-first')} 
                                                                                                                            "${t('activity-main-areas-desc')}" 
                                                                                                                            (${t('chapter')} `}
                                                                                                                                <Link
                                                                                                                                    to={(location) => ({
                                                                                                                                        ...location,
                                                                                                                                        hash: '#1.3'
                                                                                                                                    })}
                                                                                                                                >
                                                                                                                                    {t('characteristic-prof-activity')}
                                                                                                                                </Link>
                                                                                                                                {`, ${t('block-3')})`}
                                                                                                                            </span>,
                                                                                                                            'Объекты профессиональной деятельности или области знаний'
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <Select
                                                                                                                            className="form-select"
                                                                                                                            size="large"
                                                                                                                            showSearch
                                                                                                                            notFoundContent="Не найдено"
                                                                                                                            mode="multiple"
                                                                                                                            placeholder="Выберите один или несколько объектов из ранее введенных"
                                                                                                                            options={objectList}
                                                                                                                            disabled={isObjectDisabled}
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <Form.Item>
                                                                                                                    <DeleteBtn
                                                                                                                        onClick={() => remove(taskName)}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <Row wrap={false}>
                                                                                                    <Col flex="1" align="right">
                                                                                                        <Form.Item>
                                                                                                            <ButtonV2
                                                                                                                type="link"
                                                                                                                onClick={() => add(emptyObj.title[0].resources[0].task[0])}
                                                                                                                icon={<PlusOutlined />}
                                                                                                            >
                                                                                                                добавить еще задачу и объекты
                                                                                                            </ButtonV2>
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                    <Col flex="33px" />
                                                                                                </Row>
                                                                                            </div>
                                                                                        )}
                                                                                    </Form.List>
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Item>
                                                                                    <DeleteBtn
                                                                                        onClick={() => remove(resourcesName)}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                    <Row wrap={false}>
                                                                        <Col flex="1" align="right">
                                                                            <Form.Item>
                                                                                <ButtonV2
                                                                                    type="link"
                                                                                    onClick={() => add(emptyObj.title[0].resources[0])}
                                                                                    icon={<PlusOutlined />}
                                                                                >
                                                                                    добавить еще тип задач
                                                                                </ButtonV2>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col flex="33px" />
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        </Form.List>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <DeleteBtn
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <ButtonV2
                                            type="link"
                                            onClick={() => add(emptyObj.title[0])}
                                            icon={<PlusOutlined />}
                                        >
                                            Добавить еще блок полей для заполнения
                                        </ButtonV2>
                                    </Form.Item>
                                </div>
                            )}
                        </Form.List>
                    </section>
                </Form>
                <Divider />
                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                        ...calculateTabDetails(tabDetails),
                        ...importDocDetails(importedTabDetails, tabKey)
                    }, dispatch)}
                    onNextClick={() => onNextClick()}
                />
            </div>
        </div>
    );
};

export default AttestationGenProvisions;
