// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Select, Tabs } from 'antd';
import {
    ModalV2, ButtonV2, InputV2, PaginationV2
} from 'components';
// import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getUserWorkPlace } from 'models/auth/selectors';
import uniqueId from 'utils/uniqueId';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { getDocsListByUserId, setMainUser, updateDocUsersById } from 'models/documents/actions';
import './style.less';
import { useDebounce } from 'react-use';
import { setMainUser2 } from 'models/auth/actions';


export interface MainDeveloperModalProps {
    onClose: () => void;
    opopDetails: any;
    users: any,
    usersClone: any,
    setUsersClone: any
}

const programsLables: Record<string, string> = {
    специалитет: 'специалитета',
    бакалавриат: 'бакалавриата',
    магистратура: 'магистратуры',
};

const { TabPane } = Tabs;


export const MainDeveloperModal = ({
    opopDetails,
    onClose,
    users,
    usersClone,
    setUsersClone
}: MainDeveloperModalProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchedValue, setSearchedValue] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    // группировка
    // const userWorkPlace = useSelector(getUserWorkPlace);
    const [showRoles, setShowRoles] = useState(false);
    const [additionalUsers, setAddUser] = useState([]);
    const [tabKey, setTabKey] = useState('all');
    const dispatch = useDispatch();
    const has = (str, query) => str.toLowerCase().search(query) !== -1;
    const roles = [
        { label: 'Разработчик ОПОП', value: 'opop-developer' },
        { label: 'Главный разработчик ОПОП', value: 'main-developer' }
    ];
    useEffect(() => {
        if (!opopDetails) {
            return;
        }
        setAddUser(opopDetails.additionalUsers || []);
    }, [opopDetails]);
    const onPageChange = (value: number, pageSize: number) => { setCurrentPage(value); setPageSize(pageSize); };

    const onReset = () => { setPageSize(20); setCurrentPage(1); };

    const toggleUser = (user) => {
        const userList = additionalUsers.some((x) => x.id === user.id)
            ? additionalUsers.filter((x) => x.id !== user.id)
            : [...additionalUsers, user];
        dispatch(updateDocUsersById(opopDetails.id, userList));
        setAddUser(userList);
    };
    const renderTitle = (details) => {
        const program = details?.data?.[1.1]?.program || '';
        const direction = details?.data?.[1.1]?.directionCodeOrName || '';

        return (
            <div className="modal-header">
                <h4 className="title">Разработчики ОПОП</h4>
                <p className="description">
                    Программа
                    {' '}
                    {programsLables[program] || ''}
                    {' '}
                    {direction}
                </p>
            </div>
        );
    };
    const onTabChange = (value) => {
        setTabKey(value);
        if (value === 'all') {
            setShowRoles(false);
            setSearchValue('');
            setUsersClone(users);
        } else {
            setShowRoles(true);
            setSearchValue('');
            setUsersClone(users?.filter((x) => additionalUsers.some((y) => y.id === x.id)));
        }
    };
    useDebounce(() => {
        if (searchValue) {
            const query = searchValue.toLowerCase();
            if (query?.length) {
                if (tabKey === 'all') {
                    setSearchedValue(users.filter((user) => has(user.lastName, query)
                    || has(user.firstName, query)));
                } else {
                    setSearchedValue(additionalUsers.filter((user) => has(user.lastName, query)
                    || has(user.firstName, query)));
                }
            }
        } else {
            setSearchedValue(users);
        }
    }, 500, [searchValue]);
    const renderList = () => {
        let list;
        if (searchValue) {
            list = [...searchedValue];
        } else if (tabKey === 'all') {
            list = [...usersClone];
        } else {
            list = [...additionalUsers];
        }
        // группировка
        // const grouped = groupBy(usersClone, (item) => item?.info?.user_info?.work?.place);
        // const fromMyCompany = Object.entries(grouped).filter(([key])=>key === userWorkPlace);

        // if(fromMyCompany?.length) {
        //   list.push({ type: "group", title: "Моя организация" });
        // list = [
        //     ...list,
        //     ...fromMyCompany.map(([, x]) => ({
        //         type: 'item',
        //         title: `${x.firstName} ${x.lastName}`,
        //         id: x._id
        //     }))
        // ];
        //   delete grouped[userWorkPlace];
        // }

        // for (const [key, value] of Object.entries(grouped)) {
        //   list.push({ type: "group", title: key == "" ? "Не заполнено" : key });
        //   list = [
        //     ...list,
        //     ...value.map((x) => ({ type: "item", title: `${x.firstName} ${x.lastName}`, id: x._id })),
        //   ];
        // }
        const changeRole = (e, user) => {
            // const opopCopy = {...opopDetails, user: id, additionalUsers: additionalUsers.filter((x) => x !== id)};
            dispatch(setMainUser2(opopDetails, additionalUsers, user));
            onClose();
        };
        return (
            <div className="user-list">
                <div className="list-header">
                    <span>ФИО</span>
                </div>
                {showRoles && (
                    <div className="list-item">
                        <span className="item-name">
                            {`${opopDetails?.user?.firstName} ${opopDetails?.user?.lastName}`}
                        </span>
                        <span className="item-name">
                            <Select
                                disabled
                                options={roles}
                                size="large"
                                defaultValue={roles[1]}
                            />
                        </span>
                        <span className="item-name">
                            <DeleteIcon color="gray" />
                        </span>
                    </div>
                )}
                {list.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item) => (
                    // <div key={item.id || uniqueId()} className={`list-item ${item.type == "group" ? 'group' : ''}`}>
                    <div key={item.id || uniqueId()} className="list-item">
                        <span className="item-name">
                            {`${item.firstName} ${item.lastName}`}
                        </span>
                        {/* {item.type != "group"  && <> */}
                        <>
                            {showRoles ? (
                                <>
                                    <span className="item-name">
                                        <Select
                                            options={roles}
                                            size="large"
                                            defaultValue={roles[0]}
                                            onChange={(e) => changeRole(e, item)}
                                        />
                                    </span>
                                    <span onClick={() => toggleUser(item)}>
                                        {additionalUsers.some((x) => x.id === item.id) ? <DeleteIcon /> : <PlusIcon />}
                                    </span>
                                </>
                            ) : (
                                <span onClick={() => toggleUser(item)}>
                                    {additionalUsers.some((x) => x.id === item.id) ? <CheckIcon /> : <PlusIcon />}
                                </span>
                            )}
                        </>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <ModalV2
            width={800}
            centered
            destroyOnClose
            visible={!!opopDetails}
            onCancel={onClose}
            title={renderTitle(opopDetails)}
            footer={(
                <ButtonV2 onClick={onClose} ghost type="primary">
                    Закрыть
                </ButtonV2>
            )}
            className="main-developer-modal"
        >
            <Tabs defaultActiveKey="all" activeKey={tabKey} onChange={onTabChange} className="page-nav">
                <TabPane tab="Добавление разработчиков" key="all" />
                <TabPane tab="Управление разработчиками" key="add-users" />
            </Tabs>
            <InputV2 className="search-input" placeholder="Начните вводить ФИО разработчика" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <PaginationV2
                total={usersClone?.length}
                showLeftTotal
                onChangePage={onPageChange}
                onReset={onReset}
                pageSize={pageSize}
                currentPage={currentPage}
            />
            {renderList()}
        </ModalV2>
    );
};

export default MainDeveloperModal;
