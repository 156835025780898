import React, { useEffect } from 'react';
import bem from 'easy-bem';
import { Divider, Form } from 'antd';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useDispatch, useSelector } from 'react-redux';
import Next from 'components/next';
import { useTranslation } from 'react-i18next';
import { previewDoc } from '../../utils';
import DisciplineCriteria from './components/discipline-criteria';
import DisciplineBlock2 from './components/discipline-block2';
import DisciplineEvaluation from './components/discipline-evaluation';
import { emptyDocDetails } from '../../constants';
import { updateTabDetails } from '../../../../../../utils/dispatches';


const tabKey = '5.9';
const emptyObj = emptyDocDetails[tabKey];


const WorkDisciplineEvaluationTab = ({ onNextClick }) => {
    const b = bem('work-discipline-logistics-tab');
    const { t } = useTranslation('dev-educational-program');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rpdList = useSelector((state) => state.documents.rpdList);
    const currentRpdIndex = useSelector((state) => state.documents.currentRpdIndex);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rpdList[currentRpdIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rpdList?.length, currentRpdIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title m-0">{t('work-discipline-evaluational')}</h4>
                <h4 className="program-title-name">
                    {currentRpdIndex === -1
                        ? ''
                        : `${t('rpd')}: ${rpdList[currentRpdIndex]?.[5.1].disciplineCodeAndName}`}
                </h4>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <DisciplineEvaluation
                        evaluationEmptyObj={emptyObj.evaluation[0]}
                    />
                    <Divider />

                    <DisciplineBlock2
                        block2EmptyObj={emptyObj.block2[0]}
                    />
                    <Divider />

                    <DisciplineCriteria
                        criteriaEmptyObj={emptyObj.criteria[0]}
                    />
                </Form>

                <Divider />

                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                    }, dispatch)}
                    onNextClick={() => onNextClick(form)}
                />
            </div>
        </div>
    );
};

export default WorkDisciplineEvaluationTab;
