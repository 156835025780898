/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col } from 'antd';
import ButtonV2 from 'components/button-v2';
import DeleteBtn from 'components/delete-btn';
import InputV2 from 'components/input-v2';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GenBlock } from 'components/generated-block/generatedBlock';
import { getDocDetails } from 'models/documents/selectors';
import { useXlsxParser } from 'utils/excel-parse';


const WorkPlannedResultsIndicatorCodeNameSubformBlock = ({ // 5.3, блок
    fieldName, listEmptyObj, selectedCodeAndName = '', codeAndName = '',
}) => {
    const b = bem('work-planned-results-tab');
    const { t } = useTranslation('dev-educational-program');
    const docDetails = useSelector(getDocDetails);
    const {
        universalComp, commonComp, profComp
    } = useSelector((state) => state.documents.documentDetails?.['1.4'] || {});
    const xlsxParser = useXlsxParser();
    const xlsxLoaded = xlsxParser?.isLoaded();

    const idkXlsxList = useMemo(() => {
        if (xlsxLoaded && codeAndName) {
            return xlsxParser?.getCompetencyAndName6(docDetails[5.1]?.disciplineCodeAndName, codeAndName) || [];
        }
        return [];
    }, [codeAndName]);

    const compList = useMemo(() => ([
        ...(universalComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        label: `${indicatorCode}. ${indicatorName}`,
                        value: `${indicatorCode}. ${indicatorName}`,
                    })),
            })),
        ...(commonComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        label: `${indicatorCode}. ${indicatorName}`,
                        value: `${indicatorCode}. ${indicatorName}`,
                    })),
            })),
        ...(profComp || [])
            .filter(({ codeName }) => codeName)
            .map(({ codeName, list }) => ({
                label: codeName,
                value: codeName,
                option: list
                    .filter(({ indicatorCode, indicatorName }) => indicatorCode && indicatorName)
                    .map(({ indicatorCode, indicatorName }) => ({
                        label: `${indicatorCode}. ${indicatorName}`,
                        value: `${indicatorCode}. ${indicatorName}`,
                    })),
            })),
    ]), [universalComp, commonComp, profComp]);

    const idkList = useMemo(
        () => ((compList || [])
            .find(({ value }) => value === selectedCodeAndName)?.option || []),
        [selectedCodeAndName, compList]
    );

    const [isIdkDisabled, setIsIdkDisabled] = useState(false);
    useEffect(() => setIsIdkDisabled(!idkList?.length), [idkList]);


    return (
        <div className={b()}>
            <Form.List name={[fieldName, 'list']}>
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={{ md: 4, lg: 8 }}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div
                                                className={`${b('activity-item')} form-items-group`}
                                            >
                                                <div className="item-block-group-4">
                                                    <Row gutter={32}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label="Код и наименование индикатора достижения компетенции (ИДК)"
                                                                name={[name, 'codeAndNameIndicator']}
                                                                {...handleFieldEnableStatus(
                                                                    xlsxLoaded ? !!idkXlsxList?.length : !!idkList?.length,
                                                                    <span>
                                                                        {`${t('fill-in-field-first')} 
                                                                        "${t('uc-achievement-indicator-сode-and-name')}", 
                                                                        "${t('cc-achievement-indicator-сode-and-name')}" 
                                                                        или 
                                                                        "${t('pc-achievement-indicator-сode-and-name')}" 
                                                                        (${t('module')} 
                                                                        "${t('opop-general-characteristics')}", 
                                                                        ${t('chapter')} `}
                                                                        <Link
                                                                            to={(location) => ({
                                                                                ...location,
                                                                                hash: '#1.4'
                                                                            })}
                                                                        >
                                                                            {t('educational-programs-development-results')}
                                                                        </Link>
                                                                        {')'}
                                                                    </span>,
                                                                    'Код и наименование индикатора достижения компетенции (ИДК)',
                                                                )}
                                                                {...handleFieldEnableStatus(
                                                                    !!selectedCodeAndName,
                                                                    <span>
                                                                        Предварительно необходимо выбрать "Код и наименование компетенции"
                                                                    </span>,
                                                                    'Код и наименование индикатора достижения компетенции (ИДК)',
                                                                )}
                                                                {...restField}
                                                            >
                                                                <Select
                                                                    className="form-select"
                                                                    size="large"
                                                                    showSearch
                                                                    notFoundContent={t('not-found-content-text')}
                                                                    placeholder="Выберите код и наименование индикатора"
                                                                    options={xlsxLoaded ? idkXlsxList : idkList}
                                                                    disabled={xlsxLoaded ? !idkXlsxList?.length : !idkList?.length}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={32}>
                                                        <Col span={24}>
                                                            <p className="item-label">
                                                                {t('result-of-knowledge')}
                                                            </p>
                                                            <GenBlock
                                                                blockFieldName={name}
                                                                blockName="knowledge"
                                                                initialValueAdd={
                                                                    listEmptyObj.knowledge[0]
                                                                }
                                                                {...restField}
                                                            >
                                                                <Form.Item
                                                                    name={[name, 'name']}
                                                                    {...restField}
                                                                >
                                                                    <InputV2 placeholder={t('toKnow-outcome')} />
                                                                </Form.Item>
                                                            </GenBlock>
                                                        </Col>

                                                    </Row>
                                                    <Row gutter={32}>
                                                        <Col span={24}>
                                                            <GenBlock
                                                                blockFieldName={name}
                                                                blockName="skill"
                                                                initialValueAdd={
                                                                    listEmptyObj.skill[0]
                                                                }
                                                                {...restField}
                                                            >
                                                                <Form.Item
                                                                    name={[name, 'name']}
                                                                    {...restField}
                                                                >
                                                                    <InputV2 placeholder={t('toBeAble-outcome')} />
                                                                </Form.Item>
                                                            </GenBlock>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={32}>
                                                        <Col span={24}>
                                                            <GenBlock
                                                                blockFieldName={name}
                                                                blockName="possession"
                                                                initialValueAdd={
                                                                    listEmptyObj.possession[0]
                                                                }
                                                                {...restField}
                                                            >
                                                                <Form.Item
                                                                    name={[name, 'name']}
                                                                    {...restField}
                                                                >
                                                                    <InputV2 placeholder={t('toPossess-outcome')} />
                                                                </Form.Item>
                                                            </GenBlock>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Row wrap={false}>
                            <Col flex="1" align="right">
                                <Form.Item>
                                    <ButtonV2
                                        type="link"
                                        onClick={() => add(listEmptyObj)}
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add-idk')}
                                    </ButtonV2>
                                </Form.Item>
                            </Col>
                            <Col flex="33px" />
                        </Row>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default WorkPlannedResultsIndicatorCodeNameSubformBlock;
