/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col, Divider, Form, Radio, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import { Link } from 'react-router-dom';
import Text from 'antd/es/typography/Text';
import useDebouncedCallback from 'utils/use-debounce-callback';
import { useDispatch, useSelector } from 'react-redux';
import expandArr from 'utils/expandArr';
import Select from 'components/select';
import handleFieldEnableStatus from 'utils/handleFieldEnabledStatus';
import DeleteBtn from 'components/delete-btn';
import { updateTabDetails } from 'utils/dispatches';
import ButtonV3 from '../../../../../button-v3';
import { ButtonV2, InputV2, Notification } from '../../../../../index';
import Next from '../../../../../next';
import { previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '6.5';
const emptyObj = emptyDocDetails[tabKey];

const defaultVolumeValues = {
    contactWork: 0,
    otherForms: 0,
    educationalWork: 0,
};

const mainStageOptions = [
    { value: 'Организационный' },
    { value: 'Прохождение практики (основной)' },
    { value: 'Отчетный' }
];

const calculateTabDetails = ({ list = [] }, tasks = []) => ({
    list: list.map((listItem, index) => {
        const newListItem = {
            ...listItem,
            practiceIndex: index + 1,
            course: tasks[index]?.course,
            semester: tasks[index]?.semester,
            structure: (listItem?.structure || []).map((structureItem, index) => ({
                ...structureItem,
                structureIndex: index + 1,
                educationalWork: +structureItem?.contactWork + +structureItem?.otherForms,
                workFormData: (structureItem?.workFormData || []).map((workFormDataItem, index) => ({
                    ...workFormDataItem,
                    workFormDataIndex: index + 1,
                    educationalWork: +workFormDataItem?.contactWork + +workFormDataItem?.otherForms,
                }))
            })),
        };

        return {
            ...newListItem,
            ...(newListItem?.structure || []).reduce(
                (structureAcc, structureItem) => {
                    const workFormDataTotal = (structureItem?.workFormData || []).reduce(
                        (workFormDataAcc, workFormDataItem) => ({
                            contactWork: workFormDataAcc.contactWork
                                + +workFormDataItem.contactWork,
                            otherForms: workFormDataAcc.otherForms
                                + +workFormDataItem.otherForms,
                            educationalWork: workFormDataAcc.educationalWork
                                + +workFormDataItem.educationalWork,
                        }), defaultVolumeValues
                    ) || defaultVolumeValues;

                    return {
                        contactWork: structureAcc.contactWork
                            + +structureItem.contactWork
                            + workFormDataTotal.contactWork,
                        otherForms: structureAcc.otherForms
                            + +structureItem.otherForms
                            + workFormDataTotal.otherForms,
                        educationalWork: structureAcc.educationalWork
                            + +structureItem.educationalWork
                            + workFormDataTotal.educationalWork,
                    };
                }, defaultVolumeValues
            ),
        };
    }),
});

const getNewList = (tasks = [], list = []) => tasks
    .map(({ course, semester, ...rest }, index) => ({
        ...rest,
        course,
        semester,
        structure: list[index]?.structure || emptyObj.list[0].structure
    }));


const WorkProgramStructure = ({ onNextClick }) => {
    const b = bem('work-structure');
    const { t } = useTranslation('work-structure');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const tabDetails = useSelector((state) => state.documents.documentDetails?.[tabKey]);
    const workProgram = useSelector((state) => state.documents.documentDetails?.['6.1']?.workProgram);
    const { list = [] } = tabDetails || {};

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const rppList = useSelector((state) => state.documents.rppList);
    const currentRppIndex = useSelector((state) => state.documents.currentRppIndex);

    const {
        tasks = []
    } = useSelector((state) => state.documents.documentDetails?.['6.2'] || {});
    useEffect(() => {
        const newList = getNewList(tasks, list);
        form.setFieldsValue({ list: newList });
        updateTabDetails(tabKey, {
            ...tabDetails,
            list: newList
        }, dispatch);
    }, [tasks]);

    const {
        generalProfCompFunc = []
    } = useSelector((state) => state.documents.documentDetails?.['1.7'] || {});
    const digitalTechnologiesList = useMemo(
        () => expandArr(generalProfCompFunc || [], [
            'codAndNameOfIdk',
            'disciplineNameBlock',
            'digitTechNameMasteredByDisciplineStudyInternship',
        ])
            .filter(({ digitTechName }) => digitTechName)
            .map(({ digitTechName }) => ({
                label: digitTechName,
                value: digitTechName,
            })),
        [generalProfCompFunc]
    );

    const {
        tasks: resultTasks = []
    } = useSelector((state) => state.documents.documentDetails?.['6.3'] || {});
    const idkList = useMemo(
        () => expandArr(resultTasks || [], ['endToEndDigitlTech'])
            .filter(({ idkCode }) => idkCode)
            .map(({ idkCode }) => ({
                value: idkCode,
                label: idkCode,
            })),
        [resultTasks]
    );

    const [notification, setNotification] = useState([]);
    const checkForm = (fieldName) => {
        const messages = [];
        const workProgramCharacteristics = {
            sumOfContactForm: +tasks[fieldName].contactWorkVolume,
            sumOfForm: +tasks[fieldName].otherWorkVolume
        };

        const workProgramStructure = list[fieldName]?.structure.reduce(
            (prev, curr) => ({
                sumOfContactForm: prev.sumOfContactForm + +curr.contactWork,
                sumOfForm: prev.sumOfForm + +curr.otherForms
            }),
            { sumOfContactForm: 0, sumOfForm: 0 }
        );

        if (workProgramCharacteristics?.sumOfContactForm === workProgramStructure?.sumOfContactForm) {
            messages.push({
                type: 'success',
                message: 'Количество часов в полях "Контактная работа" совпадают'
            });
        } else {
            messages.push({
                type: 'error',
                message: 'Количество часов, внесенных в поля "Контактная работа" данного раздела и раздела "Общая характеристика, объем и продолжительность практики" не совпадают'
            });
        }

        if (workProgramCharacteristics?.sumOfForm === workProgramStructure?.sumOfForm) {
            messages.push({
                type: 'success',
                message: 'Количество часов в полях "Иные формы работы" совпадают'
            });
        } else {
            messages.push({
                type: 'error',
                message: 'Количество часов, внесенных в поля "Иные формы работы" данного раздела и раздела "Общая характеристика, объем и продолжительность практики" не совпадают'
            });
        }

        const newArr = [...notification];
        newArr[fieldName] = messages;
        setNotification(newArr);
    };

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
            ...calculateTabDetails(all, tasks),
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => {
        const newObj = rppList[currentRppIndex]?.[tabKey] ? { ...tabDetails?.[tabKey] } : { ...emptyObj };
        form.setFieldsValue(newObj);
    }, [rppList?.length, currentRppIndex]);

    return (
        <div className={b()}>
            <div className="constructor-form">
                <h4 className="title">{t('title')}</h4>
                <h4 className="program-title-name">
                    {currentRppIndex === -1 ? '' : `${t('rpp')} ${workProgram} ${rppList[currentRppIndex][6.1]?.type}`}
                </h4>
                {!tasks?.length
                    ? (
                        <p className="text">
                            <Text type="warning">{t('attention')}</Text>
                            <span>
                                {` Если вы хотите заполнить структуру и содержание практики, которая не
                            отображена здесь, вам необходимо заполнить информацию о ней на
                            странице `}
                                <Link
                                    to={(location) => ({ ...location, hash: '#6.2' })}
                                >
                                    {t('general-characteristics')}
                                </Link>
                            </span>
                        </p>
                    )
                    : (
                        <h3>
                            Разделы (этапы) практики и виды работы обучающегося на практике, включающие контактную и иные формы работы:
                        </h3>
                    )}

                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <Form.List name="list">
                        {(fieldsL) => fieldsL.map(({ key: keyL, name: nameL, ...restFieldL }) => (
                            <section className={b('item')} key={keyL}>
                                {nameL ? <Divider /> : null}
                                <h5 className="subtitle">
                                    {`Практика. 
                                    Курс ${list[nameL]?.course || ''}, 
                                    семестр ${list[nameL]?.semester || ''}`}
                                </h5>
                                <Row align="bottom">
                                    <Col span={22}>
                                        <Form.List name={[nameL, 'structure']}>
                                            {(fields, { add, remove }) => (
                                                <div className="form-items-list">
                                                    {fields.map(
                                                        ({
                                                            key, name, ...restField
                                                        }) => (
                                                            <div className={`${b('item')} form-items-group`} key={key}>
                                                                <Row
                                                                    gutter={[{ md: 8, lg: 16, xl: 32 }, 24]}
                                                                    align="bottom"
                                                                    wrap={false}
                                                                >
                                                                    <Col flex="1">
                                                                        <div className="item-block-group">
                                                                            <Form.Item
                                                                                name={[name, 'mainStage']}
                                                                                label={t('main-stage')}
                                                                                {...restFieldL}
                                                                                {...restField}
                                                                            >
                                                                                <Select
                                                                                    className="form-select"
                                                                                    size="large"
                                                                                    placeholder={t('type-main-stage')}
                                                                                    options={mainStageOptions}
                                                                                    notFoundContent="Нет данных"
                                                                                    disabled={!mainStageOptions?.length}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name={[name, 'name']}
                                                                                label={t('name')}
                                                                                {...handleFieldEnableStatus(
                                                                                    !!digitalTechnologiesList?.length,
                                                                                    <span>
                                                                                        {`${t('fill-in-field-first-form')} 
                                                                                        ${t('name-digital-tech')} 
                                                                                        ${t('in-chapter')} `}
                                                                                        <Link
                                                                                            to={(location) => ({
                                                                                                ...location,
                                                                                                hash: '#1.7'
                                                                                            })}
                                                                                        >
                                                                                            {t('comp-in-digital-technologies-application')}
                                                                                        </Link>
                                                                                    </span>,
                                                                                    t('name')
                                                                                )}
                                                                                {...restFieldL}
                                                                                {...restField}
                                                                            >
                                                                                <Select
                                                                                    className="form-select"
                                                                                    size="large"
                                                                                    placeholder={t('choose-name')}
                                                                                    mode="multiple"
                                                                                    options={digitalTechnologiesList}
                                                                                    notFoundContent="Нет данных"
                                                                                    disabled={!digitalTechnologiesList?.length}
                                                                                />
                                                                            </Form.Item>
                                                                            <Row gutter={32}>
                                                                                <Col flex="1 0 100px">
                                                                                    <p
                                                                                        className="item-label item-label-big"
                                                                                    >
                                                                                        {t('forms')}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col flex="0 0 176px">
                                                                                    <Form.Item
                                                                                        name={[name, 'contactWork']}
                                                                                        label={t('contact-work')}
                                                                                        {...restFieldL}
                                                                                        {...restField}
                                                                                    >
                                                                                        <InputV2
                                                                                            number
                                                                                            placeholder={t('hours')}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col flex="0 0 176px">
                                                                                    <Form.Item
                                                                                        name={[name, 'otherForms']}
                                                                                        label={t('other-forms')}
                                                                                        {...restFieldL}
                                                                                        {...restField}
                                                                                    >
                                                                                        <InputV2
                                                                                            number
                                                                                            placeholder={t('hours')}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                            <Form.Item
                                                                                name={[name, 'currentForms']}
                                                                                label={t('current-forms')}
                                                                                {...restFieldL}
                                                                                {...restField}
                                                                            >
                                                                                <InputV2
                                                                                    textarea
                                                                                    autoSize={{
                                                                                        minRows: 2,
                                                                                        maxRows: 4
                                                                                    }}
                                                                                    placeholder={t('type-forms')}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name={[name, 'codeIdk']}
                                                                                label={t('code-idk')}
                                                                                {...handleFieldEnableStatus(
                                                                                    !!idkList?.length,
                                                                                    <span>
                                                                                        {`${t('fill-in-field-first-form')} 
                                                                                        ${t('codes')} 
                                                                                        ${t('in-chapter')} `}
                                                                                        <Link
                                                                                            to={(location) => ({
                                                                                                ...location,
                                                                                                hash: '#1.4'
                                                                                            })}
                                                                                        >
                                                                                            {t('educational-programs-development-results')}
                                                                                        </Link>
                                                                                    </span>,
                                                                                    t('code-idk')
                                                                                )}
                                                                                {...restFieldL}
                                                                                {...restField}
                                                                            >
                                                                                <Select
                                                                                    className="form-select"
                                                                                    size="large"
                                                                                    placeholder={t('choose-idk')}
                                                                                    mode="multiple"
                                                                                    options={[
                                                                                        ...idkList,
                                                                                    ]}
                                                                                    notFoundContent="Нет данных"
                                                                                    disabled={!idkList?.length}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name={[name, 'needAddForm']}
                                                                                label={t('need-add-form')}
                                                                                {...restFieldL}
                                                                                {...restField}
                                                                            >
                                                                                <Radio.Group>
                                                                                    <Radio value={t('dont-add')}>
                                                                                        {t('dont-add')}
                                                                                    </Radio>
                                                                                    <Radio value={t('add')}>
                                                                                        {t('add')}
                                                                                    </Radio>
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                            {list?.[nameL]?.structure?.[name]?.needAddForm === t('add') && (
                                                                                <Form.List
                                                                                    name={[name, 'workFormData']}
                                                                                >
                                                                                    {(fields, { add, remove }) => (
                                                                                        <div className="form-items-list">
                                                                                            {fields.map(
                                                                                                ({
                                                                                                    key, name, ...restField
                                                                                                }) => (
                                                                                                    <div
                                                                                                        className={`${b('item')} form-items-group`}
                                                                                                        key={key}
                                                                                                    >
                                                                                                        <Row
                                                                                                            gutter={{ md: 4, lg: 8 }}
                                                                                                            align="bottom"
                                                                                                            wrap={false}
                                                                                                        >
                                                                                                            <Col flex="1">
                                                                                                                <div className="item-block-group-2">
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[name, 'workForm']}
                                                                                                                        label="Форма работы"
                                                                                                                    >
                                                                                                                        <InputV2
                                                                                                                            textarea
                                                                                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                                                                                            placeholder="Введите форму работы"
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[name, 'name']}
                                                                                                                        label={t('name')}
                                                                                                                    >
                                                                                                                        <Select
                                                                                                                            className="form-select"
                                                                                                                            size="large"
                                                                                                                            placeholder={t('choose-name')}
                                                                                                                            mode="multiple"
                                                                                                                            options={digitalTechnologiesList}
                                                                                                                            notFoundContent="Нет данных"
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                    <Row gutter={24}>
                                                                                                                        <Col flex="1 0 100px">
                                                                                                                            <p className="item-label item-label-big">{t('forms')}</p>
                                                                                                                        </Col>
                                                                                                                        <Col flex="0 0 176px">
                                                                                                                            <Form.Item
                                                                                                                                {...restField}
                                                                                                                                name={[name, 'contactWork']}
                                                                                                                                label={t('contact-work')}
                                                                                                                            >
                                                                                                                                <InputV2
                                                                                                                                    number
                                                                                                                                    placeholder={t('hours')}
                                                                                                                                />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                        <Col flex="0 0 176px">
                                                                                                                            <Form.Item
                                                                                                                                {...restField}
                                                                                                                                name={[name, 'otherForms']}
                                                                                                                                label={t('other-forms')}
                                                                                                                            >
                                                                                                                                <InputV2
                                                                                                                                    number
                                                                                                                                    placeholder={t('hours')}
                                                                                                                                />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[name, 'currentForms']}
                                                                                                                        label={t('current-forms')}
                                                                                                                    >
                                                                                                                        <InputV2
                                                                                                                            textarea
                                                                                                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                                                                                                            placeholder={t('type-forms')}
                                                                                                                        />
                                                                                                                    </Form.Item>
                                                                                                                    <Form.Item
                                                                                                                        {...restField}
                                                                                                                        name={[name, 'codeIdk']}
                                                                                                                        label={t('code-idk')}
                                                                                                                        {...handleFieldEnableStatus(
                                                                                                                            !!idkList?.length,
                                                                                                                            <span>
                                                                                                                                {`${t('fill-in-field-first-form')} 
                                                                                                                                ${t('codes')} 
                                                                                                                                ${t('in-chapter')} `}
                                                                                                                                <Link
                                                                                                                                    to={(location) => ({ ...location, hash: '#1.4' })}
                                                                                                                                >
                                                                                                                                    {t('educational-programs-development-results')}
                                                                                                                                </Link>
                                                                                                                            </span>,
                                                                                                                            t('code-idk')
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <Select
                                                                                                                            className="form-select"
                                                                                                                            size="large"
                                                                                                                            placeholder={t('choose-idk')}
                                                                                                                            mode="multiple"
                                                                                                                            options={idkList}
                                                                                                                            notFoundContent="Нет данных"
                                                                                                                            disabled={!idkList?.length}
                                                                                                                        />
                                                                                                                    </Form.Item>

                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <Form.Item>
                                                                                                                    <DeleteBtn
                                                                                                                        onClick={() => remove(name)}
                                                                                                                        disabled={fields?.length === 1}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                            <Row>
                                                                                                <Col span={22}>
                                                                                                    <Form.Item>
                                                                                                        <Row align="middle" justify="end">
                                                                                                            <ButtonV2
                                                                                                                type="link"
                                                                                                                className={b('btn-indent')}
                                                                                                                onClick={() => add(emptyObj.list[0].structure[0].workFormData[0])}
                                                                                                                icon={<PlusOutlined />}
                                                                                                            >
                                                                                                                добавить еще форму работы
                                                                                                            </ButtonV2>
                                                                                                        </Row>
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    )}
                                                                                </Form.List>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item>
                                                                            <DeleteBtn
                                                                                onClick={() => remove(name)}
                                                                                disabled={fields?.length === 1}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                                    <Row>
                                                        <Col span={22}>
                                                            <Form.Item>
                                                                <Row align="middle" justify="space-between">
                                                                    <ButtonV2
                                                                        type="link"
                                                                        className={b('btn-indent')}
                                                                        onClick={() => add(emptyObj.list[0].structure[0])}
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        {t('add-practice')}
                                                                    </ButtonV2>
                                                                    <ButtonV3
                                                                        value="Этап заполнен. Проверить"
                                                                        onClick={() => checkForm(nameL)}
                                                                    />
                                                                </Row>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={22}>
                                                            <Notification notifications={notification?.[keyL] || []} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </Form.List>
                                    </Col>
                                </Row>
                            </section>
                        ))}
                    </Form.List>
                </Form>
                <Divider />
                <Next
                    onPreview={() => previewDoc(tabKey, {
                        ...emptyObj,
                        ...tabDetails,
                        ...calculateTabDetails(tabDetails),
                    }, dispatch)}
                    onNextClick={() => onNextClick(form)}
                />
            </div>
        </div>
    );
};

export default WorkProgramStructure;
