// @ts-nocheck
import React, {
    ReactNode, useEffect, useMemo, useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import XLSXParserContext from './Context';
import { XLSXParserProps, Parser } from './Parser';


export { Parser } from './Parser';


export const useXlsxParser = (): XLSXParserProps => React.useContext(XLSXParserContext);

export const XLSXParserProvider = ({ children }: { children?: ReactNode }) => {
    const [data, setData] = useState(null);
    const parsingData = useSelector((state) => state.documents.documentDetails?.['3.0']?.parsingData || null);
    const parser = useMemo(() => Parser(parsingData?.fileType), [parsingData]);
    const parserRef = useRef(parser);

    useEffect(() => {
        if (!data && parsingData) {
            parserRef.current = parser;
            parser?.setPagesData(parsingData);
        }
        setData(parsingData);
    }, [parsingData]);

    return (
        <XLSXParserContext.Provider value={parserRef.current}>
            {children}
        </XLSXParserContext.Provider>
    );
};
