import React from 'react';
import bem from 'easy-bem';
import { Switch, Route } from 'react-router-dom';
// import OPOPRegistry from 'components/opop-registry';
// import OPOPDetails from 'components/opop-details';
import OPOPRegistryStrapi from 'components/opop-registry-strapi';
import OPOPDetailsStrapi from 'components/opop-details-strapi';

import './style.less';


const OPOPRegistryPage = () => {
    const b = bem('opop-registry-page');

    return (
        <div className={b()}>
            <Route>
                <Switch>
                    {/* <Route exact path="/opop-registry"> */}
                    {/*    <OPOPRegistry /> */}
                    {/* </Route> */}
                    {/* <Route exact path="/opop-registry/:id"> */}
                    {/*    <OPOPDetails /> */}
                    {/* </Route> */}
                    <Route exact path="/opop-registry">
                        <OPOPRegistryStrapi />
                    </Route>
                    <Route exact path="/opop-registry/:id">
                        <OPOPDetailsStrapi />
                    </Route>
                </Switch>
            </Route>
        </div>
    );
};

export default OPOPRegistryPage;
