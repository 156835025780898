import React from 'react';
import bem from 'easy-bem';
import { Switch, Route } from 'react-router-dom';
import './style.less';
import ApprovalApplication from '../../components/approval-application';


const OPOPConstructorPage = () => {
    const b = bem('approval-application-page');

    return (
        <div className={b()}>
            <Route>
                <Switch>
                    <Route exact path="/applications">
                        <ApprovalApplication />
                    </Route>
                </Switch>
            </Route>
        </div>
    );
};

export default OPOPConstructorPage;
