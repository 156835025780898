import axios from 'axios';


const url = process.env.REACT_APP_BACKEND_SERVISE;


export const updateStatusApi = (id, newStatus, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.put(
        `${url}/list/${id}`,
        { status: newStatus },
        config
    );
};


export const getDocListApi = (status, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.get(`${url}/list?status=${status}`, config);
};

export const setCompletedApi = (opop, token) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `${token}`
        }
    };
    return axios.post(`${url}/registry-list/`, opop, config);
};


