import * as c from './constants';


export const setUserAction = (value) => ({
    type: c.setUser.tries,
    payload: value
});

export const setMainUser2 = (opop, addUsers = [], user) => ({
    type: c.setMainUser2.tries,
    payload: { opop, addUsers, user }
});
export const setUserDoc = (data) => ({
    type: c.setUserDoc.tries,
    payload: data
});
