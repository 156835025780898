import React from 'react';
import { notification } from 'antd';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-small.svg';


const openNotification = (type, description) => notification[type]({
    description,
    message: 'null',
    duration: 5,
    placement: 'bottomRight',
    className: 'dev-educational-program',
    icon: <WarningIcon />,
    closeIcon: <CloseIcon />,
});

export default openNotification;
