import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/download-file.svg';
import {
    Divider, Form, Row, Col
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateTabDetails } from 'utils/dispatches';
import { InputV2, ButtonV2 } from '../../../../../index';
import OOCTextEditor from '../../../../../text-editor';
import ButtonV3 from '../../../../../button-v3';
import useDebouncedCallback from '../../../../../../utils/use-debounce-callback';
import { generateAndDownloadDocument, importDocDetails, previewDoc } from '../../utils';
import { emptyDocDetails } from '../../constants';


const tabKey = '7.5';
const emptyObj = emptyDocDetails[tabKey];

const finalQualifyingWork = 'защита выпускной квалификационной работы (ВКР)';


const AttestationProgramFinal = () => {
    const { t } = useTranslation('final-qualifying-work');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const moduleDetails = {
        7.1: useSelector((state) => state.documents.documentDetails?.['7.1']),
        7.2: useSelector((state) => state.documents.documentDetails?.['7.2']),
        7.3: useSelector((state) => state.documents.documentDetails?.['7.3']),
        7.4: useSelector((state) => state.documents.documentDetails?.['7.4']),
        7.5: useSelector((state) => state.documents.documentDetails?.['7.5']),
    };
    const tabDetails = moduleDetails[tabKey];

    const initialValues = tabDetails || emptyObj;
    useEffect(() => updateTabDetails(tabKey, initialValues, dispatch), []);

    const importedModuleDetails = {
        1.1: useSelector((state) => state.documents.documentDetails?.['1.1']),
        1.2: useSelector((state) => state.documents.documentDetails?.['1.2']),
    };
    const importedTabDetails = {
        7.2: moduleDetails['7.2'],
    };
    const { workProgram = [] } = importedTabDetails[7.2] || {};
    const { directionCodeOrName = '' } = useSelector((state) => state.documents.documentDetails?.['1.1'] || {});

    const [isFinalQualifyingWorkChecked, setIsFinalQualifyingWorkChecked] = useState(false);

    const onValuesChange = useDebouncedCallback((changed, all) => {
        const newTabDetails = {
            ...all,
        };
        updateTabDetails(tabKey, newTabDetails, dispatch);
    }, 500);

    useEffect(() => setIsFinalQualifyingWorkChecked((workProgram || []).includes(finalQualifyingWork)), [workProgram]);


    return (
        <div className="constructor-form">
            <h4 className="title">{t('title')}</h4>
            {isFinalQualifyingWorkChecked ? (
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                >
                    <Form.Item
                        label={t('view')}
                        name="fqwForm"
                    >
                        <InputV2
                            size="large"
                            placeholder={t('vkr')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('structure')}
                        name="fqwStructureAndRequirements"
                        className="space"
                    >
                        <OOCTextEditor
                            height="170px"
                            placeholder={t('enter-structure')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('vkr-theme')}
                        name="fqwTopics"
                        className="space"
                    >
                        <OOCTextEditor
                            height="170px"
                            placeholder={t('enter-theme')}
                        />
                    </Form.Item>
                    <Divider />
                    <h4 className="title">{t('criteria')}</h4>
                    <p className="title2">{t('criteria')}</p>
                    <Form.Item
                        className="space"
                        name="fqwExcellentCriteria"
                        label={t('great')}
                    >
                        <InputV2
                            textarea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder={t('type-criteria')}
                        />
                    </Form.Item>
                    <Form.Item
                        className="space"
                        name="fqwGoodCriteria"
                        label={t('good')}
                    >
                        <InputV2
                            textarea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder={t('type-criteria')}
                        />
                    </Form.Item>
                    <Form.Item
                        className="space"
                        name="fqwSatisfactoryCriteria"
                        label={t('satisfactory')}
                    >
                        <InputV2
                            textarea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder={t('type-criteria')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="fqwUnsatisfactoryCriteria"
                        label={t('unsatisfactory')}
                        className="space"
                    >
                        <InputV2
                            textarea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder={t('type-criteria')}
                        />
                    </Form.Item>
                </Form>
            ) : (
                <h3 style={{ margin: 0 }}>
                    {`${t('emptyFormVKR')} `}
                    <Link
                        to={(location) => ({ ...location, hash: '#7.2' })}
                    >
                        {t('generalProvisions')}
                    </Link>
                </h3>
            )}
            <Divider />

            <Row gutter={[40, 24]} align="middle" justify="end">
                <Col flex="1">
                    <Form.Item>
                        <ButtonV3
                            value={t('download')}
                            icon={<FileDownloadIcon />}
                            onClick={() => generateAndDownloadDocument(
                                {
                                    ...moduleDetails,
                                    ...importedModuleDetails
                                },
                                Object.keys(moduleDetails),
                                `Программа ГИА ${directionCodeOrName.replace(/\./g, '-')}`
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item>
                        <ButtonV2
                            onClick={() => previewDoc(tabKey, {
                                ...emptyObj,
                                ...tabDetails,
                                ...importDocDetails(importedTabDetails, tabKey)
                            }, dispatch)}
                            type="link"
                        >
                            {t('preview')}
                        </ButtonV2>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default AttestationProgramFinal;
