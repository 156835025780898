import {
    call, put, takeLatest
} from 'redux-saga/effects';
import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import { serverErrorsLogger } from '../../utils/server-errors-logger';
import { updateStatus, getDockList, setCompleted } from './constants';
import { updateStatusApi, getDocListApi, setCompletedApi } from './api';


function* setStatusSaga({ id, status }) {
    const { success, failure } = updateStatus;
    try {
        const token = yield call(getUnioneToken);

        const { data } = yield call(updateStatusApi, id, status, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* getDockListSaga({ status }) {
    const { success, failure } = getDockList;
    try {
        const token = yield call(getUnioneToken);

        const { data } = yield call(getDocListApi, status, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}

function* setCompletedSaga({ opop }) {
    const { success, failure } = setCompleted;
    try {
        const token = yield call(getUnioneToken);

        const { data } = yield call(setCompletedApi, opop, token);
        yield put({ type: success, payload: data });
    } catch (e) {
        serverErrorsLogger(e);
        yield put({ type: failure, payload: { message: e.message } });
    }
}
export default function* rootSaga() {
    yield takeLatest(updateStatus.tries, setStatusSaga);
    yield takeLatest(getDockList.tries, getDockListSaga);
    yield takeLatest(setCompleted.tries, setCompletedSaga);
}
