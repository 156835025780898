import React from 'react';
import { ButtonV2 } from 'components';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';


const DownloadBtn = (props) => {
    const {
        onClick
    } = props;

    return (
        <ButtonV2
            type="link"
            className="icon-button"
            onClick={onClick}
        >
            <DownloadIcon />
        </ButtonV2>
    );
};

export default DownloadBtn;
