/* eslint-disable max-len */
import React from 'react';
import {
    Col, Form, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import DeleteBtn from 'components/delete-btn';
import { ButtonV2, InputV2 } from '../../../../../../index';


const DisciplineTechnology = ({ technologicalExpEmptyObj }) => { // 5.6, блок 7
    const b = bem('dev-educational-program');

    return (
        <section>
            <Form.List name="technologicalExp">
                {(fields, { add, remove }) => (
                    <>
                        <div className="form-items-list">
                            {fields.map(({
                                key, name, ...restField
                            }) => (
                                <div className={`${b('task-item')} form-item`} key={key}>
                                    <Row
                                        gutter={[{ sm: 16, md: 24, lg: 32 }, 24]}
                                        align="bottom"
                                        wrap={false}
                                    >
                                        <Col flex="1">
                                            <div className="item-block-group">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'eduTech']}
                                                    label="При реализации дисциплины применяются следующие образовательные технологии:"
                                                >
                                                    <InputV2
                                                        placeholder="Введите образовательные технологии"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'explanation']}
                                                    label="Пояснение использования при реализации различных видов учебной работы"
                                                >
                                                    <InputV2
                                                        textarea
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        placeholder="Введите пояснение"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                <DeleteBtn
                                                    onClick={() => remove(name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Row>
                                <Col span={22}>
                                    <Form.Item>
                                        <Row justify="start">
                                            <ButtonV2
                                                type="link"
                                                onClick={() => add(technologicalExpEmptyObj)}
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить еще образовательные технологии и пояснение
                                            </ButtonV2>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </Form.List>
        </section>
    );
};

export default DisciplineTechnology;
