import * as c from './constants';


const initialState = {
    list: [],
    pagination: {
        page: 1,
        pageCount: 1,
        pageSize: 20,
        total: 0,
    },
    programList: [],
    completedOpops: [],
    statistic: [],
    tableData: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case c.getRegistryList.success: {
            return { ...state, ...action.payload };
        }
        case c.getProgramList.success: {
            return { ...state, ...action.payload };
        }
        case c.getCompletedRegistry.success: {
            return { ...state, ...action.payload };
        }
        case c.getListStatistic.success: {
            return { ...state, ...action.payload };
        }
        case c.getTableData.success: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};

export default reducer;
