/* eslint-disable max-len */
import { getConstant } from '../../utils/constant-factory';


export const getRegistryList = getConstant('GET_REGISTRY_LIST');
export const getProgramList = getConstant('GET_PROGRAM_LIST');
export const getCompletedRegistry = getConstant('GET_COMPLETED_REGISTRY');
export const getListStatistic = getConstant('GET_LIST_STATISTIC');
export const setDownloadStatistic = getConstant('SET_DOWNLOAD_STATISTIC');
export const getTableData = getConstant('GET_TABLE_DATA');
